import { Box, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { COLOR_CAPACITY } from '../../../constants';
import { LanguageContext } from '../../../contexts/useLanguage';

export default function CapacityAnalysisLabel() {
    const { t } = useContext(LanguageContext);
    return (
        <Box
            sx={{
                position: 'absolute',
                bottom: 35,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row',
            }}
        >
            <Typography
                variant="body1"
                component={'div'}
                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 0.5 }}
                fontSize={{ xs: '11px', sm: '14px', lg: '14px' }}
                mx={'6px'}
            >
                <Box
                    sx={{
                        width: '8px',
                        height: '8px',
                        borderRadius: '50%',
                        background: COLOR_CAPACITY.from0To8,
                        mr: '1px',
                    }}
                ></Box>
                {t('dashboard:text.0to8h')}
            </Typography>
            <Typography
                variant="body1"
                component={'div'}
                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 0.5 }}
                fontSize={{ xs: '11px', sm: '14px', lg: '14px' }}
                mx={'6px'}
            >
                <Box
                    sx={{
                        width: '8px',
                        height: '8px',
                        borderRadius: '50%',
                        background: COLOR_CAPACITY.from8To16,
                        mr: '1px',
                    }}
                ></Box>
                {t('dashboard:text.8to16h')}
            </Typography>
            <Typography
                variant="body1"
                component={'div'}
                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 0.5 }}
                fontSize={{ xs: '11px', sm: '14px', lg: '14px' }}
                mx={'6px'}
            >
                <Box
                    sx={{
                        width: '8px',
                        height: '8px',
                        borderRadius: '50%',
                        background: COLOR_CAPACITY.from16To0,
                        mr: '1px',
                    }}
                ></Box>
                {t('dashboard:text.16to24h')}
            </Typography>
        </Box>
    );
}
