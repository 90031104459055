import {
    Container,
    Typography,
    Box,
    Grid,
    TextField,
    InputAdornment,
    IconButton,
    Button,
    Pagination,
    Stack,
} from '@mui/material';
import _ from 'lodash';
import React, { useContext } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import MyBreadcrumbs from '../../../components/BreadCrumbs';
import LoadingCircular from '../../../components/LoadingCircular';
import color from '../../../config/Colors';
import { LanguageContext } from '../../../contexts/useLanguage';
import { CompaniesContext } from '../../../hooks/useCompanies';
import useEmployee, { EmployeeContext } from '../../../hooks/useEmployee';
import useMedia from '../../../hooks/useMedia';
import useScrollToTop from '../../../hooks/useScrollToTop';
import IconSearch from '../components/svg/IconSearch';
import EmployeeItem from './components/EmployeeItem';

export default function EmployeesPage() {
    const { t } = useContext(LanguageContext);
    const { isMobileSM } = useMedia();
    const navigate = useNavigate();
    useScrollToTop();

    const empContext = useContext(EmployeeContext);

    const handleChangePagination = (event: React.ChangeEvent<unknown>, value: number) => {
        empContext.setState((prev) => ({ ...prev, filter: { ...prev.filter, page: value } }));
    };
    const onChangeSearch = (e: any) => {
        empContext.setState((prev) => ({
            ...prev,
            filter: { ...prev.filter, search: { ...prev.filter.search!, value: e.target.value }, page: 1 },
        }));
    };

    return (
        <EmployeeContext.Provider value={empContext}>
            <Container sx={{ padding: `0 !important` }}>
                <Box>
                    <Typography variant="h3" fontWeight={400}>
                        {t('companies:text.employees')}
                    </Typography>
                </Box>
                <Box mt={2}>
                    <MyBreadcrumbs />
                </Box>
                <Box mt={2}>
                    <Grid
                        sx={{ width: '100%' }}
                        item
                        container
                        xs={12}
                        lg={12}
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                    >
                        <Grid item xs={7} sm={5} lg={3.125}>
                            {' '}
                            <TextField
                                fullWidth
                                placeholder={t('companies:input.searchByName')}
                                onChange={_.debounce((value) => onChangeSearch(value), 500)}
                                inputProps={{
                                    maxLength: 50,
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <IconSearch />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4.5} md={3} lg={2.5}>
                            <Button fullWidth variant="contained" onClick={() => navigate(`create`)}>
                                {' '}
                                {isMobileSM ? t('action:add') : t('companies:button.addEmployee')}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                {empContext.isLoading ? (
                    <LoadingCircular />
                ) : empContext.employeePagination.data && empContext.employeePagination.data.length > 0 ? (
                    <>
                        <Box mt={4}>
                            <Grid container item gap={2}>
                                {empContext.employeePagination.data.map((item, index) => (
                                    <EmployeeItem employee={item} key={index} />
                                ))}
                            </Grid>
                        </Box>
                        <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                            <Pagination
                                count={empContext.employeePagination.totalPage}
                                page={empContext.employeePagination.page}
                                onChange={handleChangePagination}
                                variant="outlined"
                                shape="rounded"
                                sx={{ mt: 3 }}
                            />{' '}
                        </Stack>
                    </>
                ) : (
                    <Grid
                        item
                        container
                        alignItems={'center'}
                        justifyContent={'center'}
                        sx={{
                            background: color.grey100,
                            borderRadius: '10px',
                            padding: '10px 0',
                            height: 200,
                        }}
                        mt={4}
                    >
                        <Typography fontSize={20} fontWeight={500} color={'#85858A'}>
                            {t('companies:text.noDataToDisplay')}
                        </Typography>
                    </Grid>
                )}
            </Container>
        </EmployeeContext.Provider>
    );
}
