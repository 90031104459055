/* eslint-disable eqeqeq */
import { Box, Grid, Stack, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { checkIsEmpty, DoughnutChart, getPercent } from '../../../components/chart/DoughnutChart';
import color from '../../../config/Colors';
import { LIST_COLOR_PNC_CANCEL_CHART } from '../../../constants';
import { LanguageContext } from '../../../contexts/useLanguage';
import { formatNumber } from '../../../helpers/FormatCurrency';
import useMedia from '../../../hooks/useMedia';
import { ChartShowType } from '../../../models/ChartShowType';
import { ReportType } from '../../../models/ReportType';
import { StatisticItems } from '../../../models/StatisticItems';
import { GridBorderRadius } from '../styled';
import ChartTitleContent from './ChartTitleContent';
type Props = {
    chartShowType?: ChartShowType;
    reportType?: ReportType;
    statisticItems: StatisticItems;
};
export default function PCNCancellationsChart(props: Props) {
    const { t } = useContext(LanguageContext);
    const { chartShowType: type } = props;
    const { isMobileSM } = useMedia();
    return (
        <GridBorderRadius
            container
            display={'flex'}
            flexDirection="column"
            justifyContent={'space-between'}
            item
            xs={12}
            gap={2}
            mt={props.chartShowType === ChartShowType.report ? 1 : 0}
            border={
                props.chartShowType === ChartShowType.report
                    ? 'none !important'
                    : `1px solid ${color.grey200} !important`
            }
        >
            <Grid
                // p={type == ChartShowType.report && !isMobileSM ? 1 : 0}
                item
            >
                <ChartTitleContent
                    key={'5'}
                    title={t('dashboard:title.groupByPCNCancellations')}
                    desc={t('dashboard:text.numberOfParkingChargesCancelledByEitherTheClient​')}
                    buttonLabel={t('dashboard:button.viewInsight')}
                    reportType={props.reportType || undefined}
                    chartShowType={props.chartShowType || ChartShowType.dashboard}
                />
            </Grid>

            <Grid
                item
                // p={type == ChartShowType.report && !isMobileSM ? 1 : 0}
                direction={'row'}
                justifyContent={'center'}
                alignItems={type == ChartShowType.report ? 'flex-start' : 'center'}
                container
            >
                <Grid
                    height={{
                        xs: '200px',
                        md: type == ChartShowType.report && !checkIsEmpty(props.statisticItems) ? '350px' : '262px',
                    }}
                    width={{
                        xs: '200px',
                        md: type == ChartShowType.report && !checkIsEmpty(props.statisticItems) ? '350px' : '262px',
                    }}
                    item
                    xs={type == ChartShowType.report && !checkIsEmpty(props.statisticItems) ? 6 : 12}
                >
                    <DoughnutChart chartShowType={ChartShowType.report} statisticItems={props.statisticItems} />
                </Grid>
                <Grid
                    container
                    direction={'row'}
                    justifyContent={type == ChartShowType.report && !isMobileSM ? 'flex-end' : 'center'}
                    alignItems="flex-end"
                    xs={type == ChartShowType.report && !isMobileSM ? 4 : 12}
                    item
                >
                    <Stack
                        mt={type == ChartShowType.report && !isMobileSM ? 0 : 2}
                        spacing={2}
                        direction={type == ChartShowType.report && !isMobileSM ? 'column' : 'row'}
                        justifyContent="center"
                        alignItems="flex-start"
                    >
                        <>
                            {!checkIsEmpty(props.statisticItems) && (
                                <>
                                    {props.statisticItems.items.map((item, index) => {
                                        return (
                                            <Stack
                                                direction={'row'}
                                                alignItems={'center'}
                                                justifyContent={'flex-start'}
                                                key={index}
                                            >
                                                <Box
                                                    width={'8px'}
                                                    height={'8px'}
                                                    marginRight={'8px'}
                                                    sx={{
                                                        borderRadius: '50%',
                                                        background: LIST_COLOR_PNC_CANCEL_CHART[index],
                                                    }}
                                                />
                                                <Typography
                                                    variant="body1"
                                                    fontSize={{ xs: '12px', sm: '14px', lg: '14px' }}
                                                >
                                                    {item.label} {formatNumber.format(item.amount)} (
                                                    {getPercent(item.amount, props.statisticItems)}%)
                                                </Typography>
                                            </Stack>
                                        );
                                    })}
                                </>
                            )}
                        </>
                    </Stack>
                </Grid>
            </Grid>
        </GridBorderRadius>
    );
}
