import { Box, Grid, Stack, TextField, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import _ from 'lodash';
import moment from 'moment';
import { useContext } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import color from '../../../../../config/Colors';
import { LanguageContext } from '../../../../../contexts/useLanguage';
import { RecurProps } from './DayRecurring';
import RecurWithTooltip from './RecurWithTooltip';
import { PermitFromData } from '../../hooks/useUpsertPermit';

export default function WeekRecurring({ isEdit, parentForm }: RecurProps) {
    const { indexForm } = parentForm;

    const { control, setValue } = useFormContext<PermitFromData>();
    const { weekRecurringDays } = useWatch({
        control: control,
        name: `permits.${indexForm}`,
    });
    const { t } = useContext(LanguageContext);

    const daysOfWeek = [1, 2, 3, 4, 5, 6, 0];
    const isSmallMD = useMediaQuery('(max-width:1000px)');

    const setWeekRecurringDays = (val: number[]) => {
        setValue(`permits.${indexForm}.weekRecurringDays`, val);
    };

    return (
        <Grid
            direction={'row'}
            sx={{ background: color.white, borderRadius: '5px', padding: '8px !important' }}
            alignItems="center"
            container
            item
            gap={0}
            spacing={isSmallMD ? 1 : 0}
        >
            <Grid item container xs={12} sm={7} md={5} lg={4} display={'flex'} direction={'row'} alignItems={'center'}>
                <Grid item>
                    <RecurWithTooltip />
                </Grid>
                <Controller
                    control={control}
                    name={`permits.${indexForm}.permitRecurringEvery`}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            // fullWidth
                            size="small"
                            placeholder={''}
                            type={'number'}
                            onChange={(e) => {
                                const value = Number(e.target!.value);
                                const val = value < 1 ? 1 : value > 53 ? 53 : value;
                                field.onChange(val);
                            }}
                            inputProps={{ min: 1, max: 53 }}
                            disabled={isEdit}
                            sx={{
                                minWidth: '50px',
                                mx: 2,
                                '& input': {
                                    textAlign: 'center',
                                },
                            }}
                        />
                    )}
                />

                <Grid item>
                    <Typography variant="body1" component={'span'} mr={2}>
                        {t('permits:text.week(s) on')}
                    </Typography>
                </Grid>
            </Grid>

            <Grid item container xs={12} sm={5} md={7} lg={8} direction={'row'} alignItems={'center'}>
                <Grid item>
                    <Stack direction={'row'}>
                        {daysOfWeek.map((item) => {
                            const isActive = weekRecurringDays!.findIndex((day) => day === item) >= 0;
                            return (
                                <Box
                                    key={item}
                                    sx={[
                                        {
                                            width: '32px',
                                            height: '32px',
                                            borderRadius: '50%',
                                            backgroundColor: color.grey400,
                                            cursor: 'pointer',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            mr: 1,
                                            userSelect: 'none',
                                        },
                                        isActive
                                            ? {
                                                  backgroundColor: color.priText,
                                              }
                                            : {},
                                    ]}
                                    onClick={() => {
                                        if (!isEdit) {
                                            if (isActive) {
                                                if (weekRecurringDays!.length > 1) {
                                                    const dayRecur = _.cloneDeep(weekRecurringDays!).filter(
                                                        (day) => day !== item
                                                    );
                                                    setWeekRecurringDays(dayRecur.sort((objA, objB) => objA - objB));
                                                }
                                            } else {
                                                const arr = _.cloneDeep(weekRecurringDays!);
                                                //check if day is before today
                                                arr.push(item);
                                                setWeekRecurringDays(arr.sort((objA, objB) => objA - objB));
                                            }
                                        }
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        fontSize={'10px'}
                                        color={color.white}
                                        textAlign="center"
                                    >
                                        {moment.weekdaysShort(item)}
                                    </Typography>
                                </Box>
                            );
                        })}
                    </Stack>
                </Grid>
            </Grid>
        </Grid>
    );
}
