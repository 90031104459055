import { TableBody, TableCell, TableRow } from '@mui/material';
import { formatNumber } from '../../../helpers/FormatCurrency';
import { StatisticItems } from '../../../models/StatisticItems';

type Props = {
    dataPCNTypes: StatisticItems;
};

export default function TablePCNTypes(props: Props) {
    return (
        <TableBody>
            {props.dataPCNTypes.items &&
                props.dataPCNTypes.items.length > 0 &&
                props.dataPCNTypes.items.map((item, index) => {
                    return (
                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" align="center" width={'50%'} scope="row">
                                {item.label}
                            </TableCell>
                            <TableCell align="center" width={'50%'}>
                                {formatNumber.format(item.amount)}
                            </TableCell>
                        </TableRow>
                    );
                })}
        </TableBody>
    );
}
