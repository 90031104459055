import { SVGProps, forwardRef } from 'react';

const TrueVRN = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
    const { children, ...restProps } = props;
    return (
        <svg
            style={{ cursor: 'pointer' }}
            ref={ref}
            {...restProps}
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.33335 14.6668C12 14.6668 15 11.6668 15 8.00016C15 4.3335 12 1.3335 8.33335 1.3335C4.66669 1.3335 1.66669 4.3335 1.66669 8.00016C1.66669 11.6668 4.66669 14.6668 8.33335 14.6668Z"
                stroke="#009D4F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.5 7.99995L7.38667 9.88661L11.1667 6.11328"
                stroke="#009D4F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
});

export default TrueVRN;
