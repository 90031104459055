import React from 'react';

export default function LocationIcon() {
    return (
        <svg
            className="iconLocation"
            width="14"
            height="16"
            viewBox="0 0 14 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 7.025C0 7.017 0 7.008 0 7C0 3.137 3.137 0 7 0C10.863 0 14 3.137 14 7C14 7.008 14 7.017 14 7.025C13.984 12.256 8.95 15.378 7.359 15.934C7.253 15.971 7.126 16 7 16C6.874 16 6.753 15.976 6.641 15.934C5.05 15.378 0.016 12.256 0 7.025ZM7 4C8.656 4 10 5.344 10 7C10 8.656 8.656 10 7 10C5.344 10 4 8.656 4 7C4 5.344 5.344 4 7 4Z"
                // fill="#85858A"
            />
        </svg>
    );
}
