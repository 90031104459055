import { Employee } from './../models/Employee';
import { BaseHttpController } from 'ale-base-model';
import { AxiosInstance } from 'axios';
import { Account, User } from '../models/Account';
import { FilterEmployee } from '../models/BaseFilter';
export class UserController extends BaseHttpController<User> {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'User', client);
    }

    async Upsert(emp: Employee) {
        const res = await this.doPost({ path: 'employee', body: emp, config: {} })
        return res.data
    }

    async getMe(): Promise<Account> {
        const res = await this.doGet({ path: 'GetMe', config: {} });
        // res.data.label = 'Tenant'
        return res.data;
    }

    async filter(filter: FilterEmployee) {
        const res = await this.doPost({ path: 'filter', body: filter, config: {} })
        return res.data
    }

    async delete(id: string) {
        const res = await this.client.delete(`${this.serviceURL}/${this.basePath}/${id}`)
        return res.data
    }
}
// export type AccountLabel = 'Account manager' | 'Managing agent' | 'client' | 'employee' | 'Tenant'
