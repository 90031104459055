import { Box } from '@mui/system';
import React from 'react';

type Props = {
    color: string;
};

export default function ColorBox({ color }: Props) {
    return (
        <Box
            sx={{
                width: '14px',
                height: '8px',
                backgroundColor: color,
                borderRadius: '2px',
                display: 'inline-block',
                mr: '10px',
            }}
        ></Box>
    );
}
