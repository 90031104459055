import { Account } from '../../../models/Account';
import { ScopeType } from '../../../models/permits/Permit';
import { PermitScope } from '../../../models/permits/PermitScope';
import { isBronzeRole, isGoldRole, isSilverRole } from '../addEditPermit/components/UpsertPermitItem/helpers';
import { UpsertFilter } from '../addEditPermit/hooks/useUpsertPermit';

export const GetPermitScopes = (
    filter: UpsertFilter,
    isEdit: boolean,
    account: Account,
    tenantLocationId?: number
): PermitScope[] => {
    // if (isEdit) return [];

    if (isBronzeRole(account.label)) {
        return [
            // { id: 0, scopeType: ScopeType.location, scopeId: tenantLocationId ?? 0 },
            { id: 0, scopeType: ScopeType.tenant, scopeId: account.id! },
        ];
    }

    if (isSilverRole(account.label)) {
        const a = getSliverScope(filter);
        return a;
    }
    const scopes: PermitScope[] = [];

    if (isGoldRole(account.label)) {
        if (filter.companyId && !filter.locationId && !filter.shopId) {
            scopes.push({ id: 0, scopeType: ScopeType.company, scopeId: filter.companyId });
        }

        if (filter.locationId) {
            scopes.push(...getSliverScope(filter, tenantLocationId));
        }
    }
    return scopes;
};

const getSliverScope = (filter: UpsertFilter, tenantLocationId?: number): PermitScope[] => {
    const scopes: PermitScope[] = [];

    if (!filter.shopId && !filter.zoneId) {
        return [{ id: 0, scopeType: ScopeType.location, scopeId: filter.locationId ?? 0 }];
    }

    if (filter.shopId) {
        scopes.push({ id: 0, scopeType: ScopeType.tenant, scopeId: filter.shopId });
    }

    if (filter.zoneId) {
        scopes.push({ id: 0, scopeType: ScopeType.zone, scopeId: filter.zoneId });
    }

    return scopes;
};
