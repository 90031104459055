/* eslint-disable eqeqeq */
import { Button, Checkbox, FormControlLabel, Grid, Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import CheckBoxIcon from '../../assets/images/CheckBoxIcon';
import CheckedIcon from '../../assets/images/CheckedIcon';
import BaseListRender from '../../components/BaseListRender';
import ConfirmDialogLarge from '../../components/ConfirmDialogLarge';
import NoDataToDisplay from '../../components/NoDataToDisplay';
import color from '../../config/Colors';
import { LanguageContext } from '../../contexts/useLanguage';
import { formatNumber } from '../../helpers/FormatCurrency';
import { LocalStore } from '../../helpers/localStorage';
import useMedia from '../../hooks/useMedia';
import useScrollToTop from '../../hooks/useScrollToTop';
import { LinkTo } from '../login/styles';
import ManagedByUKPC from './components/ManagedByUKPC';
import PermitItem from './components/PermitItem';
import usePermits, { PermitContext } from './hooks/usePermits';
import { ParamsFilterPermit } from './hooks/usePermitsFilter';

export default function ListPermit() {
    const { t } = useContext(LanguageContext);
    const permitContext = usePermits();
    const { isMobileSM } = useMedia();
    const {
        FilterComponents,
        permitPagination,
        isLoading,
        handleChangePagination,
        onCheckedAll,
        isCheckedAll,
        deleteIds,
        stateDialogBulksDelete,
        stateDialogDelete,
        handleOpenConfirmBulksDelete,
        handleDelete,
        handleBulksDelete,
        filterState,
        deleteLoading,
    } = permitContext;
    const language = LocalStore.getValue('iHubLanguage');

    // useScrollToTop();

    const genSearchParams = () => {
        const saveParams = new URLSearchParams({});

        if (filterState.companyId) saveParams.append(ParamsFilterPermit.company, filterState.companyId.toString());
        if (filterState.locationId) saveParams.append(ParamsFilterPermit.location, filterState.locationId.toString());
        if (filterState.shopId) saveParams.append(ParamsFilterPermit.shop, filterState.shopId.toString());
        return saveParams.toString();
    };

    return (
        <PermitContext.Provider value={permitContext}>
            <ConfirmDialogLarge
                open={stateDialogDelete.open}
                title={t('action:confirm')}
                content={<div>{t('permits:text.deletingThisPermitWillAlsoDeleteThePermits')}</div>}
                onClose={stateDialogDelete.handleClose}
                isLoading={deleteLoading}
                onConfirm={handleDelete}
            />
            <ConfirmDialogLarge
                open={stateDialogBulksDelete.open}
                title={t('action:confirm')}
                content={t('permits:text.pleaseNoteAnyPermitsCreatedWithinTheLastHour')}
                onClose={stateDialogBulksDelete.handleClose}
                onConfirm={handleBulksDelete}
            />
            <>
                <Stack flex={1} direction={{ xs: 'column', sm: 'row' }} justifyContent={'space-between'}>
                    <Stack direction={'row'} gap={1.5}>
                        <Typography variant="h3" fontWeight={400}>
                            {t('permits:title.permits')}
                        </Typography>
                        {/* <ManagedByUKPC /> */}
                    </Stack>
                    <Stack direction={'row'} justifyContent="space-between" mt={{ xs: 1, sm: 0 }}>
                        <LinkTo to={`upload-permits?${genSearchParams()}`}>
                            <Button
                                variant="outlined"
                                sx={{
                                    minWidth: '145px',
                                }}
                            >
                                {t('permits:title.uploadPermits')}
                            </Button>
                        </LinkTo>
                        <LinkTo to={`add-permits?${genSearchParams()}`}>
                            <Button variant="contained" sx={{ minWidth: '145px', ml: 1 }}>
                                {t('permits:button.addPermits')}
                            </Button>
                        </LinkTo>
                    </Stack>
                </Stack>
                <Grid container item gap={{ xs: 2, sm: 2, md: 2 }} mt={2}>
                    <Grid item xs={12}>
                        {FilterComponents}
                    </Grid>
                    <Grid item flexDirection={'row'} xs={12} mt={isMobileSM ? -1 : 2}>
                        <Stack
                            flexDirection={isMobileSM ? 'column-reverse' : 'row'}
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Stack
                                flexDirection={'row'}
                                alignItems="center"
                                justifyContent={isMobileSM ? 'space-between' : ''}
                                width={isMobileSM ? '100%' : ''}
                                mt={isMobileSM ? 2 : ''}
                            >
                                <FormControlLabel
                                    sx={{ ml: 0 }}
                                    control={
                                        <Checkbox
                                            sx={{ padding: '0px', mr: '5px' }}
                                            size="small"
                                            icon={<CheckBoxIcon style={{ width: '22px', height: '22px' }} />}
                                            checkedIcon={<CheckedIcon style={{ width: '22px', height: '22px' }} />}
                                            onChange={onCheckedAll}
                                            // defaultChecked={false}
                                            checked={isCheckedAll()}
                                        />
                                    }
                                    label={
                                        <Typography
                                            variant="body1"
                                            sx={{ width: language && language === 'en' ? '70px' : '110px' }}
                                        >
                                            {t('permits:text.selectAll')}
                                        </Typography>
                                    }
                                />
                                <Button
                                    variant="errorOutlined"
                                    size="small"
                                    sx={{ padding: '4px 16px', lineHeight: 'none', minWidth: '120px' }}
                                    onClick={deleteIds.length !== 0 ? handleOpenConfirmBulksDelete : () => {}}
                                >
                                    {t('action:delete')} ({deleteIds.length})
                                </Button>
                            </Stack>
                            <Typography variant="body1" color={color.textPrimary}>
                                {t('permits:text.totalResults')}: {formatNumber.format(permitPagination?.total) || 0}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <BaseListRender
                            styleContainer={{ mt: 2 }}
                            styleList={{ gap: 2 }}
                            noDataComponent={<NoDataToDisplay text={t('companies:text.noDataToDisplay')} />}
                            paging={permitPagination}
                            renderedItem={(permit) => <PermitItem permit={permit} key={permit.id} />}
                            isLoading={isLoading}
                            setPage={handleChangePagination}
                        />
                    </Grid>
                </Grid>
            </>
        </PermitContext.Provider>
    );
}
