import { wait } from '@testing-library/user-event/dist/utils';
import { BaseHttpController } from 'ale-base-model';
import { AxiosInstance } from 'axios';
import { SelectItem } from '../components/MySelect';
import { FilterTenant, TenantsPagination } from '../models/BaseFilter';
import { LocationWithAllZone, LocationWithDetail, LocationWithZones } from '../models/Location';
import { GetPermitFilterInfo } from '../models/permits/GetPermitFilterInfo';
import { TenantWithAllocate } from '../models/tenant/Tenant';
import { AllocatedType } from '../models/tenant/AllocatedTypeWithAmount';

export class ShopController extends BaseHttpController<TenantWithAllocate> {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'Shop', client);
    }

    async filter(filter: FilterTenant): Promise<TenantsPagination> {
        const res = await this.doPost({ path: 'filter', body: filter, config: {} });
        return res.data;
    }

    async delete(id: string) {
        const res = await this.client.delete(`${this.serviceURL}/${this.basePath}/${id}`);
        return res.data;
    }

    async getAllocatedZones() {
        const res = await this.client.get(`${this.serviceURL}/${this.basePath}/getAllocatedZones`);
        return res.data;
    }

    async getShopAllocated(props: ShopAllocatedProps): Promise<AllocatedType[]> {
        const res = await this.doPost({ path: `getShopAllocated`, body: props, config: {} });
        return res.data;
    }

    async getLocationByTenant(id: number): Promise<LocationWithAllZone> {
        const res = await this.doGet({ path: `GetLocationByTenantId/${id}`, config: {} });
        return res.data;
    }

    async getShopByZoneIds(props: number[]): Promise<TenantWithAllocate[]> {
        const res = await this.doPost({ path: `getShopByZoneIds`, body: { zoneIds: props }, config: {} });
        return res.data;
    }
}

export interface TenantWithZoneAllocated {
    allocated: number;
    used: number;
}

export interface ShopAllocatedProps {
    shopId: number;
    // zoneId: number
}
