import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
// import Reaptcha from 'reaptcha';
import ReCAPTCHA from 'react-google-recaptcha';
import color from '../../../config/Colors';
import { AuthContext } from '../../../hooks/useAuth';
import { stylesLogin } from '../styles';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { appConfig } from '../../../config/AppConfig';
import { LanguageContext } from '../../../contexts/useLanguage';
import toast from 'react-hot-toast';

interface State {
    isCaptchaValid: boolean;
    errCaptcha: string;
    isDisable: boolean;
    isEmailValid: boolean;
}
const siteKey = appConfig.gateway.siteKeyCaptcha;

export default function ForgotForm() {
    const classes = stylesLogin();
    const { sendTokenToEmail } = useContext(AuthContext);
    const [state, setState] = useState<State>({
        isCaptchaValid: false,
        isEmailValid: false,
        errCaptcha: '',
        isDisable: true,
    });
    const { t } = useContext(LanguageContext);
    const RegisterSchema = Yup.object().shape({
        email: Yup.string().required(t('validation:emailIsRequired')).email(t('validation:invalidEmailFormat')),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: RegisterSchema,
        onSubmit: (values) => {
            if (!state.isCaptchaValid) {
                setState((prev) => ({ ...prev, errCaptcha: t('validation:captchaValidationFail') }));
            } else {
                setState((prev) => ({ ...prev, errCaptcha: '' }));
                setTimeout(() => {
                    sendTokenToEmail(values.email)
                        .then((res) => {
                            toast.success(t('notification:successfullyPleaseCheckEmail'));
                        })
                        .catch((err) => {
                            if (err.response.status !== 500) toast.error(t('notification:emailNotExists'));
                        });
                    setSubmitting(false);
                }, 1000);
                setState((prev) => ({ ...prev, errCaptcha: '' }));
            }
        },
    });
    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setSubmitting } = formik;

    const onChangeReCaptcha = () => {
        setState((prev) => ({ ...prev, isCaptchaValid: true, errCaptcha: '' }));
    };

    const handleValidate = (e: any) => {
        RegisterSchema.isValid({
            email: e.target.value,
        }).then((valid) => {
            if (valid === true) {
                setState((prev) => ({ ...prev, isEmailValid: true }));
            } else {
                setState((prev) => ({ ...prev, isEmailValid: false }));
            }
        });
    };

    useEffect(() => {
        if (state.isCaptchaValid === true && state.isEmailValid === true) {
            setState((prev) => ({ ...prev, isDisable: false }));
        } else {
            setState((prev) => ({ ...prev, isDisable: true }));
        }
    }, [state.isCaptchaValid, state.isEmailValid]);

    return (
        <FormikProvider value={formik}>
            <Form noValidate onSubmit={handleSubmit}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: { md: '80px 24px', lg: '55px 24px', xs: '30px 20px' },
                        transition: 'all .5s',
                        '& #outlined-required-helper-text': {
                            position: 'absolute',
                            top: '35px',
                            left: 0,
                            fontSize: '12px',
                        },
                    }}
                >
                    <Typography variant="h3">{t('loginPage:button.resetPassword')}</Typography>
                    <Typography
                        variant="body1"
                        textAlign={'center'}
                        sx={{ mt: { lg: '32px', md: '24px', xs: '16px' } }}
                    >
                        {t('loginPage:text.providerEmail')}
                    </Typography>
                    <TextField
                        id="outlined-required"
                        label={t('loginPage:text.email')}
                        placeholder={t('input:enterYourEmail')}
                        fullWidth
                        size="small"
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        onChange={(e) => {
                            formik.handleChange(e);
                            handleValidate(e);
                        }}
                        value={formik.values.email}
                        sx={{ mt: { lg: '32px', md: '24px', xs: '16px' } }}
                    />

                    <Box sx={{ textAlign: 'center', mt: { lg: '32px', md: '24px', xs: '16px' } }}>
                        <ReCAPTCHA sitekey={siteKey} onChange={onChangeReCaptcha} />
                        {state.errCaptcha ? (
                            <Typography variant="body2" color={color.danger}>
                                {state.errCaptcha}
                            </Typography>
                        ) : (
                            <Typography variant="body2" color={color.danger}></Typography>
                        )}
                    </Box>

                    <LoadingButton
                        loading={isSubmitting}
                        startIcon={<></>}
                        disabled={state.isDisable}
                        loadingPosition="start"
                        variant="contained"
                        sx={{
                            width: 250,
                            mt: { lg: '32px', md: '24px', xs: '16px' },
                        }}
                        type="submit"
                    >
                        {t('action:confirm')}
                    </LoadingButton>
                </Box>
            </Form>
        </FormikProvider>
    );
}
