import { BaseHttpController } from 'ale-base-model';
import { AxiosInstance } from 'axios';
import { SelectItem } from '../components/MySelect';
import { Account } from '../models/Account';
import { FilterLocation, Pagination } from '../models/BaseFilter';
import { Filter } from '../models/Filter';
import { LocationWithDetail } from '../models/Location';
import { GetPermitFilterInfo } from '../models/permits/GetPermitFilterInfo';
import { SendMailSupport } from '../models/SendMailSupport';
import { Support } from '../models/Support';

export class LocationController extends BaseHttpController<Account> {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'Location', client);
    }

    async getAll(filter: FilterLocation): Promise<Pagination<LocationWithDetail>> {
        const res = await this.doPost({ path: 'getAll', body: filter, config: {} });
        return res.data;
    }

    async getAllRF(filter: FilterLocation): Promise<Pagination<LocationWithDetail>> {
        const res = await this.doPost({ path: 'getAll', body: filter, config: {} });
        return res.data;
    }

    async getLocation(idLocation: number): Promise<LocationWithDetail> {
        const res = await this.client.get(`${this.serviceURL}/Location/${idLocation}`);
        return res.data;
    }

    async filter(filter: FilterLocation): Promise<Pagination<LocationWithDetail>> {
        const res = await this.doPost({ path: 'filter', body: filter, config: {} });
        return res.data;
    }

    async sendMailSupport(mailContent: SendMailSupport) {
        const res = await this.doPost({ path: 'SendMailSupport', body: mailContent, config: {} });
        return res;
    }

    async getSupportList(locationId: number): Promise<Support[]> {
        const res = await this.doGet({ path: `GetMySupport/${locationId}`, config: {} });
        const supports: Support[] = res.data;
        return supports.filter(
            (s) => s.locationRoles.length > 0 && [0, 1, 2].includes(s.locationRoles[0].locationRoleType)
        );
    }

    async getLocationWithZonePermit(filter: GetPermitFilterInfo) {
        const res = await this.doPost({ path: 'getLocationWithZonePermit', body: filter, config: {} });
        const listLocation: SelectItem[] = [];

        res.data.location.map((item: { id: any; name: any }) =>
            listLocation.push({
                value: item.id,
                label: item.name,
            })
        );

        return { location: listLocation };
    }
}
