/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { anprController, statisticPCNController } from '../../../../controllers';
import useConvertLabel from '../../../../helpers/ConvertDataLabel';
import { AverageLengthStatistic } from '../../../../models/AverageLengthStatistic';
import { CapacityAnalysis } from '../../../../models/CapacityAnalysis';
import { DailyVisitsStatistic } from '../../../../models/DailyVisitsStatistic';
import { Filter } from '../../../../models/Filter';
import { RepeatVisit } from '../../../../models/RepeatVisits';
import { StatisticItemByZone } from '../../../../models/StatisticItemByZone';
import { StatisticItem, StatisticItems } from '../../../../models/StatisticItems';
import { GrossRevenue } from '../../../../models/grossRevenue/GrossRevenue';
import { GrossRevenueByLTStatistic } from '../../../../models/grossRevenue/GrossRevenueByLT';
import { GrossRevenueByType } from '../../../../models/grossRevenue/GrossRevenueByType';

export interface ChartData {
    dailyVisitsStatistic: DailyVisitsStatistic;
    averageLengthStatistic: AverageLengthStatistic[];
    capacityAnalysis: CapacityAnalysis;
    repeatVisits: RepeatVisit[];
    groupByPNCType: StatisticItems;
    groupByPCNCancellations: StatisticItems;
    groupPCNByZone: StatisticItemByZone[];
    groupPCNByReason: StatisticItem[];
    isCameraNotWorking?: boolean;
    grossRevenueStatistic: GrossRevenue;
    grossRevenueByTypeStatistic: GrossRevenueByType;
    grossRevenueByLTStatistic: GrossRevenueByLTStatistic;
}

export default function useGetStatisticData() {
    const [chartData, setChartData] = useState<ChartData>({
        dailyVisitsStatistic: {} as any,
        averageLengthStatistic: [],
        capacityAnalysis: {} as any,
        repeatVisits: [],
        groupByPNCType: { items: [] },
        groupByPCNCancellations: { items: [] },
        groupPCNByZone: [],
        groupPCNByReason: [],
        grossRevenueStatistic: {} as any,
        grossRevenueByTypeStatistic: {} as any,
        grossRevenueByLTStatistic: {} as any,
    });

    const [averageLengthOfStay, setAverageLengthOfStay] = useState(0);
    const [loadingRepeatVisit, setLoadingRepeatVisit] = useState(true);
    const { convertAverageLength } = useConvertLabel();
    const setPartialChartData = (partial: Partial<ChartData>) => setChartData((prev) => ({ ...prev, ...partial }));

    const getDailyVisits = (filterGMT0: Filter) => {
        anprController.getDailyVisits(filterGMT0).then((res) => setPartialChartData({ dailyVisitsStatistic: res }));
    };

    const getAverageLengthOfVisits = (filterGMT0: Filter) => {
        anprController.getAverageLengthOfVisits(filterGMT0).then((res) => {
            setPartialChartData({ averageLengthStatistic: convertAverageLength(res.averageLengthStatistics) });
            setAverageLengthOfStay(res.averageLengthOfStay);
        });
    };
    const getCapacityAnalysis = (filterGMT0: Filter) => {
        anprController.getCapacityAnalysis(filterGMT0).then((res) => setPartialChartData({ capacityAnalysis: res }));
    };

    const getRepeatVisits = (filterGMT0: Filter) => {
        setLoadingRepeatVisit(true);
        anprController
            .getRepeatVisits(filterGMT0)
            .then((res) => setPartialChartData({ repeatVisits: res }))
            .finally(() => setLoadingRepeatVisit(false));
    };

    const getGroupPCNByStatus = (filterGMT0: Filter) => {
        statisticPCNController.groupPCNByStatus(filterGMT0).then((res) => setPartialChartData({ groupByPNCType: res }));
    };

    const getGroupCancelledPCNByActor = (filterGMT0: Filter) => {
        statisticPCNController
            .groupCancelledPCNByActor(filterGMT0)
            .then((res) => setPartialChartData({ groupByPCNCancellations: res }));
    };

    const getGroupPCNByZone = (filterGMT0: Filter) => {
        statisticPCNController
            .groupPCNByZone(filterGMT0)
            .then((res) => setPartialChartData({ groupPCNByZone: res.items }));
    };

    const getGroupPCNByReason = (filterGMT0: Filter) => {
        statisticPCNController
            .groupPCNByReason(filterGMT0)
            .then((res) => setPartialChartData({ groupPCNByReason: res.items }));
    };

    const getGrossRevenue = (filterGMT0: Filter) => {
        anprController.getGrossRevenue(filterGMT0).then((res) => setPartialChartData({ grossRevenueStatistic: res }));
    };

    const getGrossRevenueByLongTerm = (filterGMT0: Filter) => {
        anprController
            .getGrossRevenueByLongTerm(filterGMT0)
            .then((res) => setPartialChartData({ grossRevenueByTypeStatistic: res }));
    };

    const getGrossRevenueAmountLTs = (filterGMT0: Filter) => {
        anprController
            .getGrossRevenueAmountLTs(filterGMT0)
            .then((res) => setPartialChartData({ grossRevenueByLTStatistic: res }));
    };

    const getCameraNotWorking = (filterGMT0: Filter) => {
        anprController.IsCameraNotWorking(filterGMT0).then((res) => setPartialChartData({ isCameraNotWorking: res }));
    };

    return {
        chartData,
        averageLengthOfStay,
        loadingRepeatVisit,
        getDailyVisits,
        getAverageLengthOfVisits,
        getCapacityAnalysis,
        getRepeatVisits,
        getGroupPCNByStatus,
        getGrossRevenue,
        getGrossRevenueByLongTerm,
        getGrossRevenueAmountLTs,
        getGroupCancelledPCNByActor,
        getGroupPCNByZone,
        getGroupPCNByReason,
        getCameraNotWorking,
    };
}

export const StatisticDataContext = React.createContext<ReturnType<typeof useGetStatisticData>>({} as any);
