import React, { SVGProps } from 'react';

type Props = {} & SVGProps<SVGSVGElement>;

export default function ExplantedIcon(props: Props) {
    return (
        <svg {...props} width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.426751 0.42678L3.82323 3.82322C3.92086 3.92085 4.07915 3.92085 4.17678 3.82322L7.57317 0.42678C7.73067 0.26928 7.61917 0 7.39637 0H0.603531C0.380801 0 0.269261 0.26929 0.426751 0.42678Z"
                fill="#85858A"
            />
        </svg>
    );
}
