import React, { useContext } from 'react';
import { Line, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, registerables, ChartOptions, ChartData, Plugin } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import font from '../../config/Fonts';
import { AverageLengthStatistic } from '../../models/AverageLengthStatistic';
import { RepeatVisit } from '../../models/RepeatVisits';
import { LIST_COLOR_CHART } from '../../constants';
import { Box, Stack, Typography } from '@mui/material';
import color from '../../config/Colors';
import { LanguageContext } from '../../contexts/useLanguage';
import { StatisticItem } from '../../models/StatisticItems';
import { AnyObject } from 'chart.js/types/basic';
ChartJS.register(...registerables);

export const options: ChartOptions<'pie'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
            position: 'right' as const,
            align: 'center',
            labels: {},
        },
        datalabels: {
            display: false,
            color: 'white',
            anchor: 'end',
            align: 'start',
            padding: {
                top: 40,
                right: 20,
                left: 20,
            },
            //set font inside label
            font: {
                family: font,
                size: 12,
            },
            //set label
            formatter(value, context) {
                let total = 0;
                context.dataset.data.map((item) => {
                    if (typeof item == 'number') {
                        total += item;
                    }
                });
                return ((value / total) * 100).toFixed(1) + '%';
            },
        },
    },
    scales: {
        x: {
            grid: {
                //hide line
                drawBorder: false,
                //hide grid line
                display: false,
            },
            ticks: {
                //hide bottom label
                display: false,
            },
        },
    },
};

type Props = {
    data: StatisticItem[];
};

export default function PieChartReason(props: Props) {
    const { t } = useContext(LanguageContext);
    const checkDataExists = () => {
        let total = 0;
        props.data.map((item) => {
            total += item.amount;
        });
        return total == 0;
    };
    const data: ChartData<'pie', number[], string> = {
        labels: props.data && props.data.map((item) => item.label),
        datasets: [
            {
                data: checkDataExists() ? [1, 1, 1, 1, 1, 1, 1] : props.data && props.data.map((item) => item.amount),
                borderWidth: 0,
                backgroundColor: LIST_COLOR_CHART,
            },
        ],
    };

    return checkDataExists() ? (
        <Stack
            sx={{
                border: `1px solid #ddd`,
                width: '200px',
                height: '200px',
                margin: 'auto',
                borderRadius: '50%',
                background: color.grey600,
            }}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
        >
            <Typography variant="h5" sx={{ color: color.white }}>
                {t('companies:text.noDataToDisplay')}
            </Typography>
        </Stack>
    ) : (
        <Pie options={options} data={data} plugins={[ChartDataLabels as Plugin<'pie', AnyObject>]} />
    );
}
