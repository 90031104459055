import React from 'react';
import { Chart as ChartJS, registerables, ChartOptions, ChartData } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import { StatisticItems } from '../../models/StatisticItems';
import { LIST_COLOR_PNC_TYPE_CHART } from '../../constants';
import font from '../../config/Fonts';
import useMedia from '../../hooks/useMedia';
import { formatDate } from '../../helpers/moment';
import { formatNumber } from '../../helpers/FormatCurrency';

ChartJS.register(...registerables);
type Props = {
    statisticItems: StatisticItems;
};

export function BarChartPCNType(props: Props) {
    const { statisticItems: item } = props;
    const { isMobileSM } = useMedia();
    const options: ChartOptions<'bar'> = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top' as const,
                display: false,
            },
            title: {
                display: false,
                text: 'Chart.js Bar Chart',
            },
            datalabels: {
                display: true,
                color: 'black',
                anchor: 'end',
                align: 'top',
                font: {
                    family: font,
                    size: isMobileSM ? 8 : 10,
                },
                //format style label on top bar
                formatter(value, context) {
                    return formatNumber.format(value);
                },
            },
        },
        layout: {
            padding: {
                top: 20,
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    font: {
                        family: font,
                    },
                    //hide bottom label
                    display: false,
                },
            },
            y: {
                grid: {
                    display: false,
                },
                ticks: {
                    font: {
                        family: font,
                    },
                    //format style label
                    // callback(this, tickValue, index, ticks) {
                    //     return tickValue + ' %';
                    // },

                    //y step
                    stepSize: 50,
                },

                //y label start at 0
                beginAtZero: true,
                min: 0,
            },
        },
    };

    const data: ChartData<'bar', number[], string> = {
        labels: item.items.map((item) => item.label),
        datasets: [
            {
                data: item.items.map((item) => item.amount),
                backgroundColor: LIST_COLOR_PNC_TYPE_CHART,
            },
        ],
    };
    return <Bar options={options} data={data} plugins={[ChartDataLabels]} />;
}
