import { useContext } from 'react';
import { AllocatedPermitType } from '../../../../../models/tenant/AllocatedTypeWithAmount';
import { LanguageContext } from '../../../../../contexts/useLanguage';

export default function useTenant() {
    const { t } = useContext(LanguageContext);

    const getLabelAllocatedTypeName = (allocatedType: AllocatedPermitType) => {
        if (allocatedType === AllocatedPermitType.Once) return t('permits:text.oneDayPermit');
        if (allocatedType === AllocatedPermitType.Dynamic) return t('permits:text.dynamic');
        if (allocatedType === AllocatedPermitType.Indefinite) return t('permits:text.indefinite');
        if (allocatedType === AllocatedPermitType.Temporary) return t('permits:text.temporary');

        return '';
    };

    return { getLabelAllocatedTypeName };
}
