/* eslint-disable react-hooks/exhaustive-deps */
import {
    Accordion,
    AccordionDetails,
    CircularProgress,
    Grid,
    InputAdornment,
    Stack,
    TextField,
    Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import _ from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Controller, UseFormReturn, useFormContext, useWatch } from 'react-hook-form';
import LabelWithRequired from '../../../../../components/LabelWithRequired';
import useAutoComplete2 from '../../../../../components/useAutoComplete2';
import { AuthContext } from '../../../../../hooks/useAuth';
import useMedia from '../../../../../hooks/useMedia';
import { Role } from '../../../../../models/permission/Role';
import { Permit, ValidateToType } from '../../../../../models/permits/Permit';
import { RecurringType } from '../../../../../models/permits/PermitRecurring';
import { PermitFromData, UpsertPermitContext } from '../../hooks/useUpsertPermit';
import { FormArrayControl, FormControl, PermitFormItem } from '../UpsertPermitItems';
import DayRecurring from '../recurringType/DayRecurring';
import MonthRecurring from '../recurringType/MonthRecurring';
import WeekRecurring from '../recurringType/WeekRecurring';
import UpsertPermitComment from './components/UpsertPermitComment';
import UpsertPermitItemDateRange from './components/UpsertPermitItemDateRange';
import UpsertPermitItemSuggestion from './components/UpsertPermitItemSuggestion';
import UpsertPermitItemTimeDuration from './components/UpsertPermitItemTimeDuration';
import UpsertPermitItemTitle from './components/UpsertPermitItemTitle';
import UpsertPermitItemTitleEdit from './components/UpsertPermitItemTitleEdit';
import useUpsertPermitItem, { UpsertPermitItemContext } from './hooks/useUpsertPermitItem';
import { SelectItem } from '../../../../../components/MySelect';
import color from '../../../../../config/Colors';
import DynamicSubRecurringItem from './components/DynamicSubRecurringItem';
import UpsertPermitItemTemporary from './components/UpsertPermitItemTemporary';
import { permitController } from '../../../../../controllers';
import WarningVRN from '../svg/WarningVRN';
import TrueVRN from '../svg/TrueVRN';
import { VRN, statusVRN } from '../../../../../models/permits/VRN';
import TooltipTrueVRN from './components/TooltipTrueVRN';
import { fontInputAddPermits } from '../../../../../config/Fonts';

export type FormItemProps = {
    // permit: Permit;
    permitForm: PermitFormItem;
    fieldsForm: FormArrayControl;
    indexForm: number;
    // formControl: FormControl;
};

export const BAY_INFO_LIMIT_CHARACTER = 40;

export default function UpsertPermitItemForm(props: FormItemProps) {
    const { indexForm } = props;

    const [isEnplaned, setEnplaned] = useState<boolean>(true);
    const [isFocused, setIsFocused] = useState(false);
    const [checkVRN, setCheckVRN] = useState(statusVRN.none);
    const [loadingVRN, setLoadingVRN] = useState(false);
    const [VRN, setVRN] = useState<VRN>({} as VRN);

    const { t, isEdit, permitEdit, itemSchema } = useContext(UpsertPermitContext);
    const upsertPermitItemData = useUpsertPermitItem({ permit: props.permitForm });
    const { account } = useContext(AuthContext);
    const { isMobileSM } = useMedia();

    const isDisableEditBayInfo = !account?.isEditBayInfo && [Role.Tenant].includes(account.label);

    const listRecur: SelectItem[] = [
        {
            value: RecurringType.once,
            label: t('permits:text.oneDayPermit'),
            description: t('permits:text.schedulePermitsForOneDayOnly'),
        },
        {
            value: RecurringType.dynamic,
            label: t('permits:text.dynamic'),
            description: t('permits:text.schedulePermitsDailyWeeklyMonthlySpecificTimeAllDay'),
        },
        {
            value: RecurringType.temporary,
            label: t('permits:text.temporary'),
            description: t('permits:text.permitDurationValidUpToOneMonthFromDateStart'),
        },
        {
            value: RecurringType.indefinite,
            label: t('permits:text.indefinite'),
            description: t('permits:text.schedulePermitsToRecurIndefinitelyAtAllTimes'),
        },
    ];

    const {
        watch,
        control,
        setValue,
        formState: { isValid, errors },
    } = useFormContext<PermitFromData>();

    const permit = useWatch({
        control: control,
        name: `permits.${indexForm}`,
    });

    const { firstName, lastName, vrnNumber, bayNumber, permitRecurringType, subPermitRecurringType } = permit;

    const { append, remove, fields } = props.fieldsForm;

    // useEffect(() => {
    //     if (permitEdit) {
    //         reset(permitEdit);
    //     }
    // }, [permitEdit]);

    useEffect(() => {
        if (!isEdit) {
            setValue(`permits.${indexForm}.bayNumber`, account.bayInfo ?? '');
        }
    }, [isDisableEditBayInfo, isEdit]);

    const handleSetSuggest = (string: string) => {
        setValue(`permits.${indexForm}.type`, string);
    };

    const getRecurTypeFromSelect = (permitRecurringType: RecurringType) => {
        return listRecur.find((item) => item.value === permitRecurringType);
    };

    const recurSelect = useAutoComplete2({
        key: props.permitForm.id,
        label: <LabelWithRequired label={t('permits:text.recurringType')} />,
        listData: listRecur,
        value: getRecurTypeFromSelect(permitRecurringType!),
        disabled: isEdit,
        setFilter: (val) => {
            setValue(`permits.${indexForm}.permitRecurringType`, val);
            handleResetValWhenChangeRecurType(val);
        },
    });

    const handleResetValWhenChangeRecurType = (permitRecurringType: RecurringType) => {
        const validateObj = { shouldTouch: true, shouldValidate: true };
        if (!isEdit) {
            setValue(`permits.${indexForm}.permitRecurringEvery`, 1);
            setValue(`permits.${indexForm}.validateTo`, new Date(2999, 1, 1), validateObj);
            if (permitRecurringType === RecurringType.indefinite) {
                setValue(`permits.${indexForm}.isCheckedAllDay`, true);
                setValue(`permits.${indexForm}.fromHour`, startOfDay(new Date()), validateObj);
                setValue(`permits.${indexForm}.toHour`, endOfDay(new Date()), validateObj);
                setValue(`permits.${indexForm}.validateToType`, ValidateToType.infinite);
            } else if (permitRecurringType === RecurringType.temporary) {
                setValue(`permits.${indexForm}.isCheckedAllDay`, true);
                setValue(`permits.${indexForm}.fromHour`, startOfDay(new Date()), validateObj);
                setValue(`permits.${indexForm}.toHour`, endOfDay(new Date()), validateObj);
                setValue(`permits.${indexForm}.validateToType`, ValidateToType.absoluteAt);
                setValue(`permits.${indexForm}.validateFrom`, startOfDay(new Date()), validateObj);
                setValue(`permits.${indexForm}.validateTo`, null, validateObj);
            } else {
                setValue(`permits.${indexForm}.isCheckedAllDay`, false);
                setValue(`permits.${indexForm}.validateToType`, ValidateToType.recurringTime);
                setValue(`permits.${indexForm}.fromHour`, null, validateObj);
                setValue(`permits.${indexForm}.toHour`, null, validateObj);
            }
        } else {
            //change recurring time
            if (permitEdit?.permitRecurring[0].recurringType! === permitRecurringType) {
                setValue(`permits.${indexForm}.permitRecurringEvery`, permitEdit?.permitRecurring[0].recurringType!);
            } else {
                setValue(`permits.${indexForm}.permitRecurringEvery`, 1);
            }
            //change data
            if (permitRecurringType === RecurringType.indefinite) {
                setValue(`permits.${indexForm}.fromHour`, startOfDay(new Date()), validateObj);
                setValue(`permits.${indexForm}.toHour`, endOfDay(new Date()), validateObj);
                setValue(`permits.${indexForm}.validateFrom`, new Date(), validateObj);
                setValue(`permits.${indexForm}.validateTo`, new Date(2999, 1, 1), validateObj);
                setValue(`permits.${indexForm}.validateToType`, ValidateToType.infinite);
                setValue(`permits.${indexForm}.isCheckedAllDay`, false);
            } else {
                setValue(`permits.${indexForm}.fromHour`, null, validateObj);
                setValue(`permits.${indexForm}.toHour`, null, validateObj);
                setValue(`permits.${indexForm}.validateFrom`, new Date(), validateObj);
                setValue(`permits.${indexForm}.validateTo`, new Date(2999, 1, 1), validateObj);
                setValue(`permits.${indexForm}.validateToType`, ValidateToType.infinite);
                setValue(`permits.${indexForm}.isCheckedAllDay`, false);
            }
        }
    };

    // useEffect(() => {
    //     handleResetValWhenChangeRecurType(permitRecurringType!);
    // }, [permitRecurringType]);

    const handleDuplicatePermit = () => {
        if (fields.length < 10) {
            const permitWithoutId = { ...permit, id: _.uniqueId() };
            append(permitWithoutId as any);
        }
    };

    const handleRemovePermit = () => {
        if (fields.length > 1) {
            remove(indexForm);
        }
    };

    const [isFormItemValid, setIsFormItemValid] = useState(false);

    useEffect(() => {
        itemSchema
            .validate(permit)
            .then(() => {
                setIsFormItemValid(true);
            })
            .catch(() => {
                setIsFormItemValid(false);
            });
    }, [permit]);

    const debounceOnChangeVRN = useCallback(
        _.debounce((value) => handleCheckVRN(value), 1500),
        []
    );
    const handleCheckVRN = async (e: any) => {
        const VRN = e.target.value;
        if (VRN) {
            setLoadingVRN(true);
            await permitController
                .lookup(VRN)
                .then((res) => {
                    setCheckVRN(statusVRN.true);
                    setVRN(res);
                })
                .catch(() => {
                    setCheckVRN(statusVRN.notFound);
                })
                .finally(() => {
                    setLoadingVRN(false);
                });
        }
    };

    return (
        // <FormikProvider value={formik}>

        <UpsertPermitItemContext.Provider value={upsertPermitItemData}>
            <Grid item>
                <Stack flex={1} mt={1}>
                    <MyAccordion disableGutters elevation={0} expanded={isEnplaned}>
                        {isEdit ? (
                            <UpsertPermitItemTitleEdit permit={props.permitForm} isValid={true} />
                        ) : (
                            <UpsertPermitItemTitle
                                permit={props.permitForm}
                                onCollapse={() => setEnplaned(!isEnplaned)}
                                isValid={isFormItemValid}
                                onDuplicate={handleDuplicatePermit}
                                onRemove={handleRemovePermit}
                                currentIndex={indexForm}
                            />
                        )}
                        <AccordionDetails>
                            <Grid
                                container
                                sx={{
                                    '& .MuiFormHelperText-root': {
                                        position: 'absolute',
                                        top: '35px',
                                        left: '-10px',
                                        fontSize: '12px',
                                    },
                                }}
                                // p={2}
                            >
                                <Grid
                                    item
                                    container
                                    spacing={Boolean(errors.permits?.[indexForm]?.vrnNumber) && isMobileSM ? 3 : 2}
                                >
                                    <Grid item xs={isMobileSM ? 12 : 4}>
                                        <Controller
                                            control={control}
                                            name={`permits.${indexForm}.vrnNumber`}
                                            render={({ field, fieldState: { error } }) => (
                                                <TextField
                                                    {...field}
                                                    sx={{
                                                        '& .MuiInputBase-root': {
                                                            fontFamily: fontInputAddPermits,
                                                        },
                                                    }}
                                                    error={!!error?.message}
                                                    helperText={error?.message}
                                                    fullWidth
                                                    size="small"
                                                    label={<LabelWithRequired label={t('permits:text.VRN')} />}
                                                    placeholder={t('permits:text.enterVRN')}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                        // debounceOnChangeVRN(e);
                                                        !e.target.value && setCheckVRN(statusVRN.none);
                                                    }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                {loadingVRN ? (
                                                                    <CircularProgress size={12} />
                                                                ) : checkVRN == statusVRN.notFound ? (
                                                                    <Tooltip
                                                                        title={t('permits:text.thisVrnIsNotValid')}
                                                                        placement="top"
                                                                    >
                                                                        <WarningVRN />
                                                                    </Tooltip>
                                                                ) : checkVRN == statusVRN.true ? (
                                                                    <Tooltip
                                                                        arrow
                                                                        title={<TooltipTrueVRN VRN={VRN} />}
                                                                        placement="top"
                                                                    >
                                                                        <TrueVRN />
                                                                    </Tooltip>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    inputProps={{
                                                        maxLength: 20,
                                                    }}
                                                    disabled={isEdit}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={isMobileSM ? 12 : 4}>
                                        <Controller
                                            control={control}
                                            name={`permits.${indexForm}.firstName`}
                                            render={({ field, fieldState: { error } }) => (
                                                <TextField
                                                    {...field}
                                                    error={!!error?.message}
                                                    helperText={error?.message}
                                                    fullWidth
                                                    size="small"
                                                    label={t('accountPage:text.firstName')}
                                                    placeholder={t('input:enterFirstName')}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                    inputProps={{
                                                        maxLength: 50,
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={isMobileSM ? 12 : 4}>
                                        <Controller
                                            control={control}
                                            name={`permits.${indexForm}.lastName`}
                                            render={({ field, fieldState: { error } }) => (
                                                <TextField
                                                    {...field}
                                                    error={!!error?.message}
                                                    helperText={error?.message}
                                                    fullWidth
                                                    size="small"
                                                    label={t('accountPage:text.lastName')}
                                                    placeholder={t('input:enterLastName')}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                    inputProps={{
                                                        maxLength: 50,
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item container spacing={2} mt={1}>
                                    <Grid item xs={8}>
                                        <Controller
                                            control={control}
                                            name={`permits.${indexForm}.type`}
                                            render={({ field, fieldState: { error } }) => (
                                                <TextField
                                                    {...field}
                                                    error={!!error?.message}
                                                    helperText={error?.message}
                                                    fullWidth
                                                    size="small"
                                                    label={t('permits:text.permitType')}
                                                    placeholder={t('input:enterPermitType')}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                    inputProps={{
                                                        maxLength: 100,
                                                    }}
                                                    sx={{
                                                        '& .MuiFormHelperText-root': {
                                                            top: '55px !important',
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                        <UpsertPermitItemSuggestion handleSetSuggest={handleSetSuggest} />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={4}
                                        sx={{
                                            '& .MuiFormHelperText-root': {
                                                top: '35px',
                                                right: '-10px !important',
                                                left: 'auto',
                                            },
                                        }}
                                    >
                                        <Controller
                                            control={control}
                                            name={`permits.${indexForm}.bayNumber`}
                                            render={({ field, fieldState: { error } }) => (
                                                <TextField
                                                    {...field}
                                                    helperText={
                                                        isFocused &&
                                                        `${field.value!.length ?? 0}/${BAY_INFO_LIMIT_CHARACTER}`
                                                    }
                                                    onFocus={() => setIsFocused(true)}
                                                    onBlur={() => setIsFocused(false)}
                                                    fullWidth
                                                    size="small"
                                                    label={t('permits:text.bayNo')}
                                                    placeholder={t('permits:text.enterBayNumber')}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                    inputProps={{
                                                        maxLength: BAY_INFO_LIMIT_CHARACTER,
                                                    }}
                                                    disabled={isDisableEditBayInfo}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} mt={2}>
                                    {recurSelect.RenderSelect}
                                </Grid>

                                {![RecurringType.indefinite].includes(permitRecurringType!) && (
                                    <Grid item xs={12} mt={1}>
                                        <Stack
                                            width={'100%'}
                                            sx={{
                                                backgroundColor: color.grey100,
                                                p: 2,
                                                borderRadius: 1,
                                            }}
                                        >
                                            {[RecurringType.dynamic].includes(permitRecurringType!) && (
                                                <>
                                                    <Grid xs={12}>
                                                        <DynamicSubRecurringItem
                                                            indexForm={indexForm}
                                                            isEdit={isEdit}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        container
                                                        spacing={2}
                                                        sm={12}
                                                        mt={1}
                                                        display={'flex'}
                                                        justifyContent={'center'}
                                                        alignItems={'center'}
                                                    >
                                                        <Grid item xs={12}>
                                                            {subPermitRecurringType === RecurringType.day ? (
                                                                <DayRecurring parentForm={props} isEdit={isEdit} />
                                                            ) : subPermitRecurringType === RecurringType.month ? (
                                                                <MonthRecurring parentForm={props} isEdit={isEdit} />
                                                            ) : subPermitRecurringType === RecurringType.week ? (
                                                                <WeekRecurring parentForm={props} isEdit={isEdit} />
                                                            ) : (
                                                                ''
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            )}

                                            {permitRecurringType !== RecurringType.temporary && (
                                                <Grid container item direction={'row'} mt={2} display={'flex'}>
                                                    <Grid item xs={12}>
                                                        <UpsertPermitItemTimeDuration
                                                            isEdit={isEdit}
                                                            parentForm={props}
                                                        />
                                                    </Grid>
                                                    <Grid xs={12} item mt={1}>
                                                        <UpsertPermitItemDateRange isEdit={isEdit} parentForm={props} />
                                                    </Grid>
                                                </Grid>
                                            )}

                                            {permitRecurringType === RecurringType.temporary && (
                                                <Grid container item direction={'row'} mt={1} display={'flex'}>
                                                    <UpsertPermitItemTemporary parentForm={props} isEdit={isEdit} />
                                                </Grid>
                                            )}
                                        </Stack>
                                    </Grid>
                                )}

                                <Grid item container mt={permitRecurringType === RecurringType.once ? 1 : 2}>
                                    <Controller
                                        control={control}
                                        name={`permits.${indexForm}.requestedBy`}
                                        render={({ field, fieldState: { error } }) => (
                                            <TextField
                                                {...field}
                                                error={!!error?.message}
                                                helperText={error?.message}
                                                fullWidth
                                                size="small"
                                                label={t('permits:text.requestedBy')}
                                                placeholder={t('input:enterWhoHasRequestToAddPermit')}
                                                onChange={(e) => {
                                                    field.onChange(e.target.value);
                                                }}
                                                inputProps={{
                                                    maxLength: 100,
                                                }}
                                                sx={{
                                                    '& .MuiFormHelperText-root': {
                                                        top: '55px !important',
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <UpsertPermitComment isEdit={isEdit} parentForm={props} />
                            </Grid>
                        </AccordionDetails>
                    </MyAccordion>
                </Stack>
            </Grid>
        </UpsertPermitItemContext.Provider>

        // </FormikProvider>
    );
}

export const MyAccordion = styled(Accordion)({
    '&.MuiPaper-root': {
        border: `1px solid #DDDDDD`,
        boxShadow: `none`,
    },
});

export type PermitFormData = UseFormReturn<Permit, any, undefined>;
