/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { v4 } from 'uuid';
import { appConfig } from '../config/AppConfig';
import { accountController, axiosClient, userController } from '../controllers';
import { LocalStore } from '../helpers/localStorage';
import { Account } from '../models/Account';
import { Application, ChangePassword, Login } from '../models/Login';
import useParamsFromStel from './useParamsFromStel';
import { Role } from '../models/permission/Role';

const JWT_KEY = 'jwt';

interface State {
    jwt?: string | null;
    isAuth?: boolean;
    account: Account;
    userAvatar?: string;
    isLoading: boolean;
    paramSave?: string;
}

export default function UseAuth() {
    const navigate = useNavigate();
    const location = useLocation();

    const { oldParams, navigateFromStelUrl, setOldParams } = useParamsFromStel();
    const [state, setState] = useState<State>({
        jwt: LocalStore.getValue(JWT_KEY),
        isAuth: false,
        isLoading: true,
        account: {} as Account,
        paramSave: navigateFromStelUrl,
    });
    const setPartialState = (partial: Partial<State>) => setState((prev) => ({ ...prev, ...partial }));

    useEffect(() => {
        const jwt = LocalStore.getValue(JWT_KEY);
        if (jwt) {
            if (location.pathname.includes('change-password')) {
                logout();
            } else {
                loginWithJwt(jwt as string);
            }
        } else {
            logout();
        }
    }, []);

    const loginWithEmail = async (login: Login) => {
        await accountController.login({ ...login, application: Application.iHub }).then((jwt) => {
            loginWithJwt(jwt);
        });
    };

    const loginWithJwt = async (jwt: string) => {
        axiosClient.defaults.headers['authorization'] = 'Bearer ' + jwt;
        setPartialState({ isAuth: false, jwt, isLoading: true });
        await userController
            .getMe()
            .then((account) => {
                setPartialState({ isAuth: true, jwt, isLoading: true });
                LocalStore.setValue(JWT_KEY, jwt);
                setState((prev) => ({
                    ...prev,
                    isLoading: false,
                    account,
                    userAvatar: getAvatar(Number(account.accountId)),
                }));

                if (oldParams.isFromStel !== null) {
                    return navigate(navigateFromStelUrl);
                }

                if (location.pathname === '/' || location.pathname === '/login') {
                    navigate('/', { replace: true });
                } else {
                    // navigate(location.pathname, { replace: true });
                }
            })
            .catch((res) => {
                console.log('here');

                logout();
            })
            .finally(() => {
                setState((prev) => ({
                    ...prev,
                    isLoading: false,
                }));
            });
    };

    const sendTokenToEmail = async (email: string) => {
        await accountController.sendLinkToMail(email);
    };

    const changePassword = async (change: ChangePassword) => {
        await accountController.changePass(change);
    };

    const changePassAccount = async (change: ChangePassword) => {
        await accountController.changePassAccount(change).then((res) => {
            if (state.account) {
                setState({ ...state, account: { ...state.account, password: change.password } });
            }
        });
    };

    const updateAccount = async (updateAccount: Account) => {
        await accountController.updateAccount(updateAccount).then((res) => {
            setState((prev) => ({ ...prev, account: res }));
        });
    };

    const logout = () => {
        LocalStore.deleteKey(JWT_KEY);
        axiosClient.defaults.headers['authorization'] = '';
        console.log('here 1111');

        setState({ isAuth: false, jwt: null, account: {} as any, isLoading: false });
    };

    const getAvatar = (idAccount: number) => {
        const uuid = v4();
        const avatar = `${appConfig.gateway.apiAzureMedia}/${idAccount}?id=${uuid}`;
        return avatar;
    };

    const isSilverRole = () => {
        return [Role.Client, Role.Employee].includes(state.account.label);
    };
    const isGoldRole = () => {
        return [Role.AccountManager, Role.ManagingAgent].includes(state.account.label);
    };
    const isBronzeRole = () => {
        return [Role.Tenant].includes(state.account.label);
    };

    const clearOldParams = () => {
        setPartialState({ paramSave: undefined });
        setOldParams({
            companyId: null,
            locationId: null,
            zoneId: null,
            isFromStel: null,
        });
    };

    return {
        ...state,
        loginWithEmail,
        loginWithJwt,
        sendTokenToEmail,
        logout,
        changePassword,
        updateAccount,
        changePassAccount,
        setState,
        getAvatar,
        isSilverRole,
        isGoldRole,
        isBronzeRole,
        navigateFromStelUrl,
        oldParams,
        clearOldParams,
    };
}

export const AuthContext = React.createContext<ReturnType<typeof UseAuth>>({} as any);
