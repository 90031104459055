import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import useMedia from '../../../../hooks/useMedia';
import color from '../../../../config/Colors';

type Props = {
    onClose: () => void;
    open: boolean;
    onConfirm: () => void;
    title: string;
    content: React.ReactNode;
};
export default function ConfirmDialogLargeOneOptions(props: Props) {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {
            xs: 300,
            md: 400,
        },
        bgcolor: 'background.paper',
        boxShadow: 24,
        py: 4,
        px: 3,
        borderRadius: '10px',
    };
    const { onClose, open, onConfirm } = props;
    const { isMobileSM } = useMedia();
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={() => onClose()}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography textAlign={'center'} variant="h4" color={color.danger}>
                            {' '}
                            {props.title}
                        </Typography>
                        <Typography mt={isMobileSM ? 2 : 3} textAlign={'center'} variant="body1" component={'div'}>
                            {' '}
                            {props.content}
                        </Typography>
                        <Grid
                            item
                            container
                            xs={12}
                            mt={isMobileSM ? 2 : 3}
                            direction={'row'}
                            justifyContent={'center'}
                            alignItems={'center'}
                        >
                            <Button variant="cancel" sx={{ minWidth: '150px' }} onClick={() => onConfirm()}>
                                OK
                            </Button>
                        </Grid>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
