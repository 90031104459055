import {
    Box,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React, { useContext, useEffect } from 'react';
import color from '../config/Colors';
import { LanguageContext } from '../contexts/useLanguage';
import { LocalStore } from '../helpers/localStorage';
import useMedia from '../hooks/useMedia';
import { SelectItem } from './MySelect';

type Props = {
    list: SelectItem[];
    label?: string;
    value?: any;
    setFilter: (val: number) => void;
};

export default function useSelect(props: Props) {
    const [index, setIndex] = React.useState(0);
    const { isMobileSM } = useMedia();
    const handleChange = (event: SelectChangeEvent<unknown>) => {
        setIndex(event.target.value as number);
        props.setFilter(event.target.value as number);
    };
    const isGermany = (LocalStore.getValue('iHubLanguage') || 'en') == 'de';

    const getValue = () => {
        const index = props.list.findIndex((item) => item.url == props.value);
        if (index >= 0) {
            return Number(props.list[index].value);
        }
    };

    const { t } = useContext(LanguageContext);
    // String text =
    const RenderSelect = props.list && props.list.length > 0 && (
        <Grid2 spacing={1} alignItems={'center'} container direction={'row'}>
            {!isMobileSM && (
                <Grid2 sm={isGermany ? 2.8 : 2.4} md={isGermany ? 1.8 : 1.5} lg={isGermany ? 1.4 : 1}>
                    <Typography sx={{ fontWeight: '400' }} variant={'h6'}>
                        {' '}
                        {t('permits:text.selectType')}:
                    </Typography>
                </Grid2>
            )}

            <Grid2 sm={isGermany ? 9.2 : 9.6} md={isGermany ? 10.2 : 10.5} lg={isGermany ? 10.6 : 11} xs={12}>
                <FormControl fullWidth size="medium">
                    <InputLabel
                        id="demo-multiple-checkbox-label"
                        sx={{
                            background: color.grey100,
                        }}
                    >
                        {props?.label || 'Tag'}
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={handleChange}
                        value={props.value ? getValue() : ''}
                        defaultValue={props.list[0].value}
                    >
                        {props.list.map((item, index) => {
                            return [
                                <MenuItem value={item.value} key={item.value}>
                                    {item.label}
                                </MenuItem>,
                                index === props.list.length - 1 ? (
                                    ''
                                ) : (
                                    <Divider
                                        sx={{
                                            my: '2px !important',
                                            borderColor: color.grey200,
                                            mx: '3px',
                                        }}
                                    />
                                ),
                            ];
                        })}
                    </Select>
                </FormControl>
            </Grid2>
        </Grid2>
    );

    return { RenderSelect, index };
}
