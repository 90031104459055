import { TableBody, TableCell, TableRow } from '@mui/material';
import { formatNumber } from '../../../helpers/FormatCurrency';
import { formatDate } from '../../../helpers/moment';
import { AnprMatchesDailyStats } from '../../../models/AnprMatchesDailyStats';
import { DailyVisitsStatistic } from '../../../models/DailyVisitsStatistic';

type Props = {
    dailyVisitsStatistic: DailyVisitsStatistic;
};

export default function TableRowsDailyEVVisits(props: Props) {
    return (
        <TableBody>
            {props.dailyVisitsStatistic.dailyData &&
                props.dailyVisitsStatistic.dailyData.map((row, index) => {
                    return (
                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell key={index} align="center" component="th" scope="row">
                                {formatDate.getLocalDay(row.inDate)}
                            </TableCell>
                            <TableCell align="center">{formatNumber.format(row.qtyTotalOfEV!)}</TableCell>
                        </TableRow>
                    );
                })}
        </TableBody>
    );
}
