import React, { SVGProps } from 'react';

type Props = {} & SVGProps<SVGSVGElement>;

export default function ChartIcon(props: Props) {
    return (
        <svg
            {...props}
            className="svg-icon-fill"
            width="19"
            height="16"
            viewBox="0 0 19 16"
            fill="#85858A"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                strokeWidth={0}
                d="M4.57692 4.92308C5.25631 4.92308 5.80769 5.47446 5.80769 6.15385V14.7692C5.80769 15.4486 5.25631 16 4.57692 16H2.11538C1.436 16 0.884613 15.4486 0.884613 14.7692V6.15385C0.884613 5.47446 1.436 4.92308 2.11538 4.92308H4.57692ZM16.8846 8.61539C17.564 8.61539 18.1154 9.16677 18.1154 9.84615V14.7692C18.1154 15.4486 17.564 16 16.8846 16H14.4231C13.7437 16 13.1923 15.4486 13.1923 14.7692V9.84615C13.1923 9.16677 13.7437 8.61539 14.4231 8.61539H16.8846ZM10.7308 0C11.4102 0 11.9615 0.551385 11.9615 1.23077V14.7692C11.9615 15.4486 11.4102 16 10.7308 16H8.26923C7.58984 16 7.03846 15.4486 7.03846 14.7692V1.23077C7.03846 0.551385 7.58984 0 8.26923 0H10.7308Z"
            />
        </svg>
    );
}
