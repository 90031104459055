import {
    Box,
    Chip,
    IconButton,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
    Stack,
    colors,
} from '@mui/material';
import React, { useContext, useState } from 'react';

import color from '../../../../../../config/Colors';
import { LanguageContext } from '../../../../../../contexts/useLanguage';
import { formatNumber } from '../../../../../../helpers/FormatCurrency';
import { AuthContext } from '../../../../../../hooks/useAuth';
import { CompaniesContext } from '../../../../../../hooks/useCompanies';
import Zone, { BayTemporary } from '../../../../../../models/Zone';
import AddIcon from '../../../../../../assets/svg/AddIcon';
import RemoveIcon from '../../../../../../assets/svg/RemoveIcon';
import useModal from '../../../../../../components/modal/useModal';
import ConfirmDialog from '../../../../../../components/ConfirmDialog';
import ModalCreate from './ModalTableZones/ModalCreate';
import ModalHistory from './ModalTableZones/ModalHistory';
import ViewHistoryTemporaryBay from '../../../../../../assets/svg/ViewHistoryTemporaryBay';
import IconEdit from '../../../../employees/components/IconEdit';
import { zoneController } from '../../../../../../controllers';
import { formatDate } from '../../../../../../helpers/moment';
import moment from 'moment';
import { ConvertToGMT0Time } from '../../../../../../helpers';
import { LocationsContext } from '../../../../../../hooks/useLocations';

type Props = {
    handleEdit: (zone: Zone) => void;
};

export default function TableZones(props: Props) {
    const popupDelete = useModal();
    const popupCreate = useModal();
    const popupHistoryTemporary = useModal();
    const [detailZone, setDetailZone] = useState<Zone>({} as Zone);
    const [zoneBayTemporary, setZoneBayTemporary] = useState<Zone>({} as Zone);

    const [isLoadingPopup, setIsLoadingPopup] = useState(false);
    const [idZoneBayTemporary, setIdZoneBayTemporary] = useState<number>();
    const { zoneDataState, getLocationDetail } = useContext(LocationsContext);
    const { zonesSelect } = zoneDataState;

    const { t } = useContext(LanguageContext);
    const SERVICE_TYPES = [
        {
            id: 0,
            name: 'ANPR',
            color: color.lightPrimary,
        },
        {
            id: 1,
            name: t('companies:text.wardenPatrol'),
            color: '#FFD9D9',
        },
        {
            id: 2,
            name: t('companies:text.selfTicket'),
            color: '#ABAD88',
        },
        {
            id: 3,
            name: 'iTicket',
            color: '#FFDC83',
        },
        {
            id: 4,
            name: t('companies:text.contraventionManagement'),
            color: '#F09453',
        },
        {
            id: 5,
            name: t('companies:text.other'),
            color: '#007BFF',
        },
    ];
    const buildNameServiceType = (number: Number[]) => {
        if (number != undefined) {
            const res = SERVICE_TYPES.filter((el) => number.includes(el.id));
            const listName = res
                .slice(2, 10)
                .map((title) => title.name)
                .join(', ');
            return res.slice(0, 2).map((item, index) => {
                return (
                    <Stack key={index} alignItems={'center'} direction={'row'}>
                        <Chip sx={{ background: item.color, borderRadius: '40px' }} key={index} label={item.name} />
                        <Tooltip
                            placement="top"
                            title={
                                <Typography variant="body2" color={'white'}>
                                    {listName}
                                </Typography>
                            }
                        >
                            {res.length - 2 < 1 ? (
                                <Box display={index == 1 ? 'block' : 'none'} width={23} />
                            ) : (
                                <Typography
                                    sx={{
                                        '&:hover': {
                                            cursor: 'pointer',
                                            color: color.secondary,
                                            textDecoration: 'underline',
                                        },
                                    }}
                                    ml={1}
                                    display={index == 1 ? 'block' : 'none'}
                                >
                                    + {res.length - 2}
                                </Typography>
                            )}
                        </Tooltip>
                    </Stack>
                );
            });
        } else {
            return <Typography>...</Typography>;
        }
    };

    const addTemporaryBay = async (data: BayTemporary) => {
        setIsLoadingPopup(true);
        const _data = {
            ZoneId: detailZone.id,
            BayTemporary: Number(data.bayTemporary),
            StartDate: ConvertToGMT0Time(data.startDate),
            EndDate: data.endDate == undefined ? null : ConvertToGMT0Time(moment(data.endDate).endOf('D').toDate()),
        };
        await zoneController
            .addBayTemporary(_data)
            .then(() => {
                getLocationDetail();
                popupCreate.handleClose();
            })
            .finally(() => {
                setIsLoadingPopup(false);
            });
    };

    const getHistoryBayTemporary = (zoneId: number) => {
        setIsLoadingPopup(true);
        zoneController
            .getHistoryBayTemporary(zoneId)
            .then((res: Zone) => {
                setZoneBayTemporary(res);
            })
            .finally(() => {
                setIsLoadingPopup(false);
            });
    };

    const onDelete = () => {
        setIsLoadingPopup(true);
        zoneController
            .stopZoneTemporary(idZoneBayTemporary!)
            .then((res) => {
                getLocationDetail();
                popupDelete.handleClose();
            })
            .finally(() => {
                setIsLoadingPopup(false);
            });
    };
    return (
        <TableBody>
            {zonesSelect &&
                zonesSelect.length > 0 &&
                zonesSelect.map((zone, index) => {
                    const isLast = index == zonesSelect.length - 1;

                    return (
                        <TableRow
                            key={index}
                            sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                                borderBottom: isLast ? `1px solid #ddd` : '',
                            }}
                        >
                            <TableCell style={{ maxWidth: 200 }} align="left" component="th" scope="row">
                                <Typography noWrap>{zone.name}</Typography>
                            </TableCell>
                            <TableCell style={{ minWidth: 80 }} align="left">
                                {zone.zoneType.name}
                            </TableCell>
                            <TableCell align="center">
                                <Stack justifyContent={'center'} alignItems={'center'} direction={'row'} spacing={1}>
                                    {buildNameServiceType(zone.services?.map((item) => item.serviceType))}
                                </Stack>
                            </TableCell>
                            <TableCell style={{ minWidth: 80 }} align="center">
                                <Typography sx={{ cursor: 'default', width: 'content' }}>
                                    {formatNumber.format(zone.totalBays)}
                                </Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Tooltip
                                    sx={{
                                        '&:hover': {
                                            '& .employeeIcon': {
                                                stroke: color.secondary,
                                                transition: '0.3s',
                                            },
                                        },
                                    }}
                                    title={
                                        <>
                                            {zone.zoneBayTemporaries?.length > 0 ? (
                                                <Typography color={color.white} fontSize={10} fontWeight={500}>
                                                    {t('permits:text.startDate')}:{' '}
                                                    {formatDate.getLocalDay(zone.zoneBayTemporaries[0]?.startDate)}{' '}
                                                    <br /> {t('permits:text.endDate')}:{' '}
                                                    {zone.zoneBayTemporaries[0]?.endDate == null
                                                        ? t('permits:text.noEndDate')
                                                        : formatDate.getLocalDay(zone.zoneBayTemporaries[0]?.endDate)}
                                                </Typography>
                                            ) : (
                                                <Typography color={color.white} fontSize={10} fontWeight={500}>
                                                    {t('companies:text.none')}
                                                </Typography>
                                            )}
                                        </>
                                    }
                                    placement="top"
                                >
                                    <Typography sx={{ cursor: 'default', width: 'content' }}>
                                        {zone.zoneBayTemporaries?.[0]?.bayTemporary ?? '-'}
                                    </Typography>
                                </Tooltip>
                            </TableCell>
                            <TableCell align="center" component="th" scope="row">
                                <Box>
                                    <Tooltip
                                        sx={{
                                            '&:hover': {
                                                '& .iconLocation': {
                                                    fill: 'orange',
                                                    transition: '0.3s',
                                                },
                                            },
                                        }}
                                        title={'View history'}
                                        placement="top"
                                    >
                                        <IconButton
                                            onClick={() => {
                                                popupHistoryTemporary.setOpen(true);
                                                setDetailZone(zone);
                                                getHistoryBayTemporary(zone.id);
                                            }}
                                            aria-label="fingerprint"
                                            color="success"
                                        >
                                            <ViewHistoryTemporaryBay />
                                        </IconButton>
                                    </Tooltip>

                                    {zone.zoneBayTemporaries?.length == 0 && (
                                        <Tooltip
                                            sx={{
                                                '&:hover': {
                                                    '& .iconLocation': {
                                                        fill: 'orange',
                                                        transition: '0.3s',
                                                    },
                                                },
                                            }}
                                            title={t('companies:text.addTemporaryBayCount')}
                                            placement="top"
                                        >
                                            <IconButton
                                                onClick={() => {
                                                    popupCreate.setOpen(true);
                                                    setDetailZone(zone);
                                                }}
                                                aria-label="fingerprint"
                                                color="success"
                                            >
                                                <AddIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}

                                    {zone.zoneBayTemporaries?.length > 0 && (
                                        <Tooltip
                                            sx={{
                                                '&:hover': {
                                                    '& .employeeIcon': {
                                                        stroke: color.secondary,
                                                        transition: '0.3s',
                                                    },
                                                },
                                            }}
                                            title={t('companies:text.stopTemporaryBayCount')}
                                            placement="top"
                                        >
                                            <IconButton
                                                onClick={() => {
                                                    popupDelete.setOpen(true);
                                                    setIdZoneBayTemporary(zone.zoneBayTemporaries?.[0].id);
                                                }}
                                                aria-label="fingerprint"
                                                color="success"
                                            >
                                                <RemoveIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </Box>
                            </TableCell>
                            <ConfirmDialog
                                open={popupDelete.open}
                                title={t('action:confirm')}
                                content={`${t('companies:text.temporaryBayDeletionEffectiveTomorrow')} ${t(
                                    'companies:tenants.deleteTemporaryBayCountConfirm'
                                )}`}
                                onClose={popupDelete.handleClose}
                                onConfirm={onDelete}
                                isLoading={isLoadingPopup}
                                sx={{
                                    width: {
                                        xs: 300,
                                        md: 493,
                                    },
                                }}
                            />
                            <ModalCreate
                                zone={detailZone}
                                handleClose={popupCreate.handleClose}
                                handleSave={async (data) => await addTemporaryBay(data)}
                                open={popupCreate.open}
                                isLoading={isLoadingPopup}
                            />
                            <ModalHistory
                                zoneBayTemporary={zoneBayTemporary}
                                handleClose={popupHistoryTemporary.handleClose}
                                handleOpen={() => {}}
                                open={popupHistoryTemporary.open}
                                isLoading={isLoadingPopup}
                            />

                            {/* <TableCell style={{ minWidth: 120 }} align="center">
                                <Tooltip
                                    title={
                                        <Box>
                                            <Typography variant="body2" color={'white'}>
                                                {t('companies:tenants.allocatedPermits')}:{' '}
                                                {formatNumber.format(zone.allocated)} -{' '}
                                                {t('companies:tenants.maxPermits')}:{' '}
                                                {formatNumber.format(zone.maxPermits)}
                                            </Typography>
                                        </Box>
                                    }
                                    placement="top"
                                >
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            '&:hover': {
                                                cursor: 'pointer',
                                                color: color.secondary,
                                                textDecoration: 'underline',
                                            },
                                        }}
                                    >
                                        {formatNumber.format(zone.allocated)}/{formatNumber.format(zone.maxPermits)}
                                    </Typography>
                                </Tooltip>
                            </TableCell> */}
                            {/* {[Role.AccountManager, Role.ManagingAgent, Role.Client].includes(account.label) ? (
                                <TableCell align="center">
                                    <Tooltip title={t('action:edit')} placement="top">
                                        <IconButton
                                            onClick={() => props.handleEdit(zone)}
                                            aria-label="fingerprint"
                                            color="success"
                                            sx={{
                                                backgroundColor: color.grey200,
                                                '&:hover': {
                                                    backgroundColor: color.grey200,
                                                },
                                                border: `5px solid ${color.grey100}`,
                                                width: '40px',
                                                height: '40px',
                                            }}
                                        >
                                            <IconEdit />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            ) : (
                                <></>
                            )} */}
                        </TableRow>
                    );
                })}
        </TableBody>
    );
}
