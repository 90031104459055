import { Button, Grid, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import color from '../../../config/Colors';
import { LanguageContext } from '../../../contexts/useLanguage';
import { AuthContext } from '../../../hooks/useAuth';
import useMedia from '../../../hooks/useMedia';
import { ChartShowType } from '../../../models/ChartShowType';
import { GridBorderRadius, TypographyCenter, TypographyRight } from '../styled';
import { ReportType } from '../../../models/ReportType';
import { Summary } from '../../../models/DailyVisitsStatistic';
import { formatDate } from '../../../helpers/moment';
import { DrawerContext } from '../../dashboardLayout/useDrawer';
import font from '../../../config/Fonts';
import { formatNumber } from '../../../helpers/FormatCurrency';
import { DEFAULT_TAB_INDEX } from '../../reports/hooks/useReport';

type Props = {
    chartShowType?: ChartShowType;
    summaryData: Summary;
};

export default function SummaryComponent(props: Props) {
    const { t } = useContext(LanguageContext);
    const { setActiveItem } = useContext(DrawerContext);
    const { isMobileSM, isMediumMD } = useMedia();
    const navigate = useNavigate();

    const handleToReport = () => {
        navigate(`/reports/${ReportType.dailyVisit}/${DEFAULT_TAB_INDEX}`);
        setActiveItem(3);
    };

    const convertDate = (date: Date) => {
        return formatDate.getLocalDay(date);
    };

    return (
        props.summaryData && (
            <Grid
                item
                container
                md={4}
                sm={12}
                xs={12}
                sx={{ height: 'max-content', pl: { md: 2, sm: 0 }, mt: { md: 0, sm: 2, xs: 2 } }}
            >
                <GridBorderRadius item container gap={2} md={12} sm={6} xs={12}>
                    <Grid item container md={12} direction="column" alignItems="center" justifyContent="center">
                        <Grid item md={6}>
                            <TypographyCenter
                                sx={{
                                    padding: '8px 24px',
                                    borderRadius: '100px',
                                    background: color.lightPrimary,
                                }}
                                variant="h5"
                            >
                                {t('dashboard:title.summary')}
                            </TypographyCenter>
                        </Grid>
                    </Grid>

                    <Grid item container md={12} sm={12} xs={12}>
                        <Grid
                            item
                            md={6}
                            sm={6}
                            xs={6}
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'space-between'}
                        >
                            <TypographyCenter variant="body1">{t('dashboard:title.totalVisit')}</TypographyCenter>
                            <TypographyCenter variant="h2">
                                {formatNumber.format(props.summaryData.totalVisits) || '0'}
                            </TypographyCenter>
                            {/* <TypographyCenter variant="h5">
                                (EV) {formatNumber.format(props.summaryData.totalVisitEV! || 0)}
                            </TypographyCenter> */}
                        </Grid>
                        <Grid
                            item
                            md={6}
                            sm={6}
                            xs={6}
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'space-between'}
                        >
                            <TypographyCenter variant="body1">{t('dashboard:title.averageVisit')}</TypographyCenter>
                            <TypographyCenter variant="h2">
                                {formatNumber.format(props.summaryData.averageVisits) || '0'}
                            </TypographyCenter>
                            {/* <TypographyCenter variant="h5">
                                (EV) {formatNumber.format(props.summaryData.averageVisitEV! || 0)}
                            </TypographyCenter> */}
                        </Grid>
                    </Grid>
                </GridBorderRadius>
                <Grid item container md={12} sm={6} sx={{ mt: { md: 1.5, sm: 0, xs: 1.5 }, pl: { md: 0, sm: 2 } }}>
                    <GridBorderRadius item container md={12} sm={12} xs={12} sx={{ height: 'max-content' }}>
                        <Grid item xs={8}>
                            <Typography variant="body1">{t('dashboard:title.dateWithMostVisits')}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <TypographyRight variant="body1">
                                {convertDate(props.summaryData.mostVisitedDate.inDate)}
                            </TypographyRight>
                        </Grid>

                        <Grid item xs={8} mt={'5px'}>
                            <Typography variant="body1">{t('dashboard:title.noOfVisits')}</Typography>
                        </Grid>
                        <Grid item xs={4} mt={'5px'}>
                            <TypographyRight variant="h4" sx={{ fontFamily: font, fontWeight: 400 }}>
                                {formatNumber.format(props.summaryData.mostVisitedDate.visitCount)}
                            </TypographyRight>
                        </Grid>
                    </GridBorderRadius>

                    <GridBorderRadius item container md={12} sm={12} sx={{ height: 'max-content', mt: '12px' }}>
                        <Grid item xs={8}>
                            <Typography variant="body1">{t('dashboard:title.dateWithFewestVisits')}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <TypographyRight variant="body1">
                                {convertDate(props.summaryData.smallVisitedDate.inDate)}
                            </TypographyRight>
                        </Grid>
                        <Grid item xs={8} mt={'5px'}>
                            <Typography variant="body1">{t('dashboard:title.noOfVisits')}</Typography>
                        </Grid>
                        <Grid item xs={4} mt={'5px'}>
                            <TypographyRight variant="h4" sx={{ fontFamily: font, fontWeight: 400 }}>
                                {formatNumber.format(props.summaryData.smallVisitedDate.visitCount)}
                            </TypographyRight>
                        </Grid>
                    </GridBorderRadius>
                </Grid>
                {isMobileSM || isMediumMD || props.chartShowType == ChartShowType.report ? (
                    ''
                ) : (
                    <Button variant="outlined" fullWidth sx={{ mt: 2 }} onClick={handleToReport}>
                        {t('dashboard:button.viewInsight')}
                    </Button>
                )}
            </Grid>
        )
    );
}
