/* eslint-disable array-callback-return */
import { Grid, Typography } from '@mui/material';
import React, { useContext } from 'react';
import PieChartReason from '../../../components/chart/PieChartReason';
import NoDataToDisplay from '../../../components/NoDataToDisplay';
import color from '../../../config/Colors';
import { LIST_COLOR_CHART } from '../../../constants';
import { LanguageContext } from '../../../contexts/useLanguage';
import { ChartShowType } from '../../../models/ChartShowType';
import { ReportType } from '../../../models/ReportType';
import { StatisticItem } from '../../../models/StatisticItems';
import { GridBorderRadius } from '../styled';
import ChartTitleContent from './ChartTitleContent';
import ColorBox from './ColorBox';

type Props = {
    chartShowType?: ChartShowType;
    reportType?: ReportType;
    groupByReason?: StatisticItem[];
};

export default function GroupPCNByReason(props: Props) {
    const { t } = useContext(LanguageContext);

    const listData = () => {
        let list: StatisticItem[] = [];
        if (props.groupByReason && props.groupByReason.length >= 5) {
            list = props.groupByReason.slice(0, 5);
            let totalOther = 0;
            props.groupByReason.map((item, index) => {
                if (index >= 5) {
                    totalOther += item.amount;
                }
            });
            if (totalOther !== 0) {
                list.push({ label: t('dashboard:text.others'), amount: totalOther });
            }
            return list;
        } else {
            return props.groupByReason;
        }
    };

    const getTotal = () => {
        let total = 0;
        props.groupByReason &&
            props.groupByReason.length > 0 &&
            props.groupByReason.map((item) => {
                total += item.amount;
            });
        return total;
    };

    return (
        <GridBorderRadius
            container
            item
            xs={12}
            gap={2}
            mt={props.chartShowType === ChartShowType.report ? 1 : { xs: 2, sm: 3, md: 4 }}
            border={
                props.chartShowType === ChartShowType.report
                    ? 'none !important'
                    : `1px solid ${color.grey200} !important`
            }
        >
            <Grid item xs={12}>
                <ChartTitleContent
                    key={'3'}
                    title={t('dashboard:title.PCNByReason')}
                    desc={t('dashboard:text.numberOfParkingChargesRaisedByContravention​')}
                    buttonLabel={t('dashboard:button.viewInsight')}
                    chartShowType={props.chartShowType || ChartShowType.dashboard}
                    reportType={props.reportType || undefined}
                />
            </Grid>

            {listData() && listData()!.length > 0 ? (
                <Grid
                    item
                    container
                    xs={12}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '16px',
                    }}
                >
                    <Grid item container xs={12} md={4} width={250} height={250}>
                        <PieChartReason data={listData() || []} />
                    </Grid>

                    <Grid item xs={12} md={8} padding={'32px 0 32px 0'} display={'flex'} justifyContent={'center'}>
                        <Grid
                            item
                            container
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'center'}
                            gap={1}
                            textAlign={'center'}
                        >
                            {listData()!.map((item, index) => {
                                if (index <= 5) {
                                    return (
                                        <Typography
                                            key={index}
                                            variant="body2"
                                            component={'div'}
                                            paddingLeft={3}
                                            textAlign={'left'}
                                            fontSize={{ xs: '12px', sm: '14px', lg: '14px' }}
                                        >
                                            <ColorBox color={LIST_COLOR_CHART[index]} />
                                            {item.label + ` (${((item.amount / getTotal()) * 100).toFixed(1)}%)`}
                                        </Typography>
                                    );
                                }
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                <Grid container item xs={12} mt={-2}>
                    <NoDataToDisplay text={t('companies:text.noDataToDisplay')} />
                </Grid>
            )}
        </GridBorderRadius>
    );
}
