/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const Params = ['companyStellaId', 'locationStellaId', 'zoneStellaId'] as const;
export type PermitParams = (typeof Params)[number];

export default function useParamsFromStel() {
    const [searchParams] = useSearchParams();
    const [oldParams, setOldParams] = useState({
        companyId: searchParams.get('companyId'),
        locationId: searchParams.get('locationId'),
        zoneId: searchParams.get('zoneId'),
        isFromStel: searchParams.get('isFromStel'),
    });

    let navigateFromStelUrl = ``;

    if (oldParams.isFromStel !== null) {
        navigateFromStelUrl += `/permits/add-permits?isFromStel=true`;
        if (oldParams.companyId !== null) {
            navigateFromStelUrl += `&companyPId=${oldParams.companyId}`;
            if (oldParams.locationId !== null) {
                navigateFromStelUrl += `&locationPId=${oldParams.locationId}`;
                if (oldParams.zoneId !== null) {
                    navigateFromStelUrl += `&zonePIds=${oldParams.zoneId}`;
                }
            }
        }
    }
    return { Params, oldParams, navigateFromStelUrl, setOldParams };
}

export const SearchParamsContext = React.createContext<ReturnType<typeof useParamsFromStel>>({} as any);
