import { Box, Theme, Typography } from '@mui/material';
import React from 'react';
import color from '../config/Colors';
import { makeStyles } from '@mui/styles';
import { logoHeader } from '../config/ImageFromEnv';

export default function LogoIconWithName() {
    const classes = styles();
    return (
        <Box className={classes.loginTitle}>
            <img src={logoHeader} alt="logo" />
            <Typography
                variant="h5"
                component={'p'}
                sx={{
                    color: color.white,
                    borderLeft: `2px solid ${color.primary}`,
                    lineHeight: '24px',
                    fontSize: '20px',
                    pl: 1,
                    ml: '5px',
                }}
            >
                <span style={{ color: color.primary }}>i</span>Hub Insights
            </Typography>
        </Box>
    );
}

const styles = makeStyles((theme: Theme) => ({
    loginTitle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 'none',
        order: '0',
        [theme.breakpoints.down('sm')]: {
            '& img': {
                height: '19px',
            },
        },
    },
}));
