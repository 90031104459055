import { FormControlLabel, Grid, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { SelectItem } from '../../../../../../components/MySelect';
import { LanguageContext } from '../../../../../../contexts/useLanguage';
import { RecurringType } from '../../../../../../models/permits/PermitRecurring';
import { Controller, useFormContext } from 'react-hook-form';
import color from '../../../../../../config/Colors';

export default function DynamicSubRecurringItem({ indexForm, isEdit }: { indexForm: number; isEdit: boolean }) {
    const { t } = useContext(LanguageContext);

    const subRecur: SelectItem[] = [
        {
            value: RecurringType.day,
            label: t('permits:text.daily'),
            description: t('permits:text.schedulePermitsToRecurEveryXNumberOfDays'),
        },
        {
            value: RecurringType.week,
            label: t('permits:text.weekly'),
            description: t('permits:text.schedulePermitsToRecurOnSpecifiedDaysOfTheWeek'),
        },
        {
            value: RecurringType.month,
            label: t('permits:text.monthly'),
            description: t('permits:text.schedulePermitsToRecurOnAParticularDateOfTheMonth'),
        },
    ];

    const { control, getValues } = useFormContext();

    return (
        <Stack p={1} sx={{ background: color.white }}>
            <Controller
                control={control}
                name={`permits.${indexForm}.subPermitRecurringType`}
                render={({ field, fieldState: { error } }) => (
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={field.value}
                        name="radio-buttons-group"
                        onChange={(e: any, val) => {
                            if (!isEdit) field.onChange(Number(val));
                        }}
                        color={color.success}
                        sx={{ flexDirection: 'row' }}
                    >
                        <Grid container spacing={2}>
                            {subRecur.map((recurItem) => {
                                const isActive = field.value === recurItem.value;
                                return (
                                    <Grid item xs={4} key={recurItem.value}>
                                        <Stack
                                            sx={{
                                                borderRadius: 1,
                                                p: '8px 8px 8px 16px',
                                                border: `1px solid ${isActive ? color.success : color.grey300}`,
                                                background: isActive ? color.lightPrimary : color.white,
                                            }}
                                        >
                                            <FormControlLabel
                                                sx={{
                                                    '&.MuiFormControlLabel-labelPlacementEnd': {
                                                        marginRight: '0px !important',
                                                    },
                                                    minWidth: '100px',
                                                }}
                                                value={recurItem.value}
                                                // disabled={validateFrom === null}
                                                control={<Radio />}
                                                label={<Typography variant="body1">{recurItem.label}</Typography>}
                                            />
                                        </Stack>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </RadioGroup>
                )}
            />
        </Stack>
    );
}
