import { Box, SxProps, Theme, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import color from '../config/Colors';
import { ReportType } from '../models/ReportType';
import { ExportType } from './reports/hooks/useReport';

export interface TableHeadContent {
    label: React.ReactNode;
    alight: 'center' | 'right' | 'left' | 'inherit' | 'justify' | undefined;
}

type Props = {
    tableHead: TableHeadContent[];
    tableRow: JSX.Element;
    exportType?: ExportType;
    reportType?: ReportType;
    propHeadStyle?: SxProps<Theme> | undefined;
};

export default function MyTable(props: Props) {
    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            sx={{
                alignItems: 'center',
                padding: '20px',
                '&.MuiPaper-root': {
                    borderRadius: 'none',
                },
            }}
            width={'100%'}
        >
            <TableContainer
                component={Paper}
                sx={{
                    borderRadius: '0px',
                    boxShadow: 'none',
                }}
            >
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow sx={{ ...props.propHeadStyle }}>
                            {props.tableHead.map((item, index) => {
                                return (
                                    <TableCell
                                        key={index}
                                        align={item.alight}
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: { xs: '14px !important', md: '16px !important' },
                                            color: color.textPrimary,
                                        }}
                                    >
                                        {item.label}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    {props.tableRow}
                </Table>
            </TableContainer>
        </Box>
    );
}
