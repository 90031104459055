import { useContext } from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { LanguageContext } from '../../../contexts/useLanguage';
import { formatNumber } from '../../../helpers/FormatCurrency';
import { StatisticItems } from '../../../models/StatisticItems';
import font from '../../../config/Fonts';

type Props = {
    dataPCNCancellations: StatisticItems;
};

export default function TablePCNCancellations(props: Props) {
    const { t } = useContext(LanguageContext);
    const total = props.dataPCNCancellations.items.reduce((t, c) => (t += c.amount), 0);
    return (
        <TableBody>
            {props.dataPCNCancellations.items &&
                props.dataPCNCancellations.items.length > 0 &&
                props.dataPCNCancellations.items.map((item, index) => {
                    return (
                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" align="center" scope="row" width={'50%'}>
                                {item.label}
                            </TableCell>
                            <TableCell align="center" width={'50%'}>
                                {formatNumber.format(item.amount)}
                            </TableCell>
                        </TableRow>
                    );
                })}
            {total > 0 && (
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{ fontWeight: 510 }} width={'50%'} component="th" align="center" scope="row">
                        {t('reportPage:text.total')}
                    </TableCell>
                    <TableCell sx={{ fontWeight: 510 }} width={'50%'} align="center">
                        {formatNumber.format(total)}
                    </TableCell>
                </TableRow>
            )}
        </TableBody>
    );
}
