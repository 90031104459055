import React, { SVGProps } from 'react';
import color from '../../../../config/Colors';

type Props = {} & SVGProps<SVGSVGElement>;

export default function BarChartIcon(props: Props) {
    return (
        <svg width="100" height="83" viewBox="0 0 100 83" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="100" height="83" rx="5" fill="#FAFAFA" />
            <rect x="27" y="66.5" width="10" height="6" rx="3" transform="rotate(-90 27 66.5)" fill="#DDDDDD" />
            <rect x="37" y="66.5" width="20" height="6" rx="3" transform="rotate(-90 37 66.5)" fill={color.primary} />
            <rect x="47" y="66.5" width="30" height="6" rx="3" transform="rotate(-90 47 66.5)" fill={color.primary} />
            <rect x="57" y="66.5" width="40" height="6" rx="3" transform="rotate(-90 57 66.5)" fill="#DDDDDD" />
            <rect x="67" y="66.5" width="50" height="6" rx="3" transform="rotate(-90 67 66.5)" fill="#DDDDDD" />
        </svg>
    );
}
