import color from '../config/Colors';

export const LIST_COLOR_CHART = process.env.REACT_APP_LIST_COLOR_PIE_CHART!.split(',');
export const LIST_COLOR_PNC_TYPE_CHART = process.env.REACT_APP_PCN_CHART_COLOR!.split(',');
export const LIST_COLOR_PNC_CANCEL_CHART = process.env.REACT_APP_PCN_CANCELLATIONS_CHART_COLOR!.split(',');
// export const LIST_COLOR_CHART = process.env.REACT_APP_LIST_COLOR_PIE_CHART!.split(',');
// export const LIST_COLOR_PNC_TYPE_CHART = ['#FFB600', '#3EB6E4', '#FF8300'];
// export const LIST_COLOR_PNC_CANCEL_CHART = ['#1A428A', '#3EB6E4'];
export const COLOR_CAPACITY = {
    from0To8: process.env.REACT_APP_LIST_COLOR_CAPACITY_0_8,
    from8To16: process.env.REACT_APP_LIST_COLOR_CAPACITY_8_16,
    from16To0: process.env.REACT_APP_LIST_COLOR_CAPACITY_16_0,
};
export const COLOR_EV_AVERAGE_LENGTH = {
    rapid: process.env.REACT_APP_EV_AVERAGE_LENGTH_RAPID,
    fast: process.env.REACT_APP_EV_AVERAGE_LENGTH_FAST,
    normal: process.env.REACT_APP_EV_AVERAGE_LENGTH_NORMAL,
};
export const COLOR_DAILY_VISITS = {
    diesel: process.env.REACT_APP_DAILY_VISITS_DIESEL,
    petrol: process.env.REACT_APP_DAILY_VISITS_PETROL,
    ev: process.env.REACT_APP_DAILY_VISITS_EV,
    hybrid: process.env.REACT_APP_DAILY_VISITS_HYBRID,
    other: color.priText,
};
//     rapid: '#507B00',
//     fast: '#A7D930',
//     normal: '#28430A',
// };
// export const COLOR_DAILY_VISITS = {
//     diesel: '#5b5655',
//     petrol: '#39979c',
//     ev: '#A7D930',
//     other: color.primary,
//     hybrid: '#FF8300',
// };
