import { Tooltip, Typography } from '@mui/material';
import React, { useContext } from 'react';
import UseLanguage, { LanguageContext } from '../../../../../contexts/useLanguage';

export default function RecurWithTooltip() {
    const { t } = useContext(LanguageContext);
    return (
        <Tooltip title={t('permits:text.repeatPermitInputBoxSpecifiesFrequency')}>
            <Typography variant="body1" component={'span'} sx={{ cursor: 'help' }}>
                {t('permits:text.recurEvery')}
            </Typography>
        </Tooltip>
    );
}
