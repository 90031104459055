import { Box, Stack } from '@mui/material';
import { stylesLogin } from './styles';
import LogoIconWithName from '../../components/LogoIconWithName';
import LoginForm from './components/LoginForm';
import { Outlet } from 'react-router-dom';
import MyAppBar from '../../components/MyAppBar';

export default function LoginLayout() {
    const classes = stylesLogin();
    return (
        <Stack className={classes.stackContainer}>
            <MyAppBar isShowLabelAccount={false} />
            <Box className={classes.loginContainer}>
                <Box>{/* <LogoIconWithName /> */}</Box>
                <Outlet />
            </Box>
        </Stack>
    );
}
