import React from 'react';

export default function EmployeeIcon() {
    return (
        <svg
            className="employeeIcon"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.8485 7.35968C12.7431 7.234 13.4318 6.46705 13.4337 5.53786C13.4337 4.62214 12.7662 3.86288 11.8908 3.71924"
                // stroke="#85858A"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.0289 9.51013C13.8953 9.63967 14.5 9.94298 14.5 10.5682C14.5 10.9985 14.2153 11.2781 13.7548 11.4538"
                // stroke="#85858A"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.00042 9.77539C5.9394 9.77539 4.17914 10.0877 4.17914 11.3349C4.17914 12.5816 5.9285 12.9028 8.00042 12.9028C10.0614 12.9028 11.8211 12.5937 11.8211 11.3458C11.8211 10.0979 10.0723 9.77539 8.00042 9.77539Z"
                // stroke="#85858A"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.00045 7.99525C9.35287 7.99525 10.4494 6.89933 10.4494 5.54627C10.4494 4.19385 9.35287 3.09729 8.00045 3.09729C6.64803 3.09729 5.55147 4.19385 5.55147 5.54627C5.54634 6.8942 6.63456 7.99076 7.9825 7.99525H8.00045Z"
                // stroke="#85858A"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.15161 7.35968C3.2564 7.234 2.56833 6.46705 2.56641 5.53786C2.56641 4.62214 3.23396 3.86288 4.10928 3.71924"
                // stroke="#85858A"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2.97106 9.51013C2.10471 9.63967 1.5 9.94298 1.5 10.5682C1.5 10.9985 1.78472 11.2781 2.24515 11.4538"
                // stroke="#85858A"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
