import { Avatar, Divider, Grid, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useContext } from 'react';
import { appConfig } from '../../../config/AppConfig';
import color from '../../../config/Colors';
import { formatDate } from '../../../helpers/moment';
import { AuthContext } from '../../../hooks/useAuth';
import { Feedback, QA } from '../../../models/feedback/Feedback';
import { GridBorderRadius } from '../../dashboard/styled';

type Props = {
    feedbackItem?: Feedback;
};

export default function FeedBackItem({ feedbackItem }: Props) {
    const { getAvatar } = useContext(AuthContext);

    const avatarUrl = `${appConfig.gateway.apiAzureMedia}/${feedbackItem?.createdBy}`;

    return (
        <GridBorderRadius
            sx={{ p: '16px 24px ', display: 'flex', gap: 2, alightSelf: 'stretch' }}
            flexDirection={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }}
            container
        >
            <Grid item xs="auto">
                <Stack
                    justifyContent={'center'}
                    alignItems={'center'}
                    alignContent={'center'}
                    height={'100%'}
                    gap={0.5}
                >
                    <Avatar
                        sx={{ width: '60px', height: '60px', borderRadius: '50%' }}
                        alt={feedbackItem?.name}
                        src={avatarUrl}
                    />
                    <Typography
                        sx={{
                            color: color.darkPrimary,
                            width: { xs: 'auto', sm: 'auto', md: '140px', lg: '140px' },
                            textAlign: 'center',
                        }}
                        noWrap
                    >
                        {feedbackItem?.name}
                    </Typography>
                    <Typography sx={{ color: color.grey600 }}>
                        {formatDate.getLocalDateString(feedbackItem?.created!)}
                    </Typography>
                </Stack>
            </Grid>
            <Divider
                orientation="vertical"
                flexItem
                sx={{ display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' } }}
            />
            <Grid item xs>
                <Stack gap={2}>{feedbackItem?.content.map((qa, index) => QuestionItem(index + 1, qa))}</Stack>
            </Grid>
        </GridBorderRadius>
    );
}

export function QuestionItem(index: number, qa: QA) {
    return (
        <Stack>
            <Typography sx={{ fontWeight: 400 }}>
                {index}. {qa.question?.question}
            </Typography>
            <Typography sx={{ pl: 3, color: color.grey600 }}>{qa.answer}</Typography>
        </Stack>
    );
}
