import React, { SVGProps } from 'react';
import color from '../../config/Colors';

type Props = {} & SVGProps<SVGSVGElement>;

export default function ZoneIcon(props: Props) {
    return (
        <svg
            {...props}
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill={color.grey600}
            className="svg-icon-fill"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.5 7.025C1.5 7.017 1.5 7.008 1.5 7C1.5 3.137 4.637 0 8.5 0C12.363 0 15.5 3.137 15.5 7C15.5 7.008 15.5 7.017 15.5 7.025C15.484 12.256 10.45 15.378 8.859 15.934C8.753 15.971 8.626 16 8.5 16C8.374 16 8.253 15.976 8.141 15.934C6.55 15.378 1.516 12.256 1.5 7.025ZM8.5 4C10.156 4 11.5 5.344 11.5 7C11.5 8.656 10.156 10 8.5 10C6.844 10 5.5 8.656 5.5 7C5.5 5.344 6.844 4 8.5 4Z"
            />
        </svg>
    );
}
