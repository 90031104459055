import React, { SVGProps } from 'react';
import color from '../../../../../config/Colors';

type Props = {} & SVGProps<SVGSVGElement>;

export default function GeneralUploadPermitIcon(props: Props) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.51 3.51092H8.3516C6.42622 3.50352 4.84797 5.03826 4.80261 6.96271V16.8793C4.76003 18.8352 6.31052 20.4561 8.26644 20.4996C8.29514 20.4996 8.32291 20.5005 8.3516 20.4996H15.7467C17.685 20.4209 19.2124 18.8195 19.1985 16.8793V8.39471L14.51 3.51092Z"
                stroke={color.primary}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.2671 3.5V6.19275C14.2671 7.50719 15.3297 8.57262 16.6442 8.57633H19.1944"
                stroke={color.primary}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.0933 15.1712H9.09473"
                stroke={color.primary}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.2008 11.6977H9.09424"
                stroke={color.primary}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
