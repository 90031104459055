import _ from 'lodash';
import React, { SVGProps } from 'react';
import color from '../../../../config/Colors';

type Props = { isDisable?: boolean } & SVGProps<SVGSVGElement>;
export default function IconResendMail(props: Props) {
    return (
        <svg
            {..._.omit(props, 'isDisable')}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
        >
            <path
                fill={props.isDisable ? color.grey400 : '#85858A'}
                d="M2.5 2.667c-.733 0-1.333.6-1.333 1.333v8c0 .733.6 1.333 1.333 1.333h7A4.332 4.332 0 019.167 12H2.5V5.333l5.334 3.334 5.333-3.334v2c.111-.004.222-.004.333 0 .337.001.673.042 1 .12V4c0-.733-.6-1.333-1.333-1.333H2.5zM2.5 4h10.667L7.834 7.333 2.5 4zm10.667 4l-1.5 1.5 1.5 1.5v-1a1.667 1.667 0 011.667 1.667c0 .266-.06.52-.174.746l.727.727A2.666 2.666 0 0013.167 9V8zm-2.22 2.193a2.666 2.666 0 002.22 4.14v1l1.5-1.5-1.5-1.5v1a1.667 1.667 0 01-1.667-1.666c0-.267.06-.52.174-.747l-.727-.727z"
            ></path>
        </svg>
    );
}
