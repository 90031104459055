/* eslint-disable eqeqeq */
import { Box, FormControlLabel, Grid, Radio, RadioGroup, TextField, Theme, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DatePicker, LocalizationProvider, PickersDay } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { addDays } from 'date-fns';
import { useContext } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import CalendarIcon from '../../../../../../assets/svg/CalendarIcon';
import LabelWithRequired from '../../../../../../components/LabelWithRequired';
import color from '../../../../../../config/Colors';
import { LanguageContext } from '../../../../../../contexts/useLanguage';
import useMedia from '../../../../../../hooks/useMedia';
import { ValidateToType } from '../../../../../../models/permits/Permit';
import { RecurringType } from '../../../../../../models/permits/PermitRecurring';
import { PermitFromData, UpsertPermitContext } from '../../../hooks/useUpsertPermit';
import { RecurProps } from '../../recurringType/DayRecurring';
export const useStylesPicker = makeStyles((theme: Theme) => ({
    inputStyle: {
        '& .Mui-selected': {
            backgroundColor: `${color.primary} !important`,
        },
    },
}));

export default function UpsertPermitItemDateRange({ isEdit, parentForm }: RecurProps) {
    const { indexForm } = parentForm;
    const {
        control,
        setValue,
        formState: { errors },
        clearErrors,
    } = useFormContext<PermitFromData>();

    const { validateFrom, permitRecurringType, validateTo, validateToType, validateToAfterRecurringTime } = useWatch({
        control: control,
        name: `permits.${indexForm}`,
    });

    const classes = useStylesPicker();
    const { t } = useContext(LanguageContext);
    const { permitEdit } = useContext(UpsertPermitContext);
    const { isMobileSM } = useMedia();

    // useEffect(() => {
    //     if (validateFrom !== null && Number(validateToType) === ValidateToType.absoluteAt) {
    //         // setFieldTouched('validateTo');
    //     } else {
    //         // setFieldTouched('validateTo', false);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [validateToType, validateFrom]);

    const isDisableEndAfter = () => {
        if (!isEdit) return false;
        if ([ValidateToType.absoluteAt, ValidateToType.infinite].includes(permitEdit!.validateToType)) return true;

        return false;
    };

    const isDisableEditingRecurTime = () => {
        if (!isEdit) return false;
        if ([ValidateToType.absoluteAt, ValidateToType.infinite].includes(permitEdit!.validateToType!)) return true;
        if (permitEdit!.status === 'expired') return true;
        return false;
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container xs={12} item justifyContent={'space-between'} alignItems="center" spacing={2}>
                <Grid
                    mt={0.5}
                    xs={12}
                    display={permitRecurringType === RecurringType.once ? 'none' : 'block'}
                    item
                    mb={Boolean(errors?.permits?.[indexForm]?.validateTo) ? 1.5 : 0}
                >
                    {![RecurringType.once, RecurringType.indefinite].includes(permitRecurringType!) && (
                        <Controller
                            control={control}
                            name={`permits.${indexForm}.validateToType`}
                            render={({ field, fieldState: { error } }) => (
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    value={field.value}
                                    name="radio-buttons-group"
                                    onChange={(e: any, val) => {
                                        const validTo = val != '0' ? addDays(validateFrom!, 1) : null;
                                        field.onChange(Number(val));
                                        setValue(`permits.${indexForm}.validateTo`, validTo, { shouldTouch: true });
                                        if (Number(val) !== 0) {
                                            clearErrors(`permits.${indexForm}.validateTo`);
                                        }
                                    }}
                                    color={color.success}
                                    sx={{ flexDirection: 'row' }}
                                >
                                    <Grid
                                        item
                                        container
                                        display={'flex'}
                                        direction={'row'}
                                        alignItems="center"
                                        mr={isMobileSM ? 0 : 2}
                                        md={4}
                                        sm={12}
                                        // mt={{ xs: 1, sm: 0 }}
                                    >
                                        <Grid xs={3.8} item>
                                            <FormControlLabel
                                                sx={{
                                                    '&.MuiFormControlLabel-labelPlacementEnd': {
                                                        marginRight: '0px !important',
                                                    },
                                                    minWidth: '100px',
                                                }}
                                                value={ValidateToType.absoluteAt}
                                                disabled={validateFrom === null}
                                                control={<Radio />}
                                                label={
                                                    <Typography variant="body1">{t('permits:text.endDate')}</Typography>
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={8.2}>
                                            <DatePicker
                                                label={<LabelWithRequired label={t('permits:text.endDate')} />}
                                                value={validateToType != 0 ? null : validateTo}
                                                onChange={(newValue) => {
                                                    if (newValue != null) {
                                                        setValue(`permits.${indexForm}.validateTo`, newValue, {
                                                            shouldValidate: true,
                                                            shouldTouch: true,
                                                        });
                                                    }
                                                }}
                                                disabled={
                                                    validateToType != ValidateToType.absoluteAt || validateFrom == null
                                                }
                                                components={{
                                                    OpenPickerIcon: CalendarIcon,
                                                }}
                                                inputFormat="dd/MM/yyyy"
                                                minDate={validateFrom}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        error={Boolean(errors?.permits?.[indexForm]?.validateTo)}
                                                        helperText={errors?.permits?.[indexForm]?.validateTo?.message}
                                                        sx={{
                                                            '& .MuiInputBase-input': {
                                                                caretColor: 'transparent',
                                                            },
                                                            // maxWidth: '180px',
                                                        }}
                                                        onKeyDown={(e) => {
                                                            e.preventDefault();
                                                        }}
                                                        fullWidth={true}
                                                    />
                                                )}
                                                renderDay={(day, _value, DayComponentProps) => {
                                                    return (
                                                        <Box className={classes.inputStyle} key={JSON.stringify(day)}>
                                                            <PickersDay {...DayComponentProps} />
                                                        </Box>
                                                    );
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        display={'flex'}
                                        direction={'row'}
                                        alignItems="center"
                                        mr={isMobileSM ? 0 : 2}
                                        item
                                        container
                                        md={4}
                                        sm={12}
                                        mt={{ xs: Boolean(errors?.permits?.[indexForm]?.validateTo) ? 4 : 1, md: 0 }}
                                    >
                                        <Grid item xs={3.8}>
                                            <FormControlLabel
                                                sx={{
                                                    '&.MuiFormControlLabel-labelPlacementEnd': {
                                                        marginRight: '0px !important',
                                                    },
                                                }}
                                                value={ValidateToType.recurringTime}
                                                control={<Radio />}
                                                disabled={isEdit ? isDisableEndAfter() : validateFrom === null}
                                                label={
                                                    <Typography
                                                        variant="body1"
                                                        sx={{
                                                            color: isDisableEndAfter() ? color.grey600 : '',
                                                        }}
                                                    >
                                                        {t('permits:text.endAfter')}
                                                    </Typography>
                                                }
                                            />
                                        </Grid>

                                        <Grid item xs={8.2}>
                                            <TextField
                                                value={validateToAfterRecurringTime}
                                                disabled={
                                                    isEdit
                                                        ? isDisableEditingRecurTime()
                                                        : validateToType != ValidateToType.recurringTime
                                                }
                                                type={'number'}
                                                inputProps={{ min: 1, max: 9999 }}
                                                onChange={(e) => {
                                                    const value = Number(e.target!.value);
                                                    const val = value < 1 ? 1 : value > 9999 ? 9999 : value;
                                                    setValue(`permits.${indexForm}.validateToAfterRecurringTime`, val);
                                                }}
                                                label={
                                                    <Tooltip
                                                        title={t(
                                                            'permits:text.setParametersRecurNumberOfOccurrencesMeansActive'
                                                        )}
                                                        placement="top"
                                                        arrow
                                                    >
                                                        <span>
                                                            <LabelWithRequired
                                                                label={t('permits:text.numberOfOccurences')}
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                }
                                                placeholder={t('permits:text.enterTime')}
                                                sx={{ minWidth: '120px' }}
                                                fullWidth
                                                size="small"
                                            />
                                        </Grid>
                                    </Grid>
                                    {/* <Grid md={2} sm={12} item mt={{ xs: 1, md: 0 }}>
                                        <FormControlLabel
                                            disabled={validateFrom === null}
                                            value={ValidateToType.infinite}
                                            control={<Radio />}
                                            label={t('permits:text.noEndDate')}
                                        />
                                    </Grid> */}
                                </RadioGroup>
                            )}
                        />
                    )}
                </Grid>
            </Grid>
        </LocalizationProvider>
    );
}
