import { TableBody, TableCell, TableRow } from '@mui/material';
import { formatDate } from '../../../helpers/moment';
import { AnprMatchesDailyStats } from '../../../models/AnprMatchesDailyStats';
import { CapacityAnalysis } from '../../../models/CapacityAnalysis';
import { DailyVisitsStatistic } from '../../../models/DailyVisitsStatistic';

type Props = {
    capacityAnalysis: CapacityAnalysis;
};

export default function TableRowsCapacityAnalysis(props: Props) {
    return (
        <TableBody>
            {props.capacityAnalysis.dailyData &&
                props.capacityAnalysis.dailyData.map((row, index) => {
                    return (
                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" align="center" scope="row">
                                {formatDate.getLocalDay(row.inDate)}
                            </TableCell>
                            <TableCell align="center">{Number(row.sumStayDuration_0_8!.toFixed(1))}%</TableCell>
                            <TableCell align="center">{Number(row.sumStayDuration_8_16!.toFixed(1))}%</TableCell>
                            <TableCell align="center">{Number(row.sumStayDuration_16_24!.toFixed(1))}%</TableCell>
                        </TableRow>
                    );
                })}
        </TableBody>
    );
}
