import React, { SVGProps } from 'react';
import color from '../../config/Colors';

type Props = {} & SVGProps<SVGSVGElement>;

export default function AddTenantIcon(props: Props) {
    return (
        <svg
            width="16"
            height="16"
            fill="#85858A"
            className="svg-icon-fill"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M5.72487 8.11954C4.91871 7.64762 4.37705 6.77262 4.37705 5.77263V3.71738C4.37705 2.21739 5.59444 1 7.09443 1C8.59443 1 9.81181 2.21739 9.81181 3.71738V5.77263C9.81181 6.77262 9.27015 7.64762 8.46399 8.11954V8.84055C8.53412 8.84072 8.60384 8.84267 8.6731 8.84635C7.70659 9.67694 7.09443 10.9083 7.09443 12.2826C7.09443 12.9267 7.22889 13.5394 7.47126 14.0942H2.56546C2.06546 14.0942 1.65967 13.6884 1.65967 13.1884V12.9166C1.65967 10.6703 3.48031 8.84599 5.72487 8.84055V8.11954Z" />
            <path d="M11.1705 15H12.0763C12.3263 15 12.5292 14.7971 12.5292 14.5471V13.1884H13.8879C14.1379 13.1884 14.3408 12.9855 14.3408 12.7355V11.8297C14.3408 11.5797 14.1379 11.3768 13.8879 11.3768H12.5292V10.0181C12.5292 9.76813 12.3263 9.56524 12.0763 9.56524H11.1705C10.9205 9.56524 10.7176 9.76813 10.7176 10.0181V11.3768H9.35892C9.10892 11.3768 8.90602 11.5797 8.90602 11.8297V12.7355C8.90602 12.9855 9.10892 13.1884 9.35892 13.1884H10.7176V14.5471C10.7176 14.7971 10.9205 15 11.1705 15Z" />
        </svg>
    );
}
