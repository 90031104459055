import { Button, Grid, TextField, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import color from '../../../../config/Colors';
import { LanguageContext } from '../../../../contexts/useLanguage';
import { formatNumber } from '../../../../helpers/FormatCurrency';
import * as Yup from 'yup';
import Zone from '../../../../models/Zone';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { zoneController } from '../../../../controllers';
import { CompaniesContext } from '../../../../hooks/useCompanies';
import toast from 'react-hot-toast';

type Props = {
    handleClose: () => void;
    zone: Zone;
};

export default function UpdatePermitsModal(props: Props) {
    const { t } = useContext(LanguageContext);
    const { setState } = useContext(CompaniesContext);

    const schema = Yup.object({
        maxPermits: Yup.number()
            .integer()
            .typeError(t(`validation:maxNoOfPermitsMustBeANumber`))
            .min(
                props.zone.allocated,
                t(`companies:tenants.maxPermitsCanNotBeLessThan`, {
                    number: formatNumber.format(props.zone.allocated),
                }) + '.'
            )
            .required(t('validation:thisFieldIsRequired')),
    });

    const [maxPermits, setMaxPermits] = useState<number>(props.zone.maxPermits);

    const {
        control,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm({
        mode: 'all',
        defaultValues: {
            maxPermits: maxPermits,
        },
        resolver: yupResolver(schema),
    });

    const onSubmit = (data: any) => {
        zoneController
            .editPermits({ zoneId: props.zone.id, maxPermits: data.maxPermits })
            .then((res) => {
                //close popup
                props.handleClose();
                //reload zone
                setState((prev) => ({ ...prev, isReloadLocationSelect: true }));
                //notifier popup
                toast.success(t('notification:changesSaved'));
            })
            .catch((err) => {
                if (err.response.status !== 500) toast.error(err.response.data.value);
            });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container sx={{ padding: '40px 24px' }}>
                <Typography variant="h4" sx={{ width: '100%' }} textAlign="center">
                    {t('companies:tenants.editMaxPermits')}
                </Typography>
                <Grid
                    item
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent="space-around"
                    xs={12}
                    mt={2}
                    sx={{
                        background: color.grey100,
                        borderRadius: '3px',
                        padding: '4px 0',
                    }}
                >
                    <Grid item xs={6} sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                        <Typography variant="body1" component={'span'} color={color.success}>
                            {t('companies:tenants.zone')}: {props.zone.name}
                        </Typography>
                    </Grid>

                    <Typography variant="body1" component={'span'} color={color.secondary}>
                        {t('companies:tenants.allocated')}: {formatNumber.format(props.zone.allocated)}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    mt={4}
                    sx={{
                        '& .MuiFormHelperText-root': {
                            position: 'absolute',
                            top: '40px',
                            left: 0,
                            fontSize: '12px',
                        },
                    }}
                >
                    <Controller
                        control={control}
                        name={'maxPermits'}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                error={!!errors.maxPermits?.message}
                                helperText={errors.maxPermits?.message as any}
                                fullWidth
                                type={'number'}
                                required
                                onInput={(e: any) => {
                                    e.target.value =
                                        Math.max(0, parseInt(e.target.value) || 0)
                                            .toString()
                                            .slice(0, 9) || 0;
                                }}
                                label={t('companies:tenants.maxPermits')}
                                placeholder={t('companies:tenants.enterMaxNoOfPermits')}
                                onChange={(e) => {
                                    setMaxPermits(Number(e.target.value));
                                    field.onChange(e);
                                }}
                                value={maxPermits}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} display="flex" flexDirection={'row'} justifyContent="space-between">
                    <Button
                        variant="cancel"
                        sx={{ minWidth: { xs: '100px', sm: '150px' } }}
                        onClick={props.handleClose}
                    >
                        {t('action:cancel')}
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        disabled={!isValid}
                        sx={{ minWidth: { xs: '100px', sm: '150px' } }}
                    >
                        {t('action:save')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}
