import React, { SVGProps } from 'react';

type Props = {} & SVGProps<SVGSVGElement>;

export default function DetailIcon(props: Props) {
    return (
        <svg
            {...props}
            className="detail-icon"
            width="16"
            height="16"
            stroke="#85858A"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.5655 11.0142H5.46719"
                // stroke="#85858A"
                fill="none"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.5655 8.05798H5.46719"
                // stroke="#85858A"
                fill="none"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.4127 5.10857H5.46729"
                // stroke="#85858A"
                fill="none"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                fill="none"
                d="M10.7013 1.5C10.7013 1.5 5.28032 1.50282 5.27185 1.50282C3.32291 1.51483 2.11612 2.79718 2.11612 4.75318V11.2468C2.11612 13.2127 3.33209 14.5 5.29798 14.5C5.29798 14.5 10.7183 14.4979 10.7275 14.4979C12.6764 14.4859 13.8839 13.2028 13.8839 11.2468V4.75318C13.8839 2.78729 12.6672 1.5 10.7013 1.5Z"
                // stroke="#85858A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
