import { BaseHttpController } from 'ale-base-model';
import { AxiosInstance } from 'axios';
import { Filter } from '../models/Filter';
import { FilterTenant } from '../models/BaseFilter';

export class AnprDashboardDownload extends BaseHttpController<object> {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'AnprDashboardDownload', client);
    }
    async DownloadDailyVisits(props: { filter: Filter; isPdf: boolean }): Promise<File> {
        const res = await this.doPost({
            path: `DownloadDailyVisits?isPdf=${props.isPdf}`,
            body: props.filter,
            config: { responseType: 'blob' },
        });
        return res.data;
    }
    async DownloadTenantList(props: { filter: FilterTenant }): Promise<File> {
        const res = await this.doPost({
            path: `DownloadTenantList`,
            body: props.filter,
            config: { responseType: 'blob' },
        });
        return res.data;
    }
    async DownloadAverageLengthOfVisits(props: { filter: Filter; isPdf: boolean }): Promise<File> {
        const res = await this.doPost({
            path: `DownloadAverageLengthOfVisits?isPdf=${props.isPdf}`,
            body: props.filter,
            config: { responseType: 'blob' },
        });
        return res.data;
    }
    async DownloadCapacityAnalysis(props: { filter: Filter; isPdf: boolean }): Promise<File> {
        const res = await this.doPost({
            path: `DownloadCapacityAnalysis?isPdf=${props.isPdf}`,
            body: props.filter,
            config: { responseType: 'blob' },
        });
        return res.data;
    }
    async DownloadRepeatVisit(props: { filter: Filter; isPdf: boolean }): Promise<File> {
        const res = await this.doPost({
            path: `DownloadRepeatVisit?isPdf=${props.isPdf}`,
            body: props.filter,
            config: { responseType: 'blob' },
        });
        return res.data;
    }
    async DownloadGroupEVDailyVisits(props: { filter: Filter; isPdf: boolean }): Promise<File> {
        const res = await this.doPost({
            path: `DownloadDailyEVVisits?isPdf=${props.isPdf}`,
            body: props.filter,
            config: { responseType: 'blob' },
        });
        return res.data;
    }
    async DownloadGroupEVAverageLength(props: { filter: Filter; isPdf: boolean }): Promise<File> {
        const res = await this.doPost({
            path: `DownloadEVLengthOfVisits?isPdf=${props.isPdf}`,
            body: props.filter,
            config: { responseType: 'blob' },
        });
        return res.data;
    }
    async DownloadGrossRevenue(props: { filter: Filter; isPdf: boolean }): Promise<File> {
        const res = await this.doPost({
            path: `DownloadGrossRevenueBySource?isPdf=${props.isPdf}`,
            body: props.filter,
            config: { responseType: 'blob' },
        });
        return res.data;
    }
    async DownloadGrossRevenueByLongTerm(props: { filter: Filter; isPdf: boolean }): Promise<File> {
        const res = await this.doPost({
            path: `DownloadGrossRevenueByLongTerm?isPdf=${props.isPdf}`,
            body: props.filter,
            config: { responseType: 'blob' },
        });
        return res.data;
    }

    async DownloadGrossRevenueByLts(props: { filter: Filter; isPdf: boolean }): Promise<File> {
        const res = await this.doPost({
            path: `DownloadGrossRevenueByLTs?isPdf=${props.isPdf}`,
            body: props.filter,
            config: { responseType: 'blob' },
        });
        return res.data;
    }
}
