import { Grid, Stack } from '@mui/material';
import { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import LoadingCircular from '../components/LoadingCircular';
import MyAppBar from '../components/MyAppBar';
import WrapContainer from '../components/layout/Wrapcontainer';
import { AuthContext } from '../hooks/useAuth';
import useMedia from '../hooks/useMedia';
import DrawerLarge from '../pages/dashboardLayout/components/DrawerLarge';
import DrawerSmall from '../pages/dashboardLayout/components/DrawerSmall';
import Footer from '../pages/dashboardLayout/components/Footer';
import useDrawer, { DrawerContext } from '../pages/dashboardLayout/useDrawer';

export default function PrivateRouter() {
    const { isAuth, isLoading } = useContext(AuthContext);
    const location = useLocation();
    const { isMediumMD, isMobileSM } = useMedia();

    const drawerContext = useDrawer();

    return isLoading ? (
        <LoadingCircular />
    ) : isAuth ? (
        <DrawerContext.Provider value={drawerContext}>
            <MyAppBar isShowLabelAccount={true} />
            <Stack
                flexDirection={'row'}
                display={'flex'}
                minHeight={'calc(100vh - 61px)'}
                width={isMobileSM || isMediumMD ? '100%' : 'calc(100vw - 8px)'}
            >
                <DrawerLarge />
                {isMediumMD || isMobileSM ? <DrawerSmall /> : ''}
                <Grid container sx={{ overflow: 'auto' }}>
                    <WrapContainer>
                        <Outlet />
                    </WrapContainer>
                    <Footer />
                </Grid>
            </Stack>
            {/* <Footer /> */}
        </DrawerContext.Provider>
    ) : (
        <Navigate to={'/'} state={{ from: location }} replace />
    );
}
