import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { LanguageContext } from '../../../contexts/useLanguage';
import { FilterSupportContext, FilterSupportEnum, SupportContextProps } from '../hook/useFilterSupportByLocation';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SendMailSupport } from '../../../models/SendMailSupport';
import { locationController } from '../../../controllers';
import { toast } from 'react-hot-toast';
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Grid,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import SupportItem from '../components/SupportItem';
import color from '../../../config/Colors';
import CheckBoxIcon from '../../../assets/images/CheckBoxIcon';
import Checked from '../../../assets/images/Checked';
import Required from '../../companyLayout/employees/components/Required';
import { LoadingButton } from '@mui/lab';

export default function SupportTab(props: SupportContextProps) {
    const filterLocationData = props;
    const { supportList, listUserId, setListUserId, setState, setSupportList, filter, onChangeFilter } =
        filterLocationData;
    const { t } = useContext(LanguageContext);
    const [loading, setLoading] = useState(false);

    const schema = Yup.object({
        subject: Yup.string().required(t('validation:subjectIsRequired')),
        message: Yup.string().required(t('validation:messageIsRequired')),
    });

    const {
        control,
        formState: { errors, isValid },
    } = useForm({
        mode: 'all',
        resolver: yupResolver(schema),
    });
    const selectAll = (e: any) => {
        let arr: number[] = [];
        if (e.target.checked) {
            supportList.map((i) => arr.push(Number(i.id)));
        } else {
            arr = [];
        }
        setListUserId(arr);
    };

    const [sendMailSupport, setSendMailSupport] = useState<SendMailSupport>({} as SendMailSupport);

    useEffect(() => {
        setSupportList([]);
        setListUserId([]);
    }, [filter.companyId, filter.locationId, setListUserId, setSupportList]);

    const onSend = () => {
        setLoading(true);
        let _sendMailSupport: SendMailSupport = {
            userIds: listUserId,
            subject: sendMailSupport.subject,
            message: sendMailSupport.message,
        };

        locationController
            .sendMailSupport(_sendMailSupport)
            .then((res) => {
                toast.success(t('notification:sent'));
                setSendMailSupport({ subject: '', message: '' } as SendMailSupport);
                setListUserId([]);
                setState((prev) => ({
                    ...prev,
                    filter: { ...prev.filter, companyId: 0, locationId: 0, zoneIds: [] },
                }));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const checkDisable = () => {
        if (isValid && listUserId.length > 0) {
            return false;
        }
        return true;
    };

    return (
        <FilterSupportContext.Provider value={filterLocationData}>
            <Stack>
                {filterLocationData.FilterComponents}

                <Box pt={2}>
                    <Typography variant="h4" fontWeight={400}>
                        {t('support:title.mySupportContacts')} ({filterLocationData.supportList.length})
                    </Typography>

                    <Box pt={4}>
                        <Grid
                            container
                            direction={'row'}
                            justifyContent={'flex-start'}
                            alignItems={'center'}
                            spacing={filterLocationData.supportList.length > 0 ? 1.5 : 0}
                        >
                            {filterLocationData.isLoading ? (
                                <Stack width={'100%'} p={2} minHeight={200}>
                                    <CircularProgress sx={{ margin: 'auto', color: color.priText }} />
                                </Stack>
                            ) : filterLocationData.supportList.length > 0 ? (
                                filterLocationData.supportList.map((item, index) => (
                                    <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                                        <SupportItem supportItem={item} />
                                    </Grid>
                                ))
                            ) : (
                                <Grid
                                    item
                                    container
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    sx={{
                                        background: color.grey100,
                                        borderRadius: '10px',
                                        padding: '10px 0',
                                        height: 200,
                                    }}
                                >
                                    <Typography fontSize={20} fontWeight={500} color={'#85858A'}>
                                        {filterLocationData.filter.locationId != 0
                                            ? t('support:text.noSupporter')
                                            : t('support:text.pleaseSelectALocationWhichNeedsSupport')}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Box>

                    {filterLocationData.supportList.length > 0 ? (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    icon={<CheckBoxIcon />}
                                    checkedIcon={<Checked />}
                                    onChange={(e) => selectAll(e)}
                                    checked={listUserId.length == filterLocationData.supportList.length}
                                />
                            }
                            label={
                                <Typography variant="body2" color={'#85858A'}>
                                    {t('support:text.selectAll')}
                                </Typography>
                            }
                        />
                    ) : (
                        <></>
                    )}
                    <Grid pt={3} container direction={'column'} justifyContent={'flex-start'} alignItems={'flex-start'}>
                        <Grid item container>
                            <Controller
                                control={control}
                                name={'subject'}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        error={!!errors.subject?.message}
                                        helperText={errors.subject?.message as any}
                                        fullWidth
                                        placeholder={t('input:enterSubject')}
                                        label={
                                            <>
                                                {t('support:text.subject')} <Required />
                                            </>
                                        }
                                        inputProps={{ maxLength: 200 }}
                                        onChange={(e) => {
                                            setSendMailSupport({ ...sendMailSupport, subject: e.target.value });
                                            field.onChange(e);
                                        }}
                                        value={sendMailSupport.subject}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        pt={3}
                        container
                        direction={'column'}
                        justifyContent={'flex-start'}
                        alignItems={'flex-start'}
                        sx={{
                            '& textarea': {
                                padding: '0px !important',
                            },
                        }}
                    >
                        <Grid item container>
                            <Controller
                                control={control}
                                name={'message'}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        // required
                                        error={!!errors.message?.message}
                                        helperText={errors.message?.message as any}
                                        sx={{
                                            '& .MuiInputBase-input': { p: '0px' },
                                            '& .MuiOutlinedInput-root': { p: '10px 14px' },
                                        }}
                                        fullWidth
                                        multiline
                                        rows={8}
                                        label={
                                            <>
                                                {t('support:text.message')} <Required />
                                            </>
                                        }
                                        placeholder={t('input:enterYourMessage')}
                                        inputProps={{ maxLength: 500 }}
                                        onChange={(e) => {
                                            setSendMailSupport({ ...sendMailSupport, message: e.target.value });
                                            field.onChange(e);
                                        }}
                                        value={sendMailSupport.message}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    <Grid pt={6} container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                        <Grid item xs={5} sm={4} md={2}>
                            <Button
                                fullWidth
                                sx={{ background: color.grey300, '&:hover': { background: color.grey400 } }}
                                onClick={() => {
                                    setSendMailSupport({ subject: '', message: '' } as SendMailSupport);
                                    setListUserId([]);
                                    setSupportList([]);
                                    setState((prev) => ({
                                        ...prev,
                                        filter: { ...prev.filter, companyId: 0, locationId: 0, zoneIds: [] },
                                    }));
                                    onChangeFilter(FilterSupportEnum.all, '');
                                }}
                            >
                                <Typography>{t('support:text.clearContent')}</Typography>
                            </Button>
                        </Grid>

                        <Grid item xs={5} sm={4} md={2}>
                            <LoadingButton
                                loading={loading}
                                loadingPosition="start"
                                startIcon={<></>}
                                variant="contained"
                                fullWidth
                                sx={{ background: color.primary, '&:hover': { background: color.darkPrimary } }}
                                disabled={checkDisable()}
                                onClick={onSend}
                            >
                                <Typography color={color.white}>{t('support:text.sendEmail')}</Typography>
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Box>
            </Stack>
        </FilterSupportContext.Provider>
    );
}
