import React, { SVGProps } from 'react';

type Props = {} & SVGProps<SVGSVGElement>;

export default function ChipDeleteIcon(props: Props) {
    return (
        <svg
            {...props}
            className="svg-icon-stroke"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            stroke="#85858A"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M9.33341 6.66663L6.66675 9.33329" strokeLinecap="round" />
            <path d="M9.33341 9.33329L6.66675 6.66663" strokeLinecap="round" />
            <path d="M14.1666 8.00004C14.1666 4.59428 11.4057 1.83337 7.99992 1.83337C4.59416 1.83337 1.83325 4.59428 1.83325 8.00004C1.83325 11.4058 4.59416 14.1667 7.99992 14.1667C11.4057 14.1667 14.1666 11.4058 14.1666 8.00004Z" />
        </svg>
    );
}
