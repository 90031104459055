import { Grid, Stack, Typography } from '@mui/material';
import React, { ReactNode } from 'react';

type Props = {
    firstItem: string;
    lastItem: ReactNode;
};
export default function LineWithTwoItem({ firstItem, lastItem }: Props) {
    return (
        <Grid item xs={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} width={'100%'}>
            <Typography variant="body1" component={'span'}>
                {firstItem}
            </Typography>
            {typeof lastItem === 'string' ? (
                <Typography variant="body1" component={'span'}>
                    {lastItem}
                </Typography>
            ) : (
                lastItem
            )}
        </Grid>
    );
}
