import { Button, Container, Stack, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import { AuthContext } from '../../hooks/useAuth';
import { Role } from '../../models/permission/Role';
import Footer from '../dashboardLayout/components/Footer';
import { BaseDivider, BaseSubHeading } from './components';
import BaseFAQ from './components/BaseFAQ';
import { AccountManagerSection, SectionFAQ, TenantSection, AgentWithClientSection } from './utils';
import { DrawerContext } from '../dashboardLayout/useDrawer';
import { LanguageContext } from '../../contexts/useLanguage';
type FAQWithAuth = {
    role: Role[];
    components: SectionFAQ[];
};
interface PdfPaths {
    [role: string]: string;
}
export default function FrequentlyAskedQuestions() {
    const { t } = useContext(LanguageContext);
    const { account } = useContext(AuthContext);
    const { setActiveItem } = useContext(DrawerContext);

    const faqWithAuth: FAQWithAuth[] = [
        { role: [Role.Tenant], components: TenantSection() },
        {
            role: [Role.AccountManager],
            components: AccountManagerSection(),
        },
        { role: [Role.ManagingAgent, Role.Client], components: AgentWithClientSection() },
    ];

    let pdfPaths: PdfPaths = {
        [Role.AccountManager]: 'ihub-ipermit-user-manual-account-manager',
        [Role.ManagingAgent]: 'ihub-ipermit-user-manual-agent-client',
        [Role.Client]: 'ihub-ipermit-user-manual-agent-client',
        [Role.Tenant]: 'ihub-ipermit-user-manual-tenant',
    };

    useEffect(() => {
        setActiveItem(7);
    }, []);
    return (
        <>
            <>
                <Stack alignItems="center" spacing={1.5}>
                    <Typography variant="h2" fontWeight={500}>
                        {t('faqs:title.frequentlyAskedQuestions')}
                    </Typography>
                    <a href={`/assets/pdf/${pdfPaths[account.label]}.pdf`} download>
                        <Button variant="contained">{t('faqs:button.downloadTheUserManualPDF')}</Button>
                    </a>
                </Stack>
                {faqWithAuth.map(
                    (item, index) =>
                        item.role.includes(account.label) && (
                            <div key={`${index}+${account.label}`}>
                                {item.components.map((item, index) => {
                                    return (
                                        <>
                                            <BaseSubHeading subHeading={item.subHeading} />
                                            {item.content.map((c, i) => {
                                                return (
                                                    <BaseFAQ
                                                        key={i}
                                                        indexOfQuestion={i + 1}
                                                        question={c.question}
                                                        answer={c.answer}
                                                    />
                                                );
                                            })}
                                            <BaseDivider />
                                        </>
                                    );
                                })}
                            </div>
                        )
                )}
            </>
        </>
    );
}
