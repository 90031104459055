import { DailyVisitsStatistic } from '../models/DailyVisitsStatistic';
import { BaseHttpController } from 'ale-base-model';
import { AxiosInstance } from 'axios';
import { Account } from '../models/Account';
import { Filter } from '../models/Filter';
import { AnprMatchesDailyStats, AnprMatchesDailyStatsByHours } from '../models/AnprMatchesDailyStats';
import { CapacityAnalysis } from '../models/CapacityAnalysis';
import { RepeatVisit } from '../models/RepeatVisits';
import { AverageLengthStatistic } from '../models/AverageLengthStatistic';
import { generateRandom } from '../helpers';
import { GrossRevenue } from '../models/grossRevenue/GrossRevenue';
import { GrossRevenueByType } from '../models/grossRevenue/GrossRevenueByType';
import { GrossRevenueByLT, GrossRevenueByLTStatistic } from '../models/grossRevenue/GrossRevenueByLT';

export class AnprController extends BaseHttpController<Account> {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'AnprDashboard', client);
    }

    async getDailyVisits(filter: Filter): Promise<DailyVisitsStatistic> {
        const res = await this.doPost({ path: 'DailyVisits', body: filter, config: {} });
        const dailyVisitsCount: DailyVisitsStatistic = {
            dailyData: res.data.dailyVisits,
            summaryData: {
                averageVisits: res.data.averageVisits,
                totalVisits: res.data.totalVisits,
                mostVisitedDate: res.data.mostVisited,
                smallVisitedDate: res.data.smallestVisited,
                totalVisitEV: res.data.totalVisitEV,
                averageVisitEV: res.data.averageVisitEV,
                mostVisitedDateEV: res.data.mostVisitedEV,
                smallVisitedDateEV: res.data.smallestVisitedEV,
            },
        };
        // dailyVisitsCount.dailyData.forEach((item, index) => {
        //     const total = item.visitCount
        //     const randomA = generateRandom(1, total / 4)
        //     const randomB = generateRandom(1, total / 4)
        //     const randomC = (total - (randomA + randomB)) / 2
        //     item.qtyTotalOfDiesel = randomC
        //     item.qtyTotalOfPetrol = randomC
        //     item.qtyTotalOfEV = randomA
        //     item.qtyTotalOfOther = randomB
        // });

        return dailyVisitsCount;
    }

    async getAverageLengthOfVisits(
        filter: Filter
    ): Promise<{ averageLengthStatistics: AverageLengthStatistic[]; averageLengthOfStay: number }> {
        const res = await this.doPost({ path: 'AverageLengthOfVisits', body: filter, config: {} });
        let obj = res.data.averageLengthOfVisits[0];
        if (!res.data.averageLengthOfVisits[0]) {
            obj = {
                sum_0_1: 0,
                sum_1_2: 0,
                sum_2_3: 0,
                sum_3_4: 0,
                sum_4_5: 0,
                sum_5_6: 0,
                sum_over_6: 0,
            };
        }

        var result = Object.keys(obj).map((key) => [key, obj[key]]);

        const list = [
            '0 - 1 hour',
            '1 - 2 hours',
            '2 - 3 hours',
            '3 - 4 hours',
            '4 - 5 hours',
            '5 - 6 hours',
            'More than 6 hours',
        ];
        let averageLengthStatistics: AverageLengthStatistic[] = [];
        let total = 0;

        result.map((item, index) => {
            if (index < 7) {
                total += item[1];
            }
        });

        list.map((item, index) => {
            averageLengthStatistics.push({
                hours: item,
                averageLengthOfStay: res.data.averageLengthOfStay,
                maxDuration: res.data.maxDuration,
                minDuration: res.data.minDuration,
                count: result[index][1],
                percentage: Number(((result[index][1] / total) * 100).toFixed(1)) || 0,
            });
        });

        return { averageLengthStatistics: averageLengthStatistics, averageLengthOfStay: res.data.averageLengthOfStay };
    }

    async getCapacityAnalysis(filter: Filter): Promise<CapacityAnalysis> {
        const res = await this.doPost({ path: 'CapacityAnalysis', body: filter, config: {} });
        const capacityAnalysis: CapacityAnalysis = {
            dailyData: res.data.capacityAnalysis,
        };
        capacityAnalysis.dailyData.forEach(element => {
            if(isNaN(element.sumStayDuration_0_8!)) element.sumStayDuration_0_8 = 0;
            if(isNaN(element.sumStayDuration_8_16!)) element.sumStayDuration_8_16 = 0;
            if(isNaN(element.sumStayDuration_16_24!)) element.sumStayDuration_16_24 = 0;
        });
        return capacityAnalysis;
    }

    async getRepeatVisits(filter: Filter) {
        const res = await this.doPost({ path: 'RepeatVisit', body: filter, config: {} });

        let obj = res.data;
        if (!res.data) {
            obj = {
                sum_0_1: 0,
                sum_1_2: 0,
                sum_2_3: 0,
                sum_3_4: 0,
                sum_4_5: 0,
                sum_5_6: 0,
                sum_over_6: 0,
            };
        }

        var result = Object.keys(obj).map((key) => [key, obj[key]]);

        const list = [1, 2, 3, 4, 5, 6, 7];
        let repeatVisits: RepeatVisit[] = [];

        let total = 0;

        result.map((item) => {
            total += item[1];
        });

        list.map((item, index) => {
            repeatVisits.push({
                visitTime: item,
                vehicleCount: result[index][1],
                percentage: Number(((result[index][1] / total) * 100).toFixed(1)) || 0,
            });
        });

        return repeatVisits;
    }

    async IsCameraNotWorking(filter: Filter): Promise<boolean> {
        const res = await this.doPost({ path: 'IsCameraNotWorking', body: filter, config: {} });
        return res.data;
    }

    async getGrossRevenue(filter: Filter): Promise<GrossRevenue> {
        const res = await this.doPost({ path: 'GrossRevenue', body: filter, config: {} });
        return res.data;
    }

    async getGrossRevenueByLongTerm(filter: Filter): Promise<GrossRevenueByType> {
        const res = await this.doPost({ path: 'GrossRevenueByLongTerm', body: filter, config: {} });
        return res.data;
    }

    async getGrossRevenueAmountLTs(filter: Filter): Promise<GrossRevenueByLTStatistic> {
        const res = await this.doPost({ path: 'GrossRevenueAmountLongTerm', body: filter, config: {} });
        return res.data;
    }
}
