/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { useContext } from 'react';
import { useLocation, Link } from 'react-router-dom';
import color from '../config/Colors';
import { Breadcrumbs, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { CompaniesContext } from '../hooks/useCompanies';
import { EmployeeContext } from '../hooks/useEmployee';
import { LocationsContext } from '../hooks/useLocations';
import { LanguageContext } from '../contexts/useLanguage';

interface PathRouter {
    router: string;
    name: string;
}

const MyBreadcrumbs = () => {
    const { pathname } = useLocation();
    const { companyPaging, companySelect } = useContext(CompaniesContext);
    const { employeePagination } = useContext(EmployeeContext);
    const { locationPaging } = useContext(LocationsContext);
    const { t } = useContext(LanguageContext);

    const pathnames = pathname.split('/').filter(Boolean);
    const paths: PathRouter[] = [];

    //delete general
    pathnames.map((item, index) => {
        if (item == 'general-information' || item == 'zones' || item == 'tenants') {
            pathnames.splice(index, 1);
        }
    });

    pathnames.map((item) => paths.push({ router: item, name: item }));

    if (companyPaging && companyPaging.data) {
        for (let i = 0; i <= paths.length; i++) {
            if (paths[i - 1]) {
                if (paths[i - 1].router == 'companies') {
                    if (companySelect) {
                        paths[i].name = companySelect.displayName;
                    }
                }
            }
        }
    }

    if (employeePagination) {
        for (let i = 0; i <= paths.length; i++) {
            if (paths[i - 1] && paths[i - 2]) {
                if (paths[i - 1].router == 'update' && paths[i - 2].router == 'employees') {
                    paths[i - 1].name = 'Update employee';
                    pathnames.pop();
                    paths.pop();
                }
            }
            if (paths[i]) {
                if (paths[i - 1] && paths[i - 1].router == 'employees') {
                    if (paths[i].router && paths[i].router == 'create') {
                        paths[i].name = 'Create employee';
                    }
                }
            }
        }
    }

    if (locationPaging) {
        for (let i = 0; i <= paths.length; i++) {
            if (paths[i - 1]) {
                if (paths[i - 1].router == 'locations') {
                    if (locationPaging.data) {
                        const location = locationPaging.data.find((item) => item.id == Number(pathnames[i]));
                        if (location) {
                            paths[i].name = location.name;
                        }
                    }
                }
            }
        }
    }

    for (let i = 0; i <= paths.length; i++) {
        if (paths[i - 1] && paths[i - 2]) {
            if (paths[i - 1].router == 'locations' && paths[i - 2].router) {
                paths[i - 1].router = paths[i - 2].router;
                pathnames[i - 1] = '';
            }
        }
    }

    //rm number before edit permit
    for (let i = 0; i <= paths.length; i++) {
        if (paths[i] && paths[i - 1]) {
            if (paths[i - 1].name == 'edit-permit') {
                pathnames.pop();
                paths.pop();
            }
        }
    }

    for (let i = 0; i <= paths.length; i++) {
        if (paths[i]) {
            if (paths[i].name == 'companies') {
                paths[i].name = t('companies:title.companies');
            }
            if (paths[i].name == 'employees') {
                paths[i].name = t('companies:text.employees');
            }
            if (paths[i].name == 'locations') {
                paths[i].name = t('companies:text.locations');
            }
            if (paths[i].name == 'Update employee') {
                paths[i].name = t('companies:title.editEmployee');
            }
            if (paths[i].name == 'Create employee') {
                paths[i].name = t('companies:title.addEmployee');
            }
            if (paths[i].name == 'permits') {
                paths[i].name = t('permits:title.permits');
            }
            if (paths[i].name == 'add-permits') {
                paths[i].name = t('permits:button.addPermits');
            }
            if (paths[i].name == 'edit-permit') {
                paths[i].name = t('permits:title.editPermit');
            }
            if (paths[i].name == 'upload-permits') {
                paths[i].name = t('permits:title.uploadPermits');
            }
            if (paths[i].name == 'export-data') {
                paths[i].name = t('permits:button.exportData');
            }
        }
    }

    return (
        <Breadcrumbs
            aria-label="breadcrumb"
            sx={{
                '& .MuiTypography-root': {
                    textDecoration: 'none',
                },
            }}
            separator={
                <NavigateNextIcon color="secondary" fontSize="small" style={{ width: '18px', height: '18px' }} />
            }
        >
            {/* {pathnames.length ? <Link onClick={() => navigate('/')}>Home</Link> : <Typography> Home </Typography>} */}
            {paths.map((item, index) => {
                const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
                const isLast = index === pathnames.length - 1;
                return isLast ? (
                    <Typography variant="body1" key={index} sx={{ color: color.secondary, textDecoration: 'none' }}>
                        {item.name}
                    </Typography>
                ) : (
                    <Link key={index} to={routeTo}>
                        <Typography
                            variant="body1"
                            key={index}
                            sx={{
                                color: color.secondary,
                                textTransform: 'capitalize',
                                cursor: 'pointer',
                                '&:hover': {
                                    textDecoration: 'underline',
                                },
                            }}
                        >
                            {item.name}
                        </Typography>
                    </Link>
                );
            })}
        </Breadcrumbs>
    );
};

export default MyBreadcrumbs;
