/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Stack, TextField, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import { Controller, FieldArrayWithId, UseFieldArrayReturn, UseFormReturn, useForm } from 'react-hook-form';
import * as yup from 'yup';
import BaseCheckbox from '../../../../../components/BaseCheckbox';
import LabelWithRequired from '../../../../../components/LabelWithRequired';
import color from '../../../../../config/Colors';
import { LanguageContext } from '../../../../../contexts/useLanguage';
import { AllocatedPermitType, AllocatedType } from '../../../../../models/tenant/AllocatedTypeWithAmount';
import { FormData } from './ModalAddTenant';
import useTenant from '../helpers/TenantHelpers';

type Props = {
    fieldData: FieldArrayWithId<FormData, 'allocatedTypes', 'id'>;
    useFields: UseFieldArrayReturn<FormData, 'allocatedTypes', 'id'>;
    formIndex: number;
    formData: UseFormReturn<FormData, any, undefined>;
    isEdit: boolean;
};

export default function AllocatedTypeItem({
    fieldData,
    useFields,
    formIndex,
    isEdit,
    formData: formDataParents,
}: Props) {
    const { t } = useContext(LanguageContext);
    const { getLabelAllocatedTypeName } = useTenant();

    // const { fields } = useFields;

    const { usedPermit, allocatedPermitType } = fieldData;

    const schema = yup.object({
        isActive: yup.boolean().transform((value) => !!value),
        allocatedPermitType: yup.number(),
        usedPermit: yup.number(),
        amount: yup
            .number()
            .when('isActive', (isActive: boolean, schema) => {
                if (isActive)
                    if (isEdit) {
                        if (fieldData.amount === 0) {
                            return schema.required(t('validation:allocatedIsRequired')).min(
                                1,
                                t(`companies:tenants.maxPermitsCanNotBeLessThan`, {
                                    number: 1,
                                })
                            );
                        } else {
                            //because
                            const isOneDay = allocatedPermitType === AllocatedPermitType.Once;
                            const validateValue = isOneDay ? 0 : usedPermit;
                            return schema.required(t('validation:allocatedIsRequired')).min(
                                validateValue,
                                t(`companies:tenants.maxPermitsCanNotBeLessThan`, {
                                    number: validateValue,
                                })
                            );
                        }
                    } else {
                        return schema.required(t('validation:allocatedIsRequired')).min(
                            1,
                            t(`companies:tenants.maxPermitsCanNotBeLessThan`, {
                                number: 1,
                            })
                        );
                    }
                else {
                    return schema;
                }
            })
            .typeError(t('validation:thisFieldIsRequired'))
            .required(t('validation:thisFieldIsRequired')),
    });

    const formData = useForm<AllocatedType>({
        mode: 'all',

        defaultValues: fieldData,
        resolver: yupResolver(schema),
    });

    const {
        control,
        formState: { isValid },
        setError,
        watch,
    } = formData;

    //set Valid to parents
    useEffect(() => {
        formDataParents.setValue(`allocatedTypes.${formIndex}.isValid`, isValid);
    }, [isValid]);

    useEffect(() => {
        if (watch().isActive && Number(watch().amount) === 0) {
            setError(`amount`, {
                type: 'min',
                message: t(`companies:tenants.maxPermitsCanNotBeLessThan`, {
                    number: 1,
                }),
            });
        }
    }, [watch().isActive]);

    console.log(watch());

    return (
        <Grid container key={fieldData.id}>
            <Grid item xs={5}>
                <Controller
                    control={control}
                    name={`isActive`}
                    render={({ field, fieldState }) => (
                        <BaseCheckbox
                            {...field}
                            label={
                                <Stack>
                                    <Typography sx={{ ml: 1 }}>
                                        {getLabelAllocatedTypeName(fieldData.allocatedPermitType)}
                                        {': '}
                                    </Typography>
                                    {isEdit && ![AllocatedPermitType.Once].includes(fieldData.allocatedPermitType) && (
                                        <Typography variant="body2" sx={{ ml: 1, color: color.success }}>
                                            {`${t('action:used')}: ${usedPermit}/${fieldData.amount}`}
                                        </Typography>
                                    )}
                                </Stack>
                            }
                            checkBoxProps={{
                                checked: field.value,
                                onChange(event, checked) {
                                    //check one day permit valid
                                    if (
                                        !checked &&
                                        isEdit &&
                                        usedPermit! > 0 &&
                                        allocatedPermitType !== AllocatedPermitType.Once
                                    )
                                        return;

                                    if (!checked) {
                                        const activeLength =
                                            formDataParents
                                                .getValues()
                                                .allocatedTypes?.map((f) => f.isActive)
                                                .filter((i) => i === true).length ?? 0;
                                        if (activeLength > 1) {
                                            formDataParents.setValue(`allocatedTypes.${formIndex}.isActive`, checked);
                                            field.onChange(checked);
                                            formData.clearErrors();
                                        }
                                    } else {
                                        formDataParents.setValue(`allocatedTypes.${formIndex}.isActive`, checked);
                                        field.onChange(checked);
                                    }
                                },
                                disabled: false,
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={7}>
                {fieldData.allocatedPermitType === AllocatedPermitType.Once ? (
                    <Typography>{t('permits:text.unlimited')}</Typography>
                ) : (
                    <Controller
                        control={control}
                        name={`amount`}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                error={!!error?.message}
                                helperText={error?.message}
                                fullWidth
                                label={<LabelWithRequired label={t('companies:tenants.maxNumberOfPermits')} />}
                                placeholder={t('companies:tenants.enterTheMaximumNumber')}
                                onInput={(e: any) => {
                                    e.target.value =
                                        Math.max(0, parseInt(e.target.value) || 0)
                                            .toString()
                                            .slice(0, 11) || 0;
                                }}
                                onChange={(e) => {
                                    field.onChange(Number(e.target.value));
                                    formDataParents.setValue(
                                        `allocatedTypes.${formIndex}.amount`,
                                        Number(e.target.value)
                                    );
                                }}
                                inputProps={{
                                    maxLength: 11,
                                }}
                                disabled={!formData.getValues().isActive}
                            />
                        )}
                    />
                )}
            </Grid>
        </Grid>
    );
}
