import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { DrawerContext } from '../useDrawer';
import { FiArrowLeft } from 'react-icons/fi';
import LogoutIcon from '../../../assets/images/LogoutIcon';
import MenuIcon from '../../../assets/images/MenuIcon';
import LanguageSelect from '../../../components/LanguageSelect';
import color from '../../../config/Colors';
import ItemDrawer from './DrawerItem';
import { DrawerHeader } from './DrawerLarge';
import { Avatar, Grid, List, Stack, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LanguageContext } from '../../../contexts/useLanguage';
import ConfirmDialog from '../../../components/ConfirmDialog';
import useModal from '../../../components/modal/useModal';
import { appConfig } from '../../../config/AppConfig';
import FeedbackForm from '../../feedback/components/FeedbackForm';

export default function DrawerSmall() {
    const {
        openDrawerSmall,
        setOpenDrawerSmall,
        ListDrawItem,
        logout,
        activeItem,
        setActiveItem,
        open,
        isMobileSM,
        isMediumMD,
        setIsShowDrawer,
    } = useContext(DrawerContext);
    const { t } = useContext(LanguageContext);

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        if (isMobileSM) {
            setIsShowDrawer(false);
        } else if (isMediumMD) {
            setIsShowDrawer(true);
        }

        setOpenDrawerSmall(open);
    };

    let navigate = useNavigate();
    const popUpConfirm = useModal();
    const isShowMultiLanguage = appConfig.flagFeatures.isShowMultiLanguage;
    return (
        <React.Fragment key={1}>
            <Drawer
                anchor={'left'}
                open={openDrawerSmall}
                onClose={toggleDrawer(false)}
                sx={{
                    pt: 5,
                    '& .MuiPaper-root': {
                        // border: 'none',
                        background: color.grey100,
                        pt: 8,
                        px: 1,
                        overflow: 'visible',
                    },
                }}
            >
                <Box sx={{ width: 200 }} role="presentation">
                    <DrawerHeader sx={{ padding: 0, justifyContent: 'center' }} className="drawer-header">
                        <Stack direction={'row'}>
                            {isShowMultiLanguage && (
                                <Stack sx={{ display: 'block' }}>
                                    <LanguageSelect isShowLabel={true} />
                                </Stack>
                            )}

                            <Avatar
                                sx={[
                                    {
                                        background: color.grey100,
                                        color: color.grey600,
                                        display: 'flex',
                                        width: '30px',
                                        height: '30px',
                                        position: 'absolute',
                                        boxShadow: '0 0 3px 2px rgba(0, 0, 0, 0.2) !important',
                                        zIndex: 9,
                                        right: '-15px',
                                    },
                                ]}
                                onClick={toggleDrawer(false)}
                            >
                                <FiArrowLeft style={{ opacity: 1, cursor: 'pointer' }} />
                            </Avatar>
                        </Stack>
                    </DrawerHeader>

                    <List
                        sx={{
                            height: '100%',
                        }}
                    >
                        <Grid container height={'100%'} justifyContent={'space-between'}>
                            <Grid item xs={12}>
                                {ListDrawItem.map((item, index) => {
                                    const isActive = item.id === activeItem;

                                    return (
                                        <Tooltip key={index} title={item.label} placement="right">
                                            <React.Fragment>
                                                <ItemDrawer
                                                    key={index}
                                                    icon={item.icon}
                                                    isActive={isActive}
                                                    label={item.label}
                                                    open={open}
                                                    isSmallDrawer={true}
                                                    onClick={() => {
                                                        setActiveItem(item.id);
                                                        navigate(item.path);
                                                        setOpenDrawerSmall(false);
                                                        setIsShowDrawer(false);
                                                    }}
                                                />
                                            </React.Fragment>
                                        </Tooltip>
                                    );
                                })}
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'space-between'}
                            >
                                <ItemDrawer
                                    key={99}
                                    icon={<LogoutIcon className="svg-item-Menu" />}
                                    isActive={false}
                                    label={t('drawer:logout')}
                                    open={open}
                                    isSmallDrawer={true}
                                    onClick={() => popUpConfirm.setOpen(true)}
                                />
                                <FeedbackForm />
                            </Grid>
                        </Grid>
                    </List>
                </Box>
            </Drawer>
            <ConfirmDialog
                open={popUpConfirm.open}
                title={t('dashboard:title.logOut')}
                content={t('dashboard:text.areYouSureWantToLogOut')}
                onClose={() => popUpConfirm.setOpen(false)}
                onConfirm={logout}
            />
        </React.Fragment>
    );
}
