import React, { useContext } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../hooks/useAuth';

export default function PublicRouter() {
    const { isAuth } = useContext(AuthContext);
    const location = useLocation();

    return isAuth ? <Navigate to={'/'} state={{ from: location }} replace /> : <Outlet />;
}
