import React, { SVGProps } from 'react';
import color from '../../../../../config/Colors';

type Props = {} & SVGProps<SVGSVGElement>;

export default function ValidIcon(props: Props) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.5588 9.50027C17.8351 9.19167 17.8089 8.71752 17.5003 8.44123C17.1917 8.16493 16.7175 8.19113 16.4412 8.49973L17.5588 9.50027ZM13.6308 12.7632L13.0721 12.2629L13.6308 12.7632ZM7.86917 12.7632L8.42794 12.2629L7.86917 12.7632ZM7.55877 11.2921C7.28248 10.9835 6.80833 10.9573 6.49973 11.2336C6.19113 11.5099 6.16493 11.984 6.44123 12.2926L7.55877 11.2921ZM21.25 12C21.25 17.1086 17.1086 21.25 12 21.25V22.75C17.9371 22.75 22.75 17.9371 22.75 12H21.25ZM12 21.25C6.89137 21.25 2.75 17.1086 2.75 12H1.25C1.25 17.9371 6.06294 22.75 12 22.75V21.25ZM2.75 12C2.75 6.89137 6.89137 2.75 12 2.75V1.25C6.06294 1.25 1.25 6.06294 1.25 12H2.75ZM12 2.75C17.1086 2.75 21.25 6.89137 21.25 12H22.75C22.75 6.06294 17.9371 1.25 12 1.25V2.75ZM16.4412 8.49973L13.0721 12.2629L14.1896 13.2635L17.5588 9.50027L16.4412 8.49973ZM8.42794 12.2629L7.55877 11.2921L6.44123 12.2926L7.3104 13.2634L8.42794 12.2629ZM13.0721 12.2629C12.3894 13.0254 11.9296 13.5363 11.5365 13.8667C11.1621 14.1814 10.9421 14.25 10.75 14.25V15.75C11.4361 15.75 11.989 15.4458 12.5016 15.0149C12.9957 14.5997 13.5372 13.9921 14.1896 13.2635L13.0721 12.2629ZM7.3104 13.2634C7.96278 13.9921 8.50434 14.5997 8.99835 15.0149C9.51099 15.4458 10.0639 15.75 10.75 15.75V14.25C10.5579 14.25 10.3379 14.1814 9.96348 13.8667C9.5704 13.5363 9.11064 13.0254 8.42794 12.2629L7.3104 13.2634Z"
                fill={color.success}
            />
        </svg>
    );
}
