import { Button, Stack, Typography } from '@mui/material';

import { Theme } from '@mui/material/styles/createTheme';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import useMedia from '../../hooks/useMedia';
import { LanguageContext } from '../../contexts/useLanguage';
import { useContext } from 'react';
import MyAppBar from '../../components/MyAppBar';

const useStyles = makeStyles((theme: Theme) => ({
    baseWrapper: {
        background: `url("${'/assets/images/holding.png'}")`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '100%',
        height: '100vh',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
    },
    baseContentBox: {
        background: 'rgba(255, 255, 255, 0.7)',
        backdropFilter: 'blur(4.5px)',
        border: '1px solid #DDDDDD',
        borderRadius: '10px',
        textAlign: 'center',
    },
}));

export default function HoldingPage() {
    const classes = useStyles();
    const navigate = useNavigate();
    const { isMobileSM } = useMedia();

    const { t } = useContext(LanguageContext);

    return (
        <Stack className={classes.baseWrapper} sx={{ px: 3 }}>
            <MyAppBar isShowLabelAccount={false} isShowLabelLanguage={false} />
            <Stack
                className={classes.baseContentBox}
                alignItems="center"
                justifyContent="center"
                spacing={3}
                sx={{
                    width: 700,
                    py: 5,
                    px: 2,
                }}
            >
                <Typography variant={'h5'} component={isMobileSM ? 'span' : 'pre'}>
                    {t('holdingPage:paragraph')}
                </Typography>
                {/* <Button
                    sx={{ width: 290 }}
                    variant="contained"
                    onClick={() => {
                        navigate('/');
                    }}
                >
                    {t('holdingPage:button.nextNewiHubVersion')}
                </Button> */}
            </Stack>
        </Stack>
    );
}
