import { Button, Grid, Typography } from '@mui/material';
import React, { useContext } from 'react';
import color from '../../../config/Colors';
import { LanguageContext } from '../../../contexts/useLanguage';
import useMedia from '../../../hooks/useMedia';
import { ChartShowType } from '../../../models/ChartShowType';
import { ReportType } from '../../../models/ReportType';
import { DrawerContext } from '../../dashboardLayout/useDrawer';
import { LinkTo } from '../../login/styles';
import { DEFAULT_TAB_INDEX } from '../../reports/hooks/useReport';

type Props = {
    title: string;
    desc?: string;
    buttonLabel: string;
    onCLick?: () => void;
    chartShowType?: ChartShowType;
    reportType?: ReportType;
    actionTextInfo?: string;
};

export default function ChartTitleContent(props: Props) {
    const { isMobileSM } = useMedia();
    const { t } = useContext(LanguageContext);
    const { setActiveItem } = useContext(DrawerContext);
    const handleToReport = () => {
        if (props.reportType) {
            setActiveItem(3);
        }
    };
    return (
        <Grid container item xs={12}>
            <Grid item xs={8}>
                <Typography variant="h5">{props.title}</Typography>
                {props.desc && (
                    <Typography color={color.priText} variant="body2" minHeight={'18px'}>
                        {props.desc}
                    </Typography>
                )}
                <Typography sx={{ mt: 0.5 }} color={color.textPrimary} variant="body2">
                    {props.actionTextInfo}
                </Typography>
            </Grid>
            {props.chartShowType != ChartShowType.report && (
                <Grid item xs={4} textAlign={'right'}>
                    <LinkTo to={`/reports/${props.reportType}/${DEFAULT_TAB_INDEX}`}>
                        <Button
                            variant="text"
                            size="small"
                            sx={{ fontSize: { xs: '12px', md: '14px' } }}
                            onClick={handleToReport}
                        >
                            {isMobileSM ? t('dashboard:button.insight') : props.buttonLabel}
                        </Button>
                    </LinkTo>
                </Grid>
            )}
        </Grid>
    );
}
