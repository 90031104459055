/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { addDays, addMonths, addSeconds, endOfDay, startOfDay, subSeconds } from 'date-fns';
import _ from 'lodash';
import moment from 'moment';
import { createContext, useContext, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { SelectItem } from '../../../../components/MySelect';
import useModal from '../../../../components/modal/useModal';
import { LanguageContext } from '../../../../contexts/useLanguage';
import { permitController, shopController } from '../../../../controllers';
import { ConvertToGMT0Time, FormatVRNString } from '../../../../helpers';
import { convertHour } from '../../../../helpers/ConvertHour';
import useYupSchema from '../../../../helpers/useYupSchema';
import { AuthContext } from '../../../../hooks/useAuth';
import { Account } from '../../../../models/Account';
import { Company } from '../../../../models/Company';
import { LocationWithDetail } from '../../../../models/Location';
import Zone from '../../../../models/Zone';
import { Role } from '../../../../models/permission/Role';
import { ExcelRawPermit, RawUploadPermitStatus } from '../../../../models/permits/ExcelRawPermit';
import { Permit, ValidateToType } from '../../../../models/permits/Permit';
import { PermitRecurring, RecurringType } from '../../../../models/permits/PermitRecurring';
import { AllocatedType } from '../../../../models/tenant/AllocatedTypeWithAmount';
import { TenantWithAllocate } from '../../../../models/tenant/Tenant';
import { DrawerContext } from '../../../dashboardLayout/useDrawer';
import { GetPermitScopes } from '../../helpers';
import { ParamsFilterPermit } from '../../hooks/usePermitsFilter';
import { StelParams } from '../components/UpsertPermitFilter';
import {
    ALL_DAY_SECONDS,
    DYNAMIC_TYPES,
    calculateFirstDate,
    genWeekDates,
    getDateWeekRecur,
    isBronzeRole,
} from '../components/UpsertPermitItem/helpers';
import { getIsCheckedAllDay, getWeeksDay } from '../helpers/PermitHelpers';

export interface UpsertPermitState {
    // filter: UpsertFilter;
    // filterData: UpsertFilterData;
    permits: Permit[];
    isEdit: boolean;
    validations: ItemValid[];
    isValidsss: boolean;
    isAdd: boolean;
    permitsAddSuccess: Permit[];
    vrnCheckingList: CheckingVRNItem[];
    clientInfo?: Account;
    isZoneChange: boolean;
}

export interface UpsertFilter {
    companyId?: number;
    locationId?: number;
    regionId?: number;
    shopId?: number;
    zoneId?: number;
}

export interface ItemValid {
    id: number;
    isValid: boolean;
}

export interface DataUpsertPermit {
    companies: Company[];
    locations: LocationWithDetail[];
    tenants: TenantWithAllocate[];
    zones: Zone[];
}

const _initDataUpsert: DataUpsertPermit = {
    companies: [],
    locations: [],
    tenants: [],
    zones: [],
};

export const PERMITS_LIMIT = 10;
export const MAXIMUM_RETREAT_DAY = 3;

export default function useUpsertPermit() {
    const { t } = useContext(LanguageContext);
    // const { filterState, handleResetFilter } = useContext(PermitFilterContext);
    const { idPermit } = useParams();
    const { yupValidate } = useYupSchema();
    const location = useLocation();
    const { oldParams, account, clearOldParams } = useContext(AuthContext);
    // console.log('🚀 ~ useUpsertPermit ~ location:', location);

    const [searchParams, setSearchParams] = useSearchParams();

    const params = {
        companyId: searchParams.get(ParamsFilterPermit.company),
        locationId: searchParams.get(ParamsFilterPermit.location),
        zoneIds: searchParams.get(ParamsFilterPermit.zones),
        shopId: searchParams.get(ParamsFilterPermit.shop),
    };

    const defaultFilter: UpsertPermitState = {
        permits: [],
        isEdit: idPermit ? true : false,
        validations: [],
        isAdd: false,
        isValidsss: true,
        permitsAddSuccess: [],
        vrnCheckingList: [],
        isZoneChange: false,
    };
    const [state, setState] = useState<UpsertPermitState>(defaultFilter);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { setActiveItem } = useContext(DrawerContext);
    const navigate = useNavigate();
    const modalWarning = useModal();

    //state for nav from stel
    const [isNoneTenants, setIsNoneTenants] = useState<boolean>(false);

    const [isFromStel, setIsFromStel] = useState<boolean>(oldParams.isFromStel !== null);

    const [isContinueUsingPermit, setIsContinueUsingPermit] = useState(false);
    const [isScopeValid, setIsScopeValid] = useState<boolean>(false);
    const [tenantLocationId, setTenantLocationId] = useState(0);
    const [zones, setZones] = useState<Zone[]>([]);
    const [vrnCheckingData, setVrnCheckingData] = useState<CheckingVRNItem[]>([]);
    const [permitEdit, setPermitEdit] = useState<Permit | null>(null);

    const [filterState, setFilterState] = useState<UpsertFilter>({
        companyId: !!params.companyId ? Number(params.companyId) : undefined,
        locationId: !!params.locationId ? Number(params.locationId) : undefined,
        shopId: !!params.shopId ? Number(params.shopId) : undefined,
        zoneId: undefined,
    });
    const setPartialFilter = (partial: Partial<UpsertFilter>) => setFilterState((prev) => ({ ...prev, ...partial }));

    const [initData, setInitData] = useState<DataUpsertPermit>(_initDataUpsert);
    const setPartialInitData = (props: Partial<DataUpsertPermit>) => setInitData((prev) => ({ ...prev, ...props }));

    const [dataState, setDataState] = useState<DataUpsertPermit>(_initDataUpsert);
    const setPartialData = (partial: Partial<DataUpsertPermit>) => setDataState((prev) => ({ ...prev, ...partial }));

    const setPartialState = (partial: Partial<UpsertPermitState>) => setState((prev) => ({ ...prev, ...partial }));

    const [tenantWithZoneAllocated, setTenantWithZoneAllocated] = useState<AllocatedType[]>([]);
    const [loadingTenantWithAllocated, setLoadingTenantWithAllocated] = useState(false);

    const stateDialog = useModal();

    const handleConfirm = () => {
        setIsContinueUsingPermit(true);
        stateDialog.handleClose();
    };

    useEffect(() => {
        setActiveItem(5);
        getAndSetEditInformation();
    }, []);

    //fetch tenant allocated for tenant
    useEffect(() => {
        if (account.label === Role.Tenant) {
            getShopAllocated(account.id!);
        }
    }, [filterState.shopId]);

    //fetch tenant allocated for another role
    useEffect(() => {
        if (filterState.shopId && account.label !== Role.Tenant) {
            getShopAllocated(filterState.shopId);
        }
    }, [filterState.shopId]);

    const schema = yup.object().shape({
        permits: yup.array().of(yup.object().shape(yupValidate)),
    });

    const itemSchema = yup.object().shape(yupValidate);

    const formControl = useForm({
        mode: 'all',
        resolver: yupResolver(schema),
        defaultValues: { permits: [defaultPermit] },
    });

    const {
        formState: { isValid },
        control,
        watch,
        reset,
    } = formControl;

    const getShopAllocated = (shopId: number) => {
        setLoadingTenantWithAllocated(true);
        shopController
            .getShopAllocated({ shopId })
            .then((res) => {
                setTenantWithZoneAllocated(res);
            })
            .finally(() => {
                setLoadingTenantWithAllocated(false);
            });
    };

    const getAndSetEditInformation = () => {
        if (idPermit) {
            permitController.getPermitInfo(Number(idPermit)).then((res) => {
                const permit = res.permit;
                const { company, location, tenant, zone } = res.zoneInfo;
                const permitUpsert = getEditPermitData(res.permit);
                setPermitEdit(getEditPermitData(res.permit));
                reset({
                    permits: [permitUpsert],
                });
                setPartialState({ permits: [permit], clientInfo: res.client });
                setPartialFilter({
                    companyId: company === null ? undefined : company.id,
                    locationId: location === null ? undefined : location.id,
                    shopId: tenant === null ? undefined : tenant.clientId!,
                    zoneId: zone === null ? undefined : zone.id,
                });

                setPartialData({
                    companies: company === null ? [] : [company],
                    locations: location === null ? [] : [location as LocationWithDetail],
                    tenants: tenant === null ? [] : [tenant],
                    zones: zone === null ? [] : [zone],
                });
            });
        }
    };

    const getEditPermitData = (permit: Permit) => {
        const permitRecurring = permit.permitRecurring[0];

        const permitUpsertData: Permit = {
            ...permit,
            fromHour: convertHour.getHoursAndMinutesFromTimeNumber(permitRecurring.fromHour),
            toHour: convertHour.getHoursAndMinutesFromTimeNumber(permitRecurring.toHour),
            permitRecurringType: DYNAMIC_TYPES.includes(permitRecurring.recurringType!)
                ? RecurringType.dynamic
                : Number(permitRecurring.recurringType),
            permitRecurringEvery: permitRecurring.recurringEvery!,
            weekRecurringDays:
                permitRecurring.recurringType === RecurringType.week
                    ? getWeeksDay(permitRecurring.permitRecurringDays!)
                    : [],
            isCheckedAllDay: getIsCheckedAllDay(permitRecurring),
            subPermitRecurringType: DYNAMIC_TYPES.includes(permitRecurring.recurringType!)
                ? permitRecurring.recurringType!
                : RecurringType.day,
        };
        return permitUpsertData;
    };

    const handleCheckVrnWhenAddPermit = () => {
        setIsLoading(true);
        const permitForms = watch();
        const permits = permitForms.permits.map((p) => handleCreatePermit(p));

        //set all id to 0 if add new permit
        const permitsAdd: Permit[] = [];
        permits.map((item) => {
            permitsAdd.push({ ...item, id: state.isEdit ? item.id : 0 });
        });

        // console.log(permits);
        // console.log('handleAddNewPermit  permits:', permits);
        // setState((p) => ({ ...p, permitsAddSuccess: [] }));

        const vrnCheckingItems = handleConvertToCheckPermitItem(permits);
        //check value permit
        permitController.checkVRNUpsert(vrnCheckingItems).then((res) => {
            setState((p) => ({ ...p, vrnCheckingList: res }));
            const countItemValid = res.reduce(
                (count, item) => (item.status === RawUploadPermitStatus.Valid ? (count += 1) : 0),
                0
            );
            //show popup when permit length
            if (permits.length !== countItemValid) {
                setVrnCheckingData(res.sort((a, b) => a.index - b.index));
                modalWarning.handleOpen();
                setIsLoading(false);
            } else {
                //show notification and back to permit
                handleAddPermit(permits);
            }
        });
    };

    const handleCancelModalConfirm = () => {
        const resetPermitInvalidDate = state.permitsAddSuccess.map((p, index) => {
            if (
                vrnCheckingData[index]?.status === RawUploadPermitStatus.InValid &&
                vrnCheckingData[index]?.reason?.includes('Start date is invalid.')
            ) {
                p.validateFrom = undefined;
            }
            return p;
        });

        modalWarning.handleClose();
        setState((p) => ({
            ...p,
            permitsAddSuccess: [],
            isAdd: false,
            permits: [],
        }));

        setTimeout(() => {
            setState((p) => ({
                ...p,
                permits: resetPermitInvalidDate,
            }));
        }, 500);
    };

    const handleConfirmAddNewPermits = () => {
        const list: number[] = [];
        const listAdd: Permit[] = [];
        state.vrnCheckingList.map((item) => {
            if (item.status == RawUploadPermitStatus.Valid) {
                list.push(item.index - 1);
            }
        });
        if (list.length > 0) {
            list.map((item) => {
                listAdd.push({ ...state.permitsAddSuccess[item], id: 0 });
            });
        }
        handleAddPermit(listAdd);
    };

    const goBackOrToPermitsPage = () => {
        let url = `/permits`;
        if (isFromStel) {
            if (!!params.locationId) {
                url += `?companyPId=${params.companyId}&locationPId=${params.locationId}`;
            }
            navigate(url);
            return;
        }

        if (window.history.length > 1) {
            navigate(-1);
        } else {
            navigate(url);
        }
    };

    const handleAddPermit = (permits: Permit[]) => {
        // console.log('🚀 ~ handleAddPermit ~ permits:', permits);
        setIsLoading(true);
        permits.forEach((p) => {
            if (!state.isEdit) p.id = 0;
        });
        // console.log('🚀 ~ permits.forEach ~ permits:', permits);
        permitController
            .Upsert(permits)
            .then((res) => {
                const filter = _.cloneDeep(filterState);
                //show notification and back to permit
                if (permits[0].id !== 0) toast.success(t('notification:permitUpdatedSuccessfully'));
                else toast.success(t('notification:permitAddedSuccessfully'));

                //clone default filter and keep filter data
                setState({
                    ...defaultFilter,
                    isAdd: false,
                    isValidsss: false,
                });

                setFilterState(filter);
                // //reload permits data
                // handleResetFilter();
                // //set loading
                setIsLoading(false);
                //return to permits page
                goBackOrToPermitsPage();
            })
            .catch((err) => {
                if (err.response.status !== 500) toast.error(err.response.data.value);
            })
            .finally(() => {
                setState((p) => ({ ...p, permitsAddSuccess: [], isAdd: false }));
                setIsLoading(false);
            });
    };

    const handleConvertToCheckPermitItem = (permits: Permit[]) => {
        const arr: CheckingVRNItem[] = [];
        permits.map((permit, index) => {
            const { fromHour, toHour, recurringType, permitRecurringDays, recurringEvery } = permit.permitRecurring[0];
            const startTimeParse = convertHour.SecondsToHoursMinStringFormat(fromHour);
            const endTimeParse = convertHour.SecondsToHoursMinStringFormat(toHour === ALL_DAY_SECONDS ? 86340 : toHour);

            arr.push({
                zoneId: filterState.zoneId ?? 0,
                companyId: filterState?.companyId ?? 0,
                locationId: isBronzeRole(account.label) ? tenantLocationId : filterState?.locationId ?? 0,
                shopId: isBronzeRole(account.label) ? account.id! : filterState.shopId!,
                index: index + 1,
                firstName: permit.firstName || '',
                lastName: permit.lastName || '',
                vrn: permit.vrnNumber,
                recurringType: Number(recurringType),
                type: permit.type,
                startDate: moment(permit.validateFrom!).format('DD/MM/YYYY'),
                endDate: permit.validateTo ? moment(permit.validateTo!).format('DD/MM/YYYY') : null,
                startTime: startTimeParse ?? undefined,
                endTime: endTimeParse ?? undefined,
                recurringEvery: recurringEvery,
                tenantId: isBronzeRole(account.label) ? account.id : filterState.shopId ?? 0,
                bayNumber: 1,
                comment: '',
                weekRecurringDays: recurringType == RecurringType.week ? getWeeksDay(permitRecurringDays) : [],
                id: state.isEdit ? permit.id : 0,
            });
        });

        return arr;
    };

    const convertValidateToWithToHour = (date: Date, duration: number) => {
        return ConvertToGMT0Time(addSeconds(startOfDay(date), duration ?? 0));
    };

    const handleCreatePermit = (permit: Permit) => {
        const {
            permitRecurringEvery,
            vrnNumber,
            permitRecurringType,
            validateFrom,
            validateTo,
            fromHour,
            toHour,
            validateToAfterRecurringTime,
            validateToType,
            subPermitRecurringType,
            weekRecurringDays,
        } = _.cloneDeep(permit);

        permit.fromHour = ConvertToGMT0Time(fromHour!)
        permit.toHour = ConvertToGMT0Time(toHour!)
        
        const { isEdit } = state;

        permit.vrnNumber = FormatVRNString(vrnNumber);
        permit.permitType = 1;
        permit.validateFrom = ConvertToGMT0Time(moment(validateFrom!).startOf('day').toDate());
        permit.permitRecurring[0].fromHour = convertHour.getSecondFromStartOfDay(fromHour!);
        permit.permitRecurring[0].toHour = convertHour.getSecondFromStartOfDay(toHour!);

        permit.tenantId = filterState.shopId ?? 0;
        permit.scopes = isEdit ? permitEdit!.scopes : GetPermitScopes(filterState, isEdit, account, tenantLocationId);
        permit.vehicleId = isEdit ? permitEdit!.vehicleId ?? 0 : 0;

        if (isEdit) {
            permit.permitRecurring = [...permitEdit!.permitRecurring];
        }

        if (!isEdit) {
            permit.permitRecurring[0].permitRecurringDays = [];
        }

        const convertGmt0ValidateFrom = ConvertToGMT0Time(startOfDay(new Date(validateFrom!)));
        const oldPermitRecurDay = permitEdit?.permitRecurring?.[0].permitRecurringDays;

        switch (permitRecurringType) {
            case RecurringType.once:
                permit.permitRecurring[0].recurringType = RecurringType.once;
                permit.validateTo = convertValidateToWithToHour(
                    new Date(validateFrom!),
                    permit.permitRecurring[0].toHour ?? 0
                );
                permit.permitRecurring[0].recurringEvery = 1;

                if (!isEdit) {
                    permit.permitRecurring[0].permitRecurringDays.push({
                        id: isEdit ? oldPermitRecurDay![0].id : 0,
                        firstExecuteAt: convertGmt0ValidateFrom,
                        nextExecuteAt: convertGmt0ValidateFrom,
                    });
                }
                break;
            case RecurringType.temporary:
                permit.permitRecurring[0].recurringType = RecurringType.temporary;
                permit.permitRecurring[0].fromHour = convertHour.getSecondFromStartOfDay(startOfDay(new Date()));
                permit.permitRecurring[0].toHour = ALL_DAY_SECONDS;
                permit.validateTo = ConvertToGMT0Time(endOfDay(validateTo!));
                //set end date
                permit.permitRecurring[0].recurringEvery = 1;
                //push recur days
                if (!isEdit) {
                    permit.permitRecurring[0].permitRecurringDays = [
                        {
                            id: 0,
                            firstExecuteAt: convertGmt0ValidateFrom,
                            nextExecuteAt: convertGmt0ValidateFrom,
                        },
                    ];
                }
                break;
            case RecurringType.indefinite:
                permit.permitRecurring[0].recurringType = RecurringType.indefinite;
                permit.permitRecurring[0].fromHour = convertHour.getSecondFromStartOfDay(startOfDay(new Date()));
                permit.permitRecurring[0].toHour = ALL_DAY_SECONDS;
                permit.validateFrom = ConvertToGMT0Time(moment().startOf('day').toDate());
                //set end date
                permit.validateTo = undefined;
                permit.permitRecurring[0].recurringEvery = 1;
                //push recur days
                if (!isEdit) {
                    permit.permitRecurring[0].permitRecurringDays = [
                        {
                            id: 0,
                            firstExecuteAt: convertGmt0ValidateFrom,
                            nextExecuteAt: convertGmt0ValidateFrom,
                        },
                    ];
                }
                break;
            case RecurringType.dynamic:
                permit.permitRecurring[0].recurringEvery = permitRecurringEvery!;
                permit.permitRecurring[0].recurringType = subPermitRecurringType!;

                if (subPermitRecurringType === RecurringType.day) {
                    if (!isEdit) {
                        permit.permitRecurring[0].permitRecurringDays.push({
                            id: isEdit ? oldPermitRecurDay![0].id : 0,
                            firstExecuteAt: convertGmt0ValidateFrom,
                            nextExecuteAt: convertGmt0ValidateFrom,
                        });
                    }
                    switch (validateToType) {
                        case ValidateToType.absoluteAt:
                            permit.validateTo = convertValidateToWithToHour(
                                new Date(validateTo!),
                                permit.permitRecurring[0].toHour ?? 0
                            );
                            break;
                        case ValidateToType.recurringTime:
                            const endDate = addDays(
                                startOfDay(new Date(validateFrom!)),
                                permitRecurringEvery! * (validateToAfterRecurringTime! - 1)
                            );
                            permit.validateTo = convertValidateToWithToHour(
                                endDate,
                                permit.permitRecurring[0].toHour ?? 0
                            );
                            break;
                        default:
                            permit.validateTo = undefined;
                            break;
                    }
                }
                if (subPermitRecurringType === RecurringType.week) {
                    const newArr = weekRecurringDays!.map((item) => (item === 0 ? 7 : item)).sort((a, b) => a - b);
                    const dateArr = genWeekDates(validateFrom!).filter((date) =>
                        newArr.includes(moment(date).isoWeekday())
                    );
                    const endOfWeekFromStartDate = moment(validateFrom!).endOf('isoWeek').startOf('day').toDate();

                    if (!isEdit) {
                        permit.permitRecurring![0].permitRecurringDays = [];
                        dateArr.map((item) => {
                            const date = ConvertToGMT0Time(
                                getDateWeekRecur(startOfDay(item), endOfWeekFromStartDate, permitRecurringEvery!)
                            );
                            permit.permitRecurring![0].permitRecurringDays.push({
                                id: 0,
                                firstExecuteAt: date,
                                nextExecuteAt: date,
                            });
                        });
                    }
                    switch (validateToType) {
                        case ValidateToType.absoluteAt:
                            permit.validateTo = convertValidateToWithToHour(
                                new Date(validateTo!),
                                permit.permitRecurring[0].toHour ?? 0
                            );

                            break;
                        case ValidateToType.recurringTime:
                            const validTo = addDays(
                                endOfWeekFromStartDate,
                                permitRecurringEvery! * 7 * (validateToAfterRecurringTime! - 1)
                            );
                            permit.validateTo = convertValidateToWithToHour(
                                validTo,
                                permit.permitRecurring[0].toHour ?? 0
                            );
                            break;
                        default:
                            permit.validateTo = undefined;
                            break;
                    }
                }
                if (subPermitRecurringType === RecurringType.month) {
                    const firstExecuteAt = calculateFirstDate(validateFrom!, permitRecurringEvery!);
                    //set first recur day
                    if (!isEdit) {
                        permit.permitRecurring[0].permitRecurringDays = [
                            {
                                id: 0,
                                firstExecuteAt: ConvertToGMT0Time(startOfDay(firstExecuteAt)),
                                nextExecuteAt: ConvertToGMT0Time(startOfDay(firstExecuteAt)),
                            },
                        ];
                    }
                    switch (validateToType) {
                        case ValidateToType.absoluteAt:
                            permit.validateTo = convertValidateToWithToHour(
                                new Date(validateTo!),
                                permit.permitRecurring[0].toHour ?? 0
                            );
                            break;
                        case ValidateToType.recurringTime:
                            const validTo = addMonths(firstExecuteAt, validateToAfterRecurringTime! - 1);
                            permit.validateTo = convertValidateToWithToHour(
                                validTo,
                                permit.permitRecurring[0].toHour ?? 0
                            );
                            break;
                        default:
                            permit.validateTo = undefined;
                            break;
                    }
                }
                break;
            default:
                break;
        }

        console.log("123123123123",permit);
        return permit;
    };

    const fieldsForm = useFieldArray({
        control: control, // control props comes from useForm (optional: if you are using FormContext)
        name: 'permits', // unique name for your Field Array
    });

    const handleClearOldParams = () => {
        clearOldParams();
        setIsFromStel(false);
    };

    return {
        t,
        setState,
        handleConfirmAddNewPermits,
        isLoading,
        modalWarning,
        handleCancelModalConfirm,
        setIsNoneTenants,
        isNoneTenants,
        isFromStel,
        // setIsFromStel,
        stateDialog,
        handleConfirm,
        isContinueUsingPermit,
        setIsContinueUsingPermit,
        setPartialState,
        // setPartialFilter,
        setPartialFilterData: setPartialData,
        isScopeValid,
        setIsScopeValid,
        tenantWithZoneAllocated,
        loadingTenantWithAllocated,
        tenantLocationId,
        setTenantLocationId,
        zones,
        setZones,
        permitEdit,
        formControl,
        itemSchema,
        handleCheckVrnWhenAddPermit,
        isValid,
        fieldsForm,

        setPartialInitData,
        dataState,
        initData,
        setPartialData,
        filterState,
        setPartialFilter,
        handleClearOldParams,
        ...state,
    };
}
export const defaultRecur: PermitRecurring = {
    id: 0,
    fromHour: null,
    toHour: null,
    recurringEvery: 1,
    recurringType: 0,
    permitRecurringDays: [],
};

export const defaultPermit: Permit = {
    id: 0,
    firstName: '',
    lastName: '',
    type: '',
    vrnNumber: '',
    comment: '',
    requestedBy: '',
    bayNumber: '',
    validateFrom: new Date(),
    validateToType: ValidateToType.infinite,
    validateTo: undefined,
    scopes: [],
    permitRecurring: [defaultRecur],
    vehicleId: 0,

    fromHour: null,
    toHour: null,
    isCheckedAllDay: false,
    permitRecurringType: RecurringType.once,
    permitRecurringEvery: 1,
    validateToAfterRecurringTime: 1,
    weekRecurringDays: [moment(new Date()).weekday()],
    subPermitRecurringType: RecurringType.day,
};

export interface CheckingVRNItem extends ExcelRawPermit {
    index: number;
    endDate: string | null;
    weekRecurringDays: number[];
    id: number;
}

export interface PermitFromData {
    permits: Permit[];
}

export const UpsertPermitContext = createContext<ReturnType<typeof useUpsertPermit>>({} as any);
