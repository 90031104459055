import React from 'react';
import { Outlet } from 'react-router-dom';
import useFilterContext, { FilterContext } from '../../contexts/FilterContext';

export default function DashboardReportProvider() {
    const filterData = useFilterContext();
    return (
        <FilterContext.Provider value={filterData}>
            <Outlet />
        </FilterContext.Provider>
    );
}
