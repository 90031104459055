import React, { SVGProps } from 'react';

type Props = {} & SVGProps<SVGSVGElement>;

export default function FilterMobileIcon(props: Props) {
    return (
        <svg {...props} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="25" height="25" rx="3.5" fill="white" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.75 8C5.33579 8 5 8.33579 5 8.75C5 9.16421 5.33579 9.5 5.75 9.5H20.25C20.6642 9.5 21 9.16421 21 8.75C21 8.33579 20.6642 8 20.25 8H5.75ZM8 12.75C8 12.3358 8.33579 12 8.75 12H17.25C17.6642 12 18 12.3358 18 12.75C18 13.1642 17.6642 13.5 17.25 13.5H8.75C8.33579 13.5 8 13.1642 8 12.75ZM11 16.75C11 16.3358 11.3358 16 11.75 16H14.25C14.6642 16 15 16.3358 15 16.75C15 17.1642 14.6642 17.5 14.25 17.5H11.75C11.3358 17.5 11 17.1642 11 16.75Z"
                fill="#009D4F"
            />
            <rect x="0.5" y="0.5" width="25" height="25" rx="3.5" stroke="#009D4F" />
        </svg>
    );
}
