import { Avatar, Box, Grid, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import _ from 'lodash';
import React, { useContext } from 'react';
import CheckBoxIcon from '../../../assets/images/CheckBoxIcon';
import Checked from '../../../assets/images/Checked';
import MailIcon from '../../../assets/images/MailIcon';
import UserIcon from '../../../assets/images/UserIcon';
import { appConfig } from '../../../config/AppConfig';
import color from '../../../config/Colors';
import { LanguageContext } from '../../../contexts/useLanguage';
import { AuthContext } from '../../../hooks/useAuth';
import { LocationRoleType, Support } from '../../../models/Support';
import { FilterSupportContext } from '../hook/useFilterSupportByLocation';

type Props = {
    supportItem: Support;
};

export default function SupportItem(props: Props) {
    const { supportItem } = props;
    const role = LocationRoleType[supportItem.locationRoles[0].locationRoleType];

    const { t } = useContext(LanguageContext);
    const { getAvatar } = useContext(AuthContext);
    const { supportList, listUserId, setListUserId } = useContext(FilterSupportContext);

    const handleChange = (e: any) => {
        let arr = _.cloneDeep(listUserId);
        if (e.target.checked) {
            arr.push(Number(e.target.value));
        } else {
            arr = arr.filter((f) => f != Number(e.target.value));
        }
        setListUserId(arr);
    };

    const checkUserId = (userId: number) => {
        if (listUserId.find((item) => item == userId)) {
            return true;
        } else {
            return false;
        }
    };

    const locationRoles = [
        {
            id: 0,
            name: t('support:text.keyAccountManager'),
        },
        {
            id: 1,
            name: t('support:text.accountManager'),
        },
        {
            id: 2,
            name: t('support:text.accountExecutive'),
        },
    ];

    const getNameLocationRole = (number: Number) => {
        const role = locationRoles.filter((e) => e.id == number);
        return role?.[0]?.name;
    };

    return (
        <Grid
            item
            container
            sx={{
                p: '16px 24px',
                border: '1px solid #EEEEEE',
                // filter: 'drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25))',
                borderRadius: '8px',
            }}
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            gap={1}
            zeroMinWidth
            overflow={'hidden'}
            textOverflow={'ellipsis'}
        >
            <Box width={40} height={40}>
                {/* <img width={'100%'} src={`${appConfig.gateway.apiAzureMedia}/${supportItem.id}`} alt="" /> */}
                <Avatar>{supportItem.firstName.slice(0, 1)}</Avatar>
            </Box>

            <Typography variant="h5" color={color.primary} noWrap>
                {supportItem.displayName.length > 25
                    ? supportItem.displayName.slice(0, 25).concat('...')
                    : supportItem.displayName}
            </Typography>

            <Typography variant="body2">
                {getNameLocationRole(supportItem.locationRoles[0].locationRoleType)}
            </Typography>

            <Grid
                item
                container
                direction={'row'}
                wrap={'nowrap'}
                alignItems={'center'}
                justifyContent={'center'}
                spacing={0.8}
            >
                <MailIcon style={{ minWidth: '16px', marginRight: '5px' }} />
                <Typography variant="body2" color={'#85858A'} noWrap sx={{}}>
                    {supportItem.email}
                </Typography>
            </Grid>

            <FormGroup>
                <FormControlLabel
                    control={
                        <Checkbox
                            value={supportItem.id}
                            size="small"
                            icon={<CheckBoxIcon />}
                            checkedIcon={<Checked />}
                            onChange={(e) => handleChange(e)}
                            checked={checkUserId(Number(supportItem.id))}
                        />
                    }
                    label={
                        <Typography
                            variant="body2"
                            color={checkUserId(Number(supportItem.id)) ? color.primary : color.grey600}
                        >
                            {checkUserId(Number(supportItem.id))
                                ? t('support:text.readyToSend')
                                : t('permits:text.select')}
                        </Typography>
                    }
                />
            </FormGroup>
        </Grid>
    );
}
