import { Box, Grid, Typography } from '@mui/material';
import { useContext } from 'react';
import MyBreadcrumbs from '../../../components/BreadCrumbs';
import LoadingCircular from '../../../components/LoadingCircular';
import { CompaniesContext } from '../../../hooks/useCompanies';
import useScrollToTop from '../../../hooks/useScrollToTop';
import MyLocationTab from './components/MyLocationTabs';
import useLocationDetail, { LocationDetailContext } from './useLocationDetail';
import { LocationsContext } from '../../../hooks/useLocations';

export enum LocationTab {
    GeneralInformation = 'general-information',
    Zones = 'zones',
    Tenant = 'tenants',
}

export default function LocationLayout() {
    const { locationSelect } = useContext(LocationsContext);
    const locationDetailContext = useLocationDetail();
    useScrollToTop();

    return (
        <LocationDetailContext.Provider value={locationDetailContext}>
            {locationSelect ? (
                <Grid item container xs={12}>
                    <Grid item>
                        <Typography variant="h3" fontWeight={400}>
                            {locationSelect.name}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Box mt={2}>
                            <MyBreadcrumbs />
                        </Box>
                    </Grid>

                    <MyLocationTab />
                </Grid>
            ) : (
                <LoadingCircular />
            )}
        </LocationDetailContext.Provider>
    );
}
