/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { LoadingButton } from '@mui/lab';
import { Box, Container, Divider, Grid, Menu, MenuItem, Stack, Typography } from '@mui/material';
import React, { useContext } from 'react';
import ExportIcon from '../../assets/images/ExportIcon';
import color from '../../config/Colors';
import { LanguageContext } from '../../contexts/useLanguage';
import MyTab from './components/MyTab';
import useReport, { ExportType, ReportContext } from './hooks/useReport';

export default function ReportPage() {
    const reportContext = useReport();
    const { FilterComponents, onSelectReportType, selectReport, open, handleClick, anchorEl, handleClose, state } =
        reportContext;

    const { t } = useContext(LanguageContext);

    return (
        <ReportContext.Provider value={reportContext}>
            <>
                <Stack flexDirection={'row'} justifyContent="space-between">
                    <Grid item>
                        <Typography textAlign={'left'} variant="h3" fontWeight={400}>
                            {t('reportPage:title.insights')}
                        </Typography>
                    </Grid>

                    <Grid item>
                        <LoadingButton
                            variant="contained"
                            loading={state.isExportLoading}
                            loadingPosition="start"
                            startIcon={<></>}
                            endIcon={<ExportIcon />}
                            sx={{
                                px: 4,
                                padding: { xs: '9.5px 10px', sm: '9.5px 20px' },
                                minWidth: { xs: '140px', sm: '160px' },
                                '& .svg-icon-fill': {
                                    fill: color.containedButtonString,
                                },
                                '&:hover': {
                                    '& .svg-icon-fill': {
                                        fill: color.white,
                                        transition: '0.2s',
                                    },
                                },
                            }}
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                        >
                            {t('reportPage:button.export')}
                        </LoadingButton>

                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            sx={{
                                width: '100%',
                                '& .MuiMenuItem-root': {
                                    lineHeight: '1.5rem',
                                    minHeight: '0',
                                },
                            }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={() => onSelectReportType(ExportType.excel)} sx={{ minWidth: '160px' }}>
                                <Typography variant="body1" margin={'auto'}>
                                    {t('reportPage:text.exportExcel')}
                                </Typography>
                            </MenuItem>
                            <MenuItem onClick={() => onSelectReportType(ExportType.pdf)} sx={{ minWidth: '160px' }}>
                                <Typography variant="body1" margin={'auto'}>
                                    {t('reportPage:text.exportPDF')}
                                </Typography>
                            </MenuItem>
                        </Menu>
                    </Grid>
                </Stack>

                <Grid item xs={12} mt={2}>
                    {/* <DashboardFilter /> */}
                    {FilterComponents}
                </Grid>

                <Box mt={3} sx={{ border: `1px solid ${color.grey300}`, borderRadius: '8px' }} p={2} pt={'22px'}>
                    <Grid mb={2} item xs={12}>
                        <Box>{selectReport.RenderSelect}</Box>
                    </Grid>
                    <Divider sx={{ background: color.grey300 }} />
                    <Grid mt={2} item xs={12}>
                        <MyTab />
                    </Grid>
                </Box>
            </>
        </ReportContext.Provider>
    );
}
