import { Stack, TextField, Typography } from '@mui/material';
import { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import color from '../../../../../config/Colors';
import { LanguageContext } from '../../../../../contexts/useLanguage';
import { FormItemProps } from '../UpsertPermitItem/UpsertPermitItemForm';
import RecurWithTooltip from './RecurWithTooltip';
import { PermitFromData } from '../../hooks/useUpsertPermit';

export type RecurProps = {
    isEdit: boolean;
    parentForm: FormItemProps;
};

export default function DayRecurring({ isEdit, parentForm }: RecurProps) {
    const { t } = useContext(LanguageContext);
    const { control } = useFormContext<PermitFromData>();
    return (
        <Stack
            flex={1}
            direction={'row'}
            padding={1}
            sx={{ background: color.white, borderRadius: '5px' }}
            alignItems="center"
        >
            <RecurWithTooltip />
            <Controller
                control={control}
                name={`permits.${parentForm.indexForm}.permitRecurringEvery`}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        label={t('permits:text.noOfDay')}
                        fullWidth
                        size="small"
                        placeholder={''}
                        onChange={(e) => {
                            const value = Number(e.target!.value);
                            const val = value < 1 ? 1 : value > 31 ? 31 : value;
                            field.onChange(val);
                        }}
                        sx={{ width: '150px', mx: 2 }}
                        type="number"
                        inputProps={{ min: 1, max: 31 }}
                        disabled={isEdit}
                    />
                )}
            />

            <Typography variant="body1" component={'span'}>
                {t('permits:text.days')}
            </Typography>
        </Stack>
    );
}
