/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import useModal from '../../../components/modal/useModal';
import { LanguageContext } from '../../../contexts/useLanguage';
import { permitController } from '../../../controllers';
import { DrawerContext } from '../../dashboardLayout/useDrawer';
import usePermitsFilter from './usePermitsFilter';

export default function usePermits() {
    const {
        FilterComponents,
        permitPagination,
        isLoading,
        handleChangePage,
        filterState,
        handleResetFilter,
        handleApplyFilter,
    } = usePermitsFilter();

    const [deleteIds, setDeleteIds] = useState<number[]>([]);
    const [deleteId, setDeleteId] = useState<number | null>(null);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const stateDialogDelete = useModal();
    const stateDialogBulksDelete = useModal();
    const { t } = useContext(LanguageContext);
    const { setActiveItem } = useContext(DrawerContext);

    useEffect(() => {
        setActiveItem(5);
    }, []);

    useEffect(() => {
        setDeleteIds([]);
    }, [filterState]);

    const handleChangePagination = (value: number) => {
        handleChangePage(value);
    };

    const onCheckedAll = (e: any) => {
        const ids: number[] = [...deleteIds];
        //checked
        if (e.target.checked) {
            if (ids.length == 0) {
                permitPagination.data.map((item) => ids.push(item.id));
            } else {
                //check id is exists in ids
                permitPagination.data.map((item) => {
                    const index = ids.findIndex((id) => id == item.id);
                    if (index < 0) {
                        ids.push(item.id);
                    }
                });
            }
        } else {
            //unChecked delete all id in page from ids
            permitPagination.data.map((item) => {
                const index = ids.findIndex((id) => id == item.id);
                if (index >= 0) {
                    ids.splice(index, 1);
                }
            });
        }
        setDeleteIds(ids);
    };

    const handleChangeChecked = (e: any) => {
        let arr = _.cloneDeep(deleteIds);
        if (e.target.checked) {
            arr.push(Number(e.target.value));
        } else {
            arr = arr.filter((f) => f != Number(e.target.value));
        }
        setDeleteIds(arr);
    };

    const checkPermitId = (id: number) => {
        if (deleteIds.find((item) => item == id)) {
            return true;
        } else {
            return false;
        }
    };

    const isCheckedAll = () => {
        let isExistsAll = true;
        if (permitPagination.data && permitPagination.data.length <= 0) {
            return false;
        }
        permitPagination.data &&
            permitPagination.data.length > 0 &&
            permitPagination.data.map((item) => {
                const index = deleteIds.findIndex((idDelete) => idDelete == item.id);
                if (index < 0) {
                    isExistsAll = false;
                }
            });
        return isExistsAll;
    };

    const handleOpenConfirmDelete = (id: number) => {
        setDeleteId(id);
        stateDialogDelete.handleOpen();
    };
    const handleOpenConfirmBulksDelete = () => {
        stateDialogBulksDelete.handleOpen();
    };

    const handleDelete = () => {
        //callApi
        setDeleteLoading(true);
        permitController
            .deletePermit([deleteId!])
            .then((res) => {
                // pushError(t('notification:fail'));
                //popup
                toast.success(t('notification:successfully'));
                setDeleteIds([]);
                //reload when delete done

                handleApplyFilter();
            })
            .finally(() => {
                setDeleteLoading(false);
                //closeDialog
                stateDialogDelete.handleClose();
            });
    };

    const handleBulksDelete = () => {
        //callApi
        permitController.deletePermit(deleteIds).then((res) => {
            //popup
            toast.success(t('notification:successfully'));
            setDeleteIds([]);
            //reload
            handleApplyFilter();
        });
        //closeDialog
        stateDialogBulksDelete.handleClose();
    };

    return {
        FilterComponents,
        permitPagination,
        isLoading,
        handleChangePagination,
        onCheckedAll,
        handleChangeChecked,
        checkPermitId,
        isCheckedAll,
        deleteIds,
        setDeleteId,
        stateDialogBulksDelete,
        stateDialogDelete,
        handleOpenConfirmDelete,
        handleOpenConfirmBulksDelete,
        handleDelete,
        handleBulksDelete,
        filterState,
        deleteLoading,
    };
}

export const PermitContext = React.createContext<ReturnType<typeof usePermits>>({} as any);
