/* eslint-disable eqeqeq */
import { Box, Tab, Tabs } from '@mui/material';
import React, { useContext } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { LocationTab } from '..';
import { MyTabPanel } from '../../../../components/MyTabPanel';
import color from '../../../../config/Colors';
import { LanguageContext } from '../../../../contexts/useLanguage';
import useMedia from '../../../../hooks/useMedia';
import { LocationDetailContext } from '../useLocationDetail';
import { LocationsContext } from '../../../../hooks/useLocations';

export default function MyLocationTab() {
    const { locationSelect } = useContext(LocationsContext);
    const { indexTab, listTab } = useContext(LocationDetailContext);
    const { isMobileSM } = useMedia();
    const { t } = useContext(LanguageContext);
    const navigate = useNavigate();
    const params = useParams();

    const findTabIndex = listTab.findIndex((i) => i.label == indexTab);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        const index = listTab.findIndex((item) => item.index == newValue);
        navigate(`/companies/${params.idCompany}/locations/${params.locationId}/${listTab[index].label}`, {
            replace: true,
        });
    };

    const getLabel = (isChosen: boolean, label: string) => {
        if (isChosen) {
            if (label == LocationTab.GeneralInformation) {
                return t('companies:tenants.generalInformation');
            } else if (label == LocationTab.Zones) {
                return t('companies:text.zones');
            } else if (label == LocationTab.Tenant) {
                return t('companies:text.tenants');
            }
        } else if (isChosen == false && !isMobileSM) {
            if (label == LocationTab.GeneralInformation) {
                return t('companies:tenants.generalInformation');
            } else if (label == LocationTab.Zones) {
                return t('companies:text.zones');
            } else if (label == LocationTab.Tenant) {
                return t('companies:text.tenants');
            }
        } else if (isChosen == false && isMobileSM) {
            return '';
        }
    };

    return (
        (locationSelect && (
            <Box mt={2} mb={4} width={'100%'}>
                <Tabs
                    value={listTab[findTabIndex].index}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    sx={{
                        height: '32px',
                        minHeight: 'auto',
                        '& .MuiTabs-indicator': {
                            backgroundColor: color.priText,
                        },
                    }}
                >
                    {listTab.map((item, index) => {
                        const isChosen = item.label === indexTab;
                        return (
                            <Tab
                                key={index}
                                label={getLabel(isChosen, item.label)}
                                {...a11yProps(index)}
                                icon={item.icon}
                                sx={[
                                    isChosen
                                        ? {
                                              background: color.lightPrimary,
                                              '& .svg-icon-fill': {
                                                  fill: color.priText,
                                              },
                                              '& .svg-icon-stroke': {
                                                  stroke: color.priText,
                                              },
                                              '& .detail-icon': {
                                                  stroke: color.priText,
                                              },
                                              color: `${color.priText} !important`,
                                          }
                                        : {},
                                    {
                                        minWidth: { xs: isChosen ? '150px' : '50px', sm: '150px', lg: '200px' },
                                    },
                                ]}
                            />
                        );
                    })}
                </Tabs>
                {listTab.map((item, index) => {
                    const itemSearch = listTab.findIndex((i) => i.label == indexTab);
                    return (
                        <MyTabPanel key={index} value={listTab[itemSearch].index} index={item.index}>
                            {item.panel}
                        </MyTabPanel>
                    );
                })}
            </Box>
        )) || <Box></Box>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
