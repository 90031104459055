import { Box, Grid, Stack, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { BarChartPCNType } from '../../../components/chart/BarChartPCN';
import color from '../../../config/Colors';
import { LIST_COLOR_PNC_TYPE_CHART } from '../../../constants';
import { LanguageContext } from '../../../contexts/useLanguage';
import useMedia from '../../../hooks/useMedia';
import { ChartShowType } from '../../../models/ChartShowType';
import { ReportType } from '../../../models/ReportType';
import { StatisticItems } from '../../../models/StatisticItems';
import { GridBorderRadius } from '../styled';
import ChartTitleContent from './ChartTitleContent';
type Props = {
    chartShowType?: ChartShowType;
    reportType?: ReportType;
    statisticItems: StatisticItems;
};
export default function PCNTypesChar(props: Props) {
    if (props.statisticItems.items.length > 3) {
        props.statisticItems.items.splice(2, 1);
    }

    const { t } = useContext(LanguageContext);
    const { isMobileSM } = useMedia();
    return (
        <GridBorderRadius
            container
            direction={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            item
            xs={12}
            gap={2}
            mt={props.chartShowType === ChartShowType.report ? 1 : 0}
            border={
                props.chartShowType === ChartShowType.report
                    ? 'none !important'
                    : `1px solid ${color.grey200} !important`
            }
        >
            <Grid container item xs={12}>
                <ChartTitleContent
                    key={'5'}
                    title={t('dashboard:title.groupByPCNTypes')}
                    desc={t('dashboard:text.numberOfParkingChargesRaisedBySiteBasedOnContraventionDate​')}
                    buttonLabel={t('dashboard:button.viewInsight')}
                    reportType={props.reportType || undefined}
                    chartShowType={props.chartShowType || ChartShowType.dashboard}
                />
            </Grid>

            <Grid
                xs={12}
                direction={'row'}
                justifyContent={props.chartShowType === ChartShowType.report ? 'space-between' : 'center'}
                alignItems={'flex-start'}
                container
                item
            >
                <Grid
                    height={{ xs: '200px', md: '262px' }}
                    xs={props.chartShowType === ChartShowType.report && !isMobileSM ? 8 : 12}
                    item
                >
                    <BarChartPCNType statisticItems={props.statisticItems} />
                </Grid>
                <Grid xs={props.chartShowType === ChartShowType.report && !isMobileSM ? 3.6 : 12} item>
                    <Stack
                        mt={2}
                        spacing={2}
                        direction={props.chartShowType === ChartShowType.report && !isMobileSM ? 'column' : 'row'}
                        justifyContent="center"
                        alignItems={props.chartShowType === ChartShowType.report ? 'flex-start' : 'center'}
                    >
                        {props.statisticItems.items.map((item, index) => {
                            return (
                                <Stack
                                    key={index}
                                    direction={'row'}
                                    alignItems={'center'}
                                    justifyContent={'flex-start'}
                                >
                                    <Box
                                        width={'8px'}
                                        height={'8px'}
                                        marginRight={'8px'}
                                        sx={{ borderRadius: '50%', background: LIST_COLOR_PNC_TYPE_CHART[index] }}
                                    />
                                    <Typography variant="body1" fontSize={{ xs: '12px', sm: '14px', lg: '14px' }}>
                                        {item.label}
                                    </Typography>
                                </Stack>
                            );
                        })}
                    </Stack>
                </Grid>
            </Grid>
        </GridBorderRadius>
    );
}
