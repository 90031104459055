import { Dialog, DialogProps, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { ReactNode, useState } from 'react';
import theme from '../../../../theme/Theme';

type Props = {
    modalContent: ReactNode;
    open: boolean;
    handleOpen: (isOpen: boolean) => void;
    handleClose: (isOpen: boolean) => void;
};

export default function TenantModal({ modalContent, open, handleOpen, handleClose }: Props) {
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [scroll, setScroll] = useState<DialogProps['scroll']>('paper');

    return (
        <Box sx={{ borderRadius: '10px' }}>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="responsive-dialog-title"
                sx={{ zIndex: 9999, borderRadius: '10px', '& .MuiPaper-root': { maxWidth: '700px' } }}
            >
                {modalContent}
            </Dialog>
        </Box>
    );
}
