import { wait } from '@testing-library/user-event/dist/utils';
import { BaseHttpController } from 'ale-base-model';
import { AxiosInstance } from 'axios';
import apiAccount from '../config/Api';
import { Account } from '../models/Account';
import { ChangePassword, Login } from '../models/Login';

export class AccountController extends BaseHttpController<Account> {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'product', client);
    }

    async login(login: Login): Promise<string> {
        const res = await this.client.post(`${this.serviceURL}${apiAccount.account}/Login`, login);
        return res.data.token
    }

    async sendLinkToMail(email: string) {
        const res = await this.client.post(
            `${this.serviceURL}${apiAccount.account}/GetLinkResetPassword`, { email: email }
        );
        return res;
    }

    async updateAccount(account: Account): Promise<Account> {
        const res = await this.client.post(
            `${this.serviceURL}${apiAccount.account}`, account
        );
        return res.data;
    }

    async checkTokenIsValid(token: string) {
        const res = await this.client.post(
            `${this.serviceURL}${apiAccount.account}/CheckTokenResetPassword?token=${token}`
        );
        return res.data;
    }

    async changePass(changePass: ChangePassword) {
        const res = await this.client.post(`${this.serviceURL}${apiAccount.account}/ChangePass`, changePass);
        return res.data;
    }

    async changePassAccount(changePass: ChangePassword) {
        const res = await this.client.post(`${this.serviceURL}${apiAccount.account}/ChangePassAccount`, changePass);
        return res.data;
    }

    async upsertAvatar(formData: FormData) {
        const res = await this.client.post(`${this.serviceURL}${apiAccount.account}/UploadAvatar`, formData);
        return res.data;
    }

    async sendMail(accountId: number, locationName: string) {
        const res = await this.client.post(`${this.serviceURL}${apiAccount.account}/ResendEmail`, { accountId, locationName });
        return res;
    }

}
