import { Stack, Typography } from '@mui/material';
import color from '../../../../../../config/Colors';
import { VRN } from '../../../../../../models/permits/VRN';

type Props = {
    VRN: VRN;
};

export default function TooltipTrueVRN(props: Props) {
    const { VRN } = props;
    return (
        <Stack direction={'row'} spacing={0.5}>
            <Stack>
                <img width={150} height={70} style={{}} src={VRN?.imageUrl} alt="" />
            </Stack>
            <Stack direction={'column'} overflow={'hidden'}>
                <Typography sx={{ whiteSpace: 'nowrap' }} variant="body2" color={color.white}>
                    {VRN?.vrm}
                </Typography>
                <Typography sx={{ whiteSpace: 'nowrap' }} color={color.white} variant="body2">
                    {VRN?.color}
                </Typography>
                <Typography sx={{ whiteSpace: 'nowrap' }} color={color.white} variant="body2">
                    {VRN?.make}
                </Typography>
                <Typography sx={{ whiteSpace: 'nowrap' }} color={color.white} variant="body2">
                    {VRN?.model}
                </Typography>
            </Stack>
        </Stack>
    );
}
