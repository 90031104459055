import { ArrowDropDownRounded } from '@mui/icons-material';
import { Box, Collapse, IconButton, Stack, Typography } from '@mui/material';
import React, { useLayoutEffect, useRef, useState } from 'react';
import useMedia from '../../../hooks/useMedia';

export type FAQs = {
    question?: string;
    answer?: React.ReactNode;
};

export default function BaseFAQ(props: FAQs & { indexOfQuestion?: number }) {
    const [open, setOpen] = useState(false);
    const { isMediumMD } = useMedia();
    const [textHeight, setTextHeight] = useState(36);
    const textRef = useRef<HTMLParagraphElement>(null);

    useLayoutEffect(() => {
        if (textRef.current) {
            const height = textRef.current.offsetHeight;
            setTextHeight(height);
        }
    }, [textRef.current?.offsetHeight]);
    return (
        <Stack
            mt={1}
            sx={{ borderRadius: '4px', border: '1px solid #DDDDDD', boxShadow: '0px 0px 40px rgba(0, 0, 0, 0.1)' }}
        >
            <Collapse in={!!open} collapsedSize={`${Math.max(textHeight, 27.42) + (!isMediumMD ? 32 : 16)}px`}>
                <Box py={!isMediumMD ? 2 : 1} px={2}>
                    <Stack
                        component="div"
                        flex={1}
                        direction="row"
                        alignItems="center"
                        justifyContent={'flex-start'}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => setOpen((prev) => !prev)}
                        spacing={1}
                    >
                        <IconButton sx={{ padding: 0 }}>
                            <ArrowDropDownRounded
                                sx={{
                                    transform: `rotate(${open ? 0 : 180}deg)`,
                                    transition: '0.3s',
                                }}
                            />
                        </IconButton>
                        <Typography ref={textRef} fontSize={!isMediumMD ? 16 : 'inherit'} fontWeight={500}>
                            {`${props.indexOfQuestion}. ${props.question}`}
                        </Typography>
                    </Stack>
                    <Box mt={1.7}>{props.answer}</Box>
                </Box>
            </Collapse>
        </Stack>
    );
}
