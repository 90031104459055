import { Container, ContainerProps } from '@mui/material';
import React from 'react';

type Props = ContainerProps;

const WrapContainer = (props: Props) => {
    return <Container sx={{ pt: { xs: 9, sm: 10, md: 12 }, minHeight: 'calc(100vh - 115px)' }} {...props} />;
};

export default WrapContainer;
