import { TableBody, TableCell, TableRow } from '@mui/material';
import { formatDate } from '../../../helpers/moment';
import { AnprMatchesDailyStats, AnprMatchesDailyStatsByHours } from '../../../models/AnprMatchesDailyStats';

type Props = {
    capacityAnalysis: AnprMatchesDailyStatsByHours[];
};

export default function TableRowsEVAverageLength(props: Props) {
    const getPercent = (val: number, dailyData: AnprMatchesDailyStats) => {
        return (
            Number(
                ((val / (dailyData.sum_ev_fast! + dailyData.sum_ev_normal! + dailyData.sum_ev_rapid!)) * 100).toFixed(1)
            ) || 0
        );
    };

    return (
        <TableBody>
            {props.capacityAnalysis &&
                props.capacityAnalysis.map((row, index) => {
                    return (
                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" align="center" scope="row">
                                {formatDate.getLocalDay(row.inDate)}
                            </TableCell>
                            <TableCell align="center">{getPercent(row.sum_ev_rapid!, row)}%</TableCell>
                            <TableCell align="center">{getPercent(row.sum_ev_fast!, row)}%</TableCell>
                            <TableCell align="center">{getPercent(row.sum_ev_normal!, row)}%</TableCell>
                        </TableRow>
                    );
                })}
        </TableBody>
    );
}
