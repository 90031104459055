import { Stack, Typography } from '@mui/material';
import { ChartData, Chart as ChartJS, ChartOptions, ChartTypeRegistry, Plugin, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useContext } from 'react';
import { Pie } from 'react-chartjs-2';
import color from '../../config/Colors';
import font from '../../config/Fonts';
import { LIST_COLOR_CHART } from '../../constants';
import { LanguageContext } from '../../contexts/useLanguage';
import { AnyObject } from 'chart.js/types/basic';
ChartJS.register(...registerables);

export const options: ChartOptions<'pie'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
            position: 'right' as const,
            align: 'center',
            labels: {},
        },

        // title: {
        //     display: true,
        //     text: 'Line Chart',
        // },
        datalabels: {
            //hide inside label
            display: false,
            color: 'black',
            anchor: 'end',
            align: 'start',
            padding: {
                top: 40,
                right: 20,
                left: 20,
            },
            //set font inside label
            font: {
                family: font,
            },
            //set label
            formatter(value, context) {
                let total = 0;
                context.dataset.data.map((item) => {
                    if (typeof item == 'number') {
                        total += item;
                    }
                });
                return ((value / total) * 100).toFixed(1) + '%';
            },
        },
    },
    scales: {
        x: {
            grid: {
                //hide line
                drawBorder: false,
                //hide grid line
                display: false,
            },
            ticks: {
                //hide bottom label
                display: false,
            },
        },
    },
};

type Props = {
    data: PieChartData[];
};

export interface PieChartData {
    label: string;
    value: number;
}

export default function PieChart(props: Props) {
    const { t } = useContext(LanguageContext);
    const checkDataExists = () => {
        let total = 0;
        props.data.map((item) => {
            total += item.value;
        });
        return total == 0;
    };
    const data: ChartData<'pie', number[], string> = {
        labels: props.data && props.data.map((item) => item.label),
        datasets: [
            {
                data: checkDataExists() ? [1, 1, 1, 1, 1, 1, 1] : props.data && props.data.map((item) => item.value),
                borderWidth: 0,
                backgroundColor: LIST_COLOR_CHART,
            },
        ],
    };

    return checkDataExists() ? (
        <Stack
            sx={{
                border: `1px solid #ddd`,
                width: '200px',
                height: '200px',
                margin: 'auto',
                borderRadius: '50%',
                background: color.grey300,
            }}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
        >
            <Typography variant="h5" sx={{ color: color.grey600 }}>
                {t('companies:text.noDataToDisplay')}
            </Typography>
        </Stack>
    ) : (
        <Pie options={options} data={data} plugins={[ChartDataLabels as Plugin<'pie', AnyObject>]} />
    );
}
