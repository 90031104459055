import { Stack, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { useContext } from 'react';
import { MdDone } from 'react-icons/md';
import color from '../../../config/Colors';
import { LanguageContext } from '../../../contexts/useLanguage';
import { SliceLongString } from '../../../helpers';
import useMedia from '../../../hooks/useMedia';
import { TenantsPagination } from '../../../models/BaseFilter';
import { ReportType } from '../../../models/ReportType';
import MyTable, { TableHeadContent } from '../../MyTable';
import { formatNumber } from '../../../helpers/FormatCurrency';
import { AllocatedPermitType } from '../../../models/tenant/AllocatedTypeWithAmount';
import useTenant from '../../companyLayout/locationDetail/components/helpers/TenantHelpers';
interface Props {
    tenants?: TenantsPagination;
}
export default function TenantsItem(props: Props) {
    const { getLabelAllocatedTypeName } = useTenant();
    const { t } = useContext(LanguageContext);
    const { tenants: paging } = props;
    const { isExtraSmallXs, isMediumMD } = useMedia();

    const isMobile = isExtraSmallXs || isMediumMD;
    const largeHead: TableHeadContent[] = [
        {
            label: t('companies:tenants.tenantName'),
            alight: 'left',
        },
        {
            label: t('companies:tenants.managerName'),
            alight: 'center',
        },
        {
            label: t('companies:tenants.emailOfTenant'),
            alight: 'center',
        },
        {
            label: t('companies:tenants.usedPermits'),
            alight: 'center',
        },
    ];
    const smallHead: TableHeadContent[] = [
        {
            label: t('companies:tenants.tenantName'),
            alight: 'center',
        },
        {
            label: t('companies:tenants.usedPermits'),
            alight: 'center',
        },
    ];

    return (
        <MyTable
            tableHead={isExtraSmallXs || isMediumMD ? smallHead : largeHead}
            reportType={ReportType.dailyVisit}
            tableRow={
                <>
                    {paging &&
                        paging.data?.length > 0 &&
                        paging.data?.map((zone, index) => {
                            const isLast = index == paging.data?.length - 1;
                            return (
                                <TableRow
                                    key={index}
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: 0 },
                                        borderBottom: isLast ? `1px solid #ddd` : '',
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontSize: '14px',
                                        }}
                                        component="th"
                                        align="left"
                                        scope="row"
                                    >
                                        {SliceLongString(zone.tenantName, 15)}
                                    </TableCell>
                                    {!isMobile && (
                                        <TableCell
                                            sx={{
                                                fontSize: '14px',
                                            }}
                                            component="th"
                                            align="center"
                                            scope="row"
                                        >
                                            {SliceLongString(zone.managerName, 15)}
                                        </TableCell>
                                    )}

                                    {!isMobile && (
                                        <TableCell align="center">
                                            <Tooltip
                                                title={
                                                    <Typography variant="body2" color={'white'}>
                                                        {zone.email}
                                                    </Typography>
                                                }
                                                placement="top"
                                            >
                                                <Typography variant="body1">
                                                    {SliceLongString(zone.email, 40)}
                                                </Typography>
                                            </Tooltip>
                                        </TableCell>
                                    )}
                                    <TableCell align={isMobile ? 'center' : 'center'} width={isMobile ? '60%' : '30%'}>
                                        <Stack>
                                            <Tooltip
                                                title={
                                                    <Stack>
                                                        {zone.allocatedTypes
                                                            ?.filter((item) => item.isActive)
                                                            .sort((a, b) => {
                                                                return a.allocatedPermitType - b.allocatedPermitType;
                                                            })
                                                            .map((item, index) => {
                                                                return (
                                                                    <Typography variant="body2" color="white">
                                                                        {index + 1}. {t('action:used')}:{' '}
                                                                        {formatNumber.format(item.usedPermit!)}
                                                                        {![AllocatedPermitType.Once].includes(
                                                                            item.allocatedPermitType
                                                                        )
                                                                            ? ` - ${t(
                                                                                  'companies:tenants.allocated'
                                                                              )}: ${item.amount}`
                                                                            : ''}
                                                                    </Typography>
                                                                );
                                                            })}
                                                    </Stack>
                                                }
                                                placement="top"
                                                key={index}
                                                sx={{ background: 'black' }}
                                            >
                                                <Stack
                                                    key={index}
                                                    sx={{
                                                        '&:hover': {
                                                            cursor: 'pointer',
                                                            text: color.secondary,
                                                            textDecoration: 'underline',
                                                        },
                                                    }}
                                                >
                                                    {zone.allocatedTypes
                                                        ?.filter((item) => item.isActive)
                                                        .sort((a, b) => {
                                                            return a.allocatedPermitType - b.allocatedPermitType;
                                                        })
                                                        .map((item, index) => {
                                                            return (
                                                                <Typography>
                                                                    {index + 1}.{' '}
                                                                    {getLabelAllocatedTypeName(
                                                                        item.allocatedPermitType
                                                                    )}
                                                                    {': '}
                                                                    {formatNumber.format(item.usedPermit!)}
                                                                    {![AllocatedPermitType.Once].includes(
                                                                        item.allocatedPermitType
                                                                    )
                                                                        ? `(${item.amount})`
                                                                        : ''}
                                                                </Typography>
                                                            );
                                                        })}
                                                </Stack>
                                            </Tooltip>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                </>
            }
        />
    );
}
