import { BaseHttpController } from 'ale-base-model';
import { AxiosInstance } from 'axios';
import { SelectItem } from '../components/MySelect';
import { EditPermit } from '../models/tenant/EditPermit';
import { TenantWithAllocate } from '../models/tenant/Tenant';
import Zone from '../models/Zone';

export class ZoneController extends BaseHttpController<TenantWithAllocate> {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'Zone', client);
    }

    async editPermits(editPermit: EditPermit) {
        const res = await this.doPost({ path: 'editPermits', body: editPermit, config: {} });
        return res.data;
    }

    async getZones(props: FilterZoneProps) {
        const res = await this.doPost({ path: `myZones`, body: props, config: {} });
        const listData: SelectItem[] = [];
        res.data.map((item: { id: any; name: any; zone: any }) => {
            listData.push({
                value: item.id,
                label: item.name,
            });
        });
        return listData;
    }

    async getZonesRF(props: FilterZoneProps): Promise<Zone[]> {
        const res = await this.doPost({ path: `myZones`, body: props, config: {} });
        return res.data;
    }

    async getZonesDetail(props: FilterZoneProps): Promise<Zone[]> {
        const res = await this.doPost({ path: `myZones`, body: props, config: {} });
        return res.data;
    }

    async addBayTemporary(props: {
        ZoneId: number,
        BayTemporary: number,
        StartDate: Date,
        EndDate: Date | null,
    }) {
        const res = await this.doPost({ path: `AddZoneBayTemporary`, body: props, config: {} });
        return res.data;
    }

    async getHistoryBayTemporary(zoneId: number) {
        const res = await this.doGet({ path: `ZoneBayTemporary/${zoneId}`, config: {} });
        return res.data;
    }

    async stopZoneTemporary(ZoneTemporaryId: number) {
        const res = await this.doPost({ path: `StopZoneTemporary`, body: { ZoneTemporaryId: ZoneTemporaryId }, config: {} })
    }

    // async permitWithAllocated(zoneIds: number[], tenantId: number): Promise<{ maxAllocated: number, allocatedPermits: number }> {
    //     const res = await this.doPost({ path: 'permitWithAllocated', body: { zoneIds, tenantId }, config: {} })
    //     return res.data
    // }
}

export interface FilterZoneProps {
    locationId: number;
    tenantId?: number;
    permitId: number;
    isTenant?: boolean;
}
