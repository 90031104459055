import {
    Stack,
    SxProps,
    Theme,
    Pagination as MuiPagination,
    Grow,
    CircularProgress,
    Grid,
    Box,
    Fade,
} from '@mui/material';
import React, { ReactNode } from 'react';
import { Pagination } from '../models/BaseFilter';
import { BaseModel } from '../models/BaseModel';
import NoDataToDisplay from './NoDataToDisplay';

type Props<T extends BaseModel> = {
    paging: Pagination<T>;
    styleContainer?: SxProps<Theme>;
    styleList?: SxProps<Theme>;
    isLoading?: boolean;
    setPage: (page: number) => void;
    renderedItem: (item: T) => JSX.Element;
    noDataComponent?: ReactNode;
};

export default function BaseListRender<T extends BaseModel>(props: Props<T>) {
    const { paging, setPage, isLoading } = props;
    return (
        <Stack sx={{ ...props.styleContainer }}>
            {isLoading ? (
                <Stack direction="row" justifyContent="center" alignItems="center" mt={10}>
                    <Grow in={true} timeout={Math.min(500, 1000)}>
                        <Box height={'100%'}>
                            <CircularProgress />
                        </Box>
                    </Grow>
                </Stack>
            ) : paging.data.length ? (
                <Stack direction="column" sx={{ ...props.styleList }}>
                    {paging.data.map((item, index) => {
                        return (
                            <Grow in={true} key={index} timeout={Math.min(500 * index, 1000)}>
                                <Box height={'100%'}>{props.renderedItem(item)}</Box>
                            </Grow>
                        );
                    })}
                </Stack>
            ) : (
                props.noDataComponent ?? <NoDataToDisplay text={'No data to display'} />
            )}

            {paging.totalPage > 1 && !isLoading && (
                <Stack mt={3} direction={'row'} justifyContent="center">
                    <MuiPagination
                        count={paging.totalPage || 1}
                        page={paging.page}
                        onChange={(_, page) => setPage(page)}
                        variant="outlined"
                        shape="rounded"
                    />
                </Stack>
            )}
        </Stack>
    );
}
