import { SvgIconProps } from '@mui/material';
import React from 'react';

export default function FaqIcon(props: SvgIconProps) {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M21.25 11C21.25 5.33908 16.6609 0.75 11 0.75C5.33908 0.75 0.75 5.33908 0.75 11C0.75 16.6609 5.33908 21.25 11 21.25C16.6609 21.25 21.25 16.6609 21.25 11Z"
                stroke="#85858A"
                strokeWidth="1.5"
                className="svg-icon-stroke"
            />
            <path
                d="M11.291 13.3379H10.0332V12.5723C10.0332 12.1029 10.1631 11.6973 10.4229 11.3555C10.5824 11.1504 10.8262 10.8929 11.1543 10.583C11.2181 10.5283 11.3298 10.4303 11.4893 10.2891C12.1774 9.67383 12.526 9.05859 12.5352 8.44336C12.5352 7.78711 12.2503 7.31999 11.6807 7.04199L11.25 6.89844C11.0905 6.86198 10.9219 6.84375 10.7441 6.84375C9.97852 6.84375 9.45671 7.11491 9.17871 7.65723C9.01921 7.97624 8.93945 8.43424 8.93945 9.03125H7.75C7.75 8.04688 7.95964 7.28353 8.37891 6.74121C8.80273 6.19889 9.43392 5.87988 10.2725 5.78418C10.4502 5.76139 10.6325 5.75 10.8193 5.75C11.9951 5.75 12.8428 6.13737 13.3623 6.91211L13.5879 7.33594C13.7292 7.67318 13.7998 8.04232 13.7998 8.44336C13.7998 9.09505 13.5605 9.69661 13.082 10.248C12.9043 10.4622 12.6764 10.6947 12.3984 10.9453C11.8105 11.4876 11.4688 11.8841 11.373 12.1348C11.3184 12.276 11.291 12.4583 11.291 12.6816V13.3379ZM11.291 14.6709V16.127H10.0332V14.6709H11.291Z"
                fill="#85858A"
                className="svg-icon-fill"
            />
        </svg>
    );
}
