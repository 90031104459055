import React, { SVGProps } from 'react';

type Props = {} & SVGProps<SVGSVGElement>;

export default function ExportIcon(props: Props) {
    return (
        <svg
            {...props}
            width="16"
            height="16"
            className="svg-icon-fill"
            viewBox="0 0 16 16"
            fill="white"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.46967 10.7803C7.76256 11.0732 8.23744 11.0732 8.53033 10.7803L12.2803 7.03033C12.5732 6.73744 12.5732 6.26256 12.2803 5.96967C11.9874 5.67678 11.5126 5.67678 11.2197 5.96967L8.75 8.43934V1.75C8.75 1.33579 8.41421 1 8 1C7.58579 1 7.25 1.33579 7.25 1.75V8.43934L4.78033 5.96967C4.48744 5.67678 4.01256 5.67678 3.71967 5.96967C3.42678 6.26256 3.42678 6.73744 3.71967 7.03033L7.46967 10.7803ZM3.75 13.0001C3.33579 13.0001 3 13.3359 3 13.7501C3 14.1643 3.33579 14.5001 3.75 14.5001H12.25C12.6642 14.5001 13 14.1643 13 13.7501C13 13.3359 12.6642 13.0001 12.25 13.0001H3.75Z"
            />
        </svg>
    );
}
