import { wait } from '@testing-library/user-event/dist/utils';
import { BaseHttpController } from 'ale-base-model';
import { AxiosInstance } from 'axios';
import { Filter } from '../models/Filter';
import { StatisticItemByZones } from '../models/StatisticItemByZone';
import { StatisticItems } from '../models/StatisticItems';

export class StatisticPCNController extends BaseHttpController<object> {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'PCNDashboard', client);
    }

    async groupCancelledPCNByActor(filter: Filter): Promise<StatisticItems> {
        const res = await this.doPost({ path: 'groupCancelledPCNByActor', body: filter, config: {} });
        return res.data;

        // await wait(1000);
        // return Promise.resolve({
        //     items: [
        //         { label: 'UKPC', amount: 180 },
        //         { label: 'Client', amount: 820 },
        //     ],
        // });
    }

    async groupPCNByReason(filter: Filter): Promise<StatisticItems> {
        const res = await this.doPost({ path: 'groupPCNByReason', body: filter, config: {} });
        return res.data;
        // await wait(1000);
        // return Promise.resolve({
        //     items: [
        //         { label: 'No parking out of hours', amount: 300 },
        //         { label: 'Not parked correctly within the markings of the bay or space', amount: 250 },
        //         {
        //             label: "Parked in a disabled person's space without clearly displaying a valid disabled person's badge",
        //             amount: 225,
        //         },
        //         {
        //             label: "Parked in a disabled person's space without clearly displaying the expiry date of the disabled person's badge",
        //             amount: 150,
        //         },
        //         { label: 'Vehicle parked on yellow lines/hatched area', amount: 120 },
        //         {
        //             label: "Parked in a disabled person's space without clearly displaying the expiry date of the disabled person's badge",
        //             amount: 100,
        //         },
        //         { label: 'Vehicle parked on yellow lines/hatched area', amount: 82 },
        //         {
        //             label: "Parked in a disabled person's space without clearly displaying the expiry date of the disabled person's badge",
        //             amount: 65,
        //         },
        //         { label: 'Vehicle parked on yellow lines/hatched area', amount: 60 },
        //     ],
        // });
    }

    async groupPCNByStatus(filter: Filter): Promise<StatisticItems> {
        const res = await this.doPost({ path: 'groupPCNByStatus', body: filter, config: {} });
        return res.data;
        // await wait(1000);
        // return Promise.resolve({
        //     items: [
        //         { label: 'Cancelled', amount: 20 },
        //         { label: 'Appealed', amount: 50 },
        //         { label: 'Issued', amount: 200 },
        //         { label: 'Charges', amount: 210 },
        //     ],
        // });
    }

    async groupPCNByZone(filter: Filter): Promise<StatisticItemByZones> {
        const res = await this.doPost({ path: 'groupPCNByZone', body: filter, config: {} });
        return res.data;
        // await wait(1000);
        // return Promise.resolve({
        //     items: [
        //         { label: 'Castlepoint Shopping Centre', amount: 856, serviceTypes: ['ANPR', 'WPS'] },
        //         { label: 'Castlepoint Shopping Centre 2', amount: 609, serviceTypes: ['ANPR', 'WPS'] },
        //         { label: 'Castlepoint Shopping Centre - East Village', amount: 522, serviceTypes: ['ANPR', 'WPS'] },
        //         { label: 'Castlepoint Shopping Centre - West Village', amount: 402, serviceTypes: ['ANPR', 'WPS'] },
        //         { label: 'Castlepoint Shopping Centre 5', amount: 150, serviceTypes: ['ANPR', 'WPS'] },
        //     ],
        // });
    }
}
