import React from 'react';

export default function RemoveIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.98083 5.25796L11.8743 0.364475C12.3603 -0.121492 13.1496 -0.121492 13.6355 0.364475C14.1215 0.850441 14.1215 1.63971 13.6355 2.12568L8.74204 7.01917L13.6355 11.9127C14.1113 12.3873 14.1113 13.1597 13.6355 13.6355C13.1597 14.1113 12.3873 14.1113 11.9127 13.6355L7.01917 8.74204L2.12568 13.6355C1.63971 14.1215 0.850441 14.1215 0.364475 13.6355C-0.121492 13.1496 -0.121492 12.3603 0.364475 11.8743L5.25796 6.98083L0.364475 2.08734C-0.111344 1.61265 -0.111344 0.840293 0.364475 0.364475C0.840293 -0.111344 1.61265 -0.111344 2.08734 0.364475L6.98083 5.25796Z"
                fill="#E01B00"
            />
        </svg>
    );
}
