/* eslint-disable react-hooks/exhaustive-deps */
import { AccordionSummary, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { useContext } from 'react';
import RemoveIcon from '../../../../../../assets/images/RemoveIcon';
import color from '../../../../../../config/Colors';
import { LanguageContext } from '../../../../../../contexts/useLanguage';
import { Permit } from '../../../../../../models/permits/Permit';
import CopyIcon from './../../svg/CopyIcon';
import ExplantedIcon from './../../svg/ExplantedIcon';

type Props = {
    permit: Permit;
    onCollapse: () => void;
    isValid: boolean;
    onDuplicate: () => void;
    onRemove: () => void;
    currentIndex: number;
};
export default function UpsertPermitItemTitle({ isValid, ...props }: Props) {
    const { t } = useContext(LanguageContext);
    return (
        <AccordionSummary
            sx={{
                '&.MuiAccordionSummary-root': {
                    flexDirection: 'row-reverse',
                },
                '&.MuiAccordionSummary-root:hover': {
                    cursor: 'default',
                },
            }}
            expandIcon={
                <IconButton onClick={props.onCollapse} sx={{ p: '10px' }}>
                    <ExplantedIcon />
                </IconButton>
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Stack ml={1} flex={1} direction="row" justifyContent={'space-between'}>
                <Stack direction={'row'}>
                    <Typography variant="body1" fontWeight={500} component="span" color={color.priText}>
                        {t('permits:text.permit')} {props.currentIndex + 1}
                    </Typography>

                    <Typography
                        variant="body1"
                        component="span"
                        color={isValid ? color.success : color.danger}
                        sx={{ ml: 1 }}
                    >
                        ({isValid ? t('permits:text.Completed') : t('permits:text.notCompleted')})
                    </Typography>
                </Stack>

                <Stack direction="row" gap={1}>
                    <Tooltip
                        title={
                            <Typography variant={'body2'} color={color.white}>
                                {t('permits:text.duplicate')}
                            </Typography>
                        }
                        placement="top"
                    >
                        <IconButton
                            aria-label="copy"
                            onClick={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                                // if (isValid) {
                                // onDuplicatePermit();
                                props.onDuplicate();
                                // }
                            }}
                            sx={{ padding: 0 }}
                        >
                            <CopyIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip
                        title={
                            <Typography variant={'body2'} color={color.white}>
                                {t('permits:text.remove')}
                            </Typography>
                        }
                        placement="top"
                    >
                        <IconButton
                            aria-label="delete"
                            onClick={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                                // handleRemovePermit(props.permit.id);
                                props.onRemove();
                            }}
                            sx={{ padding: 0 }}
                        >
                            <RemoveIcon style={{ stroke: color.danger }} />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Stack>
        </AccordionSummary>
    );
}
