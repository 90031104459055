import { Grid } from '@mui/material';
import React, { useContext } from 'react';
import { LanguageContext } from '../../../contexts/useLanguage';
import { AdvancedFilterOptions } from '../../../models/permits/FilterPermits';
import { AdvancedName, DataPermitsState, FilterStatePermitId } from '../hooks/usePermitsFilter';
import AdvancedDate from './AdvancedDate';
import AdvancedFilterControl from './AdvancedFilterControl';
import AdvancedPermitType from './AdvancedPermitType';
import AdvancedRecurType from './AdvancedRecurType';
import AdvancedStatus from './AdvancedStatus';

type Props = {
    setAdvancedFilter: React.Dispatch<React.SetStateAction<AdvancedFilterOptions>>;
    handleRemoveAdvancedFilter: (name: AdvancedName) => void;
    advancedFilter: AdvancedFilterOptions;
    filterState: FilterStatePermitId;
    dataState: DataPermitsState;
    setPartialFilter: (p: Partial<FilterStatePermitId>) => void;
};

export default function AdvancedFilterLarge({
    handleRemoveAdvancedFilter,
    setAdvancedFilter,
    advancedFilter,
    filterState,
    setPartialFilter,
    dataState,
}: Props) {
    const { t } = useContext(LanguageContext);
    const { isShowFrom, isShowPermitType, isShowRecurringType, isShowStatus, isShowTo } = advancedFilter;
    return (
        <Grid item container display="flex" flexDirection={'row'} spacing={1}>
            {isShowPermitType && (
                <Grid item>
                    <AdvancedPermitType
                        value={filterState.type!}
                        permitTypes={dataState.permitTypes}
                        setValue={(permitType) => {
                            setPartialFilter({ type: permitType, page: 1 });
                        }}
                        handleRemove={handleRemoveAdvancedFilter}
                    />
                </Grid>
            )}
            {isShowStatus && (
                <Grid item>
                    <AdvancedStatus
                        value={filterState.status!}
                        handleRemove={handleRemoveAdvancedFilter}
                        setStatus={(status) => {
                            setPartialFilter({ status: status, page: 1 });
                        }}
                    />
                </Grid>
            )}
            {isShowRecurringType && (
                <Grid item>
                    <AdvancedRecurType
                        value={filterState.recurringType!}
                        handleRemove={handleRemoveAdvancedFilter}
                        setStatus={(type) => {
                            setPartialFilter({ recurringType: type, page: 1 });
                        }}
                    />
                </Grid>
            )}
            {isShowFrom && (
                <Grid item>
                    <AdvancedDate
                        title={t('permits:text.validFrom')}
                        advancedName={'validFrom'}
                        value={filterState.validFrom}
                        setDate={(date) => {
                            setPartialFilter({ validFrom: date, page: 1 });
                        }}
                        handleRemove={handleRemoveAdvancedFilter}
                    />
                </Grid>
            )}
            {isShowTo && (
                <Grid item>
                    <AdvancedDate
                        title={t('permits:text.validTo')}
                        value={filterState.validTo}
                        advancedName={'validTo'}
                        minDate={filterState.validFrom}
                        setDate={(date) => {
                            setPartialFilter({ validTo: date, page: 1 });
                        }}
                        handleRemove={handleRemoveAdvancedFilter}
                    />
                </Grid>
            )}
            <Grid item>
                <AdvancedFilterControl
                    setShowingAdvancedFilter={setAdvancedFilter}
                    advancedFilterItems={advancedFilter}
                />
            </Grid>
        </Grid>
    );
}
