import React, { useContext, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, registerables, ChartOptions, ChartData } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import font from '../../config/Fonts';
import { formatDate } from '../../helpers/moment';
import { LanguageContext } from '../../contexts/useLanguage';
import useMedia from '../../hooks/useMedia';
import { COLOR_CAPACITY, COLOR_EV_AVERAGE_LENGTH } from '../../constants';
import useModal from '../modal/useModal';
import _ from 'lodash';
import { AnprMatchesDailyStats, AnprMatchesDailyStatsByHours } from '../../models/AnprMatchesDailyStats';
ChartJS.register(...registerables);

type Props = {
    capacityAnalysis: AnprMatchesDailyStatsByHours[];
};

export default function BarChartEVLengthOfVisits(props: Props) {
    const { isMobileSM, isMediumMD } = useMedia();
    const { t } = useContext(LanguageContext);

    const getPercent = (val: number, dailyData: AnprMatchesDailyStatsByHours) => {
        return (
            Number(
                ((val / (dailyData.sum_ev_fast! + dailyData.sum_ev_normal! + dailyData.sum_ev_rapid!)) * 100).toFixed(1)
            ) || 0
        );
    };

    const getData = (dailyData: AnprMatchesDailyStats[]) => {
        const data: ChartData<'bar', number[], string> = {
            labels: dailyData && dailyData.map((item) => formatDate.getLocalDay2(item.inDate)),
            datasets: [
                {
                    label: '< 1h',
                    data: dailyData && dailyData.map((item) => getPercent(item.sum_ev_rapid!, item)),
                    backgroundColor: COLOR_EV_AVERAGE_LENGTH.rapid,
                    // barThickness: 20,
                    //set width of bar column
                    // categoryPercentage: 1, // notice here
                    // barPercentage: 0.5,
                },
                {
                    label: '1h - 4h',
                    data: dailyData && dailyData.map((item) => getPercent(item.sum_ev_fast!, item)),
                    backgroundColor: COLOR_EV_AVERAGE_LENGTH.fast,
                    // barThickness: 20,

                    //set width of bar column
                    // categoryPercentage: 1, // notice here
                    // barPercentage: 0.5,
                },
                {
                    label: '> 4h',
                    data: dailyData && dailyData.map((item) => getPercent(item.sum_ev_normal!, item)),
                    backgroundColor: COLOR_EV_AVERAGE_LENGTH.normal,
                    // barThickness: 20,
                    //set width of bar column
                    // categoryPercentage: 1, // notice here
                    // barPercentage: 0.5,
                },
            ],
        };
        return data;
    };
    const [date, setDate] = useState<AnprMatchesDailyStatsByHours>({} as any);
    const [isRender, setIsRender] = useState<boolean>(true);

    useEffect(() => {
        setIsRender(false);
        setTimeout(() => {
            setIsRender(true);
        }, 50);
    }, [props.capacityAnalysis]);

    const options: ChartOptions<'bar'> = {
        responsive: true,
        // set height not crash
        maintainAspectRatio: false,
        plugins: {
            //note
            legend: {
                display: false,
                position: 'bottom' as const,
                align: 'center',
                //set note label font
                labels: {
                    font: {
                        family: font,
                        size: isMobileSM ? 12 : isMediumMD ? 14 : 16,
                    },
                    padding: isMobileSM ? 10 : 20,
                    boxWidth: 5,
                    boxHeight: 5,
                    usePointStyle: true,
                    pointStyle: 'circle',
                },
            },
            // title: {
            //     display: true,
            //     text: 'Line Chart',
            // },

            // Top Label
            datalabels: {
                display: true,
                color: 'black',
                anchor: 'end',
                align: 'top',
                font: {
                    family: font,
                    size: isMobileSM || props.capacityAnalysis.length > 10 ? 9 : 11,
                },
                formatter(value, context) {
                    return value + '%';
                },
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return ` ${context.formattedValue} %`;
                    },
                },
            },
        },
        layout: {
            padding: {
                top: 20,
            },
        },
        //setting grid
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    font: {
                        family: font,
                    },
                    // color: color.darkPrimary,
                },
            },
            y: {
                grid: {
                    display: false,
                },
                ticks: {
                    font: {
                        family: font,
                    },
                    //format style label
                    callback(this, tickValue, index, ticks) {
                        return tickValue + ' %';
                    },
                    //y step
                    stepSize: 25,
                },

                //y label start at 0
                beginAtZero: true,
                min: 0,
                max: 100,
            },
        },
    };

    const modalDetail = useModal();
    return (
        <React.Fragment>
            {/* <MyModalLarge
                key={1}
                open={modalDetail.open}
                handleOpen={modalDetail.handleOpen}
                handleClose={modalDetail.handleClose}
                modalContent={
                    <ModalDetailCapacity
                        data={date}
                        sum={props.capacityAnalysis.sumTotalBays}
                        handleClose={modalDetail.handleClose}
                    />
                }
            /> */}
            {isRender ? (
                <Bar
                    options={options}
                    data={getData(props.capacityAnalysis)}
                    plugins={[
                        ChartDataLabels,
                        // {
                        //     id: 'click-anywhere',
                        //     afterEvent(chart, args) {
                        //         if (args.event.type === 'click') {
                        //             if (args.event.y! > 169 && args.event.x! > 50) {
                        //                 let value = chart.scales.x.getValueForPixel(args.event.x!);
                        //                 setDate(props.capacityAnalysis.dailyData[value!]);
                        //                 modalDetail.handleOpen();
                        //             }
                        //         }
                        //     },
                        // },
                        // {
                        //     id: 'click-anywhere',
                        //     afterEvent(chart, args) {
                        //         if (args.event.type === 'mousemove') {
                        //             if (args.event.y! > 169 && args.event.x! > 50) {
                        //                 chart.canvas.style.cursor = 'pointer';
                        //             } else {
                        //                 chart.canvas.style.cursor = 'default';
                        //             }
                        //         }
                        //     },
                        // },
                    ]}
                />
            ) : (
                <></>
            )}
        </React.Fragment>
    );
}
