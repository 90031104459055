

import React, { SVGProps } from 'react';

type Props = {} & SVGProps<SVGSVGElement>;

export default function UserIcon(props: Props) {

    return (
        <svg {...props} className="svg-icon-fill svg-icon-stroke" width="20" height="20" viewBox="0 0 14 20" fill="#85858A" stroke="#85858A" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.25 4C10.25 5.79493 8.7949 7.25 7 7.25C5.2051 7.25 3.75 5.79493 3.75 4C3.75 2.20507 5.2051 0.75 7 0.75C8.7949 0.75 10.25 2.20507 10.25 4Z"   strokeWidth="1.5" />
            <path d="M13.25 15C13.25 16.0756 12.6409 17.1202 11.5138 17.9252C10.3882 18.7293 8.7947 19.25 7 19.25C5.2053 19.25 3.61181 18.7293 2.48618 17.9252C1.35911 17.1202 0.75 16.0756 0.75 15C0.75 13.9244 1.35911 12.8798 2.48618 12.0748C3.61181 11.2707 5.2053 10.75 7 10.75C8.7947 10.75 10.3882 11.2707 11.5138 12.0748C12.6409 12.8798 13.25 13.9244 13.25 15Z" strokeWidth="1.5" />
        </svg>

    )
}

