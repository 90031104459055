import { BaseHttpController } from 'ale-base-model';
import { AxiosInstance } from 'axios';

export class ReportBugController extends BaseHttpController<any> {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'Account', client);
    }

    async UploadImage(file: File) {
        const formData = new FormData();
        formData.append('file', file);
        const res = await this.doPost({
            path: 'UploadReportABugPhoto', body: formData, config: {}
        })
        return res.data
    }

    async SendReportMail(mail: ReportMail) {
        const res = await this.client.post(`${this.serviceURL}/ClientPortal/Location/SendMailReportABug`, mail);
        return res.data
    }
}

export interface ReportMail {
    accountId: number,
    photos: string[],
    subject: string,
    message: string,
    toMail: string
}
