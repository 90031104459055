import { Grid, Pagination, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import color from '../../../../config/Colors';
import { LanguageContext } from '../../../../contexts/useLanguage';
import { ArrayPagination } from '../../../../helpers';
import { ExcelRawPermit, RawUploadPermitStatus } from '../../../../models/permits/ExcelRawPermit';
import { DataUpsertPermit } from '../../addEditPermit/hooks/useUpsertPermit';
import TableRowVRNChecking from './TableRowVRNChecking';
import TableUploadPermit from './TableUploadPermit';

type Props = {
    listVRNChecking: ExcelRawPermit[];
    dataState: DataUpsertPermit;
};

interface State {
    page: number;
    perPage: number;
    total: number;
    totalPage: number;
    data: ExcelRawPermit[];
}
const ItemPerPage = 10;

export default function UploadTableConfirm({ listVRNChecking, dataState }: Props) {
    const { t } = useContext(LanguageContext);
    const [state, setState] = useState<State>(ArrayPagination(listVRNChecking, 1, ItemPerPage));
    const handleChangePagination = (event: React.ChangeEvent<unknown>, value: number) => {
        setState(ArrayPagination(listVRNChecking, value, ItemPerPage));
    };
    // const permitsLeft = filterData.zonesInfo.maxAllocated - filterData.zonesInfo.allocatedPermits || 0;
    // const { isMobileSM } = useMedia();

    const getInfo = () => {
        const valid = listVRNChecking.reduce(
            (total, item) => (item.status == RawUploadPermitStatus.Valid ? (total += 1) : total),
            0
        );
        return {
            valid: valid,
            inValid: listVRNChecking.length - valid,
        };
    };

    return (
        <Grid mt={2} container>
            <Grid
                sx={{
                    background: color.grey100,
                    padding: '16px 32px',
                }}
                container
                display="flex"
                justifyContent={{ xs: 'space-around', sm: 'center' }}
            >
                <Grid item>
                    <Typography variant="body1" fontWeight={500}>
                        {t('permits:upload.totalUploaded')}: {listVRNChecking.length}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1" color={color.success} fontWeight={500} mx={2}>
                        {t('permits:upload.validPermits')}: {getInfo().valid}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1" color={color.danger} fontWeight={500}>
                        {t('permits:upload.invalidPermits')}: {getInfo().inValid}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item container>
                <TableUploadPermit
                    tableHead={[
                        {
                            label: t('permits:upload.no'),
                            alight: 'center',
                        },
                        {
                            label: t('permits:upload.VRN'),
                            alight: 'center',
                        },
                        {
                            label: t('permits:upload.status'),
                            alight: 'center',
                        },
                        {
                            label: t('permits:upload.reason'),
                            alight: 'center',
                        },
                    ]}
                    tableRow={<TableRowVRNChecking listVRNChecking={state.data} />}
                />
            </Grid>
            <Grid
                item
                container
                display={'flex'}
                justifyContent={'center'}
                sx={{ display: listVRNChecking.length > ItemPerPage ? 'block' : 'none' }}
            >
                <Pagination
                    count={state.totalPage}
                    page={state.page}
                    onChange={handleChangePagination}
                    variant="outlined"
                    shape="rounded"
                    sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}
                />{' '}
            </Grid>
            {/* <Grid item container display={'flex'} justifyContent="end">
                <FormControlLabel
                    control={
                        <Checkbox
                            size="small"
                            icon={<CheckBoxIcon />}
                            checkedIcon={<Checked />}
                            // onChange={(e) => selectAll(e)}
                            // checked={listUserId.length == filterLocationData.supportList.length}
                        />
                    }
                    label={
                        <Typography variant="body2">
                            {t('permits:upload.iWantToSaveAllPermitsEvenPermitsWithExistedVRN')}
                        </Typography>
                    }
                />
            </Grid> */}
        </Grid>
    );
}
