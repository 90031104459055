/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { CompaniesContext } from '../../hooks/useCompanies';
import useRegions, { RegionContext } from '../../hooks/useRegions';

export default function RegionProvider() {
    const { idCompany } = useParams();
    const { setCompanyId } = useContext(CompaniesContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (idCompany) {
            setCompanyId(Number(idCompany));
        } else {
            navigate('/dashboard', { replace: true });
        }
    }, [idCompany]);

    const regionContext = useRegions({ idCompany: Number(idCompany) });

    return (
        <RegionContext.Provider value={regionContext}>
            <Outlet />
        </RegionContext.Provider>
    );
}
