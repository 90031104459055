import { Grid, Stack, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReportItemDashboard } from '.';
import color from '../../../config/Colors';
import { LanguageContext } from '../../../contexts/useLanguage';
import { LinkTo } from '../../login/styles';

type Props = {
    reportItemDashboard: ReportItemDashboard;
};

export default function ReportDashboardItem(props: Props) {
    const { t } = useContext(LanguageContext);
    const navigate = useNavigate();
    return (
        <Grid item xs={12} md={6}>
            <Stack direction={'row'} sx={{ borderRadius: '5px', border: '1px solid #DDDDDD' }} padding={2}>
                <Stack mr={2}>{props.reportItemDashboard.icon}</Stack>
                <Stack flex={1} justifyContent={'space-between'}>
                    <Stack>
                        <Typography
                            variant="h4"
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: 1,
                                WebkitBoxOrient: 'vertical',
                            }}
                            fontWeight={400}
                        >
                            {props.reportItemDashboard.title}
                        </Typography>
                        <Typography
                            variant="body1"
                            color={color.grey600}
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                            }}
                        >
                            {props.reportItemDashboard.desc}
                        </Typography>
                    </Stack>
                    <LinkTo to={`${props.reportItemDashboard.url}`}>
                        <Typography
                            variant="body1"
                            color={color.priText}
                            sx={{
                                textDecorationLine: 'underline',
                                cursor: 'pointer',
                            }}
                            component="span"
                        >
                            {t('reportPage:text.viewMore')}
                        </Typography>
                    </LinkTo>
                </Stack>
            </Stack>
        </Grid>
    );
}
