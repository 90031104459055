import { Box, Grid, styled, Tooltip, Typography } from '@mui/material';
import React, { useContext } from 'react';
import LoadingCircular from '../../../components/LoadingCircular';
import { LanguageContext } from '../../../contexts/useLanguage';
import { ChartShowType } from '../../../models/ChartShowType';
import { DailyVisitsStatistic } from '../../../models/DailyVisitsStatistic';
import { ReportType } from '../../../models/ReportType';
import { GridBorderRadius, GridWithGray100 } from '../styled';
import ChartTitleContent from './ChartTitleContent';
import Summary from './Summary';
import ScrollContainer from 'react-indiana-drag-scroll';
import BarChartDailyVisits from '../../../components/chart/BarChartDailyVisits';
import color from '../../../config/Colors';
import useMedia from '../../../hooks/useMedia';
import { COLOR_DAILY_VISITS } from '../../../constants';

type Props = {
    chartShowType?: ChartShowType;
    minHeightChart?: string;
    dailyVisitsStatistic: DailyVisitsStatistic;
    reportType?: ReportType;
};

export default function DailyVisits(props: Props) {
    const { t } = useContext(LanguageContext);
    const { isMobileSM } = useMedia();
    const dataLength =
        (props.dailyVisitsStatistic &&
            props.dailyVisitsStatistic.dailyData &&
            props.dailyVisitsStatistic.dailyData.length) ||
        0;

    return props.dailyVisitsStatistic.dailyData ? (
        <GridWithGray100
            container
            item
            md={12}
            sm={12}
            xs={12}
            borderRadius={'10px'}
            mt={props.chartShowType === ChartShowType.report ? 0 : { xs: 2, sm: 3, md: 4 }}
        >
            <GridBorderRadius
                container
                item
                md={8}
                sm={12}
                xs={12}
                gap={2}
                border={`1px solid ${color.grey200} !important`}
            >
                <ChartTitleContent
                    key={'1'}
                    title={t('dashboard:title.dailyVisit')}
                    desc={t('dashboard:text.numberOfVisitsPerDay')}
                    buttonLabel={t('dashboard:button.viewInsight')}
                    reportType={props.reportType}
                    chartShowType={props.chartShowType}
                />

                <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    // height={{ xs: '260px', sm: '300px', md: '325px' }}
                    sx={{
                        '& .chart-scroll-bar': {
                            '&::-webkit-scrollbar': {
                                height: '5px',
                            },

                            '&::-webkit-scrollbar-track': {
                                WebkitBoxShadow: 'inset 0 0 0px rgba(0, 0, 0, 0)',
                            },

                            '&::-webkit-scrollbar-thumb': {
                                height: '5px',
                                backgroundColor: color.primary,
                                borderRadius: '3px',
                            },

                            '&::-webkit-scrollbar-thumb:hover': {
                                cursor: 'pointer',
                                backgroundColor: color.darkPrimary,
                            },
                            '&::-webkit-scrollbar:vertical': {
                                display: 'none',
                            },
                        },
                    }}
                >
                    <Box position={'relative'} width={'100%'} height={'100%'}>
                        <ScrollContainer
                            className="container chart-scroll-bar"
                            style={{ height: '100%', width: '100%', paddingBottom: '5px', position: 'relative' }}
                            hideScrollbars={false}
                        >
                            <Box
                                width={{
                                    lg: dataLength > 15 ? `${dataLength * 70}px` : '100%',
                                    md: dataLength > 10 ? `${dataLength * 70}px` : '100%',
                                    sm: dataLength > 7 ? `${dataLength * 70}px` : '100%',
                                    xs: dataLength > 4 ? `${dataLength * 70}px` : '100%',
                                }}
                                height={'300px'}
                                pb={4}
                                position="relative"
                            >
                                <BarChartDailyVisits anprMatchesDailyStats={props.dailyVisitsStatistic.dailyData} />
                                {/* <LineChart key={1} anprMatchesDailyStats={props.dailyVisitsStatistic.dailyData} /> */}
                            </Box>
                        </ScrollContainer>
                        {/* <Box
                            sx={{
                                position: 'absolute',
                                bottom: 10,
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'row',
                            }}
                        >
                            <ChartSubDescription
                                backgroundColor={COLOR_DAILY_VISITS.diesel!}
                                isMobileSM={isMobileSM}
                                text={t('dashboard:text.diesel')}
                            />
                            <ChartSubDescription
                                backgroundColor={COLOR_DAILY_VISITS.petrol!}
                                isMobileSM={isMobileSM}
                                text={t('dashboard:text.petrol')}
                            />
                            <ChartSubDescription
                                backgroundColor={COLOR_DAILY_VISITS.ev!}
                                isMobileSM={isMobileSM}
                                text={t('dashboard:text.ev')}
                            />
                            <ChartSubDescription
                                backgroundColor={COLOR_DAILY_VISITS.hybrid!}
                                isMobileSM={isMobileSM}
                                text={t('dashboard:text.hybrid')}
                            />
                            <Tooltip title={t('dashboard:text.dataGatheringInProgressCheckLater')} followCursor>
                                <span>
                                    <ChartSubDescription
                                        backgroundColor={COLOR_DAILY_VISITS.other}
                                        isMobileSM={isMobileSM}
                                        text={t('dashboard:text.others')}
                                    />
                                </span>
                            </Tooltip>                            
                        </Box> */}
                    </Box>
                </Grid>
            </GridBorderRadius>

            <Summary
                chartShowType={props.chartShowType || ChartShowType.dashboard}
                summaryData={props.dailyVisitsStatistic.summaryData}
            />
        </GridWithGray100>
    ) : (
        <LoadingCircular />
    );
}

const ChartSubDescription = (props: { isMobileSM: boolean; backgroundColor: string; text: string }) => {
    return (
        <Typography
            variant="body1"
            component={'div'}
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '3px' }}
            fontSize={{ xs: '11px', sm: '14px', lg: '14px' }}
            mx={props.isMobileSM ? 0.5 : 1}
        >
            <Box
                sx={{
                    width: '8px',
                    height: '8px',
                    borderRadius: '50%',
                    background: props.backgroundColor,
                    mr: props.isMobileSM ? '2px' : '5px',
                }}
            ></Box>
            {props.text}
        </Typography>
    );
};
