import React, { SVGProps } from 'react';

type Props = { color?: string } & SVGProps<SVGSVGElement>;

export default function ReportIcon(props: Props) {
    return (
        <svg
            {...props}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#85858A"
            className="icon-svg-stroke"
        >
            <path
                d="M10.6666 12C11.0916 12.1885 11.4725 12.4638 11.7848 12.8082C12.0971 13.1526 12.3339 13.5586 12.48 14"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.6666 12C11.0916 12.1885 11.4725 12.4638 11.7848 12.8082C12.0971 13.1526 12.3339 13.5586 12.48 14"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.14673 12C4.72171 12.1885 4.34082 12.4638 4.02853 12.8082C3.71624 13.1526 3.47946 13.5586 3.3334 14"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.3333 9.99996V6.66663C11.3333 6.48982 11.2631 6.32025 11.138 6.19522C11.013 6.0702 10.8434 5.99996 10.6666 5.99996H5.33329C5.15648 5.99996 4.98691 6.0702 4.86189 6.19522C4.73686 6.32025 4.66663 6.48982 4.66663 6.66663V9.99996C4.66663 10.884 5.01782 11.7319 5.64294 12.357C6.26806 12.9821 7.1159 13.3333 7.99996 13.3333V13.3333C8.88401 13.3333 9.73186 12.9821 10.357 12.357C10.9821 11.7319 11.3333 10.884 11.3333 9.99996ZM9.99996 5.99996H5.99996V4.66663C5.99996 4.13619 6.21067 3.62749 6.58575 3.25241C6.96082 2.87734 7.46953 2.66663 7.99996 2.66663V2.66663C8.53039 2.66663 9.0391 2.87734 9.41417 3.25241C9.78925 3.62749 9.99996 4.13619 9.99996 4.66663V5.99996Z"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.48 2C11.4107 2.31219 11.2629 2.60154 11.0506 2.84069C10.8383 3.07983 10.5685 3.26087 10.2667 3.36667L9.67334 3.57333"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.34006 2C4.40937 2.31219 4.55718 2.60154 4.76949 2.84069C4.9818 3.07983 5.25161 3.26087 5.5534 3.36667L6.14673 3.57333"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14 4.73328L13.6467 5.33328C13.3923 5.74975 13.0562 6.11036 12.6586 6.39337C12.261 6.67638 11.8102 6.87592 11.3334 6.97994V6.97994"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.3334 9C11.8275 9.06121 12.3019 9.23104 12.7226 9.4973C13.1433 9.76356 13.4999 10.1196 13.7667 10.54L14 10.9067"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.66667 6.97994C4.18985 6.87592 3.73906 6.67638 3.34147 6.39337C2.94388 6.11036 2.60774 5.74975 2.35333 5.33328L2 4.73328"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2 10.9067L2.23333 10.54C2.50015 10.1196 2.8567 9.76356 3.27741 9.4973C3.69812 9.23104 4.17256 9.06121 4.66667 9V9"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
