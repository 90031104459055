import React, { useContext } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, registerables, ChartOptions, ChartData } from 'chart.js';
import font from '../../config/Fonts';
import color from '../../config/Colors';
import { formatDate } from '../../helpers/moment';
import { LanguageContext } from '../../contexts/useLanguage';
import { DailyGrossRevenueByType } from '../../models/grossRevenue/DailyGrossRevenueByType';
import { formatNumber } from '../../helpers/FormatCurrency';
ChartJS.register(...registerables);

type Props = {
    dailyGrossRevenueStatistic: DailyGrossRevenueByType[];
};

export default function LineChartLongTerm(props: Props) {
    const { t } = useContext(LanguageContext);

    const formatData = (val: number) => {
        return Number(val) || 0;
    };

    const data: ChartData<'line', number[], string> = {
        labels:
            props.dailyGrossRevenueStatistic &&
            props.dailyGrossRevenueStatistic.map((item) => {
                return 'Date: ' + formatDate.getLocalDay(item.inDate);
            }),
        datasets: [
            {
                label: t('dashboard:title.numberOfLTBookings'),
                data:
                    props.dailyGrossRevenueStatistic &&
                    props.dailyGrossRevenueStatistic.map((item) => formatData(item.qtyLongTerm)),
                fill: false,
                backgroundColor: color.white,
                borderColor: color.primary,
                tension: 0.5,
                // pointRadius: 0,
            },
        ],
    };
    const options: ChartOptions<'line'> = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'bottom' as const,
                align: 'end',
                labels: {
                    font: {
                        family: font,
                    },
                },
                display: false,
            },
            datalabels: {
                display: true,
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label(tooltipItems: any) {
                        return ` ${tooltipItems.dataset.label}: ${formatNumber.format(tooltipItems.formattedValue)}`;
                    },
                },
            },
        },

        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    font: {
                        family: font,
                    },
                    callback: function (value, index, ticks) {
                        return formatDate.getLocalDay2(props.dailyGrossRevenueStatistic[Number(value)].inDate);
                    },
                },
            },
            y: {
                grid: {
                    display: false,
                },
                ticks: {
                    font: {
                        family: font,
                    },
                },
                min: 0,
            },
        },
    };

    return <Line options={options} data={data} />;
}
