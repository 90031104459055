/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingButton } from '@mui/lab';
import {
    Box,
    CircularProgress,
    Grid,
    Grow,
    InputAdornment,
    Pagination,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import _ from 'lodash';
import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import ExportIcon from '../../assets/images/ExportIcon';
import NoDataToDisplay from '../../components/NoDataToDisplay';
import IAutoCompleteRF from '../../components/autoComplete/IAutoComplete2';
import IMultiSelectWithCheckBoxRF from '../../components/autoComplete/IMultiSelectWithCheckBoxRF';
import FilterRoot from '../../components/filter/FilterRoot';
import color from '../../config/Colors';
import { LanguageContext } from '../../contexts/useLanguage';
import { anprDashboardDownload, locationController, shopController, zoneController } from '../../controllers';
import { BlobFile } from '../../helpers/BlobFileHepler';
import useScrollToTop from '../../hooks/useScrollToTop';
import { FilterTenant, TenantsPagination } from '../../models/BaseFilter';
import { LocationWithDetail } from '../../models/Location';
import Zone from '../../models/Zone';
import IconSearch from '../companyLayout/components/svg/IconSearch';
import { DrawerContext } from '../dashboardLayout/useDrawer';
import TenantsItem from './table/TenantsItem';
import { useSearchParams } from 'react-router-dom';

interface DataState {
    locations: LocationWithDetail[];
    zones: Zone[];
}

interface LoadingState {
    locationLoading: boolean;
    zoneLoading: boolean;
    tenantsLoading: boolean;
    isExportLoading: boolean;
}

interface FilterStateId {
    locationId?: number;
    page?: number;
    zoneIds?: number[];
    searchValue?: string;
}

export enum ParamsFilterTenant {
    location = 'locationTId',
    zones = 'zoneTIds',
    page = 'pageT',
    searchValue = 'searchValueT',
}

export default function TenantList() {
    const { setActiveItem } = useContext(DrawerContext);
    const { t } = useContext(LanguageContext);
    const inputRef = useRef<HTMLInputElement | null>(null);

    const [searchParams, setSearchParams] = useSearchParams();

    const params = {
        locationId: searchParams.get(ParamsFilterTenant.location),
        zoneIds: searchParams.get(ParamsFilterTenant.zones),
        page: searchParams.get(ParamsFilterTenant.page),
        searchValue: searchParams.get(ParamsFilterTenant.searchValue),
    };

    const [dataState, setDataState] = useState<DataState>({
        locations: [],
        zones: [],
    });
    const setPartialData = (props: Partial<DataState>) => setDataState((prev) => ({ ...prev, ...props }));

    const [loading, setLoading] = useState<LoadingState>({
        locationLoading: true,
        zoneLoading: false,
        tenantsLoading: true,
        isExportLoading: false,
    });

    const setPartialLoading = (loading: Partial<LoadingState>) => {
        setLoading((prev) => ({ ...prev, ...loading }));
    };

    const [filterState, setFilterState] = useState<FilterStateId>({
        locationId: !!params.locationId ? Number(params.locationId) : undefined,
        zoneIds: !!params.zoneIds ? params.zoneIds.split(',').map(Number) : [],
        page: !!params.page ? Number(params.page) : undefined,
        searchValue: !!params.searchValue ? params.searchValue : '',
    });

    const setPartialFilter = (props: Partial<FilterStateId>) => setFilterState((prev) => ({ ...prev, ...props }));

    const [pagingTenants, setPagingTenant] = useState<TenantsPagination>({} as TenantsPagination);

    useEffect(() => {
        //function to get locations
        getLocations();

        if (params.locationId !== null) {
            getMyZones(Number(params.locationId));
        }
    }, []);

    useEffect(() => {
        handleChangeFilterToParams();
    }, [filterState, searchParams]);

    const handleChangeFilterToParams = () => {
        const newParams = new URLSearchParams({});

        if (filterState.locationId) newParams.append(ParamsFilterTenant.location, filterState.locationId.toString());
        if (filterState.zoneIds?.length) {
            const handlerIdsParam = filterState.zoneIds.map((item) => encodeURIComponent(item)).join(',');
            newParams.append(ParamsFilterTenant.zones, handlerIdsParam);
        }
        if (filterState.page && filterState.page !== 1) {
            newParams.append(ParamsFilterTenant.page, filterState.page.toString());
        }

        if (filterState.searchValue && filterState.searchValue !== '')
            newParams.append(ParamsFilterTenant.searchValue, filterState.searchValue);

        setSearchParams(newParams);
    };

    useScrollToTop();

    useEffect(() => {
        //api get tenant list
        console.log(filterState);
        getListTenants();
    }, [JSON.stringify(filterState)]);

    const getListTenants = () => {
        setPartialLoading({ tenantsLoading: true });
        shopController
            .filter({
                page: filterState.page ?? 1,
                pageSize: 6,
                search: {
                    fields: [''],
                    value: filterState.searchValue ?? '',
                },
                filter: {
                    locationId: filterState.locationId,
                    zoneIds: filterState.zoneIds,
                },
            })
            .then((res) => setPagingTenant(res))
            .finally(() => setPartialLoading({ tenantsLoading: false }));
    };

    const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setPartialFilter({ searchValue: e.target.value, page: 1 });
    };

    const onClickClearSearch = () => {
        if (inputRef.current) {
            inputRef.current.value = '';
            setPartialFilter({ searchValue: '', page: 1 });
        }
    };

    const handleResetFilter = () => {
        onClickClearSearch();
        setFilterState({});
        inputRef.current && (inputRef.current.value = '');
    };

    const getLocations = () => {
        setPartialLoading({ locationLoading: true });
        locationController
            .getAllRF(initFilterLocation)
            .then((res) => setPartialData({ locations: res.data }))
            .finally(() => setPartialLoading({ locationLoading: false }));
    };

    const getMyZones = (locationId?: number) => {
        setPartialLoading({ zoneLoading: true });
        zoneController
            .getZonesRF({
                locationId: locationId ?? 0,
                permitId: 0,
                tenantId: 0,
            })
            .then((zones) => {
                setPartialData({ zones });
                setPartialFilter({ zoneIds: zones.map((z) => z.id) });
            })
            .finally(() => setPartialLoading({ zoneLoading: false }));
    };

    const handleChangeZone = (zones?: Zone[]) => {
        setPartialFilter({ zoneIds: zones?.map((z) => z.id) ?? [], page: 1 });
    };

    const handleChangePagination = (event: React.ChangeEvent<unknown>, value: number) => {
        setPartialFilter({ page: value });
    };

    const fileType = (fileName: string) => {
        return `${fileName}.xlsx`;
    };

    const handleExportExcel = async () => {
        setPartialLoading({ isExportLoading: true });
        const nameFile = fileType(t('reportPage:title.tenantList'));
        await anprDashboardDownload
            .DownloadTenantList({
                filter: {
                    page: pagingTenants.page,
                    pageSize: 6,
                    search: {
                        fields: [''],
                        value: filterState.searchValue ?? '',
                    },
                    filter: {
                        locationId: filterState.locationId,
                        zoneIds: filterState.zoneIds,
                    },
                },
            })
            .then((res) => {
                BlobFile(res, nameFile);
            })
            .finally(() => {
                setPartialLoading({ isExportLoading: false });
            });
    };

    useEffect(() => {
        setActiveItem(9);
    }, []);

    const handleChangeLocation = async (location?: LocationWithDetail) => {
        if (location) {
            setPartialLoading({ zoneLoading: true });
            await zoneController
                .getZonesRF({
                    locationId: location.id,
                    permitId: 0,
                    tenantId: 0,
                })
                .then((zones) => {
                    setPartialData({ zones });
                    setPartialFilter({ zoneIds: zones.map((z) => z.id), locationId: location?.id, page: 1 });
                })
                .finally(() => setPartialLoading({ zoneLoading: false }));
        } else {
            setPartialFilter({ locationId: undefined, page: 1, zoneIds: [] });
        }
    };

    const getZoneValue = () => {
        const zones: Zone[] = [];
        filterState.zoneIds?.forEach((z) => {
            const find = dataState.zones.find((zone) => zone.id === z);
            if (find) zones.push(find);
        });
        return zones;
    };

    return (
        <Grid
            container
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'nowrap',
            }}
            xs={12}
        >
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography variant="h3" fontWeight={400} alignItems={'center'} maxWidth={'100%'}>
                    {t('dashboard:title.tenantList')}
                </Typography>
                <Grid item>
                    <LoadingButton
                        variant="contained"
                        loading={loading.isExportLoading}
                        loadingPosition="start"
                        startIcon={<></>}
                        endIcon={loading.isExportLoading ? <></> : <ExportIcon />}
                        sx={{
                            px: 4,
                            padding: { xs: '9.5px 10px', sm: '9.5px 20px' },
                            minWidth: { xs: '140px', sm: '160px' },
                            '& .svg-icon-fill': {
                                fill: color.containedButtonString,
                            },
                            '&:hover': {
                                '& .svg-icon-fill': {
                                    fill: color.white,
                                    transition: '0.2s',
                                },
                            },
                        }}
                        onClick={handleExportExcel}
                    >
                        {t('permits:button.exportData')}
                    </LoadingButton>
                </Grid>
            </Stack>
            <Grid item mt={4}>
                <FilterRoot
                    handleResetFilter={() => {
                        handleResetFilter();
                    }}
                    rightTitleContent={
                        <Stack direction={'row'} spacing={2}>
                            <TextField
                                inputRef={inputRef}
                                fullWidth
                                placeholder={t('companies:input.searchByNameEmail')}
                                onChange={_.debounce((value) => onChangeSearch(value), 1000)}
                                inputProps={{
                                    maxLength: 75,
                                }}
                                defaultValue={filterState.searchValue}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            sx={{
                                                '& .clear-content:hover': {
                                                    cursor: 'pointer',
                                                },
                                            }}
                                        >
                                            <IoCloseOutline
                                                className="clear-content"
                                                fontSize={'20px'}
                                                onClick={onClickClearSearch}
                                                style={{
                                                    display:
                                                        inputRef.current && inputRef.current.value ? 'block' : 'none',
                                                }}
                                            />

                                            <IconButton>
                                                <IconSearch />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Stack>
                    }
                    filterChild={
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                {/* {locationSelect.RenderSelect} */}
                                <IAutoCompleteRF
                                    listData={dataState.locations}
                                    itemValue={dataState.locations.find((l) => l.id === filterState.locationId)}
                                    label={t('dashboard:input.location')}
                                    keyLabel={'name'}
                                    keyEqual={'id'}
                                    setFilter={handleChangeLocation}
                                    isHaveAllOptions={true}
                                    allOptionLabel={t('permits:input.AllLocations')}
                                    isLoading={loading.locationLoading}
                                    disabled={loading.locationLoading}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {/* {zoneSelects.RenderSelect} */}
                                <IMultiSelectWithCheckBoxRF
                                    limitTags={3}
                                    label={t('dashboard:input.zone')}
                                    keyId={'id'}
                                    keyLabel={'publicName'}
                                    listData={dataState.zones}
                                    isDisable={!filterState.locationId}
                                    value={getZoneValue()}
                                    setFilter={(zones) => {
                                        handleChangeZone(zones);
                                    }}
                                    chipSize={300}
                                    placeHolder={filterState.zoneIds?.length ? undefined : t('permits:input.AllZones')}
                                    isLoading={loading.zoneLoading}
                                />
                            </Grid>
                        </Grid>
                    }
                />
            </Grid>
            <Grid>
                {loading.tenantsLoading ? (
                    <Stack direction="row" justifyContent="center" alignItems="center" mt={10}>
                        <Grow in={true} timeout={Math.min(500, 1000)}>
                            <Box height={'100%'}>
                                <CircularProgress />
                            </Box>
                        </Grow>
                    </Stack>
                ) : pagingTenants.data?.length ? (
                    <Box
                        mt={4}
                        sx={{
                            border: '1px solid #DDD',
                            borderRadius: '8px',
                        }}
                    >
                        <TenantsItem tenants={pagingTenants} />
                        {pagingTenants?.totalPage > 1 && !loading.tenantsLoading && (
                            <Stack direction={'row'} justifyContent="center">
                                <Pagination
                                    count={pagingTenants.totalPage}
                                    page={pagingTenants.page}
                                    onChange={handleChangePagination}
                                    variant="outlined"
                                    shape="rounded"
                                    sx={{ mt: 3, mb: '40px' }}
                                />
                            </Stack>
                        )}
                    </Box>
                ) : (
                    <NoDataToDisplay text={t('companies:text.noDataToDisplay')} />
                )}
            </Grid>
        </Grid>
    );
}

const initFilterLocation = {
    pageSize: 10000,
    page: 1,
    filter: { companyId: 0, regionId: 0 },
    search: {
        fields: [],
        value: '',
    },
};

const initFilterTenant: FilterTenant = {
    page: 1,
    pageSize: 6,
    search: {
        fields: [''],
        value: '',
    },
    filter: {
        locationId: undefined,
    },
};
