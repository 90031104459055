/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, Stack, TextField, Typography } from '@mui/material';
import React, { useContext, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import LabelWithRequired from '../../../components/LabelWithRequired';
import color from '../../../config/Colors';
import UseLanguage from '../../../contexts/useLanguage';
import ReportImageUploadItem, { FileUpload } from './ReportImageUploadItem';
import { LoadingButton } from '@mui/lab';
import { HiUpload } from 'react-icons/hi';
import { v4 as uuidv4 } from 'uuid';
import { AuthContext } from '../../../hooks/useAuth';
import { ReportMail } from '../../../controllers/ReportBugController';
import { reportBugController } from '../../../controllers';
import { toast } from 'react-hot-toast';
import { EMAIL_SEND_SUPPORT } from '../../../config/AppConfig';
import _ from 'lodash';

const MAX_FILE_SIZE = 10 * 1024 * 1024;

export default function ReportTab() {
    const { account } = useContext(AuthContext);
    const { t } = UseLanguage();
    const schema = Yup.object({
        subject: Yup.string().required(`${t('validation:subjectIsRequired')}`),
        message: Yup.string().required(`${t('validation:messageIsRequired')}`),
    });

    const uploadInputRef = useRef<HTMLInputElement | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const formData = useForm<{ message: string; subject: string }>({
        mode: 'all',
        resolver: yupResolver(schema),
        defaultValues: {
            message: '',
            subject: '',
        },
    });
    const {
        control,
        formState: { errors, isValid, touchedFields },
        setValue,
        getValues,
        reset,
    } = formData;

    const [files, setFiles] = useState<FileUpload[]>([]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = event.target.files;
        if (selectedFiles) {
            Array.from(selectedFiles).forEach((file) => {
                if (file.size < MAX_FILE_SIZE) {
                    const fileExists = files.some((f) => f.file.name === file.name);
                    if (!fileExists) {
                        const fileUpload: FileUpload = {
                            id: uuidv4(),
                            file,
                            progress: 0,
                            isError: false,
                            isUpload: true,
                        };
                        setFiles((prevFiles) => [...prevFiles, fileUpload]);
                    }
                }
            });
            //clear input file
            (event.target as HTMLInputElement).value = '';
        }
    };

    const handleRemoveFile = (id: string) => {
        setFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
    };

    const updateFileProgress = (fileUpload: FileUpload) => {
        setFiles((prev) => {
            const filesClone = _.cloneDeep(prev);
            filesClone.forEach((item, index) => {
                if (item.id === fileUpload.id) {
                    filesClone[index] = { ...fileUpload };
                }
            });
            return filesClone;
        });
    };

    const handleSendMail = () => {
        setIsLoading(true);
        const photoUrls: string[] = [];
        files.forEach((file) => {
            if (file.url) photoUrls.push(file.url);
        });
        const mail: ReportMail = {
            accountId: account.accountId,
            message: getValues().message,
            subject: getValues().subject,
            toMail: EMAIL_SEND_SUPPORT,
            photos: photoUrls,
        };

        reportBugController
            .SendReportMail(mail)
            .then((res) => {
                toast.success(t('reportPage:text.reportSentSuccessfully'));
            })
            .finally(() => {
                setIsLoading(false);
                handleClearForm();
            });
    };

    const handleClearForm = () => {
        setFiles([]);
        setValue('message', '');
        setValue('subject', '');
        reset();
    };

    const isAllUploadedDone = files.every((item) => item.isUpload === false);

    return (
        <Grid container spacing={3} sx={{ '& .MuiFormHelperText-root': { marginLeft: 0 } }}>
            <Grid item>
                <Typography variant="h3" fontWeight={400}>
                    {t('support:title.reportABug')}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    value={EMAIL_SEND_SUPPORT}
                    label={'To'}
                    placeholder={t('input:enterAddress')}
                    onChange={(e) => {}}
                    inputProps={{
                        maxLength: 200,
                    }}
                    disabled
                    fullWidth
                    size="small"
                />
            </Grid>

            <Grid item xs={12}>
                <Controller
                    control={control}
                    name={'subject'}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={<LabelWithRequired label={t('support:text.subject')} />}
                            placeholder={t('input:enterSubject')}
                            error={Boolean(touchedFields?.subject && errors?.subject)}
                            helperText={touchedFields?.subject && errors.subject?.message}
                            onChange={(e) => field.onChange(e.target.value)}
                            inputProps={{
                                maxLength: 200,
                            }}
                            // disabled={isEdit}
                            fullWidth
                            size="small"
                        />
                    )}
                />
            </Grid>

            <Grid item xs={12}>
                <Controller
                    control={control}
                    name={'message'}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={<LabelWithRequired label={t('support:text.message')} />}
                            placeholder={t('support:text.enterDetailedDescription')}
                            error={Boolean(touchedFields?.message && errors?.message)}
                            helperText={touchedFields?.message && errors.message?.message}
                            onChange={(e) => field.onChange(e.target.value)}
                            inputProps={{
                                maxLength: 500,
                            }}
                            sx={{
                                '&.MuiInputBase-input': { p: '0px' },
                                '& .MuiOutlinedInput-input': { p: '0px !important' },
                            }}
                            multiline
                            minRows={4}
                            // disabled={isEdit}
                            fullWidth
                            size="small"
                        />
                    )}
                />
            </Grid>

            <Grid item xs={12}>
                <Grid item container direction={'row'} alignItems={'center'}>
                    <Grid item xs>
                        <Typography>{t('support:text.uploadYourScreenshotsWhereNecessary')}</Typography>
                    </Grid>
                    <Grid item xs="auto">
                        <>
                            <input
                                ref={uploadInputRef}
                                type="file"
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                                multiple
                            />
                            <LoadingButton
                                variant="outlined"
                                loading={false}
                                loadingPosition="start"
                                startIcon={<></>}
                                sx={{ minWidth: { xs: '130px', sm: '200px' } }}
                                endIcon={<HiUpload style={{ fontSize: '14px' }} />}
                                // disabled={(progressPercent !== 0 && progressPercent !== 100) || isLoading || !isScopeValid}
                                onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                            >
                                {t('permits:upload.uploadFile')}
                            </LoadingButton>
                        </>
                    </Grid>
                </Grid>
                {files.length > 0 && (
                    <Stack gap={1} mt={1} sx={{ background: color.grey100, p: '16px 24px', borderRadius: '3px' }}>
                        {files.map((file, index) => (
                            <ReportImageUploadItem
                                key={file.id}
                                reportItem={file}
                                handleRemoveFile={handleRemoveFile}
                                updateFileProgress={updateFileProgress}
                            />
                        ))}
                    </Stack>
                )}
                <Stack direction={'row'} justifyContent={'space-between'} mt={2}>
                    <Button
                        variant="cancel"
                        sx={{ minWidth: { xs: '130px', sm: '200px' } }}
                        onClick={() => handleClearForm()}
                    >
                        {t('support:text.clear')}
                    </Button>
                    <LoadingButton
                        variant="contained"
                        loading={isLoading}
                        loadingPosition="start"
                        startIcon={<></>}
                        sx={{ minWidth: { xs: '130px', sm: '200px' } }}
                        disabled={!isValid || !isAllUploadedDone}
                        onClick={() => handleSendMail()}
                    >
                        {t('support:text.sendEmail')}
                    </LoadingButton>
                </Stack>
            </Grid>
        </Grid>
    );
}
