import React, { ReactNode, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import useMedia from '../../hooks/useMedia';
import color from '../../config/Colors';

type Props = {
    modalContent: ReactNode;
    open: boolean;
    handleOpen: (isOpen: boolean) => void;
    handleClose: (isOpen: boolean) => void;
};

export default function MyModal({ modalContent, open, handleOpen, handleClose }: Props) {
    return (
        <Box sx={{}}>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: {
                            xs: 350,
                            md: 500,
                        },
                        bgcolor: color.white,
                        gap: 4,
                        borderRadius: '10px',
                        // boxShadow: 24,
                    }}
                >
                    {modalContent}
                </Box>
            </Modal>
        </Box>
    );
}
