import { BaseHttpController } from 'ale-base-model'
import { AxiosInstance } from 'axios';
import apiAccount from '../config/Api';

export class AzureMediaController extends BaseHttpController<string> {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'SiteApi', client);
    }


    async getAvatar(idUser: string) {
        const res = await this.client.get(`${this.serviceURL}/${idUser}`)
        return res.data
    }

}

