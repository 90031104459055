import { SVGProps, forwardRef } from 'react';

const WarningVRN = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
    const { children, ...restProps } = props;
    return (
        <svg
            style={{ cursor: 'pointer' }}
            ref={ref}
            {...restProps}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.00001 11.3335C8.1889 11.3335 8.34734 11.2695 8.47534 11.1415C8.60334 11.0135 8.66712 10.8553 8.66668 10.6668C8.66668 10.4779 8.60268 10.3195 8.47468 10.1915C8.34668 10.0635 8.18845 9.99972 8.00001 10.0002C7.81112 10.0002 7.65268 10.0642 7.52468 10.1922C7.39668 10.3202 7.3329 10.4784 7.33334 10.6668C7.33334 10.8557 7.39734 11.0142 7.52534 11.1422C7.65334 11.2702 7.81157 11.3339 8.00001 11.3335ZM8.00001 8.66683C8.1889 8.66683 8.34734 8.60283 8.47534 8.47483C8.60334 8.34683 8.66712 8.18861 8.66668 8.00016V5.3335C8.66668 5.14461 8.60268 4.98616 8.47468 4.85816C8.34668 4.73016 8.18845 4.66639 8.00001 4.66683C7.81112 4.66683 7.65268 4.73083 7.52468 4.85883C7.39668 4.98683 7.3329 5.14505 7.33334 5.3335V8.00016C7.33334 8.18905 7.39734 8.3475 7.52534 8.4755C7.65334 8.6035 7.81157 8.66727 8.00001 8.66683ZM8.00001 14.6668C7.07779 14.6668 6.21112 14.4917 5.40001 14.1415C4.5889 13.7913 3.88334 13.3164 3.28334 12.7168C2.68334 12.1168 2.20845 11.4113 1.85868 10.6002C1.5089 9.78905 1.33379 8.92239 1.33334 8.00016C1.33334 7.07794 1.50845 6.21127 1.85868 5.40016C2.2089 4.58905 2.68379 3.8835 3.28334 3.2835C3.88334 2.6835 4.5889 2.20861 5.40001 1.85883C6.21112 1.50905 7.07779 1.33394 8.00001 1.3335C8.92223 1.3335 9.7889 1.50861 10.6 1.85883C11.4111 2.20905 12.1167 2.68394 12.7167 3.2835C13.3167 3.8835 13.7918 4.58905 14.142 5.40016C14.4922 6.21127 14.6671 7.07794 14.6667 8.00016C14.6667 8.92239 14.4916 9.78905 14.1413 10.6002C13.7911 11.4113 13.3162 12.1168 12.7167 12.7168C12.1167 13.3168 11.4111 13.7919 10.6 14.1422C9.7889 14.4924 8.92223 14.6673 8.00001 14.6668ZM8.00001 13.3335C9.4889 13.3335 10.75 12.8168 11.7833 11.7835C12.8167 10.7502 13.3333 9.48905 13.3333 8.00016C13.3333 6.51127 12.8167 5.25016 11.7833 4.21683C10.75 3.1835 9.4889 2.66683 8.00001 2.66683C6.51112 2.66683 5.25001 3.1835 4.21668 4.21683C3.18334 5.25016 2.66668 6.51127 2.66668 8.00016C2.66668 9.48905 3.18334 10.7502 4.21668 11.7835C5.25001 12.8168 6.51112 13.3335 8.00001 13.3335Z"
                fill="#E01B00"
            />
        </svg>
    );
});

export default WarningVRN;
