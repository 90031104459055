
export interface VRN {
    color: string,
    vrm: string,
    make: string,
    model: string,
    imageUrl: string,
}

export enum statusVRN {
    none,
    true,
    notFound,
}