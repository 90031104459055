/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { addDays, endOfDay, isAfter, startOfDay } from 'date-fns';
import { useFormik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import { createContext, useContext, useState } from 'react';
import { ConvertToGMT0Time, FormatVRNString } from '../../../../../../helpers';
import { convertHour } from '../../../../../../helpers/ConvertHour';
import useYupSchema from '../../../../../../helpers/useYupSchema';
import { AuthContext } from '../../../../../../hooks/useAuth';
import { Permit, ValidateToType } from '../../../../../../models/permits/Permit';
import { PermitRecurring, RecurringType } from '../../../../../../models/permits/PermitRecurring';
import { GetPermitScopes } from '../../../../helpers';
import { getDaysOfWeekRecur } from '../../../helpers/PermitHelpers';
import { UpsertPermitContext } from '../../../hooks/useUpsertPermit';
import { calculateFirstDate, genWeekDates, getDateWeekRecur } from '../helpers';

type Props = {
    permit: Permit;
};

interface State {
    permit: Permit;
    isValid: boolean;
    daysOfWeekRecur: Date[];
}

export default function useUpsertPermitItem(props: Props) {
    const { t, isEdit, filterState, tenantLocationId } = useContext(UpsertPermitContext);

    const { account } = useContext(AuthContext);

    const getValidateFrom = () => {
        if (isEdit) {
            if (isAfter(new Date(itemState.permit.validateFrom!), new Date())) {
                return new Date(itemState.permit.validateFrom!);
            } else {
                return null;
            }
        } else {
            return new Date(itemState.permit.validateFrom!);
        }
    };

    const getValidateTo = () => {
        if (isAfter(new Date(itemState.permit.validateTo!), new Date())) {
            return new Date(itemState.permit.validateTo!);
        }
        return null;
    };

    const [itemState, setItemState] = useState<State>({
        permit: props.permit,
        isValid: false,
        daysOfWeekRecur: getDaysOfWeekRecur(props.permit.permitRecurring[0].permitRecurringDays),
    });

    // const initValue = {
    //     permitType: itemState.permit.type,
    //     vrnNumber: itemState.permit.vrnNumber,
    //     firstName: itemState.permit.firstName,
    //     lastName: itemState.permit.lastName,
    //     fromHour: convertHour.getHoursAndMinutesFromTimeNumber(itemState.permit.permitRecurring[0].fromHour),
    //     toHour: convertHour.getHoursAndMinutesFromTimeNumber(itemState.permit.permitRecurring[0].toHour),
    //     validateFrom: getValidateFrom(),
    //     validateTo: getValidateTo(),
    //     endStatus: ValidateToType.infinite as number,
    //     endAfterValue: itemState.permit.validateToAfterRecurringTime || 1,
    //     bayNumber: itemState.permit.bayNumber,
    //     comment: itemState.permit.comment,
    //     requestedBy: itemState.permit.requestedBy,
    // };

    const getCheckedAllDay = () => {
        if (
            itemState.permit.permitRecurring[0] &&
            itemState.permit.permitRecurring[0].fromHour == 0 &&
            itemState.permit.permitRecurring[0].toHour == 86340
        ) {
            return true;
        } else {
            return false;
        }
    };

    //get and set recur every
    const getRecurEveryNumber = () => {
        return itemState.permit.permitRecurring[0].recurringEvery;
    };

    const setRecurEveryNumber = (num: number) => {
        const permitRecurs: PermitRecurring[] = _.cloneDeep(itemState.permit.permitRecurring);
        permitRecurs[0].recurringEvery = num;
        setItemState((prev) => ({ ...prev, permit: { ...prev.permit, permitRecurring: permitRecurs } }));
    };

    return {
        ...itemState,
        setItemState,
        itemState,
        t,
        getRecurEveryNumber,
        setRecurEveryNumber,
        getDaysOfWeekRecur,
        isEdit,
        oldPermit: props.permit,
    };
}

export const UpsertPermitItemContext = createContext<ReturnType<typeof useUpsertPermitItem>>({} as any);
