import { Box, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { GridCard } from '../../../../components/GridCard';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/styles';
import { LanguageContext } from '../../../../contexts/useLanguage';
import { useNavigate } from 'react-router-dom';
import { LocationWithDetail } from '../../../../models/Location';
import { SERVICE_TYPES } from '../../../../models/ServiceTypes';
import color from '../../../../config/Colors';
import { LocationTab } from '../../locationDetail';
import GeneralInfoIcon from '../../../../assets/images/GeneralInfoIcon';
import ZoneIcon from '../../../../assets/images/ZoneIcon';
import AddTenantIcon from '../../../../assets/images/AddTenantIcon';
import { LinkTo } from '../../../login/styles';

type Props = {
    locationWithDetail: LocationWithDetail;
};
export default function LocationItem(props: Props) {
    const { t } = useContext(LanguageContext);
    const locationWithDetail = props.locationWithDetail;

    return (
        <GridCard item container xs={12}>
            <Grid item container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Grid item xs={7}>
                    <LinkTo to={`locations/${props.locationWithDetail.id}/${LocationTab.GeneralInformation}`}>
                        <Typography
                            sx={{
                                '&:hover': {
                                    color: color.priText,
                                    textDecorationLine: 'underline',
                                    transition: '0.3s',
                                    cursor: 'pointer',
                                },
                            }}
                            // onClick={onClickSelectGeneralInfo}
                            variant="h5"
                        >
                            {locationWithDetail.name}
                        </Typography>
                    </LinkTo>
                </Grid>

                <Grid
                    item
                    container
                    display={'flex'}
                    justifyContent={'right'}
                    sx={{
                        backgroundColor: `${color.white} !important`,
                    }}
                    xs={5}
                >
                    <Grid
                        item
                        sx={{
                            backgroundColor: `${color.white} !important`,
                        }}
                    >
                        <LinkTo to={`locations/${props.locationWithDetail.id}/${LocationTab.GeneralInformation}`}>
                            <Tooltip title={t('companies:title.generalInformation')} placement="top">
                                {/* <IconButton onClick={onClickSelectLocation} aria-label="fingerprint" color="primary" sx={{}}>
                            <SeeDetailIcon style={{ cursor: 'pointer' }} />
                        </IconButton> */}
                                <IconButton
                                    // onClick={onClickSelectGeneralInfo}
                                    aria-label="fingerprint"
                                    color="success"
                                    sx={{
                                        backgroundColor: color.grey200,
                                        '&:hover': {
                                            backgroundColor: color.grey200,
                                            '.svg-icon-fill': {
                                                fill: color.primary,
                                                transitionDuration: '0.2s',
                                            },
                                            '.svg-icon-stroke': {
                                                stroke: color.primary,
                                                transitionDuration: '0.2s',
                                            },
                                        },

                                        // outline: `5px solid ${color.grey100}`,
                                        border: `5px solid ${color.grey100}`,
                                        width: '40px',
                                        height: '40px',
                                    }}
                                >
                                    <GeneralInfoIcon style={{ width: '20px', height: '20px' }} />
                                </IconButton>
                            </Tooltip>
                        </LinkTo>
                    </Grid>
                    <Grid item>
                        <LinkTo to={`locations/${props.locationWithDetail.id}/${LocationTab.Zones}`}>
                            <Tooltip title={t('companies:text.zones')} placement="top">
                                {/* <IconButton onClick={onClickSelectLocation} aria-label="fingerprint" color="primary" sx={{}}>
                            <SeeDetailIcon style={{ cursor: 'pointer' }} />
                        </IconButton> */}
                                <IconButton
                                    // onClick={onClickSelectZones}
                                    aria-label="fingerprint"
                                    color="success"
                                    sx={{
                                        backgroundColor: color.grey200,
                                        '&:hover': {
                                            backgroundColor: color.grey200,
                                            '&:hover': {
                                                backgroundColor: color.grey200,
                                                '.svg-icon-fill': {
                                                    fill: color.primary,
                                                    transitionDuration: '0.2s',
                                                },
                                                '.svg-icon-stroke': {
                                                    stroke: color.primary,
                                                    transitionDuration: '0.2s',
                                                },
                                            },
                                        },
                                        border: `5px solid ${color.grey100}`,
                                        width: '40px',
                                        height: '40px',
                                    }}
                                >
                                    <ZoneIcon />
                                </IconButton>
                            </Tooltip>
                        </LinkTo>
                    </Grid>
                    <Grid item>
                        <LinkTo to={`locations/${props.locationWithDetail.id}/${LocationTab.Tenant}`}>
                            <Tooltip title={t('companies:text.tenants')} placement="top">
                                {/* <IconButton onClick={onClickSelectLocation} aria-label="fingerprint" color="primary" sx={{}}>
                            <SeeDetailIcon style={{ cursor: 'pointer' }} />
                        </IconButton> */}
                                <IconButton
                                    aria-label="fingerprint"
                                    color="success"
                                    sx={{
                                        backgroundColor: color.grey200,
                                        '&:hover': {
                                            backgroundColor: color.grey200,
                                            '.svg-icon-fill': {
                                                fill: color.primary,
                                                transitionDuration: '0.2s',
                                            },
                                            '.svg-icon-stroke': {
                                                stroke: color.primary,
                                                transitionDuration: '0.2s',
                                            },
                                        },
                                        border: `5px solid ${color.grey100}`,
                                        width: '40px',
                                        height: '40px',
                                    }}
                                >
                                    <AddTenantIcon />
                                </IconButton>
                            </Tooltip>
                        </LinkTo>
                    </Grid>
                </Grid>
            </Grid>

            <Grid
                pt={1}
                item
                container
                direction={'column'}
                justifyContent={'flex-start'}
                alignItems={'flex-start'}
                gap={1}
            >
                <Typography variant="body1">
                    {t('companies:text.address')}: {locationWithDetail.address1}
                </Typography>

                <Stack direction={'row'} justifyContent={'flex-start'} alignItems={'center'} spacing={1}>
                    <Typography variant="body1">
                        {t('companies:text.zones')}: {locationWithDetail.totalZones}
                    </Typography>

                    {/* <Box sx={{ borderLeft: '1px solid #85858A', height: 26 }} /> */}

                    {/* <Typography variant="body1">{t('companies:text.siteLiveDate')}: </Typography> */}
                </Stack>
            </Grid>
        </GridCard>
    );
}
const ChipStyle = styled(Chip)({
    borderRadius: '100px',
    '&.MuiChip-root': {
        padding: '4px 8px',
    },
});
