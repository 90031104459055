import { Box, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import BaseListRender from '../../../components/BaseListRender';
import MyBreadcrumbs from '../../../components/BreadCrumbs';
import NoDataToDisplay from '../../../components/NoDataToDisplay';
import IAutoCompleteRF from '../../../components/autoComplete/IAutoComplete2';
import color from '../../../config/Colors';
import { LanguageContext } from '../../../contexts/useLanguage';
import { companiesController, locationController } from '../../../controllers';
import { LoadingFilter, _initLoadingFilter } from '../../../hooks/useFilter';
import { LocationsContext } from '../../../hooks/useLocations';
import useScrollToTop from '../../../hooks/useScrollToTop';
import { Region } from '../../../models/Region';
import IconSearch from '../components/svg/IconSearch';
import MapViewIcon from '../components/svg/MapViewIcon';
import MenuIcon from '../components/svg/MenuIcon';
import LocationItem from './components/LocationItem';
import ViewMap from './components/ViewMap';

interface DataState {
    regions: Region[];
}

export enum ParamsFilterLocation {
    region = 'regionLId',
    searchC = 'searchL',
}

interface FilterStateId {
    regionId?: number;
    searchValue?: string;
}

export default function Location() {
    const { idCompany } = useParams();

    const [searchParams, setSearchParams] = useSearchParams();

    const params = {
        regionId: searchParams.get(ParamsFilterLocation.region),
        searchValue: searchParams.get(ParamsFilterLocation.searchC),
    };
    const companyId = idCompany ? Number(idCompany) : 0;
    const { t } = useContext(LanguageContext);
    const [view, setView] = useState(0);
    const handleChange = (event: any) => {
        setView(event.target.value as number);
    };
    useScrollToTop();

    const [filterState, setFilterState] = useState<FilterStateId>({
        regionId: !!params.regionId ? Number(params.regionId) : 0,
        searchValue: !!params.searchValue ? params.searchValue : '',
    });

    const setPartialFilter = (partial: Partial<FilterStateId>) => setFilterState((prev) => ({ ...prev, ...partial }));

    const { locationLoading, locationPaging, setLocationLoading, setLocationPaging } = useContext(LocationsContext);

    const [loading, setLoading] = useState<LoadingFilter>(_initLoadingFilter);
    const setPartialLoading = (partial: Partial<LoadingFilter>) => setLoading((prev) => ({ ...prev, ...partial }));

    const [dataState, setDataState] = useState<DataState>({ regions: [] });
    const setPartialDataState = (partial: Partial<DataState>) => setDataState((prev) => ({ ...prev, ...partial }));

    useEffect(() => {
        if (idCompany) {
            getLocation();
        }
    }, [idCompany, filterState]);

    const onChangeSearch = (e: any) => {
        setPartialFilter({ searchValue: e.target.value });
    };

    useEffect(() => {
        handleChangeFilterToParams();
    }, [filterState, searchParams]);

    const handleChangeFilterToParams = () => {
        const newParams = new URLSearchParams({});

        if (filterState.regionId) newParams.append(ParamsFilterLocation.region, filterState.regionId.toString());
        if (filterState.searchValue) newParams.append(ParamsFilterLocation.searchC, filterState.searchValue ?? '');

        setSearchParams(newParams);
    };

    const getLocation = () => {
        setLocationLoading(true);
        locationController
            .filter({
                page: 1,
                pageSize: 1000,
                filter: {
                    companyId: companyId,
                    regionId: filterState.regionId ?? 0,
                },
                search: {
                    fields: ['name', 'address1', 'address2', 'address3', 'postcode'],
                    value: filterState.searchValue ?? '',
                },
            })
            .then((res) => {
                setLocationPaging(res);
            })
            .finally(() => {
                setLocationLoading(false);
            });
    };

    useEffect(() => {
        setPartialLoading({ isLoadingRegion: true });
        companiesController
            .GetCompanyDetailRF({
                companyId: Number(idCompany),
                locationId: 0,
                regionId: 0,
                zoneIds: [],
                from: new Date(),
                to: new Date(),
            })
            .then((res) => {
                setPartialDataState({ regions: res.regions });
            })
            .finally(() => {
                setPartialLoading({ isLoadingRegion: false });
            });
    }, [idCompany]);

    const handleChangeRegion = (region?: Region) => {
        setPartialFilter({ regionId: region?.id ?? undefined });
    };

    return (
        <>
            <Typography variant="h3" fontWeight={400}>
                {t('companies:title.locations')}
            </Typography>

            <Box mt={2}>
                <MyBreadcrumbs />
            </Box>

            <Grid mt={1} item container direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={1}>
                <Grid item xs={5.5} sm={2.5} md={2} order={{ xs: 1, sm: 1 }}>
                    <FormControl fullWidth>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={view}
                            onChange={(e) => handleChange(e)}
                            sx={{
                                background: color.lightPrimary,
                                borderRadius: '3px',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                    outline: 'none',
                                },
                            }}
                        >
                            <MenuItem value={0}>
                                <Grid
                                    container
                                    direction={'row'}
                                    justifyContent={'flex-start'}
                                    alignItems={'center'}
                                    spacing={1}
                                >
                                    <Grid item xs={3} textAlign={'right'} sx={{ height: '24px' }}>
                                        <MenuIcon />
                                    </Grid>
                                    <Grid item xs={9} color={color.priText}>
                                        {t('companies:title.listView')}
                                    </Grid>
                                </Grid>
                            </MenuItem>

                            <MenuItem value={1}>
                                <Grid
                                    container
                                    direction={'row'}
                                    justifyContent={'flex-start'}
                                    alignItems={'center'}
                                    spacing={1}
                                >
                                    <Grid item xs={3} textAlign={'right'} sx={{ height: '24px' }}>
                                        <MapViewIcon />
                                    </Grid>
                                    <Grid item xs={9} color={color.priText}>
                                        {t('companies:title.mapView')}
                                    </Grid>
                                </Grid>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6.5} md={7.5} order={{ xs: 3, sm: 2 }}>
                    <TextField
                        fullWidth
                        placeholder={t('companies:input.SearchByNameAddressPostcode')}
                        onChange={_.debounce((value) => onChangeSearch(value), 500)}
                        inputProps={{
                            maxLength: 75,
                        }}
                        defaultValue={filterState.searchValue}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton>
                                        <IconSearch />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>

                <Grid item xs={6.5} sm={3} md={2.5} order={{ xs: 2, sm: 3 }}>
                    <IAutoCompleteRF
                        listData={dataState.regions}
                        itemValue={dataState.regions.find((r) => r.id === filterState.regionId)}
                        label={'Region'}
                        keyLabel={'name'}
                        keyEqual={'id'}
                        setFilter={handleChangeRegion}
                        isHaveAllOptions={true}
                        allOptionLabel={t('dashboard:text.allRegions')}
                        isLoading={loading.isLoadingRegion}
                    />
                </Grid>
            </Grid>

            {view === 0 ? (
                <BaseListRender
                    styleContainer={{ mt: 4 }}
                    styleList={{ gap: 2 }}
                    noDataComponent={<NoDataToDisplay text={t('companies:text.noDataToDisplay')} />}
                    paging={locationPaging}
                    renderedItem={(location) => <LocationItem locationWithDetail={location} />}
                    isLoading={locationLoading}
                    setPage={(page) => {
                        // setPartialFilter({ page });
                    }}
                />
            ) : (
                <Grid item mt={4} borderRadius={'10px'} overflow={'hidden'}>
                    {locationPaging.data && locationPaging.data.length > 0 ? (
                        <ViewMap isShowLocationPopOver={true} locations={locationPaging.data} />
                    ) : (
                        <Grid
                            mt={0}
                            item
                            container
                            alignItems={'center'}
                            justifyContent={'center'}
                            sx={{
                                background: color.grey100,
                                borderRadius: '10px',
                                padding: '10px 0',
                                height: 200,
                            }}
                        >
                            <Typography fontSize={20} fontWeight={500} color={'#85858A'}>
                                {t('companies:text.noDataToDisplay')}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            )}
        </>
    );
}
