import { Grid, Typography } from '@mui/material';
import { useContext } from 'react';
import color from '../../../../../../config/Colors';
import { UpsertPermitContext } from '../../../hooks/useUpsertPermit';

type Props = {
    handleSetSuggest: (string: string) => void;
};

export default function UpsertPermitItemSuggestion(props: Props) {
    const { t } = useContext(UpsertPermitContext);
    const listSuggest: string[] = [
        t('permits:text.blueBadge'),
        t('permits:text.students'),
        t('permits:text.contractors'),
        t('permits:text.residential'),
        t('permits:text.visitor'),
        t('permits:text.staff'),
    ];
    return (
        <Grid item container direction="row" mt={'5px'}>
            <Grid>
                <Typography variant="body2" fontWeight={500} color={color.grey600} component="span">
                    {t('permits:text.suggest')}:
                </Typography>
            </Grid>
            {listSuggest.map((item, index) => {
                const isLast = index === listSuggest.length - 1;
                return (
                    <Grid key={index}>
                        <Typography
                            variant="body2"
                            color={color.grey600}
                            component="span"
                            sx={{
                                '&:hover': {
                                    textDecorationLine: 'underline',
                                    color: color.secondary,
                                    cursor: 'pointer',
                                },
                            }}
                            onClick={() => {
                                props.handleSetSuggest(item);
                            }}
                        >
                            {`\u00A0${item}${isLast ? '' : ','}`}
                        </Typography>{' '}
                    </Grid>
                );
            })}
        </Grid>
    );
}
