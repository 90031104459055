import React, { useContext } from 'react';
import { LanguageContext } from '../contexts/useLanguage';
import Required from '../pages/companyLayout/employees/components/Required';

export default function LabelWithRequired({ label }: { label: string }) {
    return (
        <>
            {label} <Required />
        </>
    );
}
