import {
    Button,
    CircularProgress,
    Fade,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import color from '../../../../../../../config/Colors';
import MyModalLarge from '../../../../../../../components/modal/MyModalLarge';
import Zone from '../../../../../../../models/Zone';
import { formatDate } from '../../../../../../../helpers/moment';
import NoDataToDisplay from '../../../../../../../components/NoDataToDisplay';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useContext } from 'react';
import { LanguageContext } from '../../../../../../../contexts/useLanguage';

type Props = {
    open: boolean;
    handleClose: () => void;
    handleOpen: () => void;
    zoneBayTemporary: Zone;
    isLoading: boolean;
};

export default function ModalHistory({ open, handleClose, zoneBayTemporary, isLoading }: Props) {
    const { t } = useContext(LanguageContext);

    return (
        <MyModalLarge
            open={open}
            handleClose={handleClose}
            handleOpen={() => {}}
            sx={{
                width: {
                    xs: 310,
                    sm: 600,
                    md: 700,
                },
            }}
            modalContent={
                <Fade in={open}>
                    <Stack px={3} py={5} spacing={3}>
                        <Stack alignItems={'center'}>
                            <Typography variant="h4">{t('companies:text.historyTemporaryBayCount')}</Typography>
                        </Stack>

                        {zoneBayTemporary.zoneBayTemporaries?.length !== 0 ? (
                            !isLoading ? (
                                <Stack spacing={4}>
                                    <Stack
                                        direction={'row'}
                                        width={'100%'}
                                        spacing={5}
                                        paddingY={1}
                                        justifyContent={'center'}
                                        bgcolor={color.grey100}
                                    >
                                        <Typography variant="body2" color={color.success}>
                                            {t('companies:tenants.zone')}: {zoneBayTemporary.name}
                                        </Typography>
                                        <Typography variant="body2" color={color.secondary}>
                                            {t('companies:text.totalBays')}: {zoneBayTemporary.totalBays}
                                        </Typography>
                                    </Stack>
                                    <TableContainer
                                        component={Paper}
                                        sx={{
                                            borderRadius: '0px',
                                            boxShadow: 'none',
                                            maxHeight: 400,
                                            '&::-webkit-scrollbar-thumb': {
                                                backgroundColor: 'transparent',
                                                borderRadius: 8,
                                            },
                                            '&:hover': {
                                                '&::-webkit-scrollbar-thumb': {
                                                    backgroundColor: color.grey400,
                                                },
                                            },
                                        }}
                                    >
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead sx={{ backgroundColor: color.grey100 }}>
                                                <TableRow>
                                                    <TableCell style={{ width: '239px' }}>
                                                        {t('permits:text.startDate')}
                                                    </TableCell>
                                                    <TableCell style={{ width: '239px' }}>
                                                        {t('permits:text.endDate')}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {t('companies:text.totalBaysCount')}
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {zoneBayTemporary.zoneBayTemporaries?.map((element) => {
                                                    return (
                                                        <Fade
                                                            in={open}
                                                            {...{ timeout: 300 }}
                                                            style={{ transformOrigin: '0 0 0' }}
                                                        >
                                                            <TableRow
                                                                sx={{
                                                                    border: `1px solid ${color.grey200}`,
                                                                    borderTop: 'none',
                                                                }}
                                                            >
                                                                <TableCell>
                                                                    {formatDate.getLocalDay(element.startDate)}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {element.endDate == null
                                                                        ? t('permits:text.noEndDate')
                                                                        : formatDate.getLocalDay(element.endDate)}
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    {element.bayTemporary}
                                                                </TableCell>
                                                            </TableRow>
                                                        </Fade>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Stack>
                            ) : (
                                <Stack direction={'row'} justifyContent={'center'}>
                                    <CircularProgress />
                                </Stack>
                            )
                        ) : (
                            <NoDataToDisplay text={t('companies:text.noDataToDisplay')} />
                        )}

                        <Grid
                            container
                            xs={12}
                            direction={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        >
                            <Grid md={3} xs={2}>
                                <Button fullWidth variant="cancel" onClick={handleClose}>
                                    {t('action:cancel')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Stack>
                </Fade>
            }
        />
    );
}
