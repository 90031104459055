import React, { useContext } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Box, CircularProgress, styled, Typography } from '@mui/material';
import color from '../../config/Colors';
import { v4 } from 'uuid';
import _ from 'lodash';
import { LanguageContext } from '../../contexts/useLanguage';
import { BiCaretDown } from 'react-icons/bi';

type Props<T> = {
    listData: T[];
    itemValue?: T;
    keyEqual: keyof T;
    keyLabel: keyof T;
    label: string | JSX.Element;
    disabled?: boolean;
    setFilter: (option?: T) => void;
    defaultData?: T;
    key?: any;
    isLoading?: boolean;

    //all options
    isHaveAllOptions?: boolean;
    allOptionLabel?: string;
    open?: boolean;
    subDescLabels?: (keyof T)[];
};

export default function IAutoCompleteRF<T>(props: Props<T>) {
    const { t } = useContext(LanguageContext);
    const handleChange = (event: any, value: T) => {
        if (!value[props.keyEqual]) {
            return props.setFilter(undefined);
        }
        return props.setFilter(value);
    };

    const getAllOptions = () => {
        const allOption: T = {} as T;
        allOption[props.keyEqual] = undefined as any;
        allOption[props.keyLabel] = props.allOptionLabel as any;
        return allOption;
    };

    const listData = props.isHaveAllOptions ? [getAllOptions()].concat(props.listData) : props.listData;

    return (
        <BoxIAutoComplete>
            <Autocomplete
                fullWidth
                disablePortal
                noOptionsText={t('dashboard:input.noOptions')}
                open={props.open}
                sx={{}}
                disabled={props.disabled || props.isLoading}
                defaultValue={props.listData[0]}
                options={listData}
                value={props.itemValue ?? listData[0]}
                getOptionLabel={(option) => (option[props.keyLabel] as unknown as string) || ''}
                isOptionEqualToValue={(option, value) => option[props.keyEqual] == value[props.keyEqual]}
                renderOption={(propss: React.HTMLAttributes<HTMLLIElement>, option, { selected }) => {
                    const isAllItem = option[props.keyEqual] === undefined;
                    return (
                        <Box key={Number(option[props.keyEqual])}>
                            {isAllItem && (
                                <li
                                    {...propss}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        position: 'absolute',
                                        top: 0,
                                        backgroundColor: selected ? color.lightPrimary : color.white,
                                        width: props.listData?.length > 6 ? 'calc(100% - 18px)' : 'calc(100% - 10px)',
                                        padding: '9px 16px',
                                    }}
                                    key={'all'}
                                >
                                    {option[props.keyLabel] as unknown as string}
                                </li>
                            )}

                            <li
                                {...propss}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    backgroundColor: selected ? color.lightPrimary : color.white,
                                }}
                            >
                                {option[props.keyLabel] as unknown as string}
                                {props.subDescLabels?.map((label, index) => (
                                    <Typography
                                        key={index}
                                        sx={{ textAlign: 'left', display: 'block' }}
                                        variant="body2"
                                    >
                                        {option[label] as unknown as string}
                                    </Typography>
                                ))}
                            </li>
                        </Box>
                    );
                }}
                onChange={(event, value) => {
                    if (value !== null) handleChange(event, value);
                }}
                renderInput={(params) => <TextField {...params} label={props.label} />}
                popupIcon={props.isLoading ? <CircularProgress size={'18px'} /> : <BiCaretDown fontSize={14} />}
                size="small"
            />
        </BoxIAutoComplete>
    );
}

export const BoxIAutoComplete = styled(Box)({
    '& #combo-box-demo-listbox': {
        padding: '5px',
    },
    '& .MuiAutocomplete-listbox': {
        padding: '5px',
    },
    '& .MuiAutocomplete-option': {
        minHeight: 'auto !important',
        mb: '2px',
        borderBottom: `1px solid ${color.grey200}`,
        borderRadius: '4px',
    },
    //css first item chosen
    '& .MuiAutocomplete-groupLabel': {
        fontSize: '14px',
        lineHeight: 'normal',
        padding: '10px 20px',
        color: 'black',
        // fontWeight: 600,
        background: '#E5F2F8',
        my: '2px',
    },
    width: '100%',
});
