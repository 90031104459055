import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridUploadBorder = styled(Grid)({
    border: '1px dashed #DDDDDD',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '15px 32px',
    gap: '24px',
    minHeight: '200px',
});
