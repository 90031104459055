/* eslint-disable eqeqeq */
import {
    Box,
    ClickAwayListener,
    IconButton,
    Stack,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { VscInfo } from 'react-icons/vsc';
import color from '../../../../config/Colors';
import { LanguageContext } from '../../../../contexts/useLanguage';
import useMedia from '../../../../hooks/useMedia';
import { ExcelRawPermit, RawUploadPermitStatus } from '../../../../models/permits/ExcelRawPermit';
import { VRNChecking } from '../../../../models/permits/VRNChecking';
import ExistedIcon from './svg/ExistedIcon';
import InvalidIcon from './svg/InvalidIcon';
import ValidIcon from './svg/ValidIcon';

type Props = {
    listVRNChecking: ExcelRawPermit[];
};

export default function TableRowVRNChecking({ listVRNChecking }: Props) {
    const { t } = useContext(LanguageContext);
    const { isSMOnly, isXSOnly } = useMedia();
    return (
        <TableBody>
            {listVRNChecking.length > 0 &&
                listVRNChecking.map((item, index) => {
                    return (
                        <TableRow key={index}>
                            <TableCell component="th" align="center" scope="row" width={'10%'} sx={{ fontWeight: 500 }}>
                                {index + 1}
                            </TableCell>
                            <TableCell align="center" width={'20%'} sx={{ fontWeight: 500 }}>
                                {item.vrn}
                            </TableCell>
                            <TableCell align="center" width={'20%'}>
                                <Stack
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        width={'90px'}
                                        display={isSMOnly ? 'none' : 'block'}
                                        color={
                                            item.status == RawUploadPermitStatus.Valid
                                                ? color.success
                                                : item.status == RawUploadPermitStatus.Existed
                                                ? '#FFB600'
                                                : color.danger
                                        }
                                        fontWeight={500}
                                    >
                                        {item.status == RawUploadPermitStatus.Valid
                                            ? t('action:valid')
                                            : item.status == RawUploadPermitStatus.Existed
                                            ? t('action:existed')
                                            : t('action:invalid')}
                                    </Typography>

                                    {item.status == RawUploadPermitStatus.Valid ? (
                                        <ValidIcon />
                                    ) : item.status == RawUploadPermitStatus.Existed ? (
                                        <ExistedIcon />
                                    ) : (
                                        <InvalidIcon />
                                    )}
                                </Stack>
                            </TableCell>
                            <TableCell align="center" width={'40%'} sx={{ fontWeight: 500 }}>
                                {item.reason ? (
                                    <TooltipRender
                                        string={item.reason}
                                        key={index}
                                        isXsOnly={isXSOnly}
                                        isSmOnly={isSMOnly}
                                    />
                                ) : (
                                    <></>
                                )}
                            </TableCell>
                        </TableRow>
                    );
                })}
        </TableBody>
    );
}

type PropsChild = {
    string: string;
    isXsOnly: boolean;
    isSmOnly: boolean;
};

const TooltipRender = ({ string, isXsOnly, isSmOnly }: PropsChild) => {
    const [open, setOpen] = useState<boolean>(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    return (
        <>
            {isXsOnly ? (
                <ClickAwayListener onClickAway={handleTooltipClose}>
                    <Box>
                        <Tooltip
                            PopperProps={{
                                disablePortal: true,
                                sx: {
                                    // zIndex: 999999,
                                    // background: 'black',
                                    color: 'white',
                                    fontSize: '12px',
                                },
                            }}
                            placement="top"
                            onClose={() => handleTooltipClose}
                            open={open}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                                <Typography variant="body2" color={'white'}>
                                    {string}
                                </Typography>
                            }
                        >
                            <IconButton onClick={handleTooltipOpen} sx={{ padding: 0 }}>
                                <VscInfo fontSize={'22px'} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </ClickAwayListener>
            ) : isSmOnly ? (
                <Tooltip title={string} sx={{ display: { xs: 'none', sm: 'block', md: 'none' } }}>
                    <VscInfo fontSize={'22px'} />
                </Tooltip>
            ) : (
                <Typography variant="body1">{string}</Typography>
            )}
        </>
    );
};
