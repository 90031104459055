/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { SelectItem } from '../../../components/MySelect';
import useAutoComplete from '../../../components/useAutoComplete';
import { LanguageContext } from '../../../contexts/useLanguage';
import { companiesController, locationController } from '../../../controllers';
import { convertToSelectedItem } from '../../../helpers/ConvertFilterItem';
import { LoadingFilter } from '../../../hooks/useFilter';
import { Filter } from '../../../models/Filter';
import { Location } from '../../../models/Location';
import { Support } from '../../../models/Support';

export interface StateFilter {
    filter: Filter;
    listCompany: SelectItem[];
    listLocation: Location[];
    isLoading: boolean;
}

export enum FilterSupportEnum {
    companyId = 'companyId',
    locationId = 'locationId',
    all = 'all',
}

export default function useFilterSupportByLocation() {
    const { t } = useContext(LanguageContext);

    const [state, setState] = useState<StateFilter>({
        filter: {
            companyId: 0,
            regionId: 0,
            locationId: 0,
            zoneIds: [],
            from: new Date(),
            to: new Date(),
        },
        listCompany: [],
        listLocation: [],
        isLoading: false,
    });
    const [supportList, setSupportList] = useState<Support[]>([]);
    const [listUserId, setListUserId] = useState<number[]>([]);
    const [loading, setLoading] = useState<LoadingFilter>({
        isLoadingCompany: false,
        isLoadingLocation: false,
    });

    const setPartialLoading = (partial: Partial<LoadingFilter>) => setLoading((prev) => ({ ...prev, ...partial }));

    useEffect(() => {
        setPartialLoading({ isLoadingCompany: true });
        companiesController
            .getMyCompany()
            .then((res) => {
                res.unshift({ value: 0, label: t('support:text.selectCompany') });
                setState((prev) => ({ ...prev, listCompany: res }));
            })
            .finally(() => setPartialLoading({ isLoadingCompany: false }));
    }, []);
    const [editingField, setEditingField] = useState<FilterSupportEnum>(FilterSupportEnum.all);

    useEffect(() => {
        setPartialLoading({ isLoadingLocation: true });
        locationController
            .getAll({
                pageSize: 10000,
                page: 1,
                filter: { companyId: state.filter.companyId || 0, regionId: 0 },
                search: {
                    fields: [],
                    value: '',
                },
            })
            .then((res) => {
                // console.log('all locations', res);
                const all: Location = { id: 0, name: t('support:text.selectLocation') } as Location;

                setState((prev) => ({
                    ...prev,
                    listLocation: [all].concat(res.data),
                }));
            })
            .finally(() => setPartialLoading({ isLoadingLocation: false }));
    }, [state.filter.companyId, editingField]);

    useEffect(() => {
        if (state.filter.locationId && state.filter.locationId != 0) {
            setState((prev) => ({ ...prev, isLoading: true }));
            locationController.getSupportList(state.filter.locationId).then((res) => {
                setSupportList(res);
                setState((prev) => ({ ...prev, isLoading: false }));
            });
        }
    }, [state.filter]);

    const onChangeFilter = (attribute: any, value: any) => {
        if (attribute == FilterSupportEnum.companyId) {
            setState((prev) => ({
                ...prev,
                filter: { ...prev.filter, [attribute]: value, locationId: 0 },
            }));
        } else if (attribute == FilterSupportEnum.locationId) {
            if (!value && state.filter.companyId) setEditingField(FilterSupportEnum.all);
            else setEditingField(attribute);

            if (state.filter.companyId !== 0 && value == 0) {
                setState((prev) => ({
                    ...prev,
                    filter: { ...prev.filter, [attribute]: value },
                }));
            } else {
                const { companyId = 0 } = state.listLocation.find((l) => l.id === value)!;
                setState((prev) => ({
                    ...prev,
                    filter: { ...prev.filter, [attribute]: value, companyId },
                }));
            }
        } else {
            setState((prev) => ({
                ...prev,
                filter: {
                    companyId: 0,
                    locationId: 0,
                },
            }));
            setEditingField(FilterSupportEnum.all);
        }
    };

    const companySelect = useAutoComplete({
        listData: state.listCompany,
        label: t('companies:title.company'),
        value: state.listCompany.find((item) => item.value == state.filter.companyId),
        setFilter: (val) => {
            onChangeFilter(FilterSupportEnum.companyId, val);
        },
        disabled: editingField === FilterSupportEnum.locationId,
        isLoading: loading.isLoadingCompany,
    });

    const _listLocationMapped = state.listLocation.map((l) => convertToSelectedItem(l, 'id', 'name'));
    const locationValue = state.listLocation.find((item) => item.id === state.filter.locationId);

    const locationSelect = useAutoComplete({
        listData: _listLocationMapped,
        // disabled: state.filter.companyId == 0 ? true : false,
        label: t('companies:title.location'),
        value: locationValue ? convertToSelectedItem(locationValue, 'id', 'name') : undefined,
        setFilter: (val) => {
            onChangeFilter(FilterSupportEnum.locationId, val);
        },
        isLoading: loading.isLoadingLocation,
    });

    const FilterComponents = (
        <Grid
            container
            direction={{ xs: 'column', md: 'row' }}
            justifyContent={'space-between'}
            alignItems={'center'}
            spacing={1}
            gap={{ xs: 1, sm: 0 }}
        >
            <Grid item container xs direction={{ xs: 'column', md: 'row' }} spacing={1}>
                <Grid item xs={6}>
                    {locationSelect.RenderSelect}
                </Grid>
                <Grid item xs={6}>
                    {companySelect.RenderSelect}
                </Grid>
            </Grid>
            <Grid item xs="auto">
                <Button variant="cancel" sx={{ ml: 'px' }} onClick={() => onChangeFilter(FilterSupportEnum.all, '')}>
                    {t('dashboard:button.resetFilter')}
                </Button>
            </Grid>
        </Grid>
    );

    return {
        ...state,
        supportList,
        FilterComponents,
        setState,
        listUserId,
        setListUserId,
        setSupportList,
        onChangeFilter,
    };
}

export const FilterSupportContext = React.createContext<ReturnType<typeof useFilterSupportByLocation>>({} as any);
export type SupportContextProps = ReturnType<typeof useFilterSupportByLocation>;
