import * as React from 'react';
import { Autocomplete, Box, Checkbox, CircularProgress, TextField, Typography } from '@mui/material';

import color from '../../config/Colors';
import UncheckedIcon from '../../assets/images/UncheckedIcon';
import CheckedIcon from '../../assets/images/CheckedIcon';
import { LanguageContext } from '../../contexts/useLanguage';
import { BiCaretDown } from 'react-icons/bi';

const icon = <UncheckedIcon fontSize="small" />;
const checkedIcon = <CheckedIcon fontSize="small" />;

type Props<T> = {
    listData: T[];
    label: string | JSX.Element;
    value: any[];
    keyLabel: keyof T;
    keyId: keyof T;
    setFilter: (option: T[]) => void;
    isDisable?: boolean;
    limitTags?: number;
    chipSize?: number;
    placeHolder?: string;
    isLoading?: boolean;
};

export default function IMultiSelectWithCheckBoxRF<T>(props: Props<T>) {
    const { t } = React.useContext(LanguageContext);
    const handleInput = (e: any, value: T[]) => {
        props.setFilter(value);
    };

    return (
        <Box
            sx={{
                '& ul': {
                    padding: '5px !important',
                    borderBottom: `1px solid ${color.grey100}`,
                },
                '& svg': {
                    width: '15px !important',
                    height: '15px !important',
                },
                '& .MuiButtonBase-root': {
                    backgroundColor: color.grey200,
                    maxWidth: `${props.chipSize || 120}px !important`,
                },
            }}
        >
            <Autocomplete
                multiple
                id="checkboxes-tags-demo1"
                options={props.listData}
                sx={{
                    '& .MuiOutlinedInput-root > span.MuiAutocomplete-tagSizeSmall': {
                        px: '5px',
                        background: color.grey200,
                        borderRadius: '5px',
                        fontSize: '12px',
                    },
                }}
                disabled={props.isDisable || false}
                popupIcon={props.isLoading ? <CircularProgress size={'16px'} /> : <BiCaretDown fontSize={14} />}
                componentsProps={{
                    popper: {
                        sx: {
                            zIndex: 99999,
                            '& .MuiAutocomplete-listbox': {
                                padding: '5px',
                            },
                            '& li': {
                                minHeight: 'auto',
                            },
                        },
                    },
                }}
                limitTags={props.limitTags ?? 2}
                disableCloseOnSelect
                noOptionsText={t('dashboard:input.noOptions')}
                // open
                size="small"
                getOptionLabel={(option) => option[props.keyLabel]}
                onChange={(event, value) => handleInput(event, value)}
                value={props.value || null}
                renderOption={(props1, option, { selected }) => {
                    return (
                        <li
                            {...props1}
                            style={{
                                borderRadius: '4px',
                                marginBottom: '2px',
                                paddingRight: '10px',
                            }}
                            key={Number(option[props.keyId])}
                        >
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8, padding: 0 }}
                                checked={selected}
                            />
                            <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {option[props.keyLabel]}
                            </Typography>
                        </li>
                    );
                }}
                renderInput={(params) => {
                    // console.log(params);

                    return (
                        <TextField
                            {...params}
                            label={props.label}
                            placeholder={props.placeHolder || t('dashboard:input.selectMore')}
                            InputLabelProps={{ shrink: true }}
                            sx={{}}
                        />
                    );
                }}
            />
        </Box>
    );
}
