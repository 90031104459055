import { TableBody, TableCell, TableRow } from '@mui/material';
import { formatNumber } from '../../../helpers/FormatCurrency';
import { AverageLengthStatistic } from '../../../models/AverageLengthStatistic';

type Props = {
    averageLengthStatistic: AverageLengthStatistic[];
};

export default function TableRowsAverageLength(props: Props) {
    return (
        <TableBody>
            {props.averageLengthStatistic &&
                props.averageLengthStatistic.length > 0 &&
                props.averageLengthStatistic.map((item, index) => {
                    return (
                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" align="center" scope="row" width={'50%'}>
                                {item.hours}
                            </TableCell>
                            <TableCell align="center" width={'50%'}>
                                {formatNumber.format(item.percentage)}%
                            </TableCell>
                        </TableRow>
                    );
                })}
        </TableBody>
    );
}
