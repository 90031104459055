/* eslint-disable eqeqeq */
import { Box, Container, Stack, Tab, Tabs, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../../contexts/useLanguage';
import Footer from '../dashboardLayout/components/Footer';
import { DrawerContext } from '../dashboardLayout/useDrawer';
import useScrollToTop from '../../hooks/useScrollToTop';

import SupportTab from './support-tab/SupportTab';
import MailIcon from '../../assets/images/MailIcon';
import color from '../../config/Colors';
import ReportIcon from '../../assets/svg/ReportIcon';
import ReportTab from './report-tab/ReportTab';
import useFilterSupportByLocation from './hook/useFilterSupportByLocation';

export default function SupportLayout() {
    const { t } = useContext(LanguageContext);
    useScrollToTop();
    const { setActiveItem } = useContext(DrawerContext);
    const [currentTab, setCurrentTab] = useState(0);
    const filterLocationData = useFilterSupportByLocation();

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setCurrentTab(newValue);
    };

    useEffect(() => {
        setActiveItem(6);
    }, [setActiveItem]);

    return (
        <React.Fragment>
            <>
                <Stack direction={'row'} justifyContent={'start'}>
                    <Typography variant="h3" fontWeight={400}>
                        {t('support:title.support')}
                    </Typography>
                </Stack>
                <Tabs
                    value={currentTab}
                    onChange={handleTabChange}
                    sx={{
                        mt: 2,
                        '&.MuiTabs-root': {
                            minHeight: 'auto',
                        },
                        '& svg': {
                            marginBottom: 0,
                            width: '16px',
                        },
                        '& .MuiTab-root': {
                            px: '16px',
                            minWidth: 'auto',
                            textTransform: 'none',
                        },
                        '& .MuiTabs-indicator': {
                            background: currentTab ? color.danger : color.priText,
                        },

                        '& .Mui-selected': {
                            color: `${currentTab ? color.danger : color.priText} !important`,
                            '& .icon-svg-stroke': {
                                stroke: currentTab ? color.danger : color.priText,
                            },
                            '& .svg-icon-fill': {
                                fill: currentTab ? color.danger : color.priText,
                            },
                            '&.MuiTab-root': {
                                background: currentTab ? color.lightDanger : color.lightPrimary,
                            },
                        },
                    }}
                >
                    <Tab value={0} icon={<MailIcon />} label={t('support:title.contactMyAccountManager')} />
                    <Tab value={1} icon={<ReportIcon />} label={t('support:title.reportABug')} />
                </Tabs>
                <Box sx={{ padding: '24px 24px 40px', border: '1px solid #ddd', borderRadius: '0px 0px 8px 8px' }}>
                    {currentTab === 0 && <SupportTab {...filterLocationData} />}
                    {currentTab === 1 && <ReportTab />}
                </Box>
            </>
        </React.Fragment>
    );
}
