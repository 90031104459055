import moment from 'moment';
import * as momentTz from 'moment-timezone';

class FormatDate {
    getLocalDateString(date: any) {
        return moment.utc(date).local().format('DD/MM/YYYY HH:mm');
    }
    getLocalDate(date: any) {
        return moment.utc(date).local();
    }
    getLocalHour(date: any) {
        return moment(date).format('HH:mm:ss');
    }
    getLocalDay(date: any) {
        return moment(date).format('DD/MM/YYYY');
    }
    getLocalDay2(date: any) {
        return moment(date).format('DD/MM');
    }
    getLocalDay3(date: any) {
        return moment(date).format('DD');
    }

    convertDateRangeToLocalTime(dateFrom: Date | undefined, dateTo: Date | undefined) {
        const dateFromStartOfDay = moment(dateFrom).startOf('day').toDate();
        const dateToEndOfDay = moment(dateTo).endOf('day').toDate();
        return {
            from: moment.utc(dateFromStartOfDay).local().toDate(),
            to: moment.utc(dateToEndOfDay).local().toDate(),
        };
    }

    convertDateRangeToLocalTimeString(dateFrom: Date, dateTo: Date) {
        const dateFromStartOfDay = moment(dateFrom).startOf('day').toDate();
        const dateToEndOfDay = moment(dateTo).endOf('day').toDate();
        return {
            from: this.getLocalDateString(dateFromStartOfDay),
            to: this.getLocalDateString(dateToEndOfDay),
        };
    }

    convertStellaToGMT(date: Date) {
        if (date === null) return date;
        const currentLondonGMT = momentTz.tz('Europe/London').utcOffset() / 60;
        return moment(date).add(currentLondonGMT, 'h').format('DD/MM/YYYY HH:mm');
    }
}

export const formatDate = new FormatDate();
