import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Pagination } from '../models/BaseFilter';
import { LocationWithDetail } from '../models/Location';
import Zone from '../models/Zone';
import { locationController, zoneController } from '../controllers';

interface ZoneDataState {
    zones: Zone[];
    zonesSelect: Zone[];
    idLocationSelect?: number;
}

export default function useLocations() {
    const { idCompany, locationId } = useParams();
    // const { companyId } = useContext(CompaniesContext);
    const [locationPaging, setLocationPaging] = useState<Pagination<LocationWithDetail>>({
        data: [],
        page: 1,
        pageSize: 6,
        total: 0,
        totalPage: 0,
    });
    const [locationLoading, setLocationLoading] = useState(true);
    const [locationSelect, setLocationSelect] = useState<LocationWithDetail | undefined>(undefined);
    const [zoneDataState, setZoneDataState] = useState<ZoneDataState>({
        zones: [],
        zonesSelect: [],
        idLocationSelect: Number(locationId),
    });

    const getLocationDetail = () => {
        if (locationId) {
            locationController.getLocation(Number(locationId)).then((res) => {
                zoneController
                    .getZonesDetail({ locationId: Number(locationId), tenantId: 0, permitId: 0 })
                    .then((resZone) => {
                        setLocationSelect(res);
                        setZoneDataState({
                            zones: resZone,
                            zonesSelect: resZone,
                            idLocationSelect: Number(locationId),
                        });
                    });
            });
        }
    };

    useEffect(() => {
        getLocationDetail();
    }, [locationId]);

    return {
        locationPaging,
        setLocationPaging,
        locationLoading,
        setLocationLoading,
        zoneDataState,
        locationSelect,
        getLocationDetail,
    };
}

export const LocationsContext = React.createContext<ReturnType<typeof useLocations>>({} as any);
