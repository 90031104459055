export interface AllocatedType {
    clientId: number;
    allocatedPermitType: AllocatedPermitType;
    amount: number;
    isActive: boolean;
    locationId: number;

    usedPermit?: number;

    isValid?: boolean;
}

export enum AllocatedPermitType {
    Once = 0,
    Temporary = 6,
    Indefinite = 5,
    Dynamic = 7,
}
