import { BaseQuestionProps } from '../../pages/feedback/components/QuestionComponent';
import { BaseModel } from '../BaseModel';

export enum QuestionType {
    TextArea,
}

export type Answer = any;

export type Question<A = Answer> = {
    Id: number;
    question: string;
    type: QuestionType;
    // options?: A[];
    Component: (props: BaseQuestionProps) => JSX.Element;
};

export interface QA<A = Answer> extends BaseModel {
    questionId: number;
    question?: Question;

    answer?: A;
}

export interface Feedback extends BaseModel {
    name: string;
    email: string;
    createdBy: number;

    content: QA[];
}
