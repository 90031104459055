import { BaseHttpController } from 'ale-base-model';
import { AxiosInstance } from 'axios';
import { Filter } from '../models/Filter';

export class PCNDashboardDownloadController extends BaseHttpController<object> {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'PCNDashboardDownload', client);
    }
    async DownloadGroupCancelledPCNByActor(props: { filter: Filter, isPdf: boolean }): Promise<File> {
        const res = await this.doPost({ path: `DownloadGroupCancelledPCNByActor?isPdf=${props.isPdf}`, body: props.filter, config: { responseType: 'blob' } })
        return res.data
    }
    async DownloadGroupPCNByReason(props: { filter: Filter, isPdf: boolean }): Promise<File> {
        const res = await this.doPost({ path: `DownloadGroupPCNByReason?isPdf=${props.isPdf}`, body: props.filter, config: { responseType: 'blob' } })
        return res.data
    }
    async DownloadGroupPCNByStatus(props: { filter: Filter, isPdf: boolean }): Promise<File> {
        const res = await this.doPost({ path: `DownloadGroupPCNByStatus?isPdf=${props.isPdf}`, body: props.filter, config: { responseType: 'blob' } })
        return res.data
    }
    async DownloadGroupPCNByZone(props: { filter: Filter, isPdf: boolean }): Promise<File> {
        const res = await this.doPost({ path: `DownloadGroupPCNByZone?isPdf=${props.isPdf}`, body: props.filter, config: { responseType: 'blob' } })
        return res.data
    }
}
