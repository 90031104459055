import { Box, Stack, Typography } from '@mui/material';
import { FAQs } from '../components/BaseFAQ';
import { useContext } from 'react';
import { LanguageContext } from '../../../contexts/useLanguage';
export type SectionFAQ = {
    subHeading: string;
    content: FAQs[];
};

export const TenantSection = (): SectionFAQ[] => {
    const { t } = useContext(LanguageContext);

    return [
        {
            subHeading: t('faqs:title.overview'),
            content: [
                {
                    question: t('faqs:text.whatFeaturesDoIHaveAccessTo'),
                    answer: (
                        <Box pl={4.5}>
                            <Typography>{t('faqs:text.accessFeaturesListedLeftSide')}:</Typography>
                            <ul style={{ margin: 0 }}>
                                <li>
                                    <Typography>
                                        <span style={{ fontWeight: 500 }}>{t('drawer:permits')}</span> -{' '}
                                        {t('faqs:text.addPermitsWithNewFunctionalities')}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <span style={{ fontWeight: 500 }}>{t('drawer:support')}</span> –{' '}
                                        {t('faqs:text.accessAccountManagersOnSite')}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <span style={{ fontWeight: 500 }}>{t('drawer:user')}</span> -{' '}
                                        {t('faqs:text.updateOrChangePasswordAndAccountDetails')}
                                    </Typography>
                                </li>
                            </ul>
                        </Box>
                    ),
                },
            ],
        },
        {
            subHeading: t('drawer:permits'),
            content: [
                {
                    question: t('faqs:text.recurringTypesQuestion'),
                    answer: (
                        <Box pl={1.5}>
                            <ul style={{ margin: 0 }}>
                                <li>
                                    <Typography>
                                        <span style={{ fontWeight: 500 }}>{t('permits:text.oneDayPermit')}</span> –{' '}
                                        {t('permits:text.schedulePermitsForOneDayOnly')}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <span style={{ fontWeight: 500 }}>{t('permits:text.daily')}</span> –{' '}
                                        {t('permits:text.schedulePermitsToRecurEveryXNumberOfDays')}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <span style={{ fontWeight: 500 }}>{t('permits:text.weekly')}</span> -{' '}
                                        {t('permits:text.schedulePermitsToRecurOnSpecifiedDaysOfTheWeek')}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <span style={{ fontWeight: 500 }}>{t('permits:text.monthly')}</span> -{' '}
                                        {t('permits:text.schedulePermitsToRecurOnAParticularDateOfTheMonth')}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <span style={{ fontWeight: 500 }}>{t('permits:text.indefinite')}</span> -{' '}
                                        {t('permits:text.schedulePermitsToRecurIndefinitelyAtAllTimes')}
                                    </Typography>
                                </li>
                            </ul>
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.visibilityQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.visibilityAnswer')}</Typography>
                            <img src="/assets/images/faqs/account/permits-2.png" alt="Permits 2" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.maximizeAllocationQuestion'),
                    answer: (
                        <Stack spacing={1} pl={4.5}>
                            <Typography>{t('faqs:text.maximizeAllocationAnswer')}</Typography>
                            <Typography>{t('faqs:text.maximizeAllocationAnswer2')}</Typography>
                            <Typography>{t('faqs:text.maximizeAllocationAnswer3')}</Typography>
                        </Stack>
                    ),
                },
                {
                    question: t('faqs:text.addPermitQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.addPermitAnswer')}</Typography>
                            <img src="/assets/images/faqs/account/permits-4.1.png" alt="Permit 4.1" width="100%" />
                            <Typography pl={4.5} mt={2}>
                                {t('faqs:text.addPermitAnswer2')}
                            </Typography>
                            <img src="/assets/images/faqs/account/permits-4.2.png" alt="Permit 4.2" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.duplicatePermitQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.duplicatePermitAnswer')}</Typography>
                            <img src="/assets/images/faqs/tenant/permits-4.png" alt="Permit 4" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.oneDayPermitQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.oneDayPermitAnswer')}</Typography>
                            <img src="/assets/images/faqs/tenant/permits-5.png" alt="Permit 5" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.dailyPermitsQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.dailyPermitsAnswer')}</Typography>
                            <img src="/assets/images/faqs/tenant/permits-6.png" alt="Permit 6" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.weeklyPermitsQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.weeklyPermitsAnswer')}</Typography>
                            <img src="/assets/images/faqs/tenant/permits-7.png" alt="Permit 7" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.monthlyPermitsQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.monthlyPermitsAnswer')}</Typography>
                            <img src="/assets/images/faqs/tenant/permits-8.png" alt="Permit 8" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.indefinitePermitsQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.indefinitePermitsAnswer')}</Typography>
                            <img src="/assets/images/faqs/tenant/permits-9.png" alt="Permit 9" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.bulkUploadQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.bulkUploadAnswer')}</Typography>
                            <img src="/assets/images/faqs/account/permits-11.1.png" alt="Permit 11.1" width="100%" />
                            <Typography pl={4.5} mt={2}>
                                {t('faqs:text.bulkUploadAnswer2')}
                            </Typography>
                            <img src="/assets/images/faqs/account/permits-11.2.png" alt="Permit 11.2" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.notSavingQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.notSavingAnswer')}</Typography>
                            <Typography pl={4.5} mt={2}>
                                {t('faqs:text.completedPermit')}
                            </Typography>
                            <img src="/assets/images/faqs/tenant/permits-11.1.png" alt="Permit 11.1" width="100%" />
                            <Typography pl={4.5} mt={2}>
                                {t('faqs:text.notCompletedPermit')}
                            </Typography>
                            <img src="/assets/images/faqs/tenant/permits-11.2.png" alt="Permit 11.2" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.filtersQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.filtersAnswer')}</Typography>
                            <img src="/assets/images/faqs/account/permits-13.png" alt="Permit 13" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.searchByVRNQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.searchByVRNAnswer')}</Typography>
                            <img src="/assets/images/faqs/account/permits-14.png" alt="Permit 14" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.deletePermitsQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.deletePermitsAnswer')}</Typography>
                            <img src="/assets/images/faqs/account/permits-15.png" alt="Permit 14" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.editPermitsQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.editPermitsAnswer')}</Typography>
                            <img src="/assets/images/faqs/account/permits-16.png" alt="Permit 16" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.resetFiltersQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.resetFiltersAnswer')}</Typography>
                            <img src="/assets/images/faqs/account/permits-17.png" alt="Permit 17" width="100%" />
                        </Box>
                    ),
                },
            ],
        },
        {
            subHeading: t('drawer:support'),
            content: [
                {
                    question: t('faqs:text.contactAccountManager'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.contactAccountManagerViaSupportTab')}</Typography>
                            <img src="/assets/images/faqs/tenant/support-1.png" alt="Support 1" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.reportBug'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.reportBugViaReportTab')}</Typography>
                            <img src="/assets/images/faqs/tenant/support-2.png" alt="Support 2" width="100%" />
                        </Box>
                    ),
                },
            ],
        },
        {
            subHeading: t('drawer:user'),
            content: [
                {
                    question: t('faqs:text.changePasswordQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.changePasswordAnswer.part1')}</Typography>
                            <img src="/assets/images/faqs/tenant/account-1.1.png" alt="Account 1.1" width="100%" />
                            <Typography pl={4.5} mt={2}>
                                {t('faqs:text.changePasswordAnswer.part2')}
                            </Typography>
                            <img src="/assets/images/faqs/tenant/account-1.2.png" alt="Account 1.2" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.changePhoneNumberQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.changePhoneNumberAnswer.part1')}</Typography>
                            <img src="/assets/images/faqs/tenant/account-2.1.png" alt="Account 2.1" width="100%" />
                            <Typography pl={4.5} mt={2}>
                                {t('faqs:text.changePhoneNumberAnswer.part2')}
                            </Typography>
                            <img src="/assets/images/faqs/tenant/account-2.2.png" alt="Account 2.2" width="100%" />
                        </Box>
                    ),
                },
            ],
        },
        {
            subHeading: t('faqs:title.accessIHubPlatformOnMobile'),
            content: [
                {
                    question: t('faqs:text.urlLinkQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>
                                {t('faqs:text.urlLinkAnswer.part1')}{' '}
                                <Typography component="span" color="secondary">
                                    <a href="https://ihub-insights-prod-ukpc.azurewebsites.net" target="blank">
                                        https://ihub-insights-prod-ukpc.azurewebsites.net
                                    </a>
                                </Typography>
                            </Typography>
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.iOSAndroidQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.iOSAndroidAnswer')}</Typography>
                        </Box>
                    ),
                },
            ],
        },
    ];
};
export const AccountManagerSection = (): SectionFAQ[] => {
    const { t } = useContext(LanguageContext);

    return [
        {
            subHeading: t('faqs:title.overview'),
            content: [
                {
                    question: t('faqs:text.whatFeaturesDoIHaveAccessTo'),
                    answer: (
                        <Box pl={4.5}>
                            <Typography>{t('faqs:text.accessFeaturesListedLeftSide')}:</Typography>
                            <ul style={{ margin: 0 }}>
                                <li>
                                    <Typography>
                                        <span style={{ fontWeight: 500 }}>{t('drawer:dashboard')}</span> –{' '}
                                        {t('faqs:text.granularLocationInsights')}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <span style={{ fontWeight: 500 }}>{t('drawer:insights')}</span> –{' '}
                                        {t('faqs:text.accessReportWithinFilter')}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <span style={{ fontWeight: 500 }}>{t('drawer:companies')}</span> -{' '}
                                        {t('faqs:text.manageCompanyLocations')}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <span style={{ fontWeight: 500 }}>{t('drawer:permits')}</span> -{' '}
                                        {t('faqs:text.addPermitsWithNewFunctionalities')}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <span style={{ fontWeight: 500 }}>{t('drawer:support')}</span> -{' '}
                                        {t('faqs:text.viewSupportAccount')}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <span style={{ fontWeight: 500 }}>{t('drawer:user')}</span> -{' '}
                                        {t('faqs:text.updateOrChangePasswordAndAccountDetails')}
                                    </Typography>
                                </li>
                            </ul>
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.addFiltersToDashboardPage'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.addFiltersByClickingOnFields')}:</Typography>
                            <img src="/assets/images/faqs/account/overview-2.png" alt="Overview 2" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.canIResetMyFilters'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.resetFiltersByClickingResetButton')}:</Typography>
                            <img src="/assets/images/faqs/account/overview-3.png" alt="Overview 3" width="100%" />
                        </Box>
                    ),
                },
            ],
        },
        {
            subHeading: t('drawer:insights'),
            content: [
                {
                    question: t('faqs:text.accessingAParticularReport'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.accessReportsByClickingViewMore')}:</Typography>
                            <img src="/assets/images/faqs/account/insight-1.png" alt="Insight 1" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.canIDownloadMyReports'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.downloadReportsViaExportButton')}:</Typography>
                            <img src="/assets/images/faqs/account/insight-2.png" alt="Insight 2" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.viewGranularDetailsOfGraphsCharts'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.viewGranularDetailsByClickingDetails')}:</Typography>
                            <img src="/assets/images/faqs/account/insight-3.png" alt="Insight 3" width="100%" />
                        </Box>
                    ),
                },
            ],
        },
        {
            subHeading: t('drawer:companies'),
            content: [
                {
                    question: t('faqs:text.manageClientsEstate'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.manageClientsEstateDescription')}:</Typography>
                            <img src="/assets/images/faqs/account/companies-1.png" alt="Companies 1" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.addEmployeesForClients'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.addEmployeesDescription')}:</Typography>
                            <img src="/assets/images/faqs/account/companies-2.1.png" alt="Companies 2.1" width="100%" />
                            <Typography pl={4.5} mt={2}>
                                {t('faqs:text.addEmployeesDescriptionContinued')}
                            </Typography>
                            <img
                                src="/assets/images/faqs/account/companies-2.2.png"
                                alt="Companies 2.2 "
                                width="100%"
                            />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.editEmployeeDetails'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.editEmployeeDetailsDescription')}:</Typography>
                            <img src="/assets/images/faqs/account/companies-3.png" alt="Companies 3" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.accessLocationsInCompany'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.accessLocationsInCompanyDescription')}:</Typography>
                            <img src="/assets/images/faqs/account/companies-4.1.png" alt="Companies 4.1" width="100%" />
                            <Typography pl={4.5} mt={2}>
                                {t('faqs:text.accessLocationsInCompanyDescriptionContinued')}
                            </Typography>
                            <img
                                src="/assets/images/faqs/account/companies-4.2.png"
                                alt="Companies 4.2 "
                                width="100%"
                            />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.viewLocationGeneralInfo'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.viewLocationGeneralInfoDescription')}:</Typography>
                            <img src="/assets/images/faqs/account/companies-5.1.png" alt="Companies 5.1" width="100%" />
                            <Typography pl={4.5} mt={2}>
                                {t('faqs:text.viewLocationGeneralInfoDescriptionContinued')}
                            </Typography>
                            <img
                                src="/assets/images/faqs/account/companies-5.2.png"
                                alt="Companies 5.2 "
                                width="100%"
                            />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.editPermitAllocationAtZoneLevel'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>
                                {t('faqs:text.editPermitAllocationAtZoneLevelDescription')}:
                            </Typography>
                            <img src="/assets/images/faqs/account/companies-6.1.png" alt="Companies 6.1" width="100%" />
                            <Typography pl={4.5} mt={2}>
                                {t('faqs:text.editPermitAllocationAtZoneLevelDescriptionContinued')}
                            </Typography>
                            <img
                                src="/assets/images/faqs/account/companies-6.2.png"
                                alt="Companies 6.2 "
                                width="100%"
                            />
                            <Typography pl={4.5} mt={2}>
                                {t('faqs:text.editPermitAllocationAtZoneLevelDescriptionConclusion')}
                            </Typography>
                            <img
                                src="/assets/images/faqs/account/companies-6.3.png"
                                alt="Companies 6.3 "
                                width="100%"
                            />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.addTenants'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.addTenantsDescription')}:</Typography>
                            <img src="/assets/images/faqs/account/companies-7.1.png" alt="Companies 7.1" width="100%" />
                            <Typography pl={4.5} mt={2}>
                                {t('faqs:text.addTenantsDescriptionContinued')}
                            </Typography>
                            <img
                                src="/assets/images/faqs/account/companies-7.2.png"
                                alt="Companies 7.2 "
                                width="100%"
                            />
                            <Typography pl={4.5} mt={2}>
                                {t('faqs:text.addTenantsDescriptionConclusion')}
                            </Typography>
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.deleteTenants'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.deleteTenantsDescription')}:</Typography>
                            <img src="/assets/images/faqs/account/companies-8.png" alt="Companies 8" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.promptTenantToSetUpPassword'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.promptTenantToSetUpPasswordDescription')}:</Typography>
                            <img src="/assets/images/faqs/account/companies-9.1.png" alt="Companies 9.1" width="100%" />
                            <img
                                src="/assets/images/faqs/account/companies-9.2.png"
                                alt="Companies 9.2"
                                width="100%"
                                style={{ marginTop: 16 }}
                            />
                        </Box>
                    ),
                },
            ],
        },
        {
            subHeading: t('drawer:permits'),
            content: [
                {
                    question: t('faqs:text.recurringTypesQuestion'),
                    answer: (
                        <Box pl={1.5}>
                            <ul style={{ margin: 0 }}>
                                <li>
                                    <Typography>
                                        <span style={{ fontWeight: 500 }}>{t('permits:text.oneDayPermit')}</span> –{' '}
                                        {t('permits:text.schedulePermitsForOneDayOnly')}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <span style={{ fontWeight: 500 }}>{t('permits:text.daily')}</span> –{' '}
                                        {t('permits:text.schedulePermitsToRecurEveryXNumberOfDays')}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <span style={{ fontWeight: 500 }}>{t('permits:text.weekly')}</span> -{' '}
                                        {t('permits:text.schedulePermitsToRecurOnSpecifiedDaysOfTheWeek')}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <span style={{ fontWeight: 500 }}>{t('permits:text.monthly')}</span> -{' '}
                                        {t('permits:text.schedulePermitsToRecurOnAParticularDateOfTheMonth')}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <span style={{ fontWeight: 500 }}>{t('permits:text.indefinite')}</span> -{' '}
                                        {t('permits:text.schedulePermitsToRecurIndefinitelyAtAllTimes')}
                                    </Typography>
                                </li>
                            </ul>
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.visibilityQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.visibilityAnswer')}</Typography>
                            <img src="/assets/images/faqs/account/permits-2.png" alt="Permits 2" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.maximizeAllocationQuestion'),
                    answer: (
                        <Stack spacing={1} pl={4.5}>
                            <Typography>{t('faqs:text.maximizeAllocationAnswer')}</Typography>
                            <Typography>{t('faqs:text.maximizeAllocationAnswer2')}</Typography>
                            <Typography>{t('faqs:text.maximizeAllocationAnswer3')}</Typography>
                        </Stack>
                    ),
                },
                {
                    question: t('faqs:text.addPermitQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.addPermitAnswer')}</Typography>
                            <img src="/assets/images/faqs/account/permits-4.1.png" alt="Permit 4.1" width="100%" />
                            <Typography pl={4.5} mt={2}>
                                {t('faqs:text.addPermitAnswer2')}
                            </Typography>
                            <img src="/assets/images/faqs/account/permits-4.2.png" alt="Permit 4.2" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.duplicatePermitQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.duplicatePermitAnswer')}</Typography>
                            <img src="/assets/images/faqs/tenant/permits-4.png" alt="Permit 4" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.oneDayPermitQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.oneDayPermitAnswer')}</Typography>
                            <img src="/assets/images/faqs/tenant/permits-5.png" alt="Permit 5" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.dailyPermitsQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.dailyPermitsAnswer')}</Typography>
                            <img src="/assets/images/faqs/tenant/permits-6.png" alt="Permit 6" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.weeklyPermitsQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.weeklyPermitsAnswer')}</Typography>
                            <img src="/assets/images/faqs/tenant/permits-7.png" alt="Permit 7" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.monthlyPermitsQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.monthlyPermitsAnswer')}</Typography>
                            <img src="/assets/images/faqs/tenant/permits-8.png" alt="Permit 8" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.indefinitePermitsQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.indefinitePermitsAnswer')}</Typography>
                            <img src="/assets/images/faqs/tenant/permits-9.png" alt="Permit 9" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.bulkUploadQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.bulkUploadAnswer')}</Typography>
                            <img src="/assets/images/faqs/account/permits-11.1.png" alt="Permit 11.1" width="100%" />
                            <Typography pl={4.5} mt={2}>
                                {t('faqs:text.bulkUploadAnswer2')}
                            </Typography>
                            <img src="/assets/images/faqs/account/permits-11.2.png" alt="Permit 11.2" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.notSavingQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.notSavingAnswer')}</Typography>
                            <Typography pl={4.5} mt={2}>
                                {t('faqs:text.completedPermit')}
                            </Typography>
                            <img src="/assets/images/faqs/tenant/permits-11.1.png" alt="Permit 11.1" width="100%" />
                            <Typography pl={4.5} mt={2}>
                                {t('faqs:text.notCompletedPermit')}
                            </Typography>
                            <img src="/assets/images/faqs/tenant/permits-11.2.png" alt="Permit 11.2" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.filtersQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.filtersAnswer')}</Typography>
                            <img src="/assets/images/faqs/account/permits-13.png" alt="Permit 13" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.searchByVRNQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.searchByVRNAnswer')}</Typography>
                            <img src="/assets/images/faqs/account/permits-14.png" alt="Permit 14" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.deletePermitsQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.deletePermitsAnswer')}</Typography>
                            <img src="/assets/images/faqs/account/permits-15.png" alt="Permit 14" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.editPermitsQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.editPermitsAnswer')}</Typography>
                            <img src="/assets/images/faqs/account/permits-16.png" alt="Permit 16" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.resetFiltersQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.resetFiltersAnswer')}</Typography>
                            <img src="/assets/images/faqs/account/permits-17.png" alt="Permit 17" width="100%" />
                        </Box>
                    ),
                },
            ],
        },
        {
            subHeading: t('drawer:support'),
            content: [
                {
                    question: t('faqs:text.contactAccountManager'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.contactAccountManagerViaSupportTab')}</Typography>
                            <img src="/assets/images/faqs/tenant/support-1.png" alt="Support 1" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.reportBug'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.reportBugViaReportTab')}</Typography>
                            <img src="/assets/images/faqs/tenant/support-2.png" alt="Support 2" width="100%" />
                        </Box>
                    ),
                },
            ],
        },
        {
            subHeading: t('drawer:user'),
            content: [
                {
                    question: t('faqs:text.changePasswordQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.changePasswordAnswer.part1')}</Typography>
                            <img src="/assets/images/faqs/tenant/account-1.1.png" alt="Account 1.1" width="100%" />
                            <Typography pl={4.5} mt={2}>
                                {t('faqs:text.changePasswordAnswer.part2')}
                            </Typography>
                            <img src="/assets/images/faqs/tenant/account-1.2.png" alt="Account 1.2" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.changePhoneNumberQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.changePhoneNumberAnswer.part1')}</Typography>
                            <img src="/assets/images/faqs/tenant/account-2.1.png" alt="Account 2.1" width="100%" />
                            <Typography pl={4.5} mt={2}>
                                {t('faqs:text.changePhoneNumberAnswer.part2')}
                            </Typography>
                            <img src="/assets/images/faqs/tenant/account-2.2.png" alt="Account 2.2" width="100%" />
                        </Box>
                    ),
                },
            ],
        },
        {
            subHeading: t('faqs:title.accessIHubPlatformOnMobile'),
            content: [
                {
                    question: t('faqs:text.urlLinkQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>
                                {t('faqs:text.urlLinkAnswer.part1')}{' '}
                                <Typography component="span" color="secondary">
                                    <a href="https://ihub-insights-prod-ukpc.azurewebsites.net" target="blank">
                                        https://ihub-insights-prod-ukpc.azurewebsites.net
                                    </a>
                                </Typography>
                            </Typography>
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.iOSAndroidQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.iOSAndroidAnswer')}</Typography>
                        </Box>
                    ),
                },
            ],
        },
        {
            subHeading: t('faqs:title.addUserToiHubViaStella'),
            content: [
                {
                    question: t('faqs:text.useStellaQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.useStellaAnswer')}</Typography>
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.userRolesQuestion'),
                    answer: (
                        <Box pl={1.5}>
                            <ul style={{ margin: 0 }}>
                                <li>
                                    <Typography>
                                        <span style={{ fontWeight: 500 }}>{t('support:text.accountManager')}</span> –{' '}
                                        {t('faqs:text.userRoles.accountManagerDescription')}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <span style={{ fontWeight: 500 }}>
                                            {t('faqs:text.userRoles.managingAgent')}
                                        </span>{' '}
                                        – {t('faqs:text.userRoles.managingAgentDescription')}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <span style={{ fontWeight: 500 }}>{t('faqs:text.userRoles.client')}</span> -{' '}
                                        {t('faqs:text.userRoles.clientDescription')}
                                    </Typography>
                                </li>
                            </ul>
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.instructionsQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.instructionsAnswer')}</Typography>
                        </Box>
                    ),
                },
            ],
        },
    ];
};

export const AgentWithClientSection = (): SectionFAQ[] => {
    const { t } = useContext(LanguageContext);

    return [
        {
            subHeading: t('faqs:title.overview'),
            content: [
                {
                    question: t('faqs:text.whatFeaturesDoIHaveAccessTo'),
                    answer: (
                        <Box pl={4.5}>
                            <Typography>{t('faqs:text.accessFeaturesListedLeftSide')}:</Typography>
                            <ul style={{ margin: 0 }}>
                                <li>
                                    <Typography>
                                        <span style={{ fontWeight: 500 }}>{t('drawer:dashboard')}</span> –{' '}
                                        {t('faqs:text.granularLocationInsights')}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <span style={{ fontWeight: 500 }}>{t('drawer:insights')}</span> –{' '}
                                        {t('faqs:text.accessReportWithinFilter')}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <span style={{ fontWeight: 500 }}>{t('drawer:companies')}</span> -{' '}
                                        {t('faqs:text.manageCompanyLocations')}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <span style={{ fontWeight: 500 }}>{t('drawer:permits')}</span> -{' '}
                                        {t('faqs:text.addPermitsWithNewFunctionalities')}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <span style={{ fontWeight: 500 }}>{t('drawer:support')}</span> -{' '}
                                        {t('faqs:text.viewSupportAccount')}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <span style={{ fontWeight: 500 }}>{t('drawer:user')}</span> -{' '}
                                        {t('faqs:text.updateOrChangePasswordAndAccountDetails')}
                                    </Typography>
                                </li>
                            </ul>
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.addFiltersToDashboardPage'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.addFiltersByClickingOnFields')}:</Typography>
                            <img src="/assets/images/faqs/account/overview-2.png" alt="Overview 2" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.canIResetMyFilters'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.resetFiltersByClickingResetButton')}:</Typography>
                            <img src="/assets/images/faqs/account/overview-3.png" alt="Overview 3" width="100%" />
                        </Box>
                    ),
                },
            ],
        },
        {
            subHeading: t('drawer:insights'),
            content: [
                {
                    question: t('faqs:text.accessingAParticularReport'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.accessReportsByClickingViewMore')}:</Typography>
                            <img src="/assets/images/faqs/account/insight-1.png" alt="Insight 1" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.canIDownloadMyReports'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.downloadReportsViaExportButton')}:</Typography>
                            <img src="/assets/images/faqs/account/insight-2.png" alt="Insight 2" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.viewGranularDetailsOfGraphsCharts'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.viewGranularDetailsByClickingDetails')}:</Typography>
                            <img src="/assets/images/faqs/account/insight-3.png" alt="Insight 3" width="100%" />
                        </Box>
                    ),
                },
            ],
        },
        {
            subHeading: t('drawer:companies'),
            content: [
                {
                    question: t('faqs:text.manageClientsEstate'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.manageClientsEstateDescription')}:</Typography>
                            <img src="/assets/images/faqs/account/companies-1.png" alt="Companies 1" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.addEmployeesForClients'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.addEmployeesDescription')}:</Typography>
                            <img src="/assets/images/faqs/account/companies-2.1.png" alt="Companies 2.1" width="100%" />
                            <Typography pl={4.5} mt={2}>
                                {t('faqs:text.addEmployeesDescriptionContinued')}
                            </Typography>
                            <img
                                src="/assets/images/faqs/account/companies-2.2.png"
                                alt="Companies 2.2 "
                                width="100%"
                            />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.editEmployeeDetails'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.editEmployeeDetailsDescription')}:</Typography>
                            <img src="/assets/images/faqs/account/companies-3.png" alt="Companies 3" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.accessLocationsInCompany'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.accessLocationsInCompanyDescription')}:</Typography>
                            <img src="/assets/images/faqs/account/companies-4.1.png" alt="Companies 4.1" width="100%" />
                            <Typography pl={4.5} mt={2}>
                                {t('faqs:text.accessLocationsInCompanyDescriptionContinued')}
                            </Typography>
                            <img
                                src="/assets/images/faqs/account/companies-4.2.png"
                                alt="Companies 4.2 "
                                width="100%"
                            />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.viewLocationGeneralInfo'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.viewLocationGeneralInfoDescription')}:</Typography>
                            <img src="/assets/images/faqs/account/companies-5.1.png" alt="Companies 5.1" width="100%" />
                            <Typography pl={4.5} mt={2}>
                                {t('faqs:text.viewLocationGeneralInfoDescriptionContinued')}
                            </Typography>
                            <img
                                src="/assets/images/faqs/account/companies-5.2.png"
                                alt="Companies 5.2 "
                                width="100%"
                            />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.editPermitAllocationAtZoneLevel'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>
                                {t('faqs:text.editPermitAllocationAtZoneLevelDescription')}:
                            </Typography>
                            <img src="/assets/images/faqs/account/companies-6.1.png" alt="Companies 6.1" width="100%" />
                            <Typography pl={4.5} mt={2}>
                                {t('faqs:text.editPermitAllocationAtZoneLevelDescriptionContinued')}
                            </Typography>
                            <img
                                src="/assets/images/faqs/account/companies-6.2.png"
                                alt="Companies 6.2 "
                                width="100%"
                            />
                            <Typography pl={4.5} mt={2}>
                                {t('faqs:text.editPermitAllocationAtZoneLevelDescriptionConclusion')}
                            </Typography>
                            <img
                                src="/assets/images/faqs/account/companies-6.3.png"
                                alt="Companies 6.3 "
                                width="100%"
                            />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.addTenants'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.addTenantsDescription')}:</Typography>
                            <img src="/assets/images/faqs/account/companies-7.1.png" alt="Companies 7.1" width="100%" />
                            <Typography pl={4.5} mt={2}>
                                {t('faqs:text.addTenantsDescriptionContinued')}
                            </Typography>
                            <img
                                src="/assets/images/faqs/account/companies-7.2.png"
                                alt="Companies 7.2 "
                                width="100%"
                            />
                            <Typography pl={4.5} mt={2}>
                                {t('faqs:text.addTenantsDescriptionConclusion')}
                            </Typography>
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.deleteTenants'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.deleteTenantsDescription')}:</Typography>
                            <img src="/assets/images/faqs/account/companies-8.png" alt="Companies 8" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.promptTenantToSetUpPassword'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.promptTenantToSetUpPasswordDescription')}:</Typography>
                            <img src="/assets/images/faqs/account/companies-9.1.png" alt="Companies 9.1" width="100%" />
                            <img
                                src="/assets/images/faqs/account/companies-9.2.png"
                                alt="Companies 9.2"
                                width="100%"
                                style={{ marginTop: 16 }}
                            />
                        </Box>
                    ),
                },
            ],
        },
        {
            subHeading: t('drawer:permits'),
            content: [
                {
                    question: t('faqs:text.recurringTypesQuestion'),
                    answer: (
                        <Box pl={1.5}>
                            <ul style={{ margin: 0 }}>
                                <li>
                                    <Typography>
                                        <span style={{ fontWeight: 500 }}>{t('permits:text.oneDayPermit')}</span> –{' '}
                                        {t('permits:text.schedulePermitsForOneDayOnly')}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <span style={{ fontWeight: 500 }}>{t('permits:text.daily')}</span> –{' '}
                                        {t('permits:text.schedulePermitsToRecurEveryXNumberOfDays')}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <span style={{ fontWeight: 500 }}>{t('permits:text.weekly')}</span> -{' '}
                                        {t('permits:text.schedulePermitsToRecurOnSpecifiedDaysOfTheWeek')}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <span style={{ fontWeight: 500 }}>{t('permits:text.monthly')}</span> -{' '}
                                        {t('permits:text.schedulePermitsToRecurOnAParticularDateOfTheMonth')}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <span style={{ fontWeight: 500 }}>{t('permits:text.indefinite')}</span> -{' '}
                                        {t('permits:text.schedulePermitsToRecurIndefinitelyAtAllTimes')}
                                    </Typography>
                                </li>
                            </ul>
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.visibilityQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.visibilityAnswer')}</Typography>
                            <img src="/assets/images/faqs/account/permits-2.png" alt="Permits 2" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.maximizeAllocationQuestion'),
                    answer: (
                        <Stack spacing={1} pl={4.5}>
                            <Typography>{t('faqs:text.maximizeAllocationAnswer')}</Typography>
                            <Typography>{t('faqs:text.maximizeAllocationAnswer2')}</Typography>
                            <Typography>{t('faqs:text.maximizeAllocationAnswer3')}</Typography>
                        </Stack>
                    ),
                },
                {
                    question: t('faqs:text.addPermitQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.addPermitAnswer')}</Typography>
                            <img src="/assets/images/faqs/account/permits-4.1.png" alt="Permit 4.1" width="100%" />
                            <Typography pl={4.5} mt={2}>
                                {t('faqs:text.addPermitAnswer2')}
                            </Typography>
                            <img src="/assets/images/faqs/account/permits-4.2.png" alt="Permit 4.2" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.duplicatePermitQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.duplicatePermitAnswer')}</Typography>
                            <img src="/assets/images/faqs/tenant/permits-4.png" alt="Permit 4" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.oneDayPermitQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.oneDayPermitAnswer')}</Typography>
                            <img src="/assets/images/faqs/tenant/permits-5.png" alt="Permit 5" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.dailyPermitsQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.dailyPermitsAnswer')}</Typography>
                            <img src="/assets/images/faqs/tenant/permits-6.png" alt="Permit 6" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.weeklyPermitsQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.weeklyPermitsAnswer')}</Typography>
                            <img src="/assets/images/faqs/tenant/permits-7.png" alt="Permit 7" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.monthlyPermitsQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.monthlyPermitsAnswer')}</Typography>
                            <img src="/assets/images/faqs/tenant/permits-8.png" alt="Permit 8" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.indefinitePermitsQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.indefinitePermitsAnswer')}</Typography>
                            <img src="/assets/images/faqs/tenant/permits-9.png" alt="Permit 9" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.bulkUploadQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.bulkUploadAnswer')}</Typography>
                            <img src="/assets/images/faqs/account/permits-11.1.png" alt="Permit 11.1" width="100%" />
                            <Typography pl={4.5} mt={2}>
                                {t('faqs:text.bulkUploadAnswer2')}
                            </Typography>
                            <img src="/assets/images/faqs/account/permits-11.2.png" alt="Permit 11.2" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.notSavingQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.notSavingAnswer')}</Typography>
                            <Typography pl={4.5} mt={2}>
                                {t('faqs:text.completedPermit')}
                            </Typography>
                            <img src="/assets/images/faqs/tenant/permits-11.1.png" alt="Permit 11.1" width="100%" />
                            <Typography pl={4.5} mt={2}>
                                {t('faqs:text.notCompletedPermit')}
                            </Typography>
                            <img src="/assets/images/faqs/tenant/permits-11.2.png" alt="Permit 11.2" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.filtersQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.filtersAnswer')}</Typography>
                            <img src="/assets/images/faqs/account/permits-13.png" alt="Permit 13" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.searchByVRNQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.searchByVRNAnswer')}</Typography>
                            <img src="/assets/images/faqs/account/permits-14.png" alt="Permit 14" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.deletePermitsQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.deletePermitsAnswer')}</Typography>
                            <img src="/assets/images/faqs/account/permits-15.png" alt="Permit 14" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.editPermitsQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.editPermitsAnswer')}</Typography>
                            <img src="/assets/images/faqs/account/permits-16.png" alt="Permit 16" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.resetFiltersQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.resetFiltersAnswer')}</Typography>
                            <img src="/assets/images/faqs/account/permits-17.png" alt="Permit 17" width="100%" />
                        </Box>
                    ),
                },
            ],
        },
        {
            subHeading: t('drawer:support'),
            content: [
                {
                    question: t('faqs:text.contactAccountManager'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.contactAccountManagerViaSupportTab')}</Typography>
                            <img src="/assets/images/faqs/tenant/support-1.png" alt="Support 1" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.reportBug'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.reportBugViaReportTab')}</Typography>
                            <img src="/assets/images/faqs/tenant/support-2.png" alt="Support 2" width="100%" />
                        </Box>
                    ),
                },
            ],
        },
        {
            subHeading: t('drawer:user'),
            content: [
                {
                    question: t('faqs:text.changePasswordQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.changePasswordAnswer.part1')}</Typography>
                            <img src="/assets/images/faqs/tenant/account-1.1.png" alt="Account 1.1" width="100%" />
                            <Typography pl={4.5} mt={2}>
                                {t('faqs:text.changePasswordAnswer.part2')}
                            </Typography>
                            <img src="/assets/images/faqs/tenant/account-1.2.png" alt="Account 1.2" width="100%" />
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.changePhoneNumberQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.changePhoneNumberAnswer.part1')}</Typography>
                            <img src="/assets/images/faqs/tenant/account-2.1.png" alt="Account 2.1" width="100%" />
                            <Typography pl={4.5} mt={2}>
                                {t('faqs:text.changePhoneNumberAnswer.part2')}
                            </Typography>
                            <img src="/assets/images/faqs/tenant/account-2.2.png" alt="Account 2.2" width="100%" />
                        </Box>
                    ),
                },
            ],
        },
        {
            subHeading: t('faqs:title.addUserToiHubViaStella'),
            content: [
                {
                    question: t('faqs:text.useStellaQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.useStellaAnswer')}</Typography>
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.userRolesQuestion'),
                    answer: (
                        <Box pl={1.5}>
                            <ul style={{ margin: 0 }}>
                                <li>
                                    <Typography>
                                        <span style={{ fontWeight: 500 }}>{t('support:text.accountManager')}</span> –{' '}
                                        {t('faqs:text.userRoles.accountManagerDescription')}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <span style={{ fontWeight: 500 }}>
                                            {t('faqs:text.userRoles.managingAgent')}
                                        </span>{' '}
                                        – {t('faqs:text.userRoles.managingAgentDescription')}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <span style={{ fontWeight: 500 }}>{t('faqs:text.userRoles.client')}</span> -{' '}
                                        {t('faqs:text.userRoles.clientDescription')}
                                    </Typography>
                                </li>
                            </ul>
                        </Box>
                    ),
                },
                {
                    question: t('faqs:text.instructionsQuestion'),
                    answer: (
                        <Box>
                            <Typography pl={4.5}>{t('faqs:text.instructionsAnswer')}</Typography>
                        </Box>
                    ),
                },
            ],
        },
    ];
};
