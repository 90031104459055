/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, LinearProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '../../../assets/svg/CloseIcon';
import color from '../../../config/Colors';
import UseLanguage from '../../../contexts/useLanguage';
import { reportBugController } from '../../../controllers';
import GeneralUploadPermitIcon from '../../permitsLayout/uploadPermits/components/svg/GeneralUploadPermitIcon';

export interface FileUpload {
    id: string;
    file: File;
    progress: number;
    isError: boolean;
    isUpload: boolean;
    url?: string;
}

type Props = {
    reportItem: FileUpload;
    handleRemoveFile: (id: string) => void;
    updateFileProgress: (fileUpload: FileUpload) => void;
};

export default function ReportImageUploadItem(props: Props) {
    const [fileUpload, setFileUpload] = useState<FileUpload>(props.reportItem);
    const [running, setRunning] = useState(true);
    const { t } = UseLanguage();
    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (running) {
            interval = setInterval(() => {
                if (fileUpload.progress !== 100) {
                    setFileUpload({ ...fileUpload, progress: fileUpload.progress + 90 });
                }
            }, 1500);
        } else {
            setFileUpload({ ...fileUpload, progress: 100 });
        }

        return () => clearInterval(interval);
    }, [running]);

    const handleUpload = () => {
        reportBugController
            .UploadImage(fileUpload.file)
            .then((res) => {
                setRunning(false);
                props.updateFileProgress({ ...fileUpload, url: res.file, progress: 100, isUpload: false });
            })
            .catch((err) => {
                setRunning(false);
                setFileUpload({ ...fileUpload, isError: true });
                props.updateFileProgress({ ...fileUpload, isError: true, progress: 100, isUpload: false });
            })
            .finally(() => {});
    };

    useEffect(() => {
        if (!props.reportItem.progress) {
            handleUpload();
        }
    }, []);

    return (
        <Grid
            container
            display={'flex'}
            direction={'row'}
            sx={{
                p: '8px 16px',
                background: 'white',
                boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.1)',
                borderRadius: '5px',
            }}
            alignItems={'center'}
            gap={{ xs: 1, md: 3 }}
        >
            <Grid item xs="auto" display={'flex'} flexDirection={'column'}>
                <GeneralUploadPermitIcon />
            </Grid>
            <Grid item xs container>
                <Grid item xs={6}>
                    <Typography
                        sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                        fontWeight={500}
                    >
                        {fileUpload.file.name}
                    </Typography>
                </Grid>
                <Grid item xs={6} display={'flex'} flexDirection={'row'} alignItems={'center'} gap={3}>
                    <Grid item xs>
                        {fileUpload.progress !== 100 && (
                            <LinearProgress
                                variant="determinate"
                                value={fileUpload.progress}
                                sx={{
                                    maxWidth: '300px',
                                    marginLeft: 'auto',
                                    '&.MuiLinearProgress-root': {
                                        borderRadius: '10px',
                                    },
                                }}
                            />
                        )}
                        {fileUpload.progress === 100 && !fileUpload.isError && (
                            <Typography textAlign={'right'} color={color.priText} fontWeight={500}>
                                {t('action:success')}
                            </Typography>
                        )}
                        {fileUpload.progress === 100 && fileUpload.isError && (
                            <Typography textAlign={'right'} color={color.danger} fontWeight={500}>
                                {t('notification:fail')}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs="auto" display={'flex'} flexDirection={'column'} alignItems={'center'}>
                        <CloseIcon
                            style={{ cursor: 'pointer' }}
                            onClick={() => props.handleRemoveFile(fileUpload.id)}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
