import React from 'react';
import color from '../../../../config/Colors';

export default function MenuIcon() {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.16666 11.25C4.75244 11.25 4.41666 11.5858 4.41666 12C4.41666 12.4142 4.75244 12.75 5.16666 12.75V11.25ZM14.5 12.75C14.9142 12.75 15.25 12.4142 15.25 12C15.25 11.5858 14.9142 11.25 14.5 11.25V12.75ZM5.16666 7.25C4.75244 7.25 4.41666 7.58579 4.41666 8C4.41666 8.41421 4.75244 8.75 5.16666 8.75V7.25ZM14.5 8.75C14.9142 8.75 15.25 8.41421 15.25 8C15.25 7.58579 14.9142 7.25 14.5 7.25V8.75ZM5.16666 3.25C4.75244 3.25 4.41666 3.58579 4.41666 4C4.41666 4.41421 4.75244 4.75 5.16666 4.75V3.25ZM14.5 4.75C14.9142 4.75 15.25 4.41421 15.25 4C15.25 3.58579 14.9142 3.25 14.5 3.25V4.75ZM5.16666 12.75H14.5V11.25H5.16666V12.75ZM5.16666 8.75H14.5V7.25H5.16666V8.75ZM5.16666 4.75H14.5V3.25H5.16666V4.75Z"
                fill={color.priText}
            />
            <path
                d="M3.83333 8.00001C3.83333 8.3682 3.53486 8.66668 3.16667 8.66668C2.79848 8.66668 2.5 8.3682 2.5 8.00001C2.5 7.63182 2.79848 7.33334 3.16667 7.33334C3.53486 7.33334 3.83333 7.63182 3.83333 8.00001Z"
                fill={color.priText}
            />
            <path
                d="M3.83333 4.00001C3.83333 4.3682 3.53486 4.66668 3.16667 4.66668C2.79848 4.66668 2.5 4.3682 2.5 4.00001C2.5 3.63182 2.79848 3.33334 3.16667 3.33334C3.53486 3.33334 3.83333 3.63182 3.83333 4.00001Z"
                fill={color.priText}
            />
            <path
                d="M3.83333 12C3.83333 12.3682 3.53486 12.6667 3.16667 12.6667C2.79848 12.6667 2.5 12.3682 2.5 12C2.5 11.6318 2.79848 11.3333 3.16667 11.3333C3.53486 11.3333 3.83333 11.6318 3.83333 12Z"
                fill={color.priText}
            />
        </svg>
    );
}
