import { Chip, MenuItem, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import ChipDeleteIcon from '../../../assets/images/ChipDeleteIcon';
import color from '../../../config/Colors';
import { LanguageContext } from '../../../contexts/useLanguage';
import { PermitStatus } from '../../../models/permits/FilterPermits';
import { AdvancedName } from '../hooks/usePermitsFilter';
import AdvancedFilter from './AdvancedFilter';

type Props = {
    value: PermitStatus;
    handleRemove: (advancedName: AdvancedName) => void;
    setStatus: (status: PermitStatus) => void;
};

export default function AdvancedStatus(props: Props) {
    const { t } = useContext(LanguageContext);
    const [name, setName] = useState<PermitStatus>(props.value);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const onClickSetName = (item: PermitStatus) => {
        setName(item);
        setAnchorEl(null);
        props.setStatus(item);
    };

    const getName = (name: PermitStatus) => {
        if (name === 'all') {
            return t('permits:text.all');
        }
        if (name === 'active') {
            return t('action:active');
        }
        if (name === 'expired') {
            return t('action:expired');
        }
        if (name === 'pending') {
            return t('action:pending');
        }
    };

    return (
        <AdvancedFilter
            isShowButton={false}
            openElement={
                <Chip
                    label={t('action:status') + ': ' + getName(name)}
                    variant="outlined"
                    sx={[
                        anchorEl !== null
                            ? {
                                  background: 'black',
                                  '& .svg-icon-stroke': {
                                      stroke: 'white',
                                  },
                                  color: 'white',
                              }
                            : {},
                        { '& svg': { mr: '5px' }, minWidth: '140px' },
                    ]}
                    deleteIcon={<ChipDeleteIcon />}
                    onClick={() => {}}
                    onDelete={() => {
                        props.handleRemove('status');
                    }}
                />
            }
            MenuItems={['all', 'active', 'expired', 'pending'].map((item, index) => {
                return (
                    <MenuItemTenant
                        handleClick={onClickSetName}
                        name={item as PermitStatus}
                        key={index}
                        getName={getName}
                    />
                );
            })}
            anchorEl={anchorEl}
            setAnchor={setAnchorEl}
        />
    );
}

type PropsChild = {
    name: PermitStatus;
    handleClick: (item: PermitStatus) => void;
    getName: (name: PermitStatus) => string | undefined;
};

function MenuItemTenant(props: PropsChild) {
    return (
        <MenuItem
            // disableRipple={true}
            sx={{
                padding: '5px 5px',
                borderBottom: `1px solid ${color.grey200}`,
                borderRadius: '5px',
                '&:hover': {
                    background: color.primary,
                },
                '& .MuiTouchRipple-child': {
                    backgroundColor: color.primary,
                },
                flexDirection: 'column',
            }}
            onClick={() => props.handleClick(props.name)}
        >
            <Typography variant="body1" component={'h6'} width={'100%'} textTransform="capitalize">
                {props.getName(props.name)}
            </Typography>
        </MenuItem>
    );
}
