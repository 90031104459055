import { Visibility, VisibilityOff } from '@material-ui/icons';
import { IconButton, InputAdornment } from '@mui/material';
import React from 'react';

type Props = {
    onClick: () => void;
    onMouseDown?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    isShowPassword: boolean;
};
export default function ButtonHidePassword(props: Props) {
    return (
        <InputAdornment position="end">
            <IconButton
                aria-label="toggle password visibility"
                onClick={props.onClick}
                onMouseDown={props?.onMouseDown}
                edge="end"
                sx={{
                    '& svg': {
                        width: '16px',
                        height: '16px',
                    },
                }}
            >
                {props.isShowPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
        </InputAdornment>
    );
}
