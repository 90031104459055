import React, { SVGProps } from 'react';
import color from '../../../../config/Colors';

type Props = {} & SVGProps<SVGSVGElement>;

export default function PieChartIcon(props: Props) {
    return (
        <svg {...props} width="100" height="83" viewBox="50 80 100 83" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="50" y="80" width="100" height="83" rx="5" fill="#FAFAFA" />
            <path
                d="M125 121.5C125 135.307 113.807 146.5 100 146.5C86.1929 146.5 75 135.307 75 121.5C75 107.693 86.1929 96.5 100 96.5C113.807 96.5 125 107.693 125 121.5ZM79 121.5C79 133.098 88.402 142.5 100 142.5C111.598 142.5 121 133.098 121 121.5C121 109.902 111.598 100.5 100 100.5C88.402 100.5 79 109.902 79 121.5Z"
                fill="#DDDDDD"
            />
            <g filter="url(#filter0_d_3009_12212)">
                <path
                    d="M100 98.5C100 97.3954 100.897 96.4917 101.998 96.58C106.041 96.904 109.955 98.208 113.396 100.392C116.837 102.576 119.683 105.562 121.698 109.082C122.246 110.04 121.81 111.237 120.811 111.707V111.707C119.812 112.177 118.628 111.742 118.065 110.792C116.384 107.955 114.052 105.546 111.252 103.769C108.452 101.992 105.28 100.909 101.997 100.595C100.897 100.49 100 99.6046 100 98.5V98.5Z"
                    fill={color.primary}
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_3009_12212"
                    x="0"
                    y="0.57399"
                    width="221.937"
                    height="215.324"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="50" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.470588 0 0 0 0 0.890196 0 0 0 0 0.509804 0 0 0 0.22 0"
                    />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3009_12212" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3009_12212" result="shape" />
                </filter>
            </defs>
        </svg>
    );
}
