import _ from 'lodash';
import React, { SVGProps } from 'react';
import color from '../../../../config/Colors';
type Props = { isDisable?: boolean } & SVGProps<SVGSVGElement>;
export default function IconMail(props: Props) {
    return (
        <svg
            {..._.omit(props, 'isDisable')}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
        >
            <path
                stroke={props.isDisable ? color.grey400 : '#85858A'}
                strokeWidth="1.5"
                d="M1.962 4.471c-.39.716-.39 1.737-.39 3.529 0 2.083 0 3.125.613 3.855.198.236.438.443.711.614.845.531 2.05.531 4.461.531h1.286c2.41 0 3.615 0 4.46-.53.273-.172.513-.38.712-.615.613-.73.613-1.772.613-3.855 0-1.805 0-2.828-.399-3.544m-12.067.015c.064-.116.137-.224.223-.326.198-.236.438-.443.711-.614C3.741 3 4.946 3 7.357 3h1.286c2.41 0 3.615 0 4.46.53.273.172.513.38.712.615.082.098.153.2.214.311m-12.067.015l.252.196 1.24 1.071C5.597 7.59 6.668 8.516 8 8.516c1.331 0 2.403-.926 4.546-2.778l1.24-1.071.243-.21"
            ></path>
        </svg>
    );
}
