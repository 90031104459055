import React, { SVGProps } from 'react';

type Props = {} & SVGProps<SVGSVGElement>;

export default function NoTenants(props: Props) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="none" viewBox="0 0 80 80">
            <rect width="70" height="70" x="5" y="5" fill="#EEE" rx="35"></rect>
            <path
                fill="#85858A"
                fillRule="evenodd"
                d="M37.883 24c-4.7 0-8.51 3.32-8.51 7.415 0 4.095 3.81 7.414 8.51 7.414 4.699 0 8.508-3.32 8.508-7.414 0-4.095-3.81-7.415-8.508-7.415zm-5.822 7.415c0-2.802 2.606-5.073 5.822-5.073 3.215 0 5.821 2.27 5.821 5.073 0 2.802-2.606 5.073-5.821 5.073-3.216 0-5.822-2.271-5.822-5.073z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#85858A"
                d="M32.509 41.17c-4.7 0-8.509 3.32-8.509 7.415C24 52.68 27.81 56 32.509 56h10.748c.742 0 1.343-.524 1.343-1.17 0-.647-.601-1.172-1.343-1.172H32.509c-3.215 0-5.822-2.27-5.822-5.073 0-2.802 2.607-5.073 5.822-5.073h10.748c.742 0 1.343-.524 1.343-1.17 0-.647-.601-1.171-1.343-1.171H32.509zM55.606 46.48c.525-.457.525-1.198 0-1.655-.524-.457-1.375-.457-1.9 0l-2.85 2.483-2.85-2.483c-.524-.457-1.375-.457-1.9 0-.524.457-.524 1.198 0 1.656l2.85 2.483-2.85 2.484c-.524.457-.524 1.198 0 1.655.525.457 1.376.457 1.9 0l2.85-2.483 2.85 2.483c.525.457 1.376.457 1.9 0 .525-.457.525-1.198 0-1.655l-2.85-2.484 2.85-2.483z"
            ></path>
            <rect width="70" height="70" x="5" y="5" stroke="#FAFAFA" strokeWidth="10" rx="35"></rect>
        </svg>
    );
}
