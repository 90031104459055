import { Container, Pagination } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import useCompanies, { CompaniesContext } from '../../hooks/useCompanies';
import { DrawerContext } from '../dashboardLayout/useDrawer';

export default function CompanyProvider() {
    const companiesContext = useCompanies();
    const { setActiveItem } = useContext(DrawerContext);
    useEffect(() => {
        setActiveItem(2);
    }, []);
    return (
        <CompaniesContext.Provider value={companiesContext}>
            <Outlet />
        </CompaniesContext.Provider>
    );
}

export function useCompaniesContext() {
    return useOutletContext<ReturnType<typeof useCompanies>>();
}
