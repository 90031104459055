import { Grid, Typography } from '@mui/material';
import React, { useContext } from 'react';
import color from '../config/Colors';
import { LanguageContext } from '../contexts/useLanguage';

type Props = {
    text: string;
};

export default function NoDataToDisplay(props: Props) {
    const { t } = useContext(LanguageContext);
    return (
        <Grid
            item
            container
            alignItems={'center'}
            justifyContent={'center'}
            sx={{
                background: `${color.grey100} !important`,
                borderRadius: '10px',
                padding: '10px 0',
                height: 200,
            }}
            mt={4}
        >
            <Typography variant="h5" fontWeight={500} color={'#85858A'}>
                {props.text}
            </Typography>
        </Grid>
    );
}
