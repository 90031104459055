import React, { SVGProps } from 'react';

type Props = {} & SVGProps<SVGSVGElement>;

export default function LogoutIcon(props: Props) {
    return (
        <svg
            width="32"
            height="32"
            className="svg-icon-fill"
            viewBox="0 0 32 32"
            fill="#85858A"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" />
            <path
                d="M19.25 10C19.25 11.7949 17.7949 13.25 16 13.25C14.2051 13.25 12.75 11.7949 12.75 10C12.75 8.20507 14.2051 6.75 16 6.75C17.7949 6.75 19.25 8.20507 19.25 10Z"
                stroke="white"
                strokeWidth="1.5"
            />
            <path
                d="M22.25 21C22.25 22.0756 21.6409 23.1202 20.5138 23.9252C19.3882 24.7293 17.7947 25.25 16 25.25C14.2053 25.25 12.6118 24.7293 11.4862 23.9252C10.3591 23.1202 9.75 22.0756 9.75 21C9.75 19.9244 10.3591 18.8798 11.4862 18.0748C12.6118 17.2707 14.2053 16.75 16 16.75C17.7947 16.75 19.3882 17.2707 20.5138 18.0748C21.6409 18.8798 22.25 19.9244 22.25 21Z"
                stroke="white"
                strokeWidth="1.5"
            />
        </svg>
    );
}
