import React, { SVGProps } from 'react';
import color from '../../../../../config/Colors';

type Props = {} & SVGProps<SVGSVGElement>;

export default function PlusIcon(props: Props) {
    return (
        <svg
            {...props}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill={color.priText}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M8.59556 2.59508C8.59556 2.35838 8.40369 2.1665 8.16699 2.1665C7.9303 2.1665 7.73842 2.35838 7.73842 2.59508L7.73842 6.73793H3.59556C3.35887 6.73793 3.16699 6.92981 3.16699 7.1665C3.16699 7.4032 3.35887 7.59508 3.59556 7.59508H7.73842V11.7379C7.73842 11.9746 7.9303 12.1665 8.16699 12.1665C8.40369 12.1665 8.59556 11.9746 8.59556 11.7379V7.59508H12.7384C12.9751 7.59508 13.167 7.4032 13.167 7.1665C13.167 6.92981 12.9751 6.73793 12.7384 6.73793H8.59556L8.59556 2.59508Z" />
        </svg>
    );
}
