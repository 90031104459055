import { Box, Stack, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { COLOR_EV_AVERAGE_LENGTH } from '../../../constants';
import { LanguageContext } from '../../../contexts/useLanguage';

export default function EVLengthOfVisitsLabel() {
    const { t } = useContext(LanguageContext);
    return (
        <Box
            sx={{
                position: 'absolute',
                bottom: 30,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row',
            }}
        >
            <Typography
                variant="body1"
                component={'div'}
                sx={{ display: 'flex', flexDirection: 'column' }}
                fontSize={{ xs: '11px', sm: '14px', lg: '14px' }}
                mx={'6px'}
            >
                <Stack direction={'row'} alignItems="center" width={'100%'}>
                    <Box
                        sx={{
                            width: '8px',
                            height: '8px',
                            borderRadius: '50%',
                            background: COLOR_EV_AVERAGE_LENGTH.rapid,
                            mr: '1px',
                        }}
                    ></Box>

                    <Typography component={'p'} ml="5px">
                        {t('dashboard:text.rapidHours')}
                    </Typography>
                </Stack>
                <Typography pl={'15px'}>{t('dashboard:text.rapidKW')}</Typography>
            </Typography>
            <Typography
                variant="body1"
                component={'div'}
                sx={{ display: 'flex', flexDirection: 'column' }}
                fontSize={{ xs: '11px', sm: '14px', lg: '14px' }}
                mx={'6px'}
            >
                <Stack direction={'row'} alignItems="center" width={'100%'}>
                    <Box
                        sx={{
                            width: '8px',
                            height: '8px',
                            borderRadius: '50%',
                            background: COLOR_EV_AVERAGE_LENGTH.fast,
                            mr: '1px',
                        }}
                    ></Box>

                    <Typography component={'p'} ml="5px">
                        {t('dashboard:text.fastHours')}
                    </Typography>
                </Stack>
                <Typography pl={'15px'}>{t('dashboard:text.fastKW')}</Typography>
            </Typography>
            <Typography
                variant="body1"
                component={'div'}
                sx={{ display: 'flex', flexDirection: 'column' }}
                fontSize={{ xs: '11px', sm: '14px', lg: '14px' }}
                mx={'6px'}
            >
                <Stack direction={'row'} alignItems="center" width={'100%'}>
                    <Box
                        sx={{
                            width: '8px',
                            height: '8px',
                            borderRadius: '50%',
                            background: COLOR_EV_AVERAGE_LENGTH.normal,
                            mr: '1px',
                        }}
                    ></Box>

                    <Typography component={'p'} ml="5px">
                        {t('dashboard:text.normalHours')}
                    </Typography>
                </Stack>
                <Typography pl={'15px'}>{t('dashboard:text.normalKW')}</Typography>
            </Typography>
        </Box>
    );
}
