import React, { useContext } from 'react';
import { LanguageContext } from '../contexts/useLanguage';
import { Question, QuestionType } from '../models/feedback/Feedback';
import { Question1, Question2 } from '../pages/feedback/components/QuestionComponent';

export default function useQuestionFeedback() {
    const { t } = useContext(LanguageContext);

    const questions: Question[] = [
        {
            Id: 1,
            question: t('feedbackPage:text.feedbackOnIHubExperience'),
            type: QuestionType.TextArea,
            Component: Question1,
        },
        {
            Id: 2,
            question: t('feedbackPage:text.futureFeatureImplementationForMoreValue'),
            type: QuestionType.TextArea,
            Component: Question2,
        },
    ];

    return { questions };
}
