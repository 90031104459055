import { SvgIconProps } from '@mui/material';
import React from 'react';

export default function FeedBackIcon(props: SvgIconProps) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.98 10.79V14.79C17.98 15.05 17.97 15.3 17.94 15.54C17.71 18.24 16.12 19.58 13.19 19.58H12.79C12.54 19.58 12.3 19.7 12.15 19.9L10.95 21.5C10.42 22.21 9.56 22.21 9.03 21.5L7.83 19.9C7.74995 19.8068 7.65212 19.7305 7.54223 19.6755C7.43234 19.6206 7.31259 19.5881 7.19 19.58H6.79C3.6 19.58 2 18.79 2 14.79V10.79C2 7.86 3.35 6.27 6.04 6.04C6.28 6.01 6.53 6 6.79 6H13.19C16.38 6 17.98 7.6 17.98 10.79Z"
                stroke="#85858A"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="svg-icon-stroke"
            />
            <path
                d="M21.98 6.79V10.79C21.98 13.73 20.63 15.31 17.94 15.54C17.97 15.3 17.98 15.05 17.98 14.79V10.79C17.98 7.6 16.38 6 13.19 6H6.78998C6.52998 6 6.27998 6.01 6.03998 6.04C6.26998 3.35 7.85998 2 10.79 2H17.19C20.38 2 21.98 3.6 21.98 6.79Z"
                stroke="#85858A"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="svg-icon-stroke"
            />
            <path
                d="M13.495 13.25H13.505M9.995 13.25H10.005M6.495 13.25H6.505"
                stroke="#85858A"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="svg-icon-stroke"
            />
        </svg>
    );
}
