/* eslint-disable array-callback-return */
import { Grid, Typography } from '@mui/material';
import moment from 'moment';
import React, { useContext } from 'react';
import PieChart, { PieChartData } from '../../../components/chart/PieChart';
import LoadingCircular from '../../../components/LoadingCircular';
import color from '../../../config/Colors';
import font from '../../../config/Fonts';
import { LIST_COLOR_CHART } from '../../../constants';
import { LanguageContext } from '../../../contexts/useLanguage';
import { formatNumber } from '../../../helpers/FormatCurrency';
import useMedia from '../../../hooks/useMedia';
import { AverageLengthStatistic } from '../../../models/AverageLengthStatistic';
import { ChartShowType } from '../../../models/ChartShowType';
import { ReportType } from '../../../models/ReportType';
import { GridBorderRadius } from '../styled';
import ChartTitleContent from './ChartTitleContent';
import ColorBox from './ColorBox';

type Props = {
    chartShowType?: ChartShowType;
    reportType?: ReportType;
    averageLengthStatistic: AverageLengthStatistic[];
    averageLengthOfStay: number;
};

export default function AverageLengthOfVisits(props: Props) {
    const { t } = useContext(LanguageContext);
    const { chartShowType: type } = props;
    const { isMobileSM, isSMOnly } = useMedia();

    const listData = () => {
        const listLabel = [
            `0 - 1 ${t('reportPage:text.hour')}`,
            `1 - 2 ${t('reportPage:text.hours')}`,
            `2 - 3 ${t('reportPage:text.hours')}`,
            `3 - 4 ${t('reportPage:text.hours')}`,
            `4 - 5 ${t('reportPage:text.hours')}`,
            `5 - 6 ${t('reportPage:text.hours')}`,
            `> 6 ${t('reportPage:text.hours')}`,
        ];

        let list: PieChartData[] = [];
        if (props.averageLengthStatistic) {
            props.averageLengthStatistic.map((item, index) => {
                list.push({
                    label: listLabel[index],
                    value: item.count,
                });
            });
            return list;
        }
        return list;
    };

    const SecondsToTime = (seconds: number) => {
        const duration = moment.duration(seconds, 'seconds');
        const hours = duration.hours();
        const minutes = duration.minutes();
        const secs = duration.seconds();

        return `${hours + 'h ' + minutes + 'm ' + secs + 's'}`;
    };

    return props.averageLengthStatistic && props.averageLengthStatistic.length > 0 ? (
        <GridBorderRadius
            container
            item
            md={12}
            gap={type === ChartShowType.report ? 1 : 2}
            mt={type === ChartShowType.report ? 1 : 0}
            border={
                props.chartShowType === ChartShowType.report
                    ? 'none !important'
                    : `1px solid ${color.grey200} !important`
            }
        >
            <Grid container item xs={12}>
                <ChartTitleContent
                    key={'2'}
                    title={t('dashboard:title.averageLengthOfVisits')}
                    desc={t('dashboard:text.dwellTimesOfTheNumberOfVisits')}
                    buttonLabel={t('dashboard:button.viewInsight')}
                    chartShowType={props.chartShowType || ChartShowType.dashboard}
                    reportType={props.reportType || undefined}
                />
            </Grid>
            {props.chartShowType === ChartShowType.report && (
                <Grid item xs={12} display="flex" flexDirection="row" justifyContent={{ xs: 'center', sm: 'end' }}>
                    <Typography
                        variant="body1"
                        textAlign="end"
                        sx={{ fontSize: '12px', p: '8px 16px', background: color.grey100, borderRadius: '8px' }}
                        component={'span'}
                    >
                        {t('dashboard:title.averageLengthOfStay')}:
                        <Typography
                            variant="body1"
                            width={{ xs: '5px', sm: '30px' }}
                            component="p"
                            display={'inline-block'}
                        ></Typography>{' '}
                        {SecondsToTime(props.averageLengthOfStay)}
                    </Typography>
                </Grid>
            )}

            <Grid
                item
                xs={12}
                container
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '16px',
                }}
            >
                <Grid
                    sx={{ height: '250px' }}
                    item
                    md={type === ChartShowType.report ? 4 : 6}
                    lg={type === ChartShowType.report ? 4 : 12}
                    sm={6}
                    xs={12}
                >
                    <PieChart data={listData()} />
                </Grid>
                <Grid
                    item
                    container
                    md={type === ChartShowType.report ? 6 : 6}
                    lg={type === ChartShowType.report ? 6 : 12}
                    sm={6}
                    xs={12}
                    padding={isMobileSM ? '16px 0 0 0' : '16px 16px 0 16px'}
                    direction={'row'}
                    alignItems={'flex-start'}
                    justifyContent={'space-between'}
                >
                    {isSMOnly ? (
                        <>
                            <Grid
                                item
                                container
                                md={5}
                                sm={12}
                                xs={6}
                                flexDirection={'column'}
                                justifyContent={'center'}
                                textAlign={'left'}
                                paddingLeft={8}
                                gap={1}
                            >
                                {props.averageLengthStatistic.map((item, index) => {
                                    if (index < 4) {
                                        return (
                                            <Typography
                                                key={index}
                                                variant="body1"
                                                fontSize={{ xs: '12px', sm: '14px', lg: '14px' }}
                                                component={'div'}
                                                sx={{}}
                                            >
                                                <ColorBox color={LIST_COLOR_CHART[index]} />
                                                {`${item.hours} (${item.percentage}%)`}
                                            </Typography>
                                        );
                                    } else {
                                        if (index === 6) {
                                            return (
                                                <Typography
                                                    key={index}
                                                    variant="body1"
                                                    fontSize={{ xs: '12px', sm: '14px', lg: '14px' }}
                                                    component={'div'}
                                                    sx={{}}
                                                >
                                                    <ColorBox color={LIST_COLOR_CHART[index]} />
                                                    {`> 6 ${t('reportPage:text.hours')} (${formatNumber.format(
                                                        item.percentage
                                                    )}%)`}
                                                </Typography>
                                            );
                                        }
                                        return (
                                            <Typography
                                                key={index}
                                                variant="body1"
                                                fontSize={{ xs: '12px', sm: '14px', lg: '14px' }}
                                                component={'div'}
                                                sx={{}}
                                            >
                                                <ColorBox color={LIST_COLOR_CHART[index]} />
                                                {`${item.hours} (${formatNumber.format(item.percentage)}%)`}
                                            </Typography>
                                        );
                                    }
                                })}
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid
                                item
                                container
                                md={5}
                                sm={12}
                                xs={6}
                                flexDirection={'column'}
                                justifyContent={'center'}
                                textAlign={'left'}
                                paddingLeft={{ xs: 0, sm: 3, md: 3 }}
                                gap={1}
                            >
                                {props.averageLengthStatistic.map((item, index) => {
                                    if (index < 4) {
                                        return (
                                            <Typography
                                                key={index}
                                                variant="body1"
                                                fontFamily={font}
                                                fontSize={{ xs: '12px', sm: '14px', lg: '14px' }}
                                                component={'div'}
                                                sx={{}}
                                            >
                                                <ColorBox color={LIST_COLOR_CHART[index]} />
                                                {`${item.hours} (${item.percentage}%)`}
                                            </Typography>
                                        );
                                    }
                                })}
                            </Grid>
                            <Grid
                                item
                                container
                                md={5}
                                sm={12}
                                xs={6}
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'center'}
                                textAlign={'left'}
                                paddingLeft={{ xs: 0, sm: 3, md: 3 }}
                                gap={1}
                            >
                                {props.averageLengthStatistic.map((item, index) => {
                                    if (index >= 4) {
                                        if (index === 6) {
                                            return (
                                                <Typography
                                                    key={index}
                                                    variant="body1"
                                                    fontSize={{ xs: '12px', sm: '14px', lg: '14px' }}
                                                    component={'div'}
                                                    sx={{}}
                                                >
                                                    <ColorBox color={LIST_COLOR_CHART[index]} />
                                                    {`> 6 ${t('reportPage:text.hours')} (${formatNumber.format(
                                                        item.percentage
                                                    )}%)`}
                                                </Typography>
                                            );
                                        }
                                        return (
                                            <Typography
                                                key={index}
                                                variant="body1"
                                                fontSize={{ xs: '12px', sm: '14px', lg: '14px' }}
                                                component={'div'}
                                                sx={{}}
                                            >
                                                <ColorBox color={LIST_COLOR_CHART[index]} />
                                                {`${item.hours} (${formatNumber.format(item.percentage)}%)`}
                                            </Typography>
                                        );
                                    }
                                })}
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
        </GridBorderRadius>
    ) : (
        <LoadingCircular />
    );
}
