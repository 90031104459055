import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import color from '../../../../config/Colors';
import UseLanguage from '../../../../contexts/useLanguage';
import NoTenants from './svg/NoTenants';

export default function RedirectNoneTenants() {
    const { t } = UseLanguage();
    return (
        <Box mt={4} border={`1px solid ${color.grey300}`} borderRadius={'4px'} width={'100%'} p={'40px 32px'}>
            <Stack direction={'column'} sx={{ textAlign: 'center' }} gap={3}>
                <Typography>
                    <NoTenants />
                </Typography>
                <Typography variant="h5" fontWeight={500} color={color.grey600}>
                    {t('permits:text.thereAreNoTenants') + ', ' + t('permits:text.pleaseAddATenants')}
                </Typography>
            </Stack>
        </Box>
    );
}
