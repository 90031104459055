import React, { SVGProps } from 'react';

type Props = {} & SVGProps<SVGSVGElement>;

export default function RemoveIcon(props: Props) {
    return (
        <svg
            {...props}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            className="svg-icon-stroke"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#85858A"
        >
            <path
                d="M12.9864 6.22058C12.9864 6.22058 12.6048 10.9535 12.3834 12.9471C12.278 13.8993 11.6898 14.4573 10.7264 14.4749C8.89296 14.5079 7.05743 14.51 5.22471 14.4713C4.29781 14.4524 3.71946 13.8874 3.61616 12.952C3.39339 10.9408 3.01392 6.22058 3.01392 6.22058"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M13.9584 3.95183H2.0415" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M11.6621 3.95183C11.1104 3.95183 10.6354 3.56181 10.5272 3.02141L10.3564 2.16689C10.251 1.77266 9.89402 1.5 9.48714 1.5H6.51248C6.1056 1.5 5.74861 1.77266 5.6432 2.16689L5.47243 3.02141C5.36421 3.56181 4.88917 3.95183 4.33752 3.95183"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
