import { BaseModel } from './BaseModel';

export interface Support extends BaseModel {
    firstName: string;
    lastName: string;
    displayName: string;
    email: string;
    telephone: string;
    mobile: string;
    isSuspended: boolean;
    objectId: string;
    locationRoles: LocationRole[];
    reportedDataIssues?: any;
    assignedDataIssues?: any;
    dataIssueComments?: any;
    userPermissionGroups?: any;
}
export interface LocationRole {
    id: number;
    location: any;
    locationId: number;
    locationRoleType: LocationRoleType;
    userId: number;
}

export enum LocationRoleType {
    'Key Account Manager' = 0,
    'Account Manager' = 1,
    'Account Executive' = 2,
}
