import { Typography, useTheme } from '@mui/material';

export const BaseSubHeading = ({ subHeading }: { subHeading: string }) => {
    const theme = useTheme();
    return (
        <Typography sx={{ fontSize: '16px !important' }} fontWeight={500} color={theme.palette.grey[600]}>
            {subHeading}
        </Typography>
    );
};
export const BaseDivider = () => {
    return <div style={{ width: '100%', height: '1px', borderBottom: '1px solid #ddd', margin: '8px 0px 16px 0px' }} />;
};
