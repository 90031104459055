import { Box } from '@mui/system';
import React from 'react';

type Props = {
    color: string;
};

export default function ColorBoxCircle({ color }: Props) {
    return (
        <Box
            sx={{
                width: '8px',
                height: '8px',
                backgroundColor: color,
                borderRadius: '100%',
                display: 'inline-block',
                mr: '5px',
            }}
        />
    );
}
