import { Box, Stack, TextField, Typography } from '@mui/material';
import { useContext } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import color from '../../../../../config/Colors';
import { LanguageContext } from '../../../../../contexts/useLanguage';
import { RecurProps } from './DayRecurring';
import { PermitFromData } from '../../hooks/useUpsertPermit';

export default function MonthRecurring({ isEdit, parentForm }: RecurProps) {
    const { indexForm } = parentForm;
    const { control } = useFormContext<PermitFromData>();
    const { permitRecurringEvery } = useWatch({ control: control, name: `permits.${indexForm}` });

    const { t } = useContext(LanguageContext);

    const { language } = useContext(LanguageContext);

    const getIndicator = () => {
        return [1, 21, 31].includes(permitRecurringEvery!)
            ? 'st'
            : [2, 22].includes(permitRecurringEvery!)
            ? 'nd'
            : [3, 23].includes(permitRecurringEvery!)
            ? 'rd'
            : 'th';
    };

    const isValidDateOfAllMonth = () => {
        if ([29, 30, 31].includes(permitRecurringEvery!)) return false;
        return true;
    };

    return (
        <Stack
            flex={1}
            direction={'column'}
            padding={1}
            sx={{ background: color.white, borderRadius: '5px' }}
            alignItems="flex-start"
        >
            <Stack direction={'row'} alignItems="center" width={'100%'}>
                <Typography variant="body1" component={'span'} mr={2}>
                    {t('permits:text.onThe')}
                </Typography>
                <Controller
                    control={control}
                    name={`permits.${indexForm}.permitRecurringEvery`}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            // fullWidth
                            size="small"
                            placeholder={''}
                            onChange={(e) => {
                                const value = Number(e.target!.value);
                                const val = value < 1 ? 1 : value > 31 ? 31 : value;
                                field.onChange(val);
                            }}
                            sx={{ minWidth: '120px', mr: 2 }}
                            type="number"
                            inputProps={{ min: 1, max: 31 }}
                            disabled={isEdit}
                        />
                    )}
                />
                <Typography variant="body1" component={'span'}>
                    {language == 'en' ? getIndicator() : ''} {t('permits:text.ofEveryMonth')}
                </Typography>
            </Stack>

            {!isValidDateOfAllMonth() && (
                <Box mt={3}>
                    <Typography fontSize={10} color={color.textPrimary}>
                        ({t('permits:text.pleaseNoteThatTheScheduledPermitWillNotRecurForTheMonths')})
                    </Typography>
                </Box>
            )}
        </Stack>
    );
}
