import { useContext, useEffect } from 'react';
import { appConfig } from '../../config/AppConfig';
import { FilterContext, GROSS_REVENUE_CROSS_LOCATION_ID } from '../../contexts/FilterContext';
import { LanguageContext } from '../../contexts/useLanguage';
import useMedia from '../../hooks/useMedia';
import useScrollToTop from '../../hooks/useScrollToTop';
import { ReportType } from '../../models/ReportType';
import { DrawerContext } from '../dashboardLayout/useDrawer';
import ActiveLTBooking from './components/ActiveLTBooking';
import AverageLengthOfVisits from './components/AverageLengthOfVisits';
import CapacityAnalysis from './components/CapacityAnalysisChart';
import DailyVisits from './components/DailyVisits';
import GrossRevenueByLTs from './components/GrossRevenueByLTs';
import GrossRevenueChart from './components/GrossRevenueChart';
import GroupPCNByReason from './components/GroupPCNByReason';
import PCNByZones from './components/PCNByZones';
import PCNCancellationsChart from './components/PCNCancellationsChart';
import PCNTypesChar from './components/PCNTypesChar';
import RepeatVisits from './components/RepeatVisits';
import { GridWithGray100 } from './styled';
import { Box, Fade, Grid, Typography } from '@mui/material';

export default function Dashboard() {
    const { t } = useContext(LanguageContext);
    const { setActiveItem } = useContext(DrawerContext);
    const filterData = useContext(FilterContext);
    const { isMobileSM } = useMedia();
    const {
        FilterComponents,
        dailyVisitsStatistic,
        averageLengthStatistic,
        averageLengthOfStay,
        capacityAnalysis,
        repeatVisits,
        groupPCNByZone,
        groupPCNByReason,
        groupByPNCType,
        groupByPCNCancellations,
        grossRevenueStatistic,
        grossRevenueByTypeStatistic,
        setReportState,
        isCameraNotWorking,
        grossRevenueByLTStatistic,
        filterState,
    } = filterData;
    const { isMediumMD } = useMedia();
    useScrollToTop();

    useEffect(() => {
        setActiveItem(1);
        setReportState((prev) => ({ ...prev, showingPage: 'dashboard', report: ReportType.none }));
    }, []);

    return (
        <>
            <FilterContext.Provider value={filterData}>
                <>
                    <Typography variant="h3" mb={2} fontWeight={400}>
                        {t('dashboard:title.dashboard')}
                    </Typography>

                    <Grid container mt={2}>
                        {FilterComponents}
                        {!!isCameraNotWorking && (
                            <Fade in={!!isCameraNotWorking}>
                                <Box
                                    sx={{
                                        p: '8px 16px',
                                        background: '#FEF3F2',
                                        borderLeft: '4px solid #FEE4E2',
                                        borderRadius: '4px',
                                        mt: isMobileSM ? '16px' : '32px',
                                    }}
                                >
                                    <Typography variant="h6" fontStyle="italic" lineHeight="16px">
                                        {t('dashboard:text.ANPRcamerasNotFullyOperational')}
                                    </Typography>
                                </Box>
                            </Fade>
                        )}
                        <DailyVisits dailyVisitsStatistic={dailyVisitsStatistic} reportType={ReportType.dailyVisit} />
                        <Grid
                            container
                            item
                            direction={'row'}
                            justifyContent={'space-between'}
                            lg={12}
                            mt={{ xs: 2, sm: 3, md: 4 }}
                        >
                            <Grid item container mb={isMediumMD ? 2 : 0} lg={5.82}>
                                <AverageLengthOfVisits
                                    averageLengthStatistic={averageLengthStatistic}
                                    averageLengthOfStay={averageLengthOfStay}
                                    reportType={ReportType.averageLength}
                                />
                            </Grid>
                            <Grid item container lg={5.82} mt={{ md: 3, lg: 0 }}>
                                <RepeatVisits reportType={ReportType.repeatVisits} repeatVisits={repeatVisits} />
                            </Grid>
                        </Grid>

                        <CapacityAnalysis
                            capacityAnalysis={capacityAnalysis}
                            reportType={ReportType.capacityAnalysis}
                        />

                        {/* <Grid
                            container
                            item
                            direction={'row'}
                            justifyContent={'space-between'}
                            lg={12}
                            mt={{ xs: 2, sm: 3, md: 4 }}
                        >
                            <EVDailyVisits
                                dailyVisitsStatistic={dailyVisitsStatistic}
                                reportType={ReportType.evDailyVisit}
                            />
                        </Grid>

                        <EVLengthOfVisits capacityAnalysis={capacityAnalysis} reportType={ReportType.evAverageLength} /> */}

                        {appConfig.flagFeatures.isEnableReportPCNs === true ? (
                            <>
                                {/* <Grid
                                    container
                                    item
                                    direction={'row'}
                                    justifyContent={'space-between'}
                                    // alignItems={'start'}
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    mt={{ xs: 2, sm: 3, md: 4 }}
                                >
                                    <Grid item container mb={isMediumMD ? 2 : 0} lg={5.82} md={5.82} xs={12}>
                                        <PCNTypesChar
                                            statisticItems={groupByPNCType}
                                            reportType={ReportType.groupByPCNTypes}
                                        />
                                    </Grid>
                                    <Grid container item lg={5.82} md={5.82} xs={12}>
                                        <PCNCancellationsChart
                                            key={'7'}
                                            statisticItems={groupByPCNCancellations}
                                            reportType={ReportType.groupByPCNCancellations}
                                        />
                                    </Grid>
                                </Grid> */}

                                <PCNByZones reportType={ReportType.PCNByZones} groupPCNByZone={groupPCNByZone} />

                                <GroupPCNByReason
                                    reportType={ReportType.PCNByReason}
                                    groupByReason={groupPCNByReason}
                                />
                            </>
                        ) : (
                            <></>
                        )}

                        {appConfig.flagFeatures.isEnableGrossRevenue &&
                            filterState.locationId === GROSS_REVENUE_CROSS_LOCATION_ID && (
                                <GrossRevenueChart
                                    grossRevenueStatistic={grossRevenueStatistic}
                                    reportType={ReportType.grossRevenueBySTs}
                                />
                            )}

                        {appConfig.flagFeatures.isEnableGrossRevenue &&
                            filterState.locationId === GROSS_REVENUE_CROSS_LOCATION_ID && (
                                <GridWithGray100
                                    container
                                    item
                                    display={'flex'}
                                    flexDirection={'row'}
                                    wrap={'wrap'}
                                    // justifyContent={'space-between'}
                                    xs={12}
                                    mt={{ xs: 2, sm: 3, md: 4 }}
                                    padding={`0 !important`}
                                    borderRadius={'8px'}
                                >
                                    <Grid item xs={12} sm={12} md={12} lg={6}>
                                        <ActiveLTBooking
                                            grossRevenueStatistic={grossRevenueByTypeStatistic}
                                            reportType={ReportType.activeLongTermBooking}
                                            minHeightChart="300px"
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={6}>
                                        <GrossRevenueByLTs
                                            dailyStatistic={grossRevenueByLTStatistic}
                                            reportType={ReportType.grossRevenueByLTs}
                                            minHeightChart="300px"
                                        />
                                    </Grid>
                                </GridWithGray100>
                            )}
                    </Grid>
                </>
            </FilterContext.Provider>
        </>
    );
}
