import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Fade,
    FormControlLabel,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import MyModalLarge from '../../../../../../../components/modal/MyModalLarge';
import color from '../../../../../../../config/Colors';
import LabelWithRequired from '../../../../../../../components/LabelWithRequired';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CalendarIcon from '../../../../../../../assets/svg/CalendarIcon';
import CheckBoxIcon from '../../../../../../../assets/images/CheckBoxIcon';
import Checked from '../../../../../../../assets/images/Checked';
import { Controller, useForm } from 'react-hook-form';
import Zone, { BayTemporary } from '../../../../../../../models/Zone';
import useMedia from '../../../../../../../hooks/useMedia';
import moment from 'moment';
import { useContext } from 'react';
import { LanguageContext } from '../../../../../../../contexts/useLanguage';

type Props = {
    open: boolean;
    handleClose: () => void;
    handleSave: (data: BayTemporary) => Promise<void>;
    zone: Zone;
    isLoading: boolean;
};

export default function ModalCreate({ open, handleClose, handleSave, zone, isLoading }: Props) {
    const { t } = useContext(LanguageContext);
    const { isMobileSM } = useMedia();

    const {
        control,
        handleSubmit,
        clearErrors,
        reset,
        resetField,
        watch,
        formState: { errors },
    } = useForm<BayTemporary>({
        defaultValues: {
            bayTemporary: undefined,
            startDate: undefined,
            endDate: undefined,
            noEndDate: false,
        },
        mode: 'all',
    });

    return (
        <MyModalLarge
            open={open}
            handleClose={() => {
                clearErrors();
                reset();
                !isLoading && handleClose();
            }}
            handleOpen={() => {}}
            sx={{
                width: { xs: 310, sm: 600, md: 700 },
            }}
            modalContent={
                <Fade in={open}>
                    <Stack px={3} py={5} spacing={{ xs: 2, sm: 3, md: 4 }}>
                        <Stack alignItems={'center'} spacing={2}>
                            <Typography variant="h4">{t('companies:text.addTemporaryBayCount')}</Typography>
                            <Stack
                                direction={{ xs: 'column', sm: 'row' }}
                                width={'100%'}
                                spacing={{ xs: 0, sm: 5 }}
                                paddingY={1}
                                alignItems={{ xs: 'center', sm: 'normal' }}
                                justifyContent={'center'}
                                bgcolor={color.grey100}
                            >
                                <Typography variant="body2" color={color.success}>
                                    {t('companies:tenants.zone')}: {zone.name}
                                </Typography>
                                <Typography variant="body2" color={color.secondary}>
                                    {t('companies:text.totalBays')}: {zone.totalBays}
                                </Typography>
                            </Stack>
                        </Stack>

                        <Controller
                            control={control}
                            name="bayTemporary"
                            rules={{
                                required: {
                                    value: true,
                                    message: t('validation:pleaseEnterTemporaryBayCount'),
                                },
                                max: {
                                    value: zone.totalBays,
                                    message: t('validation:smallerThanTheTotalBay'),
                                },
                            }}
                            render={({ field }) => (
                                <Box>
                                    <TextField
                                        fullWidth
                                        label={<LabelWithRequired label={t('companies:text.totalBaysCount')} />}
                                        value={field.value}
                                        placeholder={t('input:enterTemporaryBayCount')}
                                        onChange={(e) => {
                                            const reg = /[a-zA-Z!@#$e]/;
                                            const value = e.target.value;
                                            field.onChange(value.replace(reg, ''));
                                        }}
                                    />
                                    {errors.bayTemporary && (
                                        <Typography variant="body2" color={color.error}>
                                            {errors.bayTemporary.message}
                                        </Typography>
                                    )}
                                </Box>
                            )}
                        />

                        <Grid container gap={1}>
                            <Grid xs={12} md={4.5} mb={{ xs: 1, sm: 2, md: 0 }}>
                                <Controller
                                    control={control}
                                    name="startDate"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: t('validation:pleaseSelectAStartDate'),
                                        },
                                    }}
                                    render={({ field }) => (
                                        <Box>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    label={<LabelWithRequired label={t('permits:text.startDate')} />}
                                                    value={watch().startDate === undefined ? null : field.value}
                                                    minDate={new Date()}
                                                    components={{
                                                        OpenPickerIcon: CalendarIcon,
                                                    }}
                                                    inputFormat="dd/MM/yyyy"
                                                    onChange={field.onChange}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            placeholder={t('permits:text.selectDate')}
                                                            {...params}
                                                            fullWidth={true}
                                                            sx={{
                                                                '& .MuiInputBase-input': {
                                                                    caretColor: 'transparent',
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                            {errors.startDate && (
                                                <Typography variant="body2" color={color.error}>
                                                    {errors.startDate.message}
                                                </Typography>
                                            )}
                                        </Box>
                                    )}
                                />
                            </Grid>
                            <Grid xs={10} sm={8.9} md={4.5}>
                                <Controller
                                    control={control}
                                    name="endDate"
                                    rules={{
                                        required: {
                                            value: watch().noEndDate ? false : true,
                                            message: t('validation:pleaseSelectAEndDate'),
                                        },
                                        validate: (value: Date) => {
                                            const valid = moment(value).isBefore(watch('startDate'));
                                            return !watch().noEndDate && valid === true
                                                ? t('validation:invalidDate')
                                                : undefined;
                                        },
                                    }}
                                    render={({ field }) => (
                                        <Box>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    label={t('permits:text.endDate')}
                                                    value={watch().endDate === undefined ? null : field.value}
                                                    minDate={watch().startDate ?? new Date()}
                                                    components={{
                                                        OpenPickerIcon: CalendarIcon,
                                                    }}
                                                    inputFormat="dd/MM/yyyy"
                                                    disabled={watch().startDate === undefined || watch().noEndDate}
                                                    onChange={field.onChange}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            fullWidth={true}
                                                            sx={{
                                                                '& .MuiInputBase-input': {
                                                                    caretColor: 'transparent',
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                            {errors.endDate && (
                                                <Typography variant="body2" color={color.error}>
                                                    {errors.endDate.message}
                                                </Typography>
                                            )}
                                        </Box>
                                    )}
                                />
                            </Grid>
                            <Grid xs={1} sm={2.5} flexGrow={{ xs: 1, md: 0 }}>
                                <Stack justifyContent={'center'} justifyItems={'center'} alignItems={'center'}>
                                    <Controller
                                        control={control}
                                        name="noEndDate"
                                        render={({ field }) => (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        size="small"
                                                        icon={<CheckBoxIcon />}
                                                        checkedIcon={<Checked />}
                                                        onChange={(checked) => {
                                                            resetField('endDate');
                                                            field.onChange(checked);
                                                        }}
                                                        checked={field.value}
                                                    />
                                                }
                                                label={
                                                    <Typography mt={'2px'} display={{ xs: 'none', sm: 'block' }}>
                                                        {t('permits:text.noEndDate')}
                                                    </Typography>
                                                }
                                            />
                                        )}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            xs={12}
                            mt={isMobileSM ? 2 : 3}
                            direction={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        >
                            <Grid md={3} xs={2}>
                                <Button
                                    disabled={isLoading}
                                    fullWidth
                                    variant="cancel"
                                    onClick={() => {
                                        handleClose();
                                        clearErrors();
                                        reset();
                                    }}
                                >
                                    {t('action:cancel')}
                                </Button>
                            </Grid>
                            <Grid md={3} xs={2}>
                                <Button
                                    disabled={isLoading}
                                    fullWidth
                                    variant="contained"
                                    onClick={handleSubmit((data: BayTemporary) => {
                                        handleSave(data).then(() => {
                                            clearErrors();
                                            reset();
                                        });
                                    })}
                                >
                                    {isLoading ? <CircularProgress size={16} /> : t('action:save')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Stack>
                </Fade>
            }
        />
    );
}
