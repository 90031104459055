import { Box, Grid } from '@mui/material';
import React, { useContext } from 'react';
import LoadingCircular from '../../../components/LoadingCircular';
import { LanguageContext } from '../../../contexts/useLanguage';
import { ChartShowType } from '../../../models/ChartShowType';
import { DailyVisitsStatistic } from '../../../models/DailyVisitsStatistic';
import { ReportType } from '../../../models/ReportType';
import { GridBorderRadius, GridWithGray100 } from '../styled';
import ChartTitleContent from './ChartTitleContent';
import ScrollContainer from 'react-indiana-drag-scroll';
import color from '../../../config/Colors';
import useMedia from '../../../hooks/useMedia';
import BarChartEVDailyVisits from '../../../components/chart/BarChartEVDailyVisits';
import EVSummary from './EVSummary';

type Props = {
    chartShowType?: ChartShowType;
    minHeightChart?: string;
    dailyVisitsStatistic: DailyVisitsStatistic;
    reportType?: ReportType;
};

export default function EVDailyVisits(props: Props) {
    const { t } = useContext(LanguageContext);
    const { isMobileSM } = useMedia();
    const dataLength =
        (props.dailyVisitsStatistic &&
            props.dailyVisitsStatistic.dailyData &&
            props.dailyVisitsStatistic.dailyData.length) ||
        0;

    return props.dailyVisitsStatistic.dailyData ? (
        <GridWithGray100
            container
            item
            md={12}
            sm={12}
            xs={12}
            borderRadius={'10px'}
            mt={props.chartShowType === ChartShowType.dashboard ? { xs: 2, sm: 3, md: 4 } : 0}
        >
            <GridBorderRadius container item md={8} sm={12} xs={12} gap={2}>
                <ChartTitleContent
                    key={'1'}
                    title={t('dashboard:text.dailyEVVisits')}
                    desc={t('dashboard:text.EVDailyVisitsDesc')}
                    buttonLabel={t('dashboard:button.viewInsight')}
                    reportType={props.reportType}
                    chartShowType={props.chartShowType}
                />

                <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    // height={{ xs: '260px', sm: '300px', md: '325px' }}
                    sx={{
                        '& .chart-scroll-bar': {
                            '&::-webkit-scrollbar': {
                                height: '5px',
                            },

                            '&::-webkit-scrollbar-track': {
                                WebkitBoxShadow: 'inset 0 0 0px rgba(0, 0, 0, 0)',
                            },

                            '&::-webkit-scrollbar-thumb': {
                                height: '5px',
                                backgroundColor: color.primary,
                                borderRadius: '3px',
                            },

                            '&::-webkit-scrollbar-thumb:hover': {
                                cursor: 'pointer',
                                backgroundColor: color.darkPrimary,
                            },
                            '&::-webkit-scrollbar:vertical': {
                                display: 'none',
                            },
                        },
                    }}
                >
                    <Box position={'relative'} width={'100%'} height={'100%'}>
                        <ScrollContainer
                            className="container chart-scroll-bar"
                            style={{ height: '100%', width: '100%', paddingBottom: '5px', position: 'relative' }}
                            hideScrollbars={false}
                        >
                            <Box
                                width={{
                                    lg: dataLength > 15 ? `${dataLength * 70}px` : '100%',
                                    md: dataLength > 10 ? `${dataLength * 70}px` : '100%',
                                    sm: dataLength > 7 ? `${dataLength * 70}px` : '100%',
                                    xs: dataLength > 4 ? `${dataLength * 70}px` : '100%',
                                }}
                                height={'100%'}
                                pb={1}
                                position="relative"
                            >
                                <BarChartEVDailyVisits anprMatchesDailyStats={props.dailyVisitsStatistic.dailyData} />
                                {/* <LineChart key={1} anprMatchesDailyStats={props.dailyVisitsStatistic.dailyData} /> */}
                            </Box>
                        </ScrollContainer>
                    </Box>
                </Grid>
            </GridBorderRadius>

            <EVSummary
                chartShowType={props.chartShowType || ChartShowType.dashboard}
                summaryData={props.dailyVisitsStatistic.summaryData}
            />
        </GridWithGray100>
    ) : (
        <LoadingCircular />
    );
}
