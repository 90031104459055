import { Grid, TextField, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import color from '../../../../../../config/Colors';
import { LanguageContext } from '../../../../../../contexts/useLanguage';
import { RecurProps } from '../../recurringType/DayRecurring';
import PlusIcon from '../../svg/PlusIcon';
import { PermitFromData } from '../../../hooks/useUpsertPermit';

export default function UpsertPermitComment({ isEdit, parentForm }: RecurProps) {
    const { indexForm } = parentForm;
    const { control } = useFormContext<PermitFromData>();
    const { comment } = useWatch({
        control: control,
        name: `permits.${indexForm}`,
    });
    const { t } = useContext(LanguageContext);

    const [isOpenComment, setIsOpenComment] = useState<boolean>(comment === '' || comment === null ? false : true);

    return !isOpenComment ? (
        <Typography
            variant="body1"
            color={color.priText}
            sx={{
                cursor: 'pointer',
                display: 'flex',
                direction: 'row',
                alignItems: 'center',
                '&:hover': {
                    textDecoration: 'underline',
                },
                mt: 2,
            }}
            onClick={() => setIsOpenComment(true)}
        >
            <PlusIcon
                style={{
                    width: '16px',
                    height: '16px',
                    fill: color.priText,
                    marginRight: '5px',
                }}
            />{' '}
            {t('permits:text.addComment')}
        </Typography>
    ) : (
        <Grid item container mt={2}>
            <Controller
                control={control}
                name={`permits.${indexForm}.comment`}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        error={!!error?.message}
                        helperText={error?.message}
                        fullWidth
                        size="small"
                        label={t('permits:text.comment')}
                        placeholder={t('permits:text.enterComment')}
                        onChange={(e) => {
                            field.onChange(e.target.value);
                        }}
                        inputProps={{
                            maxLength: 500,
                        }}
                    />
                )}
            />
        </Grid>
    );
}
