import React, { SVGProps } from 'react';

type Props = {} & SVGProps<SVGSVGElement>;

export default function Reports(props: Props) {
    return (
        <svg
            {...props}
            width="20"
            className="svg-icon-stroke"
            stroke="#85858A"
            height="20"
            viewBox="0 0 16 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.5098 1.51092H4.35142C2.42604 1.50352 0.847789 3.03826 0.802431 4.96271V14.8793C0.759851 16.8352 2.31033 18.4561 4.26626 18.4996C4.29495 18.4996 4.32272 18.5005 4.35142 18.4996H11.7465C13.6849 18.4209 15.2122 16.8195 15.1983 14.8793V6.39471L10.5098 1.51092Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.2671 1.5V4.19275C10.2671 5.50719 11.3297 6.57262 12.6442 6.57633H15.1944"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M10.0932 13.1712H5.0946" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.20039 9.69766H5.09387" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}
