import { Box, Button, Grid, SxProps, Theme, Typography, styled } from '@mui/material';
import React, { useContext } from 'react';
import color from '../../config/Colors';
import { BsFilter } from 'react-icons/bs';
import { LanguageContext } from '../../contexts/useLanguage';

type BaseFilterProps = {
    rightTitleContent?: React.ReactNode;
    handleResetFilter: () => void;
    filterChild?: React.ReactNode;
    baseStyle?: SxProps<Theme>;
};

export default function FilterRoot(props: BaseFilterProps) {
    const { t } = useContext(LanguageContext);

    return (
        <BoxRadiusGrey sx={props.baseStyle}>
            <Grid container gap={2}>
                <Grid container spacing={2} alignItems={'center'}>
                    <Grid item md={8}>
                        <GFlexRowCenter item gap={2}>
                            <BsFilter style={{ color: color.grey600, fontSize: '18px' }} />
                            <Typography>{t('dashboard:button.filter')}</Typography>
                            <Button variant="cancelSmall" onClick={props.handleResetFilter}>
                                {t('dashboard:button.resetFilter')}
                            </Button>
                        </GFlexRowCenter>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        {props.rightTitleContent}
                    </Grid>
                </Grid>

                {props.filterChild}
            </Grid>
        </BoxRadiusGrey>
    );
}

export const BoxRadiusGrey = styled(Box)({
    width: '100%',
    background: color.grey100,
    borderRadius: 10,
    padding: 16,
});

export const GFlexRow = styled(Grid)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

export const GFlexRowCenter = styled(Grid)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});
