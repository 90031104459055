import { Button, Stack, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { LanguageContext } from '../../../contexts/useLanguage';

type Props = {
    setModalState: () => void;
};

export default function ToolbarTitleDatePicker({ setModalState }: Props) {
    const { t } = useContext(LanguageContext);
    return (
        <Stack width={'280px'} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant="body1">{t('permits:text.selectDate')}</Typography>
            <Button variant="cancelSmall" sx={{ ml: 'px' }} onClick={setModalState}>
                {t('permits:button.reset')}
            </Button>
        </Stack>
    );
}
