import { TableBody, TableCell, TableRow } from '@mui/material';
import { formatNumber } from '../../../helpers/FormatCurrency';
import { StatisticItemByZone } from '../../../models/StatisticItemByZone';

type Props = {
    dataPCNByZones: StatisticItemByZone[];
};

export default function TablePCNByZones(props: Props) {
    return (
        <TableBody>
            {props.dataPCNByZones &&
                props.dataPCNByZones.length > 0 &&
                props.dataPCNByZones.map((item, index) => {
                    return (
                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" align="center" scope="row">
                                {item.label}
                            </TableCell>
                            <TableCell align="center">{formatNumber.format(item.amount)}</TableCell>
                            <TableCell align="center">
                                {item.serviceTypes &&
                                    item.serviceTypes.length > 0 &&
                                    item.serviceTypes.map((type, index) => {
                                        const isLast: boolean = index == item.serviceTypes.length - 1;
                                        return isLast ? type : type + '/';
                                    })}
                            </TableCell>
                        </TableRow>
                    );
                })}
        </TableBody>
    );
}
