import { Avatar, Button, Grid, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React, { useContext, useState } from 'react';
import color from '../../../config/Colors';
import { LanguageContext } from '../../../contexts/useLanguage';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { AuthContext } from '../../../hooks/useAuth';
import { Account } from '../../../models/Account';
import { LoadingButton } from '@mui/lab';
import { accountController, userController } from '../../../controllers';
import LabelWithRequired from '../../../components/LabelWithRequired';
import toast from 'react-hot-toast';

type Props = {
    handleClose: () => void;
};

export default function UpdateForm({ handleClose }: Props) {
    const { t } = useContext(LanguageContext);
    const { account, setState, getAvatar } = useContext(AuthContext);

    const RegisterSchema = Yup.object().shape({
        firstName: Yup.string().required(t('validation:firstNameIsRequired')),
        lastName: Yup.string().required(t('validation:lastNameIsRequired')),
    });

    const formik = useFormik({
        initialValues: {
            password: account?.password,
            firstName: account?.firstName,
            email: account?.email,
            lastName: account?.lastName,
            phoneNumber: account?.phoneNumber,
        },
        validationSchema: RegisterSchema,
        onSubmit: (values) => {
            const accountUpdate: Account = {
                ...account!,
                firstName: values.firstName,
                lastName: values.lastName,
                phoneNumber: values.phoneNumber!,
            };

            userController
                .upsert(accountUpdate)
                .then((res1) => {
                    setState((prev) => ({ ...prev, account: res1 }));
                    toast.success(t('notification:accountUpdatedSuccessfully'));
                    handleClose();
                })
                .catch((err) => {
                    if (err.response.status !== 500) toast.error(t('notification:fail'));
                });
            const formData = new FormData();
            if (!imgTemp) {
                return;
            }
            formData.append('file', imgTemp);
            accountController.upsertAvatar(formData).then((res) => {
                setState((prev) => ({ ...prev, userAvatar: getAvatar(Number(account?.accountId)) }));
            });
        },
    });
    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [imgTemp, setImgTemp] = useState<any>(null);
    const [img, setImg] = useState<any>(getAvatar(Number(account?.accountId!)));
    const handleFileUpload = (e: any) => {
        setIsLoading(true);
        setImg(URL.createObjectURL(e.target.files[0]));
        setImgTemp(e.target.files[0]);
        setIsLoading(false);
    };

    return (
        <Box p={'40px 24px'}>
            <FormikProvider value={formik}>
                <Form noValidate onSubmit={handleSubmit}>
                    <Grid
                        container
                        gap={4}
                        sx={{
                            '& .MuiFormHelperText-root': {
                                mx: 0,
                                fontSize: '12px',
                            },
                        }}
                    >
                        <Grid item xs={12} gap={4} bgcolor={color.grey100} borderRadius={'10px'} p={'10px'}>
                            <Grid
                                item
                                xs={12}
                                display={'flex'}
                                flexDirection={'row'}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                <Avatar
                                    sx={{ width: '60px', height: '60px', border: `8px solid ${color.grey200}` }}
                                    alt={account?.firstName || 'A'}
                                    src={img}
                                />

                                <LoadingButton
                                    loading={isLoading}
                                    loadingPosition="start"
                                    startIcon={<></>}
                                    variant="outlined"
                                    color="inherit"
                                    component="label"
                                    size="small"
                                    sx={{ ml: 4 }}
                                >
                                    {t('accountPage:button.uploadAvatar')}
                                    <input hidden accept="image/*" type="file" onChange={handleFileUpload} />
                                </LoadingButton>
                            </Grid>
                        </Grid>
                        <Grid item container xs={12}>
                            <Grid item container xs={12} spacing={1}>
                                <Grid item xs={6}>
                                    <TextField
                                        id="outlined-required"
                                        label={<LabelWithRequired label={t('accountPage:text.firstName')} />}
                                        placeholder={t('input:enterFirstName')}
                                        sx={{}}
                                        inputProps={{
                                            maxLength: 50,
                                        }}
                                        fullWidth
                                        size="small"
                                        {...getFieldProps('firstName')}
                                        error={Boolean(touched.firstName && errors.firstName)}
                                        helperText={touched.firstName && errors.firstName}
                                        onChange={formik.handleChange}
                                        value={formik.values.firstName}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="outlined-required"
                                        label={<LabelWithRequired label={t('accountPage:text.lastName')} />}
                                        placeholder={t('input:enterLastName')}
                                        sx={{}}
                                        inputProps={{
                                            maxLength: 50,
                                        }}
                                        fullWidth
                                        size="small"
                                        {...getFieldProps('lastName')}
                                        error={Boolean(touched.lastName && errors.lastName)}
                                        helperText={touched.lastName && errors.lastName}
                                        onChange={formik.handleChange}
                                        value={formik.values.lastName}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} mt={3}>
                                <TextField
                                    id="outlined-required"
                                    label={t('accountPage:text.email')}
                                    sx={{}}
                                    inputProps={{
                                        maxLength: 320,
                                    }}
                                    fullWidth
                                    disabled
                                    {...getFieldProps('email')}
                                    value={formik.values.email}
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} mt={3}>
                                <TextField
                                    id="outlined-required"
                                    label={t('accountPage:text.phoneNumber')}
                                    placeholder={t('input:enterPhoneNumber')}
                                    sx={{}}
                                    fullWidth
                                    size="small"
                                    {...getFieldProps('phoneNumber')}
                                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                                    // type={'number'}
                                    inputProps={{
                                        maxLength: 11,
                                    }}
                                    helperText={touched.phoneNumber && errors.phoneNumber}
                                    onChange={formik.handleChange}
                                    value={formik.values.phoneNumber}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            display={'flex'}
                            flexDirection={'row'}
                            justifyContent={'space-between'}
                            mt={1}
                        >
                            <Button sx={{ minWidth: '150px' }} variant="cancel" onClick={handleClose}>
                                {t('action:cancel')}
                            </Button>
                            {/* <Button sx={{ minWidth: '150px' }} type={'submit'} variant="contained">
                                {t('accountPage:button.save')}
                            </Button> */}
                            <LoadingButton
                                loading={isSubmitting}
                                loadingPosition="start"
                                startIcon={<></>}
                                variant="contained"
                                sx={{ minWidth: '150px' }}
                                type="submit"
                            >
                                {t('action:save')}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Form>
            </FormikProvider>
        </Box>
    );
}
