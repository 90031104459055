import { Box, Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import color from '../../../config/Colors';
import { intelliparkLogo, ukpcLogo } from '../../../config/ImageFromEnv';
import { LanguageContext } from '../../../contexts/useLanguage';
import useMedia from '../../../hooks/useMedia';
import moment from 'moment';
import { appConfig } from '../../../config/AppConfig';

export default function Footer() {
    const { t } = useContext(LanguageContext);
    const { isMobileSM, isMDOnly } = useMedia();

    const year = moment().format('YYYY');
    const companyInfo = appConfig.companyInfo;
    return (
        <Box
            width={'100%'}
            mt={5}
            sx={{ background: color.white, height: 'max-content', borderTop: `1px solid ${color.grey300}` }}
        >
            <Stack
                p={2}
                spacing={1}
                direction={isMobileSM ? 'column' : 'row'}
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography
                    color={color.textPrimary}
                    variant="body1"
                    fontWeight={400}
                    align={isMobileSM ? 'center' : 'left'}
                    fontSize={{ xs: '12px', sm: '12px', md: '14px' }}
                >
                    {' '}
                    {/* {t('footer:text.line1',)} <br /> {t('footer:text.line2')} */}
                    {t('footer:textGE.line1', { year: year, companyName: companyInfo.name.replaceAll('_', ' ') })} <br />{' '}
                    {companyInfo.address.replaceAll('_', ' ')}
                </Typography>
                {/* <Stack direction="row" alignItems="center">
                    <Typography
                        color={color.textPrimary}
                        variant="body1"
                        fontWeight={400}
                        fontSize={{ xs: '12px', sm: '12px', md: '14px' }}
                        component={'pre'}
                    >
                        {t('footer:text.poweredBy')}
                    </Typography>
                    <Box
                        sx={{
                            width: 120,
                            height: 30,
                            background: `url(${intelliparkLogo})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                        }}
                    />
                </Stack> */}
            </Stack>
        </Box>
    );
}
