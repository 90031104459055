class FormatNumber {
    format = (value: number) => {
        if (!value || value === 0) return 0;
        if ((value * 100) % 100 === 0) return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        else return String(value.toFixed(1)).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    formatEURO = (value: number) => {
        if (!value || value === 0) return 0;
        if ((value * 100) % 100 === 0) return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        else return String(value.toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };
}

export const formatNumber = new FormatNumber();
