/* eslint-disable eqeqeq */
import {
    Box,
    ClickAwayListener,
    IconButton,
    Stack,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import color from '../../../../config/Colors';
import { LanguageContext } from '../../../../contexts/useLanguage';
import useMedia from '../../../../hooks/useMedia';
import { RawUploadPermitStatus } from '../../../../models/permits/ExcelRawPermit';
import ExistedIcon from '../../uploadPermits/components/svg/ExistedIcon';
import InvalidIcon from '../../uploadPermits/components/svg/InvalidIcon';
import ValidIcon from '../../uploadPermits/components/svg/ValidIcon';
import { CheckingVRNItem } from '../hooks/useUpsertPermit';
import { VscInfo } from 'react-icons/vsc';
import { ArrayPagination } from '../../../../helpers';

export default function TableRowUpsertVRNChecking({ vrnCheckingList }: { vrnCheckingList: CheckingVRNItem[] }) {
    const { t } = useContext(LanguageContext);
    const { isSMOnly, isXSOnly } = useMedia();
    return (
        <TableBody>
            {vrnCheckingList.length > 0 &&
                vrnCheckingList.map((item, index) => {
                    return (
                        <TableRow key={index}>
                            <TableCell
                                component="th"
                                align="center"
                                scope="row"
                                sx={{ fontWeight: 500, width: { xs: '20%', sm: '15%', md: '15%' } }}
                            >
                                {t('permits:text.permit') + ' '}
                                {item.index}
                            </TableCell>
                            <TableCell align="center" width={'15%'} sx={{ fontWeight: 500 }}>
                                {item.vrn}
                            </TableCell>
                            <TableCell align="center" width={'20%'}>
                                <Stack
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        width={'90px'}
                                        display={{ xs: 'none', sm: 'block' }}
                                        color={
                                            item.status == RawUploadPermitStatus.Valid
                                                ? color.success
                                                : item.status == RawUploadPermitStatus.Existed
                                                ? '#FFB600'
                                                : color.danger
                                        }
                                        fontWeight={500}
                                    >
                                        {item.status == RawUploadPermitStatus.Valid
                                            ? t('action:valid')
                                            : item.status == RawUploadPermitStatus.Existed
                                            ? t('action:existed')
                                            : t('action:invalid')}
                                    </Typography>

                                    {item.status == RawUploadPermitStatus.Valid ? (
                                        <ValidIcon />
                                    ) : item.status == RawUploadPermitStatus.Existed ? (
                                        <ExistedIcon />
                                    ) : (
                                        <InvalidIcon />
                                    )}
                                </Stack>
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{ fontWeight: 500, width: { xs: '20%', sm: '30%', md: '40%' } }}
                            >
                                {item.reason ? (
                                    <TooltipRender
                                        string={item.reason}
                                        key={index}
                                        isXsOnly={isXSOnly}
                                        isSmOnly={isSMOnly}
                                    />
                                ) : (
                                    <></>
                                )}
                            </TableCell>
                        </TableRow>
                    );
                })}
        </TableBody>
    );
}

type PropsChild = {
    string: string;
    isXsOnly: boolean;
    isSmOnly: boolean;
};

const TooltipRender = ({ string, isXsOnly, isSmOnly }: PropsChild) => {
    const [open, setOpen] = useState<boolean>(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    return (
        <>
            {isXsOnly ? (
                <ClickAwayListener onClickAway={handleTooltipClose}>
                    <Box sx={{ display: { xs: 'block', sm: 'none', md: 'none' } }}>
                        <Tooltip
                            PopperProps={{
                                disablePortal: true,
                                sx: {
                                    // zIndex: 999999,
                                    // background: 'black',
                                    color: 'white',
                                },
                            }}
                            placement="top"
                            onClose={() => handleTooltipClose}
                            open={open}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={string}
                        >
                            <IconButton onClick={handleTooltipOpen} sx={{ padding: 0 }}>
                                <VscInfo fontSize={'22px'} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </ClickAwayListener>
            ) : isSmOnly ? (
                <Tooltip title={string} sx={{ display: { xs: 'none', sm: 'block', md: 'none' } }}>
                    <IconButton onClick={handleTooltipOpen} sx={{ padding: 0 }}>
                        <VscInfo fontSize={'22px'} />
                    </IconButton>
                </Tooltip>
            ) : (
                <Typography sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }} variant="body1">
                    {string}
                </Typography>
            )}
        </>
    );
};
