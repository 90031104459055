import { TableBody, TableCell, TableRow } from '@mui/material';
import { formatNumber } from '../../../helpers/FormatCurrency';
import { StatisticItemByZone } from '../../../models/StatisticItemByZone';
import { StatisticItem } from '../../../models/StatisticItems';

type Props = {
    dataPCNByReason: StatisticItem[];
};

export default function TablePCNByReasons(props: Props) {
    return (
        <TableBody>
            {props.dataPCNByReason &&
                props.dataPCNByReason.length > 0 &&
                props.dataPCNByReason.map((item, index) => {
                    return (
                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" align="center" scope="row" width={'60%'}>
                                {item.label}
                            </TableCell>
                            <TableCell align="center" width={'40%'}>
                                {formatNumber.format(item.amount)}
                            </TableCell>
                        </TableRow>
                    );
                })}
        </TableBody>
    );
}
