import { CircularProgress, Stack } from '@mui/material';
import React from 'react';
import color from '../config/Colors';
type Props = {
    minHeight?: string | number;
};
export default function LoadingCircular(props: Props) {
    return (
        <Stack width={'100%'} p={2} minHeight={props.minHeight ? props.minHeight : '150px'}>
            <CircularProgress sx={{ margin: 'auto', color: color.priText }} />
        </Stack>
    );
}
