import { TableBody, TableCell, TableRow } from '@mui/material';
import { formatNumber } from '../../../helpers/FormatCurrency';
import { formatDate } from '../../../helpers/moment';
import { GrossRevenueByLT, GrossRevenueByLTStatistic } from '../../../models/grossRevenue/GrossRevenueByLT';
import { GrossRevenueByType } from '../../../models/grossRevenue/GrossRevenueByType';

type Props = {
    grossRevenueByLTStatistic: GrossRevenueByLTStatistic;
};

export default function TableRowsActiveLTs(props: Props) {
    return (
        <TableBody>
            {props.grossRevenueByLTStatistic.dailyData &&
                props.grossRevenueByLTStatistic.dailyData.map((row, index) => {
                    return (
                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell key={index} align="center" component="th" scope="row">
                                {formatDate.getLocalDay(row.date)}
                            </TableCell>
                            <TableCell align="center">£{formatNumber.formatEURO(row.total!)}</TableCell>
                            {/* <TableCell align="center">£{formatNumber.formatEURO(row.totalLongTerm!)}</TableCell> */}
                        </TableRow>
                    );
                })}
        </TableBody>
    );
}
