import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { Button, InputAdornment, TextField } from '@mui/material';
import { BsPlus } from 'react-icons/bs';
import color from '../../../config/Colors';
import IconSearch from '../../companyLayout/components/svg/IconSearch';
import { LanguageContext } from '../../../contexts/useLanguage';

type Props = {
    onChangeSearch?: (text: string) => void;
    isShowButton?: boolean;
    buttonFunction?: () => void;
    openElement?: React.ReactNode;
    MenuItems?: React.ReactNode;
    isOpen?: any;
    anchorEl: null | HTMLElement;
    setAnchor: (val: any) => void;
    isSearch?: boolean;
    minWidthMenu?: string;
    searchValue?: string;
};

export default function AdvancedFilter(props: Props) {
    const { t } = React.useContext(LanguageContext);
    const open = Boolean(props.anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        props.setAnchor(event.currentTarget);
    };
    const handleClose = () => {
        props.setAnchor(null);
    };

    const onChangeSearch = (e: any) => {
        props.onChangeSearch && props.onChangeSearch(e.target.value);
    };

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        e.stopPropagation();
    };
    return (
        <React.Fragment>
            <Box
                onClick={handleClick}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                sx={{
                    display: 'inline-block',
                }}
            >
                {props.openElement || (
                    <Button variant="outlined" sx={{ padding: '5px', minWidth: '32px' }}>
                        <BsPlus fontSize={'20px'} />
                    </Button>
                )}
            </Box>

            <Menu
                anchorEl={props.anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        mt: 1,
                        padding: 1,
                        border: `1px solid ${color.grey300}`,
                        '& .MuiMenuItem-root': {
                            '&:hover': {
                                background: 'none',
                            },
                        },
                        minWidth: '150px',
                    },
                }}
                MenuListProps={{
                    sx: {
                        padding: 0,
                        transition: 'none !important',
                        '&:focus': {
                            backgroundColor: '#FFF',
                        },
                    },
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                {props.isSearch == true ? (
                    <MenuItem sx={{ padding: 0, mt: '2px' }} disableRipple={true} onKeyDown={(e: any) => onKeyDown(e)}>
                        <TextField
                            autoFocus={true}
                            fullWidth
                            placeholder={t('companies:input.searchByName')}
                            onChange={onChangeSearch}
                            value={props.searchValue || ''}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <IconSearch />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </MenuItem>
                ) : (
                    ''
                )}
                <Box sx={{ maxHeight: '250px', overflowY: 'auto', mt: '5px' }}>{props.MenuItems}</Box>
                {props.isShowButton == false ? (
                    ''
                ) : (
                    <MenuItem
                        sx={{ display: 'flex', justifyContent: 'right', padding: 0, mt: '5px' }}
                        disableRipple={true}
                    >
                        <Button variant="contained" size="small" onClick={() => {}}>
                            {t('permits:button.addFields')}
                        </Button>
                    </MenuItem>
                )}
            </Menu>
        </React.Fragment>
    );
}
