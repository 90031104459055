import React, { SVGProps } from 'react';
import color from '../../config/Colors';

type Props = {} & SVGProps<SVGSVGElement>;

export default function GeneralInfoIcon(props: Props) {
    return (
        <svg
            {...props}
            className={'svg-icon-stroke'}
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            stroke="#85858A"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M11.0656 11.0142H5.96729" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.0656 8.05796H5.96729" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.9127 5.10856H5.96729" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.2014 1.5C11.2014 1.5 5.78042 1.50282 5.77194 1.50282C3.823 1.51483 2.61621 2.79718 2.61621 4.75318V11.2468C2.61621 13.2127 3.83218 14.5 5.79807 14.5C5.79807 14.5 11.2184 14.4979 11.2276 14.4979C13.1765 14.4859 14.384 13.2028 14.384 11.2468V4.75318C14.384 2.78729 13.1673 1.5 11.2014 1.5Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
