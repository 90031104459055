import React, { useContext } from 'react';
import { LanguageContext } from '../contexts/useLanguage';
import { AverageLengthStatistic } from '../models/AverageLengthStatistic';
import { DailyVisitsStatistic } from '../models/DailyVisitsStatistic';

export default function useConvertLabel() {
    const { t } = useContext(LanguageContext);

    const convertAverageLength = (averageLengths: AverageLengthStatistic[]) => {
        const listLabel = [
            `0 - 1 ${t('reportPage:text.hour')}`,
            `1 - 2 ${t('reportPage:text.hours')}`,
            `2 - 3 ${t('reportPage:text.hours')}`,
            `3 - 4 ${t('reportPage:text.hours')}`,
            `4 - 5 ${t('reportPage:text.hours')}`,
            `5 - 6 ${t('reportPage:text.hours')}`,
            `${t('reportPage:text.moreThan6Hours')}`,
        ];

        averageLengths.forEach((element, index) => {
            element.hours = listLabel[index];
        });
        return averageLengths;
    };

    return { convertAverageLength };
}
