import { Box, Grid } from '@mui/material';
import React, { useContext } from 'react';
import NoDataToDisplay from '../../../components/NoDataToDisplay';
import { LanguageContext } from '../../../contexts/useLanguage';
import { CapacityAnalysis } from '../../../models/CapacityAnalysis';
import { ChartShowType } from '../../../models/ChartShowType';
import { ReportType } from '../../../models/ReportType';
import { GridBorderRadius } from '../styled';
import ChartTitleContent from './ChartTitleContent';
import ScrollContainer from 'react-indiana-drag-scroll';
import color from '../../../config/Colors';
import EVLengthOfVisitsLabel from './EVLengthOfVisitsLabel';
import BarChartEVLengthOfVisits from '../../../components/chart/BarChartEVLengthOfVisits';

type Props = {
    chartShowType?: ChartShowType;
    reportType?: ReportType;
    capacityAnalysis: CapacityAnalysis;
};

export default function EVLengthOfVisits(props: Props) {
    const { t } = useContext(LanguageContext);
    const dataLength =
        (props.capacityAnalysis && props.capacityAnalysis.dailyData && props.capacityAnalysis.dailyData.length) || 0;

    return (
        <Grid container padding={props.chartShowType === ChartShowType.report ? 0 : 0}>
            <GridBorderRadius
                container
                item
                xs={12}
                gap={2}
                mt={props.chartShowType === ChartShowType.report ? 0 : { xs: 2, sm: 3, md: 4 }}
            >
                <Grid item xs={12}>
                    <ChartTitleContent
                        key={'3'}
                        title={t('dashboard:text.averageLengthOfEVVisits')}
                        // desc={t('dashboard:text.averageLengthOfEVVisitsDesc')}
                        buttonLabel={t('dashboard:button.viewInsight')}
                        chartShowType={props.chartShowType || ChartShowType.dashboard}
                        reportType={props.reportType || undefined}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    container
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '16px 0px 16px 0',
                        position: 'relative',
                        '& .chart-scroll-bar': {
                            '&::-webkit-scrollbar': {
                                height: '5px',
                            },

                            '&::-webkit-scrollbar-track': {
                                WebkitBoxShadow: 'inset 0 0 0px rgba(0, 0, 0, 0)',
                            },

                            '&::-webkit-scrollbar-thumb': {
                                height: '5px',
                                backgroundColor: color.primary,
                                borderRadius: '3px',
                            },

                            '&::-webkit-scrollbar-thumb:hover': {
                                cursor: 'pointer',
                                backgroundColor: color.darkPrimary,
                            },
                            '&::-webkit-scrollbar:vertical': {
                                display: 'none',
                            },
                        },
                    }}
                >
                    {props.capacityAnalysis.dailyData ? <EVLengthOfVisitsLabel /> : <></>}
                    <Box width={'100%'} style={{ minHeight: '250px' }}>
                        <ScrollContainer
                            className="container chart-scroll-bar"
                            style={{ height: '100%', width: '100%', paddingBottom: '5px' }}
                            hideScrollbars={false}
                        >
                            {props.capacityAnalysis.dailyData ? (
                                <Box
                                    width={{
                                        lg: dataLength > 15 ? `${dataLength * 130}px` : '100%',
                                        md: dataLength > 10 ? `${dataLength * 120}px` : '100%',
                                        sm: dataLength > 7 ? `${dataLength * 120}px` : '100%',
                                        xs: dataLength > 4 ? `${dataLength * 110}px` : '100%',
                                    }}
                                    height={'245px'}
                                    pb={6}
                                >
                                    <BarChartEVLengthOfVisits capacityAnalysis={props.capacityAnalysis.dailyData} />
                                </Box>
                            ) : (
                                <Box width={'100%'} mt={-2}>
                                    <NoDataToDisplay text={t('companies:text.noDataToDisplay')} />
                                </Box>
                            )}
                        </ScrollContainer>
                    </Box>
                </Grid>
            </GridBorderRadius>
        </Grid>
    );
}
