import { BaseModel } from '../BaseModel';
import { PermitStatus } from './FilterPermits';
import { PermitRecurring, RecurringType } from './PermitRecurring';
import { PermitScope } from './PermitScope';

export interface Permit extends BaseModel {
    type: string;
    vrnNumber: string;
    validateFrom?: Date | null;
    validateTo?: Date | null;
    validateToAfterRecurringTime?: number;
    validateToType: ValidateToType;
    firstName: string;
    lastName: string;
    tenantId?: number;
    scopes: PermitScope[];
    permitRecurring: PermitRecurring[];
    bayNumber?: string;
    comment?: string;
    permitType?: number;
    requestedBy?: string;
    status?: PermitStatus;
    vehicleId?: number;

    fromHour?: Date | null;
    toHour?: Date | null;
    isCheckedAllDay?: boolean;
    isValid?: boolean;
    permitRecurringType?: RecurringType;
    permitRecurringEvery?: number;
    weekRecurringDays?: number[];
    subPermitRecurringType?: RecurringType;
}

// const initValue = {
//     permitType: itemState.permit.type,
//     vrnNumber: itemState.permit.vrnNumber,
//     firstName: itemState.permit.firstName,
//     lastName: itemState.permit.lastName,
//     fromHour: convertHour.getHoursAndMinutesFromTimeNumber(itemState.permit.permitRecurring[0].fromHour),
//     toHour: convertHour.getHoursAndMinutesFromTimeNumber(itemState.permit.permitRecurring[0].toHour),
//     validateFrom: getValidateFrom(),
//     validateTo: getValidateTo(),
//     endStatus: ValidateToType.infinite as number,
//     endAfterValue: itemState.permit.validateToAfterRecurringTime || 1,
//     bayNumber: itemState.permit.bayNumber,
//     comment: itemState.permit.comment,
//     requestedBy: itemState.permit.requestedBy,
// };

export enum ValidateToType {
    absoluteAt = 0,
    recurringTime = 1,
    infinite = 2,
}

export interface PermitListItem extends Permit {
    locationName?: string;
    zoneName?: string;
    scopeType?: ScopeType;
    scopeName?: string;
    tenantName?: string
}

export enum ScopeType {
    global = 0,
    company = 1,
    region = 2,
    location = 3,
    zone = 4,
    portfolio = 5,
    tenant = 6,
}
