import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { companiesController, locationController, zoneController } from '../controllers';
import { FilterCompany, Pagination } from '../models/BaseFilter';
import { Company, CompanyWithDetail } from '../models/Company';
import { Location, LocationWithDetail } from '../models/Location';
import Zone from '../models/Zone';
import { LoadingFilter, _initLoadingFilter } from './useFilter';

interface State {
    companyId?: number;
    companySelect?: Company;
}

export default function useCompanies() {
    const [state, setState] = useState<State>({
        companySelect: {} as any,
    });

    const [companyPaging, setCompanyPaging] = useState<Pagination<CompanyWithDetail>>({
        data: [],
        page: 1,
        pageSize: 6,
        total: 0,
        totalPage: 0,
    });
    const [pagingLoading, setPagingLoading] = useState(true);

    const { idCompany } = useParams();

    const setCompanyId = (idCompany: number) => {
        setState((prev) => ({ ...prev, companyId: idCompany }));
    };

    useEffect(() => {
        if (idCompany) {
            GetCompanyDetail(Number(idCompany));
        }
    }, [idCompany]);

    const GetCompanyDetail = (idCompany: number) => {
        companiesController.getCompaniesInfo(idCompany).then((res) => {
            setState((prev) => ({ ...prev, companySelect: res }));
        });
    };

    return {
        ...state,
        setState,
        setCompanyId,
        // zones,
        companyPaging,
        setCompanyPaging,
        pagingLoading,
        setPagingLoading,
    };
}

export const CompaniesContext = React.createContext<ReturnType<typeof useCompanies>>({} as any);
