import { styled, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import color from '../../config/Colors';
import { loginBackground } from '../../config/ImageFromEnv';

export const stylesLogin = makeStyles((theme: Theme) => ({
    loginContainer: {
        borderRadius: '9px',
        width: 500,
        // height: 500,
        background: 'rgba(255, 255, 255, 0.8)',
        [theme.breakpoints.down('md')]: {
            width: 400,
            // height: 400,
        },
        [theme.breakpoints.down('sm')]: {
            width: 320,
            // height: 400,
        },
    },
    stackContainer: {
        background: `url("${loginBackground}")`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '100%',
        height: '100vh',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
    },
    loginTitle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 2,
        background: '#101010',
        flex: 'none',
        order: '0',
        height: 64,
        alignSelf: 'stretch',
        flexGrow: '0',
        borderTopLeftRadius: '9px',
        borderTopRightRadius: '9px',
        border: `1px solid ${color.white}`,
    },
    loginForm: {},
}));

export const LinkTo = styled(Link)({
    color: 'inherit',
    textDecoration: 'none',
    "&:hover": {
        textDecoration: 'none',

    }
})
