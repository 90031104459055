import React, { SVGProps } from 'react';

type Props = {} & SVGProps<SVGSVGElement>;

export default function PermitsIcon(props: Props) {
    return (
        <svg
            {...props}
            width="20"
            height="14"
            className="svg-icon-fill"
            viewBox="0 0 20 14"
            fill="#85858A"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M20 9V10.5C20 11.0531 19.5531 11.5 19 11.5H17.9594C17.7219 12.9188 16.4875 14 15 14C13.5125 14 12.2781 12.9188 12.0406 11.5H7.95937C7.72187 12.9188 6.4875 14 5 14C3.5125 14 2.27938 12.9188 2.04156 11.5H1C0.447812 11.5 0 11.0531 0 10.5V7C0 6.15312 0.525313 5.43125 1.2675 5.1375L2.56875 1.88594C3.02437 0.746875 4.12813 0 5.35313 0H11.0375C11.95 0 12.7844 0.414375 13.3813 1.12594L16.5063 5.03125C18.475 5.25312 20 6.9625 20 8.97188V9ZM5.35313 2C4.94375 2 4.57813 2.22187 4.425 2.62812L3.47813 5H7V2H5.35313ZM8.5 5H13.9187L11.8188 2.375C11.6313 2.1375 11.3156 2 11.0375 2H8.5V5ZM16.4156 11.5C16.4687 11.3156 16.5 11.175 16.5 11C16.5 10.1719 15.8281 9.5 15 9.5C14.1719 9.5 13.5 10.1719 13.5 11C13.5 11.175 13.5031 11.3156 13.5844 11.5C13.7906 12.0813 14.3469 12.5 15 12.5C15.6531 12.5 16.2094 12.0813 16.4156 11.5ZM6.41563 11.5C6.46875 11.3156 6.5 11.175 6.5 11C6.5 10.1719 5.82812 9.5 5 9.5C4.17188 9.5 3.5 10.1719 3.5 11C3.5 11.175 3.50312 11.3156 3.58437 11.5C3.79062 12.0813 4.34688 12.5 5 12.5C5.65312 12.5 6.20938 12.0813 6.41563 11.5Z" />
        </svg>
    );
}
