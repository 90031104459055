import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LanguageContext } from '../../../../contexts/useLanguage';
import color from '../../../../config/Colors';
import { Box } from '@mui/material';

type Props = {
    handleAgree: () => void;
};

export default function useDialog(props: Props) {
    const [open, setOpen] = React.useState(false);
    const { t } = React.useContext(LanguageContext);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAgree = () => {
        props.handleAgree();
        handleClose();
    };
    const handleDisagree = () => {
        handleClose();
    };

    const RenderDialog = (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Box sx={{ padding: '15px' }}>
                    <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', color: color.danger }}>
                        {t('action:confirm')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {t('companies:title.areYouSureToDeleteThisEmployee')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button onClick={handleDisagree} variant="cancelSmall" sx={{ padding: '6px 16px' }}>
                            {t('action:cancel')}
                        </Button>
                        <Button
                            onClick={handleAgree}
                            autoFocus
                            size="small"
                            variant="errorOutlined"
                            sx={{ padding: '4px 16px' }}
                        >
                            {t('action:confirm')}
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div>
    );

    return { open, setOpen, RenderDialog, handleClickOpen };
}
