import { TableBody, TableCell, TableRow } from '@mui/material';
import { useContext } from 'react';
import { LanguageContext } from '../../../contexts/useLanguage';
import { formatNumber } from '../../../helpers/FormatCurrency';
import { RepeatVisit } from '../../../models/RepeatVisits';

type Props = {
    repeatVisits: RepeatVisit[];
};

export default function TableRowsRepeatVisit(props: Props) {
    const { t } = useContext(LanguageContext);
    return (
        <TableBody>
            {props.repeatVisits &&
                props.repeatVisits.map((item, index) => {
                    return (
                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" align="center" scope="row">
                                {index == 0
                                    ? item.visitTime + ` ${t('dashboard:text.time')}`
                                    : index == 6
                                    ? `> 6 ${t('dashboard:text.times')}`
                                    : item.visitTime + ` ${t('dashboard:text.times')}`}
                            </TableCell>
                            <TableCell align="center">{formatNumber.format(item.vehicleCount)}</TableCell>
                            <TableCell align="center">{formatNumber.format(item.percentage)}%</TableCell>
                        </TableRow>
                    );
                })}
        </TableBody>
    );
}
