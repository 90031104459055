import { Grid, Typography } from '@mui/material';
import { useContext } from 'react';
import { FieldArrayWithId, UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';
import LoadingCircular from '../../../../components/LoadingCircular';
import { Permit } from '../../../../models/permits/Permit';
import { UpsertPermitContext } from '../hooks/useUpsertPermit';
import UpsertPermitItemForm from './UpsertPermitItem/UpsertPermitItemForm';

export default function UpsertPermitItems() {
    const { t, isEdit, fieldsForm } = useContext(UpsertPermitContext);

    const { fields } = fieldsForm;

    return (
        <Grid xs={12} item container>
            {!isEdit && (
                <Typography variant="body1" fontWeight={500}>
                    {t('permits:text.newPermits')} ({fields.length})
                </Typography>
            )}
            {fields.length > 0 ? (
                <Grid xs={12} item mt={1}>
                    {fields.map((permit, index) => {
                        return (
                            <UpsertPermitItemForm
                                permitForm={permit}
                                key={permit.id}
                                indexForm={index}
                                fieldsForm={fieldsForm}
                            />
                        );
                    })}
                </Grid>
            ) : (
                <Grid xs={12} item mt={1}>
                    <LoadingCircular />
                </Grid>
            )}
        </Grid>
    );
}

export type PermitFormItem = FieldArrayWithId<
    {
        permits: Permit[];
    },
    'permits',
    'id'
>;

export type FormControl = UseFormReturn<
    {
        permits: Permit[];
    },
    any,
    undefined
>;

export type FormArrayControl = UseFieldArrayReturn<
    {
        permits: Permit[];
    },
    'permits',
    'id'
>;
