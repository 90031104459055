export interface Login {
    username: string
    password: string
    application?: Application
}

export interface ChangePassword {
    token?:string,
    id: string,
    password: string,
    oldPassword?: string
}

export enum Application { iPark, iHub }